import './commissions_table.css';
import React,{useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import {makeStyles} from '@material-ui/core/styles';
import Widget_count_elements from '../widget_count_elements/widget_count_elements'


function commission_treated(stat){
    return(
      <>
        {
         stat == 0
         ?<Button variant="contained" className="status_waiting">en cours</Button>
         :<Button variant="contained" className="status_success">traité</Button>
        }
      </>
  
    );
  }

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

const columns = [
    { id: 'Objet', label: "Objet d'appel", minWidth: 0 },
    { id: 'direction', label: "Direction", minWidth: 0 },
    { id: 'categorie', label: "Famille d'achat", minWidth: 0 },
    { id: 'limiteDate', label: "Date limite", minWidth: 0 },
    { id: 'openingDate', label: "Date d'ouverture des plis", minWidth: 0 },
    { id: 'treated', label: "Traitement", minWidth: 0 }
  ];
  


function Last_commissions({categories,data}) {

    const classes = useStyles();
    const [show_elemnts,setShowing_elements] = useState(false);


    function getCategorieById(id){
        var categorie="";
        categories.map((catg)=>{
          if(catg.id_category == id) categorie = catg.name;
        })
        return categorie;
    }



//////////////////////
/////////////////////////////////////////////////////////
///////////////////////////////////

  return (
    <>
      {
        show_elemnts!==true
          ?<Widget_count_elements setShowing_elements={setShowing_elements} elements_number={(data.count)} fontColor="rgb(242 150 129)" progress={data.progress} />
          :<TableContainer className="commissions_table" component={Paper}  onDoubleClick={() => setShowing_elements(false)} style={{cursor:'pointer'}} onTouchStartCapture={(e)=>{e.stopPropagation();setShowing_elements(false)}} >
            <Table className={classes.table} aria-label="sticky table">
                <TableHead> 
                <TableRow>
                    {
                        columns.map((column) => (
                            <TableCell key={column.id} align="left">{column.label}</TableCell>
                        ))
                    }
                </TableRow>
                </TableHead>
                <TableBody>
                {
                    (data.latest_commissions).length!=0
                    ? data.latest_commissions.map((commission,index1) => (
                        <TableRow key={commission.offer_id+"col"+index1}>
                            <TableCell align="left">{commission.object}</TableCell>
                            <TableCell align="left">{commission.direction}</TableCell>
                            <TableCell align="left">{getCategorieById(commission.purchase_family)}</TableCell>
                            <TableCell align="left">{(new Date(commission.limit_date)).toLocaleString()}</TableCell>
                            <TableCell align="left">{(new Date(commission.openOffer_date)).toLocaleString()}</TableCell>
                            <TableCell align="center">
                                {
                                    commission_treated(
                                      commission.validated_legal && commission.validated_financial && commission.validated_technical
                                       ?true:false
                                    )
                                }
                            </TableCell>
                        </TableRow>                
                    ))
                            
                    :<div style={{margin:"10px",fontSize:".8rem",width:'100%', textAlign:"center",alignContent:"center"}}>Aucune commission disponible</div>
                }
                </TableBody> 
            </Table>
        </TableContainer>
      }

      </>
  );
}

export default React.memo(Last_commissions);
