import {Navbar,Form,NavDropdown} from 'react-bootstrap';
import React, {useState,useEffect } from 'react';
import pa_logo from './PA_logo.jpeg';

import './navBar.css';


function NavBar() {

  const [info,setInfo]=useState(null);
  let dataUser = JSON.parse(localStorage.getItem('admin-info'))
 
  useEffect(()=>{
      setInfo(dataUser);
  },[]);


  function logout(){
    localStorage.clear();
    window.location.href="/";
  }

  return (
      <div id='myTopnav' className='service_topnav topnav' style={{width:"100%"}}>
        <Navbar bg="dark" variant="dark" style={{width:"100%",margin:'0px'}}>
        <div className="navbar_content_container">
            <div className="brand">
                <img src={ pa_logo } />
            </div>
            <div className="selection">
               <Form inline className='buttons'>
                  <div className='user_name_container' style={{width:'fit-content',display:'flex',marginRight:"25px",fontSize:"1rem"}}>
                      {info!=null?info.fullName:""}
                  </div>
                </Form>
            </div>
           
        </div>
        
      </Navbar>
    </div>


  );
}

export default NavBar;
