import React, { useState, useEffect } from 'react'

import './add_proposal.css'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import PaymentIcon from '@material-ui/icons/Payment';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import LabelIcon from '@material-ui/icons/Label';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DownloadIcon from '@mui/icons-material/Download';
import Fab from '@mui/material/Fab';
import { makeStyles } from '@material-ui/core/styles';

import { get_suppliers_by_category } from '../../../../services/for_allServices';
import { add_offer_call } from '../../../../services/purchase_service';
import Set_supplier from '../set_suppliers/set_supplier';



const useStyles = makeStyles((theme) => ({
    button: { margin: theme.spacing(1), },
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
}));

function setDateTime(date) {
    let day = date.toISOString();
    let day_comp = day.split(":");
    return day_comp[0] + ":" + day_comp[1];
}


function getFileName(event) {
    const name = event.target.files[0].name;
    const lastDot = name.lastIndexOf('.');

    const fileName = name.substring(0, lastDot);
    const ext = name.substring(lastDot + 1);
    return fileName + "." + ext;
}


function Add_proposal({ openAdd, handleCloseAdd, dateToday, offers, setOffers, profilInfo, update, setOffers_added, categories }) {

    const classes = useStyles();
    const [formData, setFormData] = useState(null);
    let user = JSON.parse(localStorage.getItem('user-info'));



    const [valide, setValid] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [nextStep, setNextStep] = useState(false);
    const [categorie, setcategorie] = useState(null);
    const [suppliers, setSuppliers] = useState([]);

    const [objectAdd, setObjectAdd] = useState(null);
    const [offer_dateAdd, setoffer_dateAdd] = useState(dateToday);
    const [limit_dateAdd, setlimit_dateAdd] = useState(null);
    const [openOffer_dateAdd, setopenOffer_dateAdd] = useState(null);

    const [estimationAdd, setEstimationAdd] = useState(null);
    const [directionAdd, setDirectionAdd] = useState(null);

    const [workBookAdd, setworkBookAdd] = useState(null);
    const [RCAdd, setRCAdd] = useState(null);
    const [complementDocsAdd, setcomplementDocsAdd] = useState(null);

    const [workBookFile, setworkBookFile] = useState("");
    const [rcFile, setrcFile] = useState("");
    const [complementDocsFile, setcomplementDocsFile] = useState("");

    const [workBookLoaded, setworkBookLoaded] = useState("");
    const [rcLoaded, setrcLoaded] = useState("");
    const [complementDocsLoaded, setcomplementDocsLoaded] = useState("");


    const [errorText, setErrorText] = useState("");

    function validated_elem() {
        return submitted && valide;
    }

    async function add_proposal() {
        setSubmitted(true);
        setValid(true);
        setErrorText("");
        //console.log(limit_dateAdd);
        if (objectAdd === null || limit_dateAdd === null || estimationAdd === null ||
            openOffer_dateAdd === null || directionAdd === null || categorie === null) {
            setErrorText("Champs invalides !");
            setValid(false);
        } else {
            if (workBookAdd === null || RCAdd === null || complementDocsAdd === null) {
                setErrorText("Importez tout Les Document !!");
                setValid(false);
            } else {
                let tmp_form = new FormData();
                tmp_form.append('id', user.id);
                tmp_form.append('tutorName', profilInfo.fullName);
                tmp_form.append('object', objectAdd);
                tmp_form.append('id_service', user.id);
                tmp_form.append('purchase_family', categorie);
                tmp_form.append('offer_date', dateToday);
                tmp_form.append('limit_date', limit_dateAdd);
                tmp_form.append('openOffer_date', openOffer_dateAdd);
                tmp_form.append('estimation', estimationAdd);
                tmp_form.append('direction', directionAdd);
                tmp_form.append('workBook', workBookAdd);
                tmp_form.append('rc', RCAdd);
                tmp_form.append('docsComplementary', complementDocsAdd);
                tmp_form.append('facturation', 0);

                setFormData(tmp_form);

                get_suppliers_by_category(categorie).then((response) => {
                    //console.log(response.data);
                    setSuppliers(response.data);
                    if (response.data.length == 0) {
                        setErrorText("Aucun fournisseur disponible !");
                        setValid(false);
                    } else setNextStep(true);
                }).catch((error) => {
                    //console.log(error.response);
                });
            }

        }
    }


    function upload_workBook(event) {
        setworkBookAdd(event.target.files[0]);
        setworkBookFile(getFileName(event));
        setworkBookLoaded(URL.createObjectURL(event.target.files[0]));
    }

    function upload_result(event) {
        setRCAdd(event.target.files[0]);
        setrcFile(getFileName(event));
        setrcLoaded(URL.createObjectURL(event.target.files[0]));
    }

    function upload_complementDocs(event) {
        setcomplementDocsAdd(event.target.files[0]);
        setcomplementDocsFile(getFileName(event));
        setcomplementDocsLoaded(URL.createObjectURL(event.target.files[0]));
    }

    function download(choice) {
        if (workBookLoaded != "" && choice == "workBook") {
            var fileLink = document.createElement('a');
            fileLink.href = workBookLoaded;
            fileLink.setAttribute('download', workBookFile);
            document.body.appendChild(fileLink);
            fileLink.click();
        } else {
            if (rcLoaded != "" && choice == "rc") {
                var fileLink = document.createElement('a');
                fileLink.href = rcLoaded;
                fileLink.setAttribute('download', rcFile);
                document.body.appendChild(fileLink);
                fileLink.click();
            } else {
                if (complementDocsLoaded != "" && choice == "docsComplementary") {
                    var fileLink = document.createElement('a');
                    fileLink.href = complementDocsLoaded;
                    fileLink.setAttribute('download', complementDocsFile);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                }
            }
        }
    }

    return (
        <Dialog fullWidth={true} maxWidth={'md'} open={openAdd} onClose={handleCloseAdd} aria-labelledby="form-dialog-title" className="update_porpose" >
            <DialogTitle id="form-dialog-title">Ajouter un appel d'offre</DialogTitle>
            <DialogContent>
                <div className="inputs_container">
                    <TextField label="Objet" className="input_form"
                        InputProps={{ startAdornment: (<InputAdornment position="start"> <LabelIcon className="my_icon" /> </InputAdornment>), }} onChange={(e) => setObjectAdd(e.target.value)}
                    />
                    <TextField label="Estimation (MAD) " type="number" className="input_form"
                        InputProps={{ startAdornment: (<InputAdornment position="start"> <PaymentIcon className="my_icon" /> </InputAdornment>), }} onChange={(e) => setEstimationAdd(e.target.value)}
                    />
                    <FormControl className="input_form">
                        <InputLabel>Famille D'achat</InputLabel>

                        <Select onChange={(e) => { setcategorie(e.target.value) }}>
                            <MenuItem value="null" disabled>
                                <em>None</em>
                            </MenuItem>
                            {
                                categories.map((categorie) => (
                                    <MenuItem key={categorie.id_category} value={categorie.id_category}>{categorie.name}</MenuItem>
                                ))
                            }
                        </Select>

                        <FormHelperText>choisissez votre Famille d'achat</FormHelperText>
                    </FormControl>
                </div>
                <div className="inputs_container">
                    <TextField
                        type="datetime-local"
                        className="input_form" label="Date offre" readOnly value={dateToday}
                        InputProps={{ startAdornment: (<InputAdornment position="start"> <EventAvailableIcon className="my_icon" /> </InputAdornment>), }}
                    />
                    <TextField
                        type="datetime-local"
                        className="input_form" label="Date limite" min={new Date()} inputProps={{ min: dateToday }}
                        InputProps={{ startAdornment: (<InputAdornment position="start"> <EventBusyIcon className="my_icon" /> </InputAdornment>) }} onChange={(e) => setlimit_dateAdd(e.target.value)}
                    />
                    {
                        (limit_dateAdd) != ""
                            ? <TextField
                                disabled={limit_dateAdd == null ? true : false}
                                type="datetime-local"
                                className="input_form" label="Date d'ouverture des plis" inputProps={{ min: setDateTime(new Date(limit_dateAdd)) }}
                                InputProps={{ startAdornment: (<InputAdornment position="start"> <EventBusyIcon className="my_icon" /> </InputAdornment>) }} onChange={(e) => setopenOffer_dateAdd(e.target.value)}
                            />
                            : <TextField
                                disabled={true}
                                type="datetime-local"
                                className="input_form" label="Date d'ouverture des plis"
                                InputProps={{ startAdornment: (<InputAdornment position="start"> <EventBusyIcon className="my_icon" /> </InputAdornment>) }} onChange={(e) => setopenOffer_dateAdd(e.target.value)}
                            />
                    }


                </div>
                <div className="inputs_container">
                    <FormControl className="input_form">
                        <InputLabel>Direction</InputLabel>
                        <Select onChange={(e) => { setDirectionAdd(e.target.value) }}>
                            <MenuItem value="null" disabled>
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value="Direction financiere">Direction financiere</MenuItem>
                            <MenuItem value="Direction juridique">Direction juridique</MenuItem>
                            <MenuItem value="Direction informatique">Direction informatique </MenuItem>
                        </Select>
                        <FormHelperText>choisissez votre Direction</FormHelperText>
                    </FormControl>
                </div>

                <div className='inputs_container'>
                    <div className='documentsDownload'>
                        <label htmlFor="workBook" style={{ marginRight: '7px' }}>
                            <Fab size="small" component='span' ><CloudUploadIcon className="my_icon" /> </Fab>
                            <input accept="application/*" className={classes.input} name="workBook" id="workBook" multiple type="file" onChange={(e) => upload_workBook(e)} />
                        </label>
                        {
                            workBookFile != ""
                                ? <Fab size="small" onClick={() => download('workBook')} ><DownloadIcon className="my_icon" /> </Fab>
                                : <Fab size="small" className="disabled_button" ><DownloadIcon className="my_icon" /></Fab>
                        }
                        <span className='documentsTitle'>Cahier De Charge </span>
                    </div>

                    <div className='documentsDownload'>
                        <label htmlFor="rc" style={{ marginRight: '7px' }}>
                            <Fab size="small" component='span'><CloudUploadIcon className="my_icon" /> </Fab>
                            <input accept="application/*" className={classes.input} name="rc" id="rc" multiple type="file" onChange={(e) => upload_result(e)} />
                        </label>
                        {
                            rcFile != ""
                                ? <Fab size="small" onClick={() => download('rc')} ><DownloadIcon className="my_icon" /> </Fab>
                                : <Fab size="small" className="disabled_button" ><DownloadIcon className="my_icon" /></Fab>
                        }
                        <span className='documentsTitle'>RC</span>
                    </div>

                    <div className='documentsDownload'>
                        <label htmlFor="complementDocs" style={{ marginRight: '7px' }}>
                            <Fab size="small" component='span' ><CloudUploadIcon className="my_icon" /> </Fab>
                            <input accept="application/*" className={classes.input} name="complementDocs" id="complementDocs" multiple type="file" onChange={(e) => upload_complementDocs(e)} />
                        </label>
                        {
                            complementDocsFile != ""
                                ? <Fab size="small" onClick={() => download('docsComplementary')} ><DownloadIcon className="my_icon" /> </Fab>
                                : <Fab size="small" className="disabled_button" ><DownloadIcon className="my_icon" /></Fab>
                        }
                        <span className='documentsTitle'> Docs complémentaires </span>
                    </div>
                </div>
                
            </DialogContent>
            <DialogActions>
                <div className='dialogBottom'>
                    <div>
                        {
                            validated_elem()
                                ? <></>
                                : <p className={submitted ? "col-md-10 error" : "col-md-10 valid-champ"}>
                                    {submitted ? errorText : ""}
                                </p>
                        }
                    </div>
                    <div>
                        <Button variant="contained" className="submit_but cancel_but" startIcon={<CancelIcon />} onClick={handleCloseAdd} >
                            Annuler
                        </Button>
                        <Button variant="contained" className="submit_but valide_but" startIcon={<AddIcon />} onClick={add_proposal} >
                            Suivant
                        </Button>
                    </div>
                </div>

            </DialogActions>
            {
                nextStep == true ?
                    <Set_supplier suppliersEdit={""} handleClose={() => setNextStep(false)} handleCloseAdd={handleCloseAdd} update={update} open={nextStep}
                        formData={formData} setFormData={setFormData} offers={offers} setOffers={setOffers}
                        suppliers={suppliers} setOffers_added={setOffers_added} />
                    : <></>
            }


        </Dialog>
    )
}

export default Add_proposal
