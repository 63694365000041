import "../../assets/css/docs_modal.css"
import React, { useState } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Fab from '@mui/material/Fab';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DownloadIcon from '@mui/icons-material/Download';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { download_doc, import_doc } from "../../services/for_allServices";
import State_details from "./state_details";


function setStatus(stat){
    return(
      <>
        {
         stat == "" || stat == "a validé"
         ?<Button variant="contained" className="status_waiting">{stat!=""?stat:"en cours"}</Button>
         :stat == "non validé"
            ?<Button variant="contained" className="status_deny">{"non validé"}</Button>
            :<Button variant="contained" className="status_success">{stat}</Button>
        }
      </>
  
    );
  }

  
const useStyles = makeStyles((theme) => ({
    button: { margin: theme.spacing(1),},
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
  }));


function Docs_modal({docs,setDocs,wf,wf_identifier}){
    let user = JSON.parse(localStorage.getItem('user-info'));

    const classes = useStyles();
    const [waiting, setWaiting] = useState(false);
    const [view_reason_modal, setview_reason_modal] = useState(null);
    

    function importDoc(doc_id,doc_name,e){
        setWaiting(true);
        let formData = new FormData();
        formData.append('workflow',wf);
        formData.append('wf_identifier',wf_identifier);
        formData.append('doc_id',doc_id);
        formData.append('doc_name',doc_name);
        formData.append('doc_content',e.target.files[0]);
        import_doc(formData).then((response)=>{
            setDocs(response.data);
            setWaiting(false);
        }).catch((error)=>{
            //console.log(error.response);
            setWaiting(false);
        })
    }

    function downloadDoc(doc_id,doc_name){
        const formData = new FormData();
        formData.append('workflow',wf);
        formData.append('wf_identifier',wf_identifier);
        formData.append('doc_id',doc_id);
        download_doc(formData).then((response) => {
            var my_blob = new Blob([response.data]);
            var fileURL = window.URL.createObjectURL(my_blob);
            var fileLink = document.createElement('a');
            //console.log(my_blob);
            fileLink.href = fileURL;
            let file_extension = '';
            const disposition = response.headers['content-disposition'];
            if (disposition && disposition.includes('filename')) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    file_extension = matches[1].replace(/['"]/g, '').split(".")[1];
                }
            }
            fileLink.setAttribute('download', wf_identifier+"_"+doc_name+"."+file_extension);
            document.body.appendChild(fileLink);
            fileLink.click();
        }).catch((error) => {
            //console.log(error.response);
        }) 
    }



    return (
        <Dialog  className='docs_modal' fullWidth={true} maxWidth="sm"  open={docs?true:false} onClose={() => {setDocs(null)}} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Documents</DialogTitle>
                <DialogContent>
                    {
                        docs.map((doc,id) => {
                            return(
                                <div style={{width:"95%",display:'flex',margin:"0 auto 13px auto",justifyContent:"space-between"}}>
                                    <span className='documentsTitle' style={{fontSize:"1.1rem",width:'100%'}} >{doc.doc_name}</span>
                                    {
                                        doc.doc_type==="imported"
                                        ?<div className='documentsDownload'>
                                                <label htmlFor={"doc_"+doc.doc_name} style={{marginRight:"7px"}} className={!(doc.permission).find(element => element === (user.role).toString())?"disabled_button":''}> 
                                                <Fab size="small" color="default" component="span">
                                                    <CloudUploadIcon className="my_icon" />
                                                </Fab>
                                                <input accept="application/*" onChange={(e)=>importDoc(doc.doc_id,doc.doc_name,e)} className={classes.input} name={"doc_"+doc.doc_name}
                                                 id={"doc_"+doc.doc_name} multiple type="file"   />
                                                </label>
                                                <Fab size="small" onClick={()=>downloadDoc(doc.doc_id,doc.doc_name)} className={doc.doc_content===""?"disabled_button":''} color="default"><DownloadIcon className="my_icon" /></Fab>
                                                <span style={{marginLeft:"10px"}} className='request_state_container'>
                                                    {setStatus(doc.state)}
                                                    <>
                                                        {
                                                            doc.state === "non validé"
                                                            ?<span className='view_reason_button' onClick={() => {setview_reason_modal(doc.validation);} }>Motif</span>
                                                            :<></>
                                                        }
                                                    </>
                                                </span>
                                            </div>
                                        :<div className='documentsDownload'>
                                            <Fab size="small"  color="default"><ContentPasteIcon className="my_icon" /></Fab>
                                            <div style={{marginLeft:"10px"}} className='request_state_container'>
                                                {setStatus(doc.state)}
                                                <>
                                                        {
                                                            doc.state === "non validé"
                                                            ?<span className='view_reason_button' onClick={() => setview_reason_modal(doc.validation) }>Motif</span>
                                                            :<></>
                                                        }
                                                    </>
                                            </div>
                                        </div>
                                    }
                                </div>
                            )
                        })
                    }
                </DialogContent>
                {
                    view_reason_modal!==null
                    ?<State_details doc_validation={view_reason_modal} setdoc_validation={setview_reason_modal} />
                    :<></>
                }
                <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={waiting}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
              </Dialog>
    )
}

export default Docs_modal
