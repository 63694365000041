import React from 'react'
import {useState,useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button";
import DoneIcon from '@mui/icons-material/Done';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import { set_supplier_order_rating } from '../../../../services/purchase_service';


function Rating_modal({ open_rating_modal, setOpen_rating_modal,set_supplier_rating_score_result }) {

    const [wait_submitting,setwait_submitting] = useState(false);

    const [technical_score,settechnical_score] = useState(0);
    const [finance_score,setfinance_score] = useState(0);

    useEffect(()=>{
        let tmp_data = open_rating_modal;
        if(tmp_data.ratings){
            let rating_tmp = JSON.parse(tmp_data.ratings);
            settechnical_score(rating_tmp.technical_score)
            setfinance_score(rating_tmp.finance_score);
        }
    },[]);

    async function submit_supplier_ratings_scores(){
        //if(technical_score == 0 || finance_score == 0) //console.log("empty !");
       // else{
            let tmp_rating_score = parseFloat((technical_score+finance_score)/2).toFixed(2);
            let data = {
                "technical_score" : technical_score,
                "finance_score" : finance_score
            }
            //console.log(open_rating_modal);
            let formdata = new FormData();
            formdata.append("request_code",open_rating_modal.request_code);
            formdata.append("id_supplier",open_rating_modal.id_supplier);
            formdata.append("ratings",JSON.stringify(data));
            formdata.append("rating_score",tmp_rating_score);
            //console.log(parseFloat((technical_score+finance_score)/2).toFixed(2));
            setwait_submitting(true);
            await set_supplier_order_rating(formdata).then( async (response)=>{
                //console.log(response.data);
                await setwait_submitting(false);
                await set_supplier_rating_score_result(open_rating_modal.id,JSON.stringify(data),tmp_rating_score);
                await setOpen_rating_modal(null);
            }).catch((error)=>{
                setwait_submitting(false);
                //console.log(error.response);
            })
            // }
    }

    return (
        <Dialog className="data_complements_container" fullWidth={true} maxWidth={"md"} open={open_rating_modal !== null ? true : false} onClose={() => setOpen_rating_modal(null)} >
            <DialogTitle id="form-dialog-title">Ratings</DialogTitle>
            <DialogContent>
                <div class="ratings_container_modal_content">
                    <div class="rating_score_container">
                        <div>Score technique</div>
                        <Rating name="customized-10" precision={1} max={5} value={technical_score} defaultValue={technical_score} onChange={(event,newValue)=>settechnical_score(newValue)}/>
                        <span class="rating_score">{technical_score}/5</span>
                    </div>
                    <div class="rating_score_container">
                        <div>Score financier</div>
                        <Rating name="customized-10" precision={1} max={5} value={finance_score} defaultValue={finance_score} onChange={(event,newValue)=>setfinance_score(newValue)} />
                        <span class="rating_score">{finance_score}/5</span>
                    </div>
                </div>

                <div className="dialogBottom">
                    {/* <div>
                        <Button className="discussion_but submit_but"
                            variant="contained" startIcon={<DynamicFormIcon className="my_icon"/>} > Evaluation
                        </Button>  
                    </div>  */} 

                    <div>
                        <Button className="valide_but submit_but" onClick={submit_supplier_ratings_scores}
                            variant="contained" startIcon={<DoneIcon className="my_icon"/>} > Soumettre
                        </Button>  
                    </div>  
                </div>

            </DialogContent>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={wait_submitting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Dialog>
    )
}

export default Rating_modal;
