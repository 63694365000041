import './authentication_page.css'
import React,{useState} from 'react';
import pa_logo from './PA_logo.jpeg';
import { Row,InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import EmailIcon from '@mui/icons-material/Email';
import Button from '@material-ui/core/Button';
import CircularProgress from '@mui/material/CircularProgress';


import {login_request } from '../../services/for_allServices';



function Authentication_page() {
    
    const [wait_submitting,setWait_submitting] = useState(false);

    const [email,setEmail] = useState("");
    const [pwd,setPwd] = useState("");
    const [rc,setRC] = useState("");

    const [need_rc,setneed_rc] = useState(false);

    const [showPassword,setShowPassword] = useState(false);

    const [errorText,setErrorText] = useState(null);
    const [errorEmail,setErrorEmail] = useState(null);


    //////////////////////////////
    /////////////email validation
    function invalid_email_pattern(email){
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if(email!==""){
            if (!pattern.test(email)) {
                return true ;
            }else{
                return false ;
            }
        }else return true;
    }


    function submit() {
        setErrorText(null);
        setErrorEmail(null);
        if(email==="" || pwd==="" || (need_rc && rc==="")){
            setErrorText("Champs invalid !");
        }else{
            if(invalid_email_pattern(email)){
                setErrorEmail("Email invalid !");
            }else{
                setWait_submitting(true);
                let formData = new FormData();
                formData.append('email',email);
                formData.append('pwd',pwd);                
                formData.append('rc',rc?rc:"null");                
                login_request(formData).then((response)=> {
                    if(response.data==="rc"){
                        setWait_submitting(false);
                        setneed_rc(true);
                    }else{
                       if(response.data.admin){
                            localStorage.setItem("admin-info",JSON.stringify(response.data))
                            window.location = "/gadmin";
                        }
                        else{
                            localStorage.setItem("user-info",JSON.stringify(response.data))
                            if(response.data.role===0){
                                window.location = "/fournisseur";
                            }else if(response.data.role===1){
                                window.location = "/serviceAchat";
                            }
                            else if(response.data.role===2){
                                window.location = "/serviceMetier";
                            }
                            else if(response.data.role===3){
                                window.location = "/serviceFinance";
                            }
                            else if(response.data.role===4){
                                window.location = "/auditeur";
                            }
                            else if(response.data.role===10){
                                window.location = "/eadmin";
                            }
                        } 
                    }
                    /**/
                }).catch((error)=>{
                    setErrorText("Informations incorrectes !");
                    setWait_submitting(false);
                    setneed_rc(false);
                    setRC("");
                    //console.log(error.response)
                });
            }
        }
    }
  
  return (
    <div className="authentication_page_container">
        <div className="logo_container">
             <img src={pa_logo} className="brand" />
        </div>
        <div className="authentication_page_body" style={{background:"white"}}>
            <h2>Authentification</h2>
            <div className="form" >
                <Row className="mb-12">
                    {
                        <p className={errorText || errorEmail ? "col-md-12 error" : "col-md-12 valid-champ"}>
                                {errorText || errorEmail}
                        </p>
                    }
                   
                    {
                        need_rc===false
                        ?<>
                        <Form.Group className="col-md-12">
                            <label>Email</label>
                            <InputGroup hasValidation>
                            <Form.Control
                                    required
                                    type="email"
                                    placeholder="E-mail *"
                                    value={email}
                                    onChange={ e =>  {setEmail(e.target.value);}}
                                    className={errorText || errorEmail ? "is-invalid":""}
                                />
                                <InputGroup.Text  className={ errorText || errorEmail ? "invalid-inputgroup" : "valid-inputgroup"} > 
                                    <EmailIcon className='my_icon' />
                                </InputGroup.Text>        
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="col-md-12">
                            <label>Mot de passe</label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    required
                                    autoComplete="new-password"
                                    type={!showPassword?"password":"text"}
                                    placeholder="Mot de passe *"
                                    value={pwd}
                                    onChange={ e =>  {setPwd(e.target.value);}}
                                    className={ errorText ? "is-invalid":""}
                                />
                                <InputGroup.Text className={ errorText ? "invalid-inputgroup" : ""} style={{cursor:"pointer"}}  onClick={()=>setShowPassword(!showPassword)}> 
                                    {!showPassword?<VisibilityIcon className='my_icon' />:<VisibilityOff className='my_icon' />}
                                </InputGroup.Text>        
                            </InputGroup>
                        </Form.Group>
                        </>
                        :<Form.Group className="col-md-12">
                            <label>Registre du commerce</label>
                            <Form.Control
                                    required
                                    autoComplete="new-password"
                                    type="text"
                                    placeholder="Registre du commerce *"
                                    value={rc}
                                    onChange={ e =>  {setRC(e.target.value)}}
                                    className={ errorText && rc==="" ? "is-invalid":""}
                                />
                        </Form.Group>
                    }

                </Row> 
                <Row className='form_footer'>
                    <Button variant="contained" className={wait_submitting?"submit_but disabled_button":"submit_but"} onClick={submit} >
                            {wait_submitting?<CircularProgress className='my_icon' color="inherit" />:"Authentifier"}
                    </Button>
                </Row>
                
                
            </div>
        </div>
    </div>
  );
}

export default Authentication_page;
