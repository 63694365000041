import './rss_widget.css'
import React,{useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/core/styles';

import Loading_animation_view from '../../../loading_animation_view/loading_animation_view'


const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    container: {
      maxHeight: 440,
    },
  });
  


const RSS_widget = ({news}) => {
  
    return (
      <>
        <div className="all_news_container">
            {
               news!=null
               ?news.map((elem,id)=>(
                   <div className="news_container" key={elem.getElementsByTagName("title")[0].innerHTML} onClick={()=>window.open(elem.getElementsByTagName("link")[0].innerHTML, '_blank')}>
                        <div className="img_news_container" style={{backgroundImage: `url(${elem.getElementsByTagName("media:content")[0].getAttribute('url')})`}}>
                        </div>
                        <div className="title_description_container">
                            <div className="news_title">{elem.getElementsByTagName("title")[0].innerHTML}</div>
                            <div className="news_description">{elem.getElementsByTagName("description")[0].innerHTML}</div>
                            <div className="news_released_time">{(new Date(elem.getElementsByTagName("pubDate")[0].innerHTML)).toLocaleString()}</div>
                        </div>
                    </div>
               ))
               :<Loading_animation_view />
            }
            
        </div>
      </>
    );
  }


  export default React.memo(RSS_widget)