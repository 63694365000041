import './add_contract_modal.css'
import React, { useEffect, useState} from 'react'
import Button from '@material-ui/core/Button';
import swal from 'sweetalert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@mui/icons-material/Add';
import InputAdornment from '@material-ui/core/InputAdornment';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import RemoveIcon from '@mui/icons-material/Remove';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { add_contract } from '../../../../services/company_admin_service';
import { Fab } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    button: { margin: theme.spacing(1),},
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    }
  }));

function Add_contract_modal({add_contract_modal,setadd_contract_modal,setcontract_added,update_contract_modal,setupdate_contract_modal}){

    const classes = useStyles();

    const [errorText,setErrorText] = useState("");

    const [contract,setcontract]=useState(update_contract_modal?update_contract_modal[0].contract_type:'');
    const [subTypes,setSubTypes]=useState(update_contract_modal?update_contract_modal:[null]);

    const [waiting,setWaiting] = useState(false)

    useEffect(()=>{
      if(update_contract_modal){
        let tmp_arr = [];
        update_contract_modal.map((subtype)=>{
          tmp_arr.push(subtype.contract_subtype);
        })
        setSubTypes(tmp_arr);
      }
    },[])

    function addsubtype(){
      let tmp_arr = [ ...subTypes ];
      tmp_arr.push(null);
      setSubTypes(tmp_arr);
    }

    function removesubtype(id){
      //console.log(id,subTypes);
      let tmp_arr = [ ...subTypes ];
      tmp_arr.splice(id,1);
      setSubTypes(tmp_arr);
    }

    
    function setsubtype_contract(value,id){
      let tmp_arr = [ ...subTypes ];
      tmp_arr[id] = value;
      setSubTypes(tmp_arr);
    }

    function submit(){
        setErrorText('');
        if(contract==="" || subTypes[0]==null) setErrorText('Champs invalides !');
        else{
            swal({
                title: "Etes-vous sûr ?",
                text: "!!!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then((willDelete) => {
              if (willDelete) {
                setWaiting(true);
                let formData = new FormData();
                if(update_contract_modal){
                  formData.append('update_contract',contract)
                  formData.append('contract_prev_type',update_contract_modal[0].contract_type);
                } 
                formData.append('contract_type',contract);
                formData.append('contract_subtype',subTypes);
                add_contract(formData).then((response) => {
                    setWaiting(false);
                    if(!update_contract_modal){
                      swal("Le contrat a été ajoutée avec succès !", {
                        icon: "success",
                        buttons: true,
                        }).then((willDelete) => {
                            if (willDelete) {
            
                            }
                        })
                      }else{
                        swal("Le contrat a été modifié avec succès !", {
                          icon: "success",
                          buttons: true,
                          }).then((willDelete) => {
                              if (willDelete) {
              
                              }
                          })
                          setupdate_contract_modal(null)
                      }
                        setadd_contract_modal(false);
                        setcontract_added(response.data);
                }).catch((error) => {
                    //console.log(error.response);
                    let error_data = error.response.data;
                    if(error_data.errors){
                        if(error_data.errors.contract_type) setErrorText("Contrat existant !");
                    }
                    setWaiting(false);
                });
              }
            })
        }
    }

    return (
      <Dialog fullWidth={true} maxWidth={'md'} open={add_contract_modal} onClose={()=>{setadd_contract_modal(false);setupdate_contract_modal(null)}} aria-labelledby="form-dialog-title" >
        <DialogTitle id="form-dialog-title">
          {update_contract_modal?"Modifier votre contrat":"Ajouter votre contrat"}
          </DialogTitle>
        <DialogContent>
          <div className="add_contract_modal_infos">
                <TextField className={classes.margin} label="Contrat" style={{width:"250px"}} value={contract}
                    InputProps={{ startAdornment: ( <InputAdornment position="start"> <BookmarkIcon  /> </InputAdornment> )}} 
                    onChange={(e)=>setcontract(e.target.value)} />

                <div className='subTypes_input_container'>
                    {
                      subTypes.map((subType,id)=>(
                        <div className='subType_input' key={id}>
                          <TextField className={classes.margin} label={"Sous type "+(id+1)} style={{width:"250px",marginTop:'8px'}} value={subType}
                                InputProps={{ startAdornment: ( <InputAdornment position="start"> <BookmarkIcon  /> </InputAdornment> ) }} onChange={(e)=>setsubtype_contract(e.target.value,id)} />
                          {
                            id!==0 && (update_contract_modal?id>update_contract_modal.length:true)?
                              <div style={{display:'flex',alignItems:'center',marginLeft:'9px',paddingTop:'20px'}}>
                                <RemoveIcon className="closeSharpIcon" style={{background:'#fcf5f5'}} onClick={()=>removesubtype(id)} />
                              </div>:<></>
                            }
                        </div>
                        
                      ))
                    }
                    <Fab size="small" color="Info" style={{marginTop:'11px',background:'#4fc3f7',color:'white'}} onClick={addsubtype}><AddIcon className="my_icon"  /></Fab>
                </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className='dialogBottom'>
            <div>
                {
                    errorText === ""
                    ?<></>
                    :<p className={errorText ? "col-md-10 error" : "col-md-10 valid-champ"}>
                            {errorText}
                        </p>
                }
            </div>
            <div>
                <Button variant="contained" className="submit_but cancel_but" startIcon={<CancelIcon />} onClick={()=>{setadd_contract_modal(false);setupdate_contract_modal(null)}} >
                    Annuler
                </Button>
                <Button variant="contained" className="submit_but valide_but" startIcon={<AddIcon />} onClick={submit} >
                    {update_contract_modal?"Modifier":"Ajouter"}
                </Button>  
            </div>
               
          </div>
        </DialogActions>

        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={waiting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>

      </Dialog>
    )
}

export default Add_contract_modal
