import './files_table.css';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

const columns = [
    { id: 'number', label: "Type de fichier", minWidth: 0,align: 'left'  },
    { id: 'name', label: "Nom de fichier", minWidth: 0 ,align: 'left' },
    { id: 'size', label: "Taille de fichier", minWidth: 0,align: 'left'  },
    { id: 'created', label: "Date d'ajout", minWidth: 0,align: 'left'  },
 ];
  

 function bytesToSize(bytes) {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes == 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}


function Files_table({files_data}) {

  const classes = useStyles();
  return (
    <>
    <TableContainer className="files_table_container" component={Paper}>
            <Table className={classes.table} aria-label="sticky table">
                <TableHead>
                <TableRow>
                    {
                        columns.map((column) => (
                            <TableCell key={column.id} align={column.align}>{column.label}</TableCell>
                        ))
                    }
                </TableRow>
                </TableHead>
                <TableBody>
                {
                    files_data.length!==0
                    ?
                    <>
                        {
                          files_data.map((file,id) => (
                             <TableRow key={"file_col"+id}>
                                    <TableCell align="left">{file.file_type}</TableCell>
                                    <TableCell align="left">{file.file_name}</TableCell>
                                    <TableCell align="center">
                                        {bytesToSize(file.file_size)}
                                    </TableCell>
                                    <TableCell align="center">
                                        {(new Date(file.created_at)).toLocaleString().split(/[,| ]/)[0]}
                                    </TableCell>
                                </TableRow>                
                            ))
                        }
                    </>
                    :<div style={{margin:"10px",fontSize:".8rem",width:'100%', textAlign:"center",alignContent:"center"}}>Aucun fichier déposer</div>
                }
                </TableBody> 
            </Table>
        </TableContainer>
    </>
  );
}

export default Files_table;
