
import './set_another_openingDate_view.css'

import React, { useState,useEffect} from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CancelIcon from '@material-ui/icons/Cancel';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import DialogTitle from '@material-ui/core/DialogTitle';

import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import SendIcon from '@material-ui/icons/Send';
import {makeStyles} from '@material-ui/core/styles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { update_commission } from '../../../../services/commission_service';





const useStyles = makeStyles((theme) => ({
    button: { margin: theme.spacing(1),},
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
  }));

function setDateTime(date){
    let day = date.toISOString();
    let day_comp = day.split(":");
    return day_comp[0]+":"+day_comp[1];
}
  

function Set_another_openingDate({view_propose_openingDate_modal,setview_propose_openingDate_modal,offers_commissions,index,setOffer_commission}){

    const classes = useStyles();

    const [valide, setValid] = useState(true);
    const [submitted, setSubmitted] = useState(false);    

    const [new_proposed_date,setNew_proposed_date]=useState("");

    const [waiting, setWaiting] = useState(false);
    const [errorText,setErrorText] = useState("");


    function validated_elem(){
        return submitted && valide;
    }

    function propose_nex_openingdate() {  
        setSubmitted(true);
        setValid(true);
        setErrorText("");
        if(new_proposed_date==""){
            setErrorText("Champs invalides !");
            setValid(false);
        }else{
            if((new Date(view_propose_openingDate_modal.proposed_meet_date1)).getTime() > (new Date(new_proposed_date)).getTime()){
                setErrorText("La date proposée est inférieure à la date d'ouverture !");
                setValid(false);
            }else{
                setWaiting(true);
                let formData = new FormData();
                formData.append("proposed_meet_date2",new_proposed_date);
                update_commission(view_propose_openingDate_modal.id,formData).then(response=>{
                    setWaiting(false);
                    let tmp_commissions = [...offers_commissions];
                    tmp_commissions[index]=response.data;
                    setOffer_commission(tmp_commissions);
                    setview_propose_openingDate_modal(null);
                }).catch((error)=>{
                    setWaiting(false);
                    //console.log(error.response);
                })
            }
        }
    }

    return (
        <Dialog className="dates_proposition_dialog" fullWidth={true} maxWidth="md" open={view_propose_openingDate_modal!=null?true:false} onClose={()=>{setErrorText("");setview_propose_openingDate_modal(null)}} aria-labelledby="form-dialog-title" >            
            <DialogTitle id="form-dialog-title">Voulez-vous changer la date d'ouverture des plis ? </DialogTitle>
            <DialogContent>
                <div className="plus_details_dialog_container">
                        <TextField
                            type="text"
                            className="header_form disabled_button" label="Ancienne" value={(new Date(view_propose_openingDate_modal.proposed_meet_date1)).toLocaleString()}
                            InputProps={{ startAdornment: ( <InputAdornment position="start"> <EventAvailableIcon className="my_icon" /> </InputAdornment> )}}
                       />
                        <TextField
                            type="datetime-local"
                            className="header_form" label="Nouvelle" min={new Date()} inputProps={{min: setDateTime(new Date(view_propose_openingDate_modal.proposed_meet_date1))}}
                            InputProps={{ startAdornment: ( <InputAdornment position="start"> <EventAvailableIcon className="my_icon"  /> </InputAdornment> )}} onChange={(e)=>setNew_proposed_date(e.target.value)}
                        />
                </div>
            </DialogContent>
            <DialogActions>
            <div className='dialogBottom'>
                <div>
                    {
                        validated_elem() 
                        ?<></>
                        :<p className={submitted ? "col-md-10 error" : "col-md-10 valid-champ"}>
                                {submitted ? errorText : ""}
                            </p>
                    }
                </div>
                <div>
                   <Button variant="contained" className="submit_but cancel_but" startIcon={<CancelIcon className="my_icon" />} onClick={()=>{setErrorText("");setview_propose_openingDate_modal(null)}} >
                        Annuler
                    </Button>

                    <Button variant="contained" className="submit_but valide_but" startIcon={<SendIcon className="my_icon" />} onClick={propose_nex_openingdate} >
                        Envoyer
                    </Button>
                
                </div>
            </div>      
                
            </DialogActions>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waiting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Dialog>
    )
}

export default Set_another_openingDate
