import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from "@material-ui/core/Input";

import Loading_animation_view from '../../../loading_animation_view/loading_animation_view';
import { count_total_amount_byState } from "../../../../services/supplier_service";
import Widget_count_elements from "../widget_count_elements/widget_count_elements";



const useStyles = makeStyles((theme) => ({
  button: { margin: theme.spacing(1),},
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    width: '100%',
  },
  input: {
    display: 'none',
  },
  container: {
    maxHeight: 440,
  },
  select: {
    "&:focus": {
      backgroundColor: "white"
    }
  },
  selectInput: {
    "&:hover:not($disabled):not($focused):not($error):before": {
      borderBottomWidth: 0
    },
    "&:after": {
      borderBottomWidth: 0
    }
  },
  disabled: {},
  focused: {},
  error: {}
}));



const columns = [
  { id: 'code', numeric: true, disablePadding: false, label: 'Code de demande',align:"left" },
  { id: 'fullName', numeric: true, disablePadding: false, label: "Demandeur",align:"left" },
  { id: 'product_number', numeric: true, disablePadding: false, label: 'Nombre des articles',align:"left" },
  { id: 'fullName', numeric: true, disablePadding: false, label: "Total",align:"left" },
  { id: 'desired_delivery_date', numeric: true, disablePadding: false, label: 'Date de livraison souhaitée' ,align:"left"},
  { id: 'delivery_date', numeric: true, disablePadding: false, label: 'Date de livraison',align:"left" },
  { id: 'state', numeric: true, disablePadding: false, label: 'Status de la commande',align:"center" },
];
  
  


function state(request_state){
  return(
    <>
      {
       request_state === null
       ?<Button variant="contained" className="status_waiting">En cours</Button>
       :request_state === "refusée" || request_state === "validation refusée" || request_state === "non retenue" || request_state === "refusée par le fournisseur"  || request_state === "non conforme" || request_state === "annulée"
          ?<Button variant="contained" className="status_deny">{request_state}</Button>
          :request_state === "sous réserve de validation"
            ?<Button variant="contained" className="status_waiting">{request_state}</Button>
            :<Button variant="contained" className="status_success">{request_state}</Button>
      }
    </>

  );
}



  function Requests({requests}){
    
    return(
        <>
        {
            requests.map((row,idx) => (
                <TableRow tabIndex={-1} key={idx}>
                    <TableCell align="left" component="th" scope="row">
                              {row.request_infos[0].request_code}
                            </TableCell>
                            <TableCell align="left">{row.request_infos[0].requester_firstName+" "+row.request_infos[0].requester_lastName}</TableCell>
                            <TableCell align="left">{row.request_products}</TableCell>
                            <TableCell align="left">{row.request_total+" MAD"}</TableCell>
                            <TableCell align="left">
                                {(new Date(row.request_infos[0].delivery_supposedDate)).toLocaleString().split(/[,| ]/)[0]}
                            </TableCell>
                            <TableCell align="left">
                                {
                                    row.request_infos[0].delivery_date?(new Date(row.request_infos[0].delivery_date)).toLocaleString().split(/[,| ]/)[0]
                                    :'Pas encore'
                                
                                }
                            </TableCell>
                            <TableCell align="center">
                                <div className='request_state_container'>
                                    {state(row.request_infos[0].order_state)}
                                </div>
                            </TableCell>
                </TableRow>               
             ))
        }
        </>
    );
}


function Orders_totalAmount_byState({id_supplier,time_range}){
  const classes = useStyles();
  const selectInputClasses = {
    root: classes.selectInput,
    disabled: classes.disabled,
    focused: classes.focused,
    error: classes.error
  };

  const [show_elemnts,setShowing_elements] = useState(false);
  const [selected_state,setselected_state]=useState("all");
  const [content_charged,setContent_charged]=useState(false);
  const [request_type,setRequest_type]=useState("avec_catalogue");
  const [data,setData]=useState(null);


  useEffect(()=>{
    setContent_charged(false);
    setData(null);
    setShowing_elements(false);
    setselected_state("all");
    count_total_amount_byState(id_supplier,time_range,request_type,"all").then((response)=>{
      console.log(response.data);
        setData(response.data);
        setContent_charged(true);
    }).catch((error)=>{
        console.log(error.response);
    })

  },[request_type])

  function set_state_to_display(value){
    setselected_state(value);
    setContent_charged(false);
    setData(null);
    setShowing_elements(false);
    count_total_amount_byState(id_supplier,time_range,request_type,value).then((response)=>{
        setData(response.data);
        setContent_charged(true);
    }).catch((error)=>{
        //console.log(error.response);
    })
  }
      

  return (
      <div className="charts_of_bills_count_byState_container">
          <div className="bills_count_byState_state" style={{justifyContent:'space-between'}}>
          <Tooltip title="source" placement="left">
              <span>
              <FormControl variant="standard" className="header_form">
                  <Select required value={request_type} inputProps={{ classes: { select: classes.select } }}
                          input={<Input classes={selectInputClasses} />}
                          onChange={e => {setRequest_type(e.target.value) }} >
                          <MenuItem value={"avec_catalogue"} >{"Avec catalogue"}</MenuItem>
                          <MenuItem value={"sans_catalogue"} >{"Sans catalogue"}</MenuItem>
                  </Select>
              </FormControl>
              </span>
          </Tooltip>
           <Tooltip title="Statut de la demande" placement="left">
              <span>
                <FormControl variant="standard" className="header_form" >
                  <Select required value={selected_state} 
                        onChange={e => {set_state_to_display(e.target.value) }}
                        input={<Input classes={selectInputClasses} />}
                        inputProps={{ classes: { select: classes.select } }}
                        >
                      
                      <MenuItem value={"all"}>Tous les commandes</MenuItem>
                      <MenuItem value={"null"}>En cours</MenuItem>
                      <MenuItem value={"annulée"}>Annulée</MenuItem>
                      <MenuItem value={"refusée"}>Refusée</MenuItem>
                      <MenuItem value={"refusée par le fournisseur"}>Refusée par le fournisseur</MenuItem>
                      <MenuItem value={"acceptée"}>Acceptée</MenuItem>
                      <MenuItem value={"validation refusée"}>Validation refusée</MenuItem>
                      <MenuItem value={"sous réserve de validation"}>Sous réserve de validation</MenuItem>
                      <MenuItem value={"validée pour la livraison"}>Validée pour la livraison</MenuItem>
                      <MenuItem value={"en cours de preparation"}>En cours de preparation</MenuItem>
                      <MenuItem value={"en cours de livraison"}>En cours de livraison</MenuItem>
                      <MenuItem value={"bien reçu"}>Bien reçu</MenuItem>
                      <MenuItem value={"conforme"}>Conforme</MenuItem>
                      <MenuItem value={"non conforme"}>Non conforme</MenuItem>
                      <MenuItem value={"facture réglée"}>Facture réglée</MenuItem>
                  </Select>
                </FormControl>
               </span>
            </Tooltip>
          </div>
          <div style={{flex:1,width:'100%',marginTop:'9px'}}>
            {
                content_charged!==false
                ? <React.Fragment>
                {
                  show_elemnts!==true
                    ?<Widget_count_elements setShowing_elements={setShowing_elements} elements_number={parseFloat((data.total).toFixed(2))+" MAD"} fontColor="#51a5cf" progress={data.progress} />
                    :<TableContainer className="delay_of_market_widget" onDoubleClick={() => setShowing_elements(false)} style={{cursor:'pointer'}} 
                            onTouchStartCapture={(e)=>{e.stopPropagation();setShowing_elements(false)}} >
                        <Table className={classes.table} aria-label="sticky table">
                            <TableHead>
                            <TableRow>
                                {
                                    columns.map((column) => (
                                        <TableCell key={column.id} align="left">{column.label}</TableCell>
                                    ))
                                }
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            <>
                                <Requests requests={data.data} />
                            </>
                            </TableBody> 
                        </Table>
                    </TableContainer>
                }
                
                  
                </React.Fragment>
                :<Loading_animation_view />
            }
          </div>
          
            
      </div>
    );
}

export default React.memo(Orders_totalAmount_byState);