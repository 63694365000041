import React, { useEffect, useLayoutEffect, useState } from 'react'
import   './chatView.css'
import SendIcon from '@material-ui/icons/Send';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@mui/material/Fab';
import Form from 'react-bootstrap/Form';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import defaultProfil_pic from '../../../service_navBar/defaultProfil_pic.png';

import { sendMsg } from '../../../../services/supplier_service';
import { get_allPurchaseServices } from '../../../../services/for_allServices';
import Loading_animation_view from '../../../loading_animation_view/loading_animation_view';
import { count_unread_msg_supplier, load_chat_supplier } from '../../../../services/purchase_service';


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }));
  

function ChatView ({ handleClose, show ,id_creator }){

  const classes = useStyles();

  let user = JSON.parse(localStorage.getItem('user-info'));
  const id_supplier = user.id;

  const [message,setmessage]=useState("");
  const [selected_service,setSelected_service]=useState({});
  const [services,setServices]=useState(null);
  const [chats,setChats]=useState(null);

  useLayoutEffect(()=>{
    let msgs_cont = document.getElementById('messages');
    if(msgs_cont) msgs_cont.scrollTop = msgs_cont.scrollHeight;
  },[])

  useEffect(()=>{
    async function load_data(){
        let servs = await get_allPurchaseServices().then((response)=>{
            return response.data;
        }).catch((error)=>{
            //console.log(error.response);
        })

        for(let i in servs){
            let serv = servs[i];
            await count_unread_msg_supplier(id_supplier,serv.id).then((response)=>{
                //console.log(response.data);
                serv.msg_non_lue = (response.data).msg_non_lue;  
                serv.last_msg = (response.data).last_msg;  
            }).catch((error) => {
                //console.log(error.response);
            });
        }

        setServices(servs);
    }
    load_data();
  },[]);


  async function view_serv_text_msgs(id,serv){
    setChats(null);
    await setSelected_service(serv);
    await load_chat_supplier(id_supplier,serv.id).then((response)=>{
       // //console.log(response.data);
        setChats(response.data);
        let tmp_servs = [ ...services ];
        tmp_servs[id].msg_non_lue=0;
        setServices(tmp_servs);
    }).catch((error)=>{
        //console.log(error.response);
    });   
    let msgs_cont = document.getElementById('messages');
    if(msgs_cont) msgs_cont.scrollTop = msgs_cont.scrollHeight;
  }

  function send(id_serv){
      if(message!==""){
        let msg = {
            "id_personne": id_supplier,
            "time": new Date().toISOString(),
            "content": message,
            "type": "text",
            "lue": false
          }
        const formData = new FormData();
        formData.append('id_supplier',id_supplier);
        formData.append('id_service',id_serv);
        formData.append('content',JSON.stringify(msg));
        sendMsg(formData).then((response)=>{
            //console.log(response.data);
            let array_tmp = chats;
            array_tmp.push(msg);
            let msgs_cont = document.getElementById('messages');
            msgs_cont.scrollTop = msgs_cont.scrollHeight;
            let tmp_servs = [ ...services ];
            tmp_servs.map((serv,id) => {
                if(serv.id===id_serv) tmp_servs[id].last_msg=message;
            })
            setServices(tmp_servs);
            setmessage("");
        }).catch((error)=>{
            //console.log(error.response);
        })
      }
      
  }


    return (
        <Dialog disableEnforceFocus fullScreen open={show} onClose={handleClose} className="my_full_dialog contract_full_dialog chatModal_container">
            <AppBar className={classes.appBar}  >
                <Toolbar style={{backgroundColor:'#343a40'}} >
                <div className='page_title'>
                  <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" >
                      <CloseIcon className="my_icon" />
                  </IconButton>
                  <span>Chat</span>
                </div>
                </Toolbar>
            </AppBar>
            <>
                {
                    services!==null
                    ?<div id="frame">
                        <div id="sidepanel">
                            <div id="contacts">
                                <ul>
                                {
                                services.map((serv,id) => {
                                    return(
                                         <li className={selected_service.id === serv.id?"active contact":"contact"} key={id} id={serv.id} onClick={(e)=>{view_serv_text_msgs(id,serv)}}>
                                            <div className="wrap">
                                                <div className="img_cont">
                                                    <img src={serv.profile_img!==""?`data:image/png;base64,${serv.profile_img}`:defaultProfil_pic} />
                                                </div>
                                                <div className="meta">
                                                    <p className="name">{serv.id===id_creator?serv.fullName+" ( createur )":serv.fullName}</p>
                                                    <>{serv.last_msg!==""?<p className="preview">{serv.last_msg}</p>:<></>}</>
                                                </div>
                                                <>{serv.msg_non_lue!==0?<div id="text_non_lue">{serv.msg_non_lue}</div>:<></>}</>
                                            </div>
                                        </li>
                                        )
                                     })
                                }
                                </ul>
                            </div>
                        </div>
                        <>
                          {
                            Object.keys(selected_service).length !== 0
                            ?chats!==null
                                ?<div className="chatView_container" key="chat_room">
                                    <div id="user" className="contact-profile" style={{display:'flex',justifyContent:'space-between'}}>
                                        <span>
                                        {selected_service.id===id_creator?selected_service.fullName+" ( createur )":selected_service.fullName}
                                        </span>
                                    </div>
                                    <ul id="messages" className='msgs messages'>
                                        {
                                        chats.length!=0
                                            ?chats.map((chat)=>{
                                                return <>
                                                    {
                                                        chat.id_personne === id_supplier
                                                        ?<li key={chat.time} className="emetteur">
                                                            <div>Vous</div>
                                                            <span>{chat.content}</span>
                                                            <div className="temps">{new Date(chat.time).toLocaleString()}</div>
                                                        </li>
                                                        :<li key={chat.time} className="destinateur">
                                                            <div>service</div>
                                                            <span>{chat.content}</span>
                                                            <div className="temps">{new Date(chat.time).toLocaleString()}</div>
                                                        </li>
                                                    }
                                                </>
                                            })
                                            :<></>
                                        }
                                    </ul>
                                    <div className="input_container">
                                        <div className="textarea_container" >
                                                <Form.Control as="textarea" placeholder="Votre message ici" value={message} rows={2} onChange={(e)=>setmessage(e.target.value)} />
                                        </div>
                                        <div className="sendBut_container">
                                            <Fab size="small" color="secondary" style={{backgroundColor:'transparent',color:"black"}} onClick={()=>send(selected_service.id)}>
                                                <SendIcon className="my_icon" />
                                            </Fab>
                                        </div>
                                    </div>
                                </div>
                                :<Loading_animation_view />
                           :<></> 
                          }
                        </>
                        
                    </div>
                    :<Loading_animation_view />
                }
            </>
    </Dialog>
        
    )

}

export default ChatView;


/*
<Dialog fullWidth={true} maxWidth={'md'} className="chatModal_container" open={show} aria-labelledby="form-dialog-title" >
        <DialogContent> */