import '../../../../assets/css/bills.css'
import React, {useEffect,useState} from 'react'
import { useHistory } from "react-router-dom";

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import TableContainer from '@material-ui/core/TableContainer';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import swal from 'sweetalert';

import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import Button from '@material-ui/core/Button';
import DownloadIcon from '@mui/icons-material/Download';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from "react-js-pagination";

import { download_bills_docs } from '../../../../services/for_allServices';
import { getSupplier_market, notify_finance_service, upload_bill_docs, getSupplier_categories } from '../../../../services/supplier_service';

import State_details from '../state_details/state_details';
import Loading_animation_view from '../../../loading_animation_view/loading_animation_view'
import Set_new_bill from '../set_new_bill/set_new_bill'

const useStyles = makeStyles((theme) => ({
  button: { margin: theme.spacing(1),},
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    width: '100%',
  },
  input: {
    display: 'none',
  },
  container: {
    maxHeight: 440,
  },
}));



function setState(bill_state,financeService_accord,purchaseService_accord,businessService_accord){
  return(
    <>
      <Tooltip title="" placement="bottom" >
          <span style={{marginLeft:'2%'}}>
              {
                financeService_accord==="0" || purchaseService_accord==="0" || businessService_accord==="0"
                ?<Button className="status_deny" >{"refusée"}</Button>
                :bill_state==="comptabilisée"
                  ?<Button className="status_success" >{bill_state}</Button>
                    :bill_state==="à valider" && (purchaseService_accord===null || businessService_accord===null)
                    ?<Button className="status_success" >{bill_state}</Button>
                      :bill_state==="mis en règlement"
                      ?<Button className="status_success" >{bill_state}</Button>
                      :bill_state==="réglée"
                      ?<Button className="status_success" >{bill_state}</Button>
                      :bill_state==="archivée"
                        ?<Button className="status_success" >{bill_state}</Button>
                        :<Button className="status_waiting" >{"en cours"}</Button>
              }
          </span>
      </Tooltip>
    </>
  );
}


function verify_if_able_to_seeDetails(bill){
  if(bill.bill_state==="à valider" || bill.bill_state==="refusée" ) return true;
  return false;
}

function verify_if_able_update(bills,all=false){
  if(bills.length===0 && all===true) return true;
  var able = false;
  bills.map((bill)=>{
    if( bill.bill_state===null || bill.financeService_accord==="0" || bill.purchaseService_accord==="0" || bill.businessService_accord==="0" ) able =  true;
  })
  return able;
}

function numberWithSpaces(x) {
  if(x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  else return x;
}

function Bill_column({bill,id}){

  const classes = useStyles();
  const [view_state_details,setview_state_details] = useState(null);
  const [waiting, setWaiting] = useState(false);

  function upload__billDocs(event,id_submission,bill_number,choice){
    setWaiting(true);
    let formData = new FormData();
    formData.append(choice,event.target.files[0]);
    formData.append('id_submission',id_submission);
    formData.append('bill_number',bill_number);
    upload_bill_docs(formData).then((response)=>{
        //console.log(response.data);
        setWaiting(false);
    }).catch((error) => {
        //console.log(error.response);
        setWaiting(false);
    });
  }

  function download_billDocs(id_submission,bill_number,choice) {
    const formData = new FormData();
    formData.append('id_submission',id_submission);
    formData.append('bill_number',bill_number);
    formData.append('choice',choice);
    download_bills_docs(formData).then((response) => {
       var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        let file_extension = '';
        const disposition = response.headers['content-disposition'];
        if (disposition && disposition.includes('filename')) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                file_extension = matches[1].replace(/['"]/g, '').split(".")[1];
            }
        }
        fileLink.setAttribute('download', id_submission+"_"+choice+bill_number+"."+file_extension);
        document.body.appendChild(fileLink);
        fileLink.click();
    }).catch(err=>{
      //console.log(err,err.response);
    });
  }

  return (
        <div className='bill_container'>
          <div className='bill_infos_container'>
            <div className='bill_infos'>
              <label htmlFor={"bill_doc"+id} className={ verify_if_able_update([bill]) ?"":"disabled_button"}> 
                     <Fab size="small" color="default" component="span" >
                         <CloudUploadIcon className="my_icon" />
                      </Fab>
                      <input accept="application/*" className={classes.input} name={"bill_doc"+id} id={"bill_doc"+id} multiple type="file" onChange={(e) => upload__billDocs(e,bill.id_submission,bill.bill_number,"bill_content")} />
              </label>
              <span className='download_bill_button' onClick={()=>download_billDocs(bill.id_submission,bill.bill_number,"bill_content")} >
                  <Fab size="small" color="default" >
                      <DownloadIcon className="my_icon" />
                  </Fab>
              </span>
              <div className='bill_name'>Facture N°{bill.bill_number}</div>
            </div>
            <div className='bill_amount'>
              {
                bill.amount_without_tax!==null
                ?<>{bill.amount_without_tax+" MAD"}</>
                :<span style={{opacity:".8"}} >non défini</span>
              }
            </div>
            <div className='bill_treatedDate'>
              {
                bill.expiration_date!==null
                ?(new Date(bill.expiration_date)).toLocaleString().split(/[,| ]/)[0]
                :<span style={{opacity:".8"}} >non défini</span>
              }
            </div>
            <div className='bill_state_container'>
              <div className='bill_state'>{ setState(bill.bill_state,bill.financeService_accord,bill.purchaseService_accord,bill.businessService_accord) }
              {
                verify_if_able_to_seeDetails(bill)
                ?<div className="refuse_raison_button" 
                      onClick={()=>setview_state_details(
                                [
                                  [bill.financeService_refuse_reason,bill.purchaseService_refuse_reason,bill.businessService_refuse_reason],
                                  [bill.financeService_accord,bill.purchaseService_accord,bill.businessService_accord]
                                ]
                              )}>
                   Details
                </div>
                :<div></div>
              }   
              </div>
            </div>
            <div className='bill_treatedDate'>{(new Date(bill.limit_date)).toLocaleString().split(/[,| ]/)[0]}</div>
          </div>

          <div className='bill_compDocs'>
              <div className='bill_compDoc'>
                  <label htmlFor={"compDoc_reception"+id} className={ verify_if_able_update([bill]) ?"":"disabled_button"}>
                          <Fab size="small" color="default" component="span" >
                            <CloudUploadIcon className="my_icon" />
                          </Fab>
                          <input accept="application/*" className={classes.input} name={"compDoc_reception"+id} id={"compDoc_reception"+id} multiple type="file"  onChange={(e) => upload__billDocs(e,bill.id_submission,bill.bill_number,"reception_pv")} />
                  </label>
                  <span style={{marginRight:'6px'}} onClick={()=>download_billDocs(bill.id_submission,bill.bill_number,"reception_pv")} >
                      <Fab size="small" color="default" >
                          <DownloadIcon className="my_icon" />
                      </Fab>
                  </span>
                  <span className='docs_name'>PV de réception</span>
              </div>
              <div className='bill_compDoc'>
                  <label htmlFor={"compDoc_delivery"+id} className={ verify_if_able_update([bill]) ?"":"disabled_button"}>
                          <Fab size="small" color="default" component="span" >
                            <CloudUploadIcon className="my_icon" />
                          </Fab>
                          <input accept="application/*" className={classes.input} name={"compDoc_delivery"+id} id={"compDoc_delivery"+id} multiple type="file"  onChange={(e) => upload__billDocs(e,bill.id_submission,bill.bill_number,"delivery_pv")} />
                  </label>
                  <span style={{marginRight:'6px'}} onClick={()=>download_billDocs(bill.id_submission,bill.bill_number,"delivery_pv")} >
                      <Fab size="small" color="default" >
                          <DownloadIcon className="my_icon" />
                      </Fab>
                  </span>
                  <span className='docs_name'>PV de livraison</span>
              </div>
          </div>
          {
            view_state_details!==null
            ?<State_details view_state_details={view_state_details} setview_state_details={setview_state_details} />
            :<></>
          }
          <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waiting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
      </div>
  );
}

function Markets_list({markets,setMarket_newBill,notify_financeService}){
  return(
      <>
      {
          markets.map((market,id) => (
              <TableRow key={"market"+id} className={market.contract_signed?"":"disabled_button"}>
                  <TableCell align="left">{market.submission_id}</TableCell>
                  <TableCell align="left">{market.service_name}</TableCell>
                  <TableCell align="left">{market.offer_object}</TableCell>
                  <TableCell align="left">{numberWithSpaces(market.offer_amount)+" MAD"}</TableCell>
                  <TableCell align="left">
                    <div>
                    {
                          (market.bills).length!=0
                          ?<>
                            <div className='bill_container_titles'>
                              <div className='bill_infos_container'>
                                <div className='bill_infos'></div>
                                <div className='bill_amount'> Montant <br /> hors Tax</div>
                                <div className='bill_treatedDate'>Date <br /> d'échéance</div>
                                <div className='bill_state'>Status</div>
                                <div className='bill_treatedDate'>Date limite</div>
                              </div>
                              <div className='bill_compDocs'>
                                    <span className='docs_name'>Docs complémentaires</span>
                              </div>
                            </div>
                            {
                              market.bills.map((bill,id2) => (
                                  <Bill_column bill={bill} id={id+id2}  key={"market_bill"+id+id2}  />
                              ))
                            }
                          </>
                          :<></>
                        }
                        
                        <div className='add_bill_container'>
                            {
                              (market.bills).length==0
                              ?<span>Aucune facture déposée</span>
                              :<span></span>
                            }
                            <div>
                              <Tooltip title="Déposer une facture" placement="bottom">
                                <span className={ verify_if_able_update(market.bills,true) ?"add_button":"add_button"} onClick={()=>setMarket_newBill(market.submission_id,id)}>
                                    Déposer une facture
                                </span>
                              </Tooltip>
                              <Tooltip title="Notifier le service de finance" placement="bottom">
                                <span className={(market.bills).length!=0?"add_button":"add_button disabled_button"} style={{marginLeft:"1px"}} onClick={()=>notify_financeService(market.submission_id)}>
                                    Notifier
                                </span>
                              </Tooltip>
                            </div>
                            
                        </div>  
                    </div>
                  </TableCell>
              </TableRow>                
          ))
      }
      </>
  );
}


function Markets_bills({filter_bills_src,url_updated , setUrl_updated}) {

    let supplier=JSON.parse(localStorage.getItem('user-info'))
    let history = useHistory();

    const [markets,setMarkets]=useState(null);

    const [add_bill,setAdd_bill]=useState(null);
    const [chosenSubmission,setChosenSubmission]=useState(null);
    const [filter_categorie,setFilter_categorie]=useState("all");
    const [search_value,setsearch_value]=useState("");
    const [search_filter,setsearch_filter]=useState('all');

    const [categories,setCategories]=useState([]);

    const [waiting, setWaiting] = useState(false);
    const [currentPage,setCurrentPage]=useState(1);
    const [dataPerPage,setdataPerPage] =useState(5);
    const [data_count,setdata_count]=useState(0);

    useEffect(()=>{
        setdata_count(0);
        setMarkets(null);
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        var tmp_searchFilter ="all";
        var tmp_searchValue = null;
        var tmp_filterCategorie = "all";

        async function load_data(){
          if(urlParams.get('filtre') && urlParams.get('valeur')){
              tmp_searchFilter = urlParams.get('filtre');
              tmp_searchValue = urlParams.get('valeur');
          }if(urlParams.get('categorie')){
              tmp_filterCategorie = urlParams.get('categorie');
          }
          await setsearch_filter(tmp_searchFilter);
          await setsearch_value(tmp_searchValue);
          await setFilter_categorie(tmp_filterCategorie);
          let page = (urlParams.get('page')?urlParams.get('page'):1);
          await setCurrentPage(page);

          await getSupplier_categories(supplier.id).then((response)=>{
              setCategories(response.data);
          }).catch((error)=>{
          //console.log(error.response);
          })
          
          await getSupplier_market(supplier.id,tmp_filterCategorie,tmp_searchFilter,tmp_searchValue,page).then((response)=> {
              setMarkets(response.data.data);
              setdata_count(response.data.total);
          }).catch(err=>{
          //console.log(err,err.response);
          });

        }
        load_data();

    },[]);

  function setMarket_newBill(id_submission,id_list){
      setChosenSubmission(id_submission);
      setAdd_bill(id_list);
  }

  function submit_new_bills(newBills,id){
      let tmp_array = [...markets];
      tmp_array[id].bills = newBills;
      setMarkets(tmp_array);
  }

  function notify_financeService(id_submission){
    setWaiting(true);
    let formData = new FormData();
    formData.append("id_submission",id_submission);
    notify_finance_service(formData).then((response)=>{
      setWaiting(false);
        swal("Le service de finance sera bientôt notifié !", {
          icon: "success",
          buttons: true,
        }).then((willDelete) => {
            if (willDelete) {
        }})
    }).catch((error)=>{
      setWaiting(false);
      //console.log(error.response);
    })
  }

  function search(){
    let path = history.location.pathname;
    if(filter_categorie!=="all") history.push(path+"?src="+filter_bills_src+"&categorie="+filter_categorie+"&filtre="+search_filter+"&valeur="+search_value);
    else history.push(path+"?src="+filter_bills_src+"&filtre="+search_filter+"&valeur="+search_value);
    setUrl_updated(search_value);
  }

  function set__categorie(value){
    setFilter_categorie(value);
    let path = history.location.pathname;
    if(value!=="all") history.push(path+"?src="+filter_bills_src+"&categorie="+value);
    else history.push(path+"?src="+filter_bills_src+"&categorie=all");
    setUrl_updated(value);
  }

  function set__filter(value){
    if(value==="all"){
      setsearch_filter(value);
      let path = history.location.pathname;
      if(filter_categorie!=="all") history.push(path+"?src="+filter_bills_src+"&categorie="+filter_categorie+"&filtre="+value);
      else history.push(path+"?src="+filter_bills_src+"&filtre="+value);
      setUrl_updated(value);
    }else setsearch_filter(value);
  }


  function set_bills_src(value){
    let path = history.location.pathname;
    history.push(path+"?src="+value);
    setUrl_updated(value);
  }

  async function change_page(page){
    await setCurrentPage(page);
    let path = history.location.pathname;
    let params = history.location.search;
    let search_params = params.split('&');
    let tmp_params = path;
    if(!params) history.push(path+"?page="+page);
    else{
      for(let i in search_params){
        if(search_params[i].includes("page=")){
          if(i==0) tmp_params=tmp_params+"?page="+page;
          else tmp_params=tmp_params+"&page="+page;
        }else{
          if(i==0) tmp_params=tmp_params+search_params[i];
          else tmp_params=tmp_params+"&"+search_params[i];
        }
      }
      if(!tmp_params.includes("page=")) tmp_params=tmp_params+"&page="+page;
      history.push(tmp_params);
    }
    await setUrl_updated(page);
  }

  return (
    <>
    {
        markets==null
        ?<Loading_animation_view />
        :<div className='purchase_bills_container'>
            <div className='bills_src_header'>
            <FormControl variant="standard" className="header_form">
                <InputLabel id="demo-simple-select-helper-label">Factures/paiement</InputLabel>
                <Select required value={filter_bills_src} 
                        onChange={e => {set_bills_src(e.target.value) }} >
                        <MenuItem value={"marchés"} >{"Marchés"}</MenuItem>
                        <MenuItem value={"commandes"} >{"Bons de commande"}</MenuItem>
                </Select>
            </FormControl>
            </div>
            <div className='three_forms_header_container'>
             <div className='page_title'>Marchés</div>
              <div className='search_filter_container'>
                <FormControl variant="standard" className="header_form" >
                        <InputLabel id="demo-simple-select-helper-label">Famille d'achat</InputLabel>
                        <Select required value={filter_categorie} 
                            onChange={e => {set__categorie(e.target.value) }} >
                            
                            <MenuItem value={"all"}>Toutes les familles d'achat</MenuItem>
                            {
                            categories.map((categorie,id)=>(
                                <MenuItem value={categorie.id_category} key={id} >{categorie.name}</MenuItem>
                            ))
                            }
                            
                        </Select>
                    </FormControl>

                  <FormControl className="header_form" >
                    <InputLabel id="demo-simple-select-helper-label">Filtre</InputLabel>
                        <Select value={search_filter}  onChange={(e)=>{set__filter(e.target.value)}}>
                        <MenuItem value="all">Aucun</MenuItem>
                        <MenuItem value='Code'>Code de marché</MenuItem>
                        <MenuItem value='Object'>Objet d'appel</MenuItem>
                        </Select>
                  </FormControl>

                        <Grid container spacing={1} alignItems="flex-start" className={search_filter==="all"?"search_bar disabled_button":"search_bar" } >
                        <Grid item>
                            <SearchIcon onClick={search} className={search_value==""?"my_icon disabled_button":"my_icon" } style={{cursor:"pointer"}}  />
                        </Grid>
                        <Grid>
                            <TextField label="Chercher" value={search_value} onChange={(e)=>{setsearch_value(e.target.value)}}/>
                        </Grid>
                        </Grid>
                </div>
        </div>
            <TableContainer className="bills_table_container">
                <Table aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell>Code de marché</TableCell>
                        <TableCell>Service d'achat</TableCell>
                        <TableCell>Objet d'appel</TableCell>
                        <TableCell>Montant</TableCell>
                        <TableCell>Factures</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                    markets.length!==0
                    ?<Markets_list markets={markets} setMarket_newBill={setMarket_newBill} notify_financeService={notify_financeService} />
                    :<div style={{margin:"10px",fontSize:".8rem",width:'100%', textAlign:"center",alignContent:"center"}}>Aucune facture disponible</div>
                    }
                </TableBody>
                </Table>
            </TableContainer>
            {
                markets.length!==0
                  ?<div className="pagination_container">
                      <Pagination
                          activePage={Number(currentPage)}
                          itemsCountPerPage={dataPerPage}
                          totalItemsCount={Number(data_count)}
                          pageRangeDisplayed={4}
                          onChange={change_page.bind(this)}
                      />
                  </div>
                  :<></>
              }
       </div>
    }
        {
            add_bill!=null
            ?<Set_new_bill add_bill={add_bill} setAdd_bill={setAdd_bill} id_submission={chosenSubmission} submit_new_bills={submit_new_bills} />
            :<></>
        }
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={waiting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </>
  );
}

export default Markets_bills;