import '../../../../assets/css/setContract_forSubmission.css';
import React, { useState , useEffect } from 'react'
import { lighten, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@mui/material/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import Fab from '@mui/material/Fab';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import Input from "@material-ui/core/Input";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import swal from 'sweetalert';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {get_contract_last_content,download_contractPDF, contract_check, contract_is_being_edited, save_contract_content } from '../../../../services/purchase_service'
import {accept_contract_terms, notify_service_with_contractContent} from '../../../../services/supplier_service';

import Loading_animation_view from '../../../loading_animation_view/loading_animation_view'


import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
    input: {
      display: 'none',
    },
    select: {
      "&:focus": {
        backgroundColor: "white"
      }
    },
    selectInput: {
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottomWidth: 0
      },
      "&:after": {
        borderBottomWidth: 0
      }
    },
    disabled: {},
    focused: {},
    error: {}
  }));


function Contract_content_view({setContract,handleCloseSetContrat,chosenSubmission,supplier}){

    const classes = useToolbarStyles();

    const selectInputClasses = {
      root: classes.selectInput,
      disabled: classes.disabled,
      focused: classes.focused,
      error: classes.error
    };
  

    const [waiting,setWaiting] = useState(false);
    const [contract_type,setContract_type]=useState(chosenSubmission.contract_type);
    const [contract_id,setcontract_id] = useState(chosenSubmission.contract_subtypes[0].id);
    const [contract_signed,setContract_signed] = useState(false);

    const [able_toload_contract,setAble_toload_contract]=useState(false);
    const [supplier_accepted_terms,setSupplier_accepted_terms]=useState(null);
    const [ReadOnlyContrat,setReadOnlyContrat]=useState(false);
    const [editing_contract,setEditing_contract]=useState(false);
    const [editorDefaultContent, seteditorDefaultContent] = useState(null);
    const [editorContent, seteditorContent] = useState(null);
    const [contentChanged, setContentChanged] = useState(false);
    const [contentSaved, setcontentSaved] = useState(false);

    const [content_charged, setContent_charged] = useState(false);
    const [contract_form_pdf, setcontract_form_pdf] = useState(null);
    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }

    useEffect(()=>{
      seteditorDefaultContent(null);
      seteditorContent(null)
      setcontract_form_pdf(null);
      setReadOnlyContrat(false);
      setEditing_contract(false);
      setAble_toload_contract(false);
      setContent_charged(false);

      //console.log(chosenSubmission);

      contract_check(chosenSubmission.id_submission,contract_id).then((response)=>{
        //console.log(response.data);
        if(response.data!==""){
          let submission_contract = response.data[contract_type][0];
          if( submission_contract.contract_being_edited !== supplier.id ){
            if( submission_contract.contract_being_edited !== 0) setReadOnlyContrat(true);
          }else{
            setReadOnlyContrat(false);
            setEditing_contract(true);
          }

          if(submission_contract.contract_content){
            setAble_toload_contract(true);
            const formData = new FormData();
            formData.append('id_submission',chosenSubmission.id_submission);
            formData.append('contract_id',contract_id);
            download_contractPDF(formData).then((response_blob) => {
                var file = new Blob([response_blob.data], {type: 'application/PDF'});
                var fileURL = URL.createObjectURL(file);
                setcontract_form_pdf(fileURL);
            }).catch(err=>{
              //console.log(err.response);
            });
          }else{
            get_contract_last_content(chosenSubmission.id_submission,contract_id).then((response_content)=>{
              seteditorDefaultContent(response_content.data.contract_content);
              seteditorContent(response_content.data.contract_content);
            }).catch((error)=>{
              //console.log(error.response);
            })
    
          }
          setSupplier_accepted_terms(submission_contract.supplier_accord);
          setContract_signed(submission_contract.signed);
        }else{
          seteditorDefaultContent("");
          seteditorContent("");
        }
        setContent_charged(true);
      }).catch((error)=>{
        //console.log(error.response);
      });


    },[contract_id]);


    const handleEditorChange = (content) => {
      seteditorContent(content);
      //console.log(content);
      if(editorDefaultContent!=content && content!="<p><br></p>" && content!="<p></p>"){
        setContentChanged(true);
        setcontentSaved(false);
      }
      else setContentChanged(false);
    }

    function edit_contract(){
      setWaiting(true);
      let formData = new FormData();
      if(editing_contract == false) formData.append("being_edited",1);
      if(editing_contract == true) formData.append("being_edited",0);
      formData.append("id_login",supplier.id);
      formData.append('contract_id',contract_id);
      contract_is_being_edited(chosenSubmission.id_submission,formData).then((response)=>{
        //console.log(response.data);
        setWaiting(false);
        setEditing_contract(!editing_contract);
      }).catch((error)=>{
        //console.log(error.response);
      })
    }


    function isContentExist(){
      if(editorContent!="<p></p>" && editorContent!="<p><br></p>" && editorContent!=null) return true;
      else return false;
    }


    function SaveContratContent(){
      setWaiting(true);
      let formData = new FormData();
      formData.append('content',editorContent);
      formData.append('contract_type',contract_type);
      formData.append('contract_id',contract_id);
      formData.append('id_submission',chosenSubmission.id_submission);
      formData.append('id_login',supplier.id);
      save_contract_content(formData).then(response => {
        setcontentSaved(true);
        setContentChanged(false);
        setWaiting(false);
          let formData2 = new FormData();
          formData2.append("being_edited",false);
          formData2.append('contract_id',contract_id);
          contract_is_being_edited(chosenSubmission.id_submission,formData2).then((response)=>{
            setEditing_contract(false);
          }).catch((error)=>{
            //console.log(error.response);
          })
      })
      .catch((error)=>{
        //console.log(error.response);
      });
      
    }


  function accept_contract_bysupplier(supplier_accord){
    swal({
      title: "Etes-vous sûr ?",
      text: "!!!!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
  })
  .then((willDelete) => {
    if (willDelete) {
      setWaiting(true);
      let formData = new FormData();
      formData.append('supplier_accord',supplier_accord);
      formData.append('id_submission',chosenSubmission.id_submission);
      formData.append('contract_id',contract_id);
      accept_contract_terms(formData).then(response => {
          setWaiting(false);
          swal("Votre réponse a été enregistrée avec succès, le service sera bientôt notifié !", {
            icon: "success",
            buttons: true,
          }).then((willDelete) => {
              if (willDelete) { 
                    setSupplier_accepted_terms(supplier_accord);
          }}) 
        });
    }})
   }


  function download_contract_pdf(){
    setWaiting(true);
    const formData = new FormData();
    formData.append('id_submission',chosenSubmission.id_submission);
    formData.append('contract_id',contract_id);
    download_contractPDF(formData).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', (chosenSubmission.id_submission)+"_contrat.pdf");
        document.body.appendChild(fileLink);
        fileLink.click();
        setWaiting(false);
    }).catch(err=>{
      //console.log(err.response);
    });
  }

   
  function sendContractTService(){
    setWaiting(true);
    const formData = new FormData();
    formData.append('id_submission',chosenSubmission.id_submission);
    formData.append('contract_id',contract_id);
    formData.append('contract_type',contract_type);
    notify_service_with_contractContent(formData).then((response => {
      setWaiting(false);
      swal("Le service sera bientôt notifié !", {
        icon: "success",
        buttons: true,
      }).then((willDelete) => {
          if (willDelete) { 
      }}) 
    })).catch((error) => {
      //console.log(error.response);
      setWaiting(false);
    });
}



    return (
      <Dialog disableEnforceFocus fullScreen open={setContract!=null} onClose={handleCloseSetContrat}  className="my_full_dialog contract_full_dialog">

        <AppBar className={classes.appBar}>
            <Toolbar className="contract_content_toolbar supplier_contract_content_toolbar">
              {
                contract_type!=null
                ?<div className="contract_content_toolbar_main_container">
                    <div className="contract_content_toolbar_left">

                        <div className='page_title'>
                          <IconButton edge="start" color="inherit" onClick={handleCloseSetContrat} aria-label="close" >
                              <CloseIcon className="my_icon" />
                          </IconButton>
                          <span>{"Contrat "+contract_type}</span>
                        </div>
                        <div className='search_filter_container'>
                          <Tooltip title="Sous types de contrat" placement="right" >
                            <div className='select_container' >
                              <FormControl className={!editing_contract ?"header_form":"header_form disabled_button"} variant="standard" >
                                        <Select required
                                              value={contract_id}
                                              input={<Input classes={selectInputClasses} />}
                                              inputProps={{ classes: { select: classes.select } }}
                                              style={{backgroundColor:"inherit"}}
                                              onChange={e => {
                                                  setcontract_id(e.target.value);
                                              }} >
                                            {
                                            (chosenSubmission.contract_subtypes).map((sub_types,id)=>{
                                                return <MenuItem key={sub_types.contract_subtype} value={sub_types.id}>{sub_types.contract_subtype}</MenuItem>
                                              })
                                            }
                                        </Select>
                                </FormControl>
                            </div>
                          </Tooltip>

                          {
                            content_charged==true
                            ?<Tooltip title="" placement="bottom" >
                              <span style={{marginLeft:'6px'}}>
                                <Button className={ contract_signed ? "status_success" : "status_waiting"}>
                                      { contract_signed ? "signé" : "En cours"}
                                </Button>
                              </span>
                            </Tooltip>
                            :<></>
                          }
                        </div>
                    </div>
                    <div className="contract_content_toolbar_right">
                          <Tooltip title={
                                    ReadOnlyContrat ?'En cours de modification par fournisseur'
                                    :editing_contract ? "Annuler la modification" : "Modifier le contrat"
                                } placement="bottom" >
                          <span style={{marginLeft:'6px'}}>
                            <Button className={
                              ReadOnlyContrat?"submit_but disabled_button contract_being_edited"
                              :editing_contract ? "submit_but unable_to_changeContract" 
                                          : supplier_accepted_terms? " submit_but disabled_button able_to_changeContract":"submit_but able_to_changeContract"
                            } onClick={edit_contract}>
                                  {
                                      ReadOnlyContrat?'En cours de modification'
                                      :editing_contract ? "annuler" : "modifier"
                                  }
                            </Button>
                          </span>
                          
                        </Tooltip>

                        <Tooltip title="Enregistrer le contrat" placement="bottom" >
                        <span style={{marginLeft:'6px'}}>
                          <Button autoFocus className={contentChanged?"submit_but border_button":"submit_but border_button disabled_button"} color="inherit"  onClick={SaveContratContent}>
                              Enregistrer
                          </Button>
                          </span>
                        </Tooltip>

                        <Tooltip title="Accepter les terms de contrat" placement="bottom" >
                          <span style={{marginLeft:'6px'}}>
                          <Button autoFocus className={supplier_accepted_terms || contentSaved || ReadOnlyContrat || editorDefaultContent!==editorContent?"submit_but border_button disabled_button":"submit_but border_button"} color="inherit" style={{color:"rgb(83 236 151)",fontWeight:"600"}}  onClick={() => accept_contract_bysupplier(1)}>
                              Accepter
                          </Button>
                          </span>
                        </Tooltip>

                        <span className="icon_buttons_container"> 
                          <Tooltip title="Exporter en fichier pdf" placement="bottom" >
                            <span style={{marginLeft:'8px'}}>
                            <Fab onClick={download_contract_pdf} size="small" color="warning" className={able_toload_contract?"":"disabled_button"} >
                              <GetAppRoundedIcon className="my_icon"/>
                            </Fab>
                            </span>
                          </Tooltip>
                          
                          <Tooltip title="Notifier le service d'achat" placement="bottom" >
                            <span style={{marginLeft:'6px'}}>
                              <Fab size="small" color="warning" className={contentSaved?"":"disabled_button"} onClick={sendContractTService} ><NavigateNextIcon className="my_icon" /></Fab>  
                            </span>
                          </Tooltip>
                        </span>
                    </div>
                
                </div>
                :<></>
              }
                
                
            </Toolbar>
        </AppBar>
        {
            editorDefaultContent!=null || contract_form_pdf!=null
            ?<div className="text_editor_container">
                {
                    contract_form_pdf!=null
                    ?<iframe src={contract_form_pdf} width="100%" height="100%" />
                    :<SunEditor
                        lang="fr"
                        width="100%"
                        height="100%"
                        disable={ReadOnlyContrat || able_toload_contract || !editing_contract}
                        setOptions={{
                          buttonList: [
                            [
                            'undo', 'redo',
                            'bold', 'underline', 'italic', 'strike', 'subscript', 'superscript',
                            'font', 'fontSize',
                            'paragraphStyle', 'blockquote',
                            'fontColor', 'hiliteColor', 'textStyle',
                            'removeFormat',
                            'outdent', 'indent',
                            'align', 'horizontalRule', 'list', 'lineHeight',
                            'link', 'image',
                            'print','fullScreen'
                            ]
                        ]
                        }}
                        defaultValue={editorDefaultContent}
                        onChange={handleEditorChange}
                      /> 
                  }
                  
              </div>
            :<Loading_animation_view />
        }
        

        <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waiting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
    </Dialog>
    );
}

export default Contract_content_view;
