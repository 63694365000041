
import './charts_ofBillsAmount_payed_unpayed.css'
import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const Charts_ofBillsAmount_payed_unpayed = ({data}) => {
    const months = ["janvier", "février", "mars", "avril", "mai", "juin", "juilet", "août", "septembre","octobre","novembre","décembre"];
    const [content_charged,setContent_charged]=useState(false);
    const [chart_data,setChart_data]=useState({
      series: [{
        name: 'À réglée',
        data: []
      }, {
        name: 'Réglée',
        data: []
      }],
      options: {
        chart: {
          type: 'bar',
          events: {
            mounted: (chart) => {
              chart.windowResizeHandler();
            }
          },
          toolbar: {
            show: false
         }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '60%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['transparent']
        },
        xaxis: {
          categories: months,
        },
        yaxis: {
          title: {
            text: 'MAD'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " MAD"
            }
          }
        }
      }
    });
        
    useEffect(()=>{
        let tmp_data = { ...chart_data };
        data.map((elem)=>{
            tmp_data.series[0].data.push(elem.total_amount?elem.total_amount:0);
            tmp_data.series[1].data.push(elem.payed_amount?elem.payed_amount:0);
        });
        setChart_data(tmp_data);
        setContent_charged(true);
    },[])
  
  return (
    <div style={{height:'100%',width:'100%'}}>
        {
          content_charged!==false
          ?<>
              <ReactApexChart options={chart_data.options} series={chart_data.series} 
                    type="bar"
                    width="98%"
                    height="96%"
                    style={{margin:"auto"}}
               />
           </>
          :<></>
        }
    </div>
  );

}

export default React.memo(Charts_ofBillsAmount_payed_unpayed);