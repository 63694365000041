import React, { useState } from 'react'
import './download_offer_files.css'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import ClearIcon from '@material-ui/icons/Clear';

import Fab from '@mui/material/Fab';


import { download_offer_files } from '../../../../services/purchase_service';


function CompareFinalDate_current(finalDate){
    let day = new Date(finalDate);
    let current_date = new Date();
    if(day.getTime() < current_date.getTime()) return false;
    else return true;
}


function Download_offer_files({handleClose,open,chosen_offer,OpenDocModal}){

    const [errorText,setErrorText] = useState("");

     function download_proposalFiles(choice) {
      //console.log(chosen_offer);
        let formData = new FormData();
        formData.append('offer_id',chosen_offer.offer_id);
        formData.append('choice',choice);
        download_offer_files(formData).then((response) => {
           var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            let file_extension = '';
            const disposition = response.headers['content-disposition'];
            if (disposition && disposition.includes('filename')) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    file_extension = matches[1].replace(/['"]/g, '').split(".")[1];
                }
            }
            fileLink.href = fileURL;
            fileLink.setAttribute('download', chosen_offer.offer_id+choice+"."+file_extension);
            document.body.appendChild(fileLink);
            fileLink.click();
        }).catch((error) => {
            //console.log(error.response);
        });
      }

      function pursue_applying(){
        setErrorText('');
        if(!CompareFinalDate_current(chosen_offer.limit_date)){
          setErrorText('L\'offre a atteint la date limite !','error');
        }else{
          handleClose();
          OpenDocModal();
        }
        
      }

    return (
        <Dialog open={open} onClose={() => {handleClose();setErrorText('');}} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Fiche societé </DialogTitle>
                <DialogContent>
                  <label>Veuillez télécharger ces documents relatifs à l'offre pour mieux vous informer !</label><br/>
                  <div className="inputs_container" style={{marginTop:'10px'}}>
                        <div className='documentsDownload' style={{width:'fit-content'}}>
                          <label htmlFor="workBook" style={{marginRight:'7px'}}> 
                              <Fab size="small" component='span' onClick={()=>download_proposalFiles("workBook")} ><GetAppRoundedIcon className="my_icon"/> </Fab>
                          </label>
                          <span className='documentsTitle'>Cahier De Charge </span>
                      </div>

                      <div className='documentsDownload' style={{width:'fit-content'}}>
                          <label htmlFor="rc" style={{marginRight:'7px'}}> 
                              <Fab size="small" component='span' onClick={()=>download_proposalFiles("rc")}><GetAppRoundedIcon className="my_icon"/> </Fab>                          </label>
                          <span className='documentsTitle'>RC</span>
                      </div>

                      <div className='documentsDownload' style={{width:'fit-content'}}>
                          <label htmlFor="docsComplementary"  style={{marginRight:'7px'}}>
                                  <Fab size="small" component='span' onClick={()=>download_proposalFiles("docsComplementary")} ><GetAppRoundedIcon className="my_icon"/> </Fab>
                          </label>
                          <span className='documentsTitle'> Docs complémentaire </span>
                      </div>
                  </div>
                </DialogContent>
                <DialogActions>
                   <div className='dialogBottom'>
                      <div>
                          {
                              errorText == "" 
                              ?<></>
                              :<p className={errorText != "" ? "col-md-10 error" : "col-md-10 valid-champ"}>
                                       {errorText}
                                  </p>
                          }
                      </div>
                      <div>
                          <Button variant="contained" className="submit_but cancel_but" startIcon={<ClearIcon className="my_icon"  />} onClick={()=>{handleClose();setErrorText('');}} >
                            Annuler
                          </Button>

                          <Button variant="contained" className="submit_but valide_but"  startIcon={<NavigateNextIcon className="my_icon" />} onClick={pursue_applying} >
                            Suivant
                          </Button>
                      </div>
                    </div>
                </DialogActions>
              </Dialog>
    )
}

export default Download_offer_files
