import '../../../../../assets/css/order_details.css';
import React, { useEffect,useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import swal from 'sweetalert';
import Button from '@material-ui/core/Button';
import Form from 'react-bootstrap/Form'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';

import empty from '../../../../empty.jpg'

import {makeStyles} from '@material-ui/core/styles';
import Loading_animation_view from '../../../../loading_animation_view/loading_animation_view';
import { get_categories } from '../../../../../services/purchase_service';
import { requester_change_order_state } from '../../../../../services/for_allServices';
import Set_refuse_reason from '../set_refuse_reason/set_refuse_reason';


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

const columns = [
    { id: 'unit_amount', label: "Prix unitaire (MAD)", minWidth: 0,align: 'left'  },
    { id: 'product_promo', label: "Promo (%)", minWidth: 0,align: 'left'  },
    { id: 'count', label: "Quantité", minWidth: 0 ,align: 'left' },
    { id: 'amount', label: "Prix total (MAD)", minWidth: 0,align: 'left'  }
  ];
  

function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function Products({categories,products,set_price_by_unit,set_promo_by_unit,set_amount_by_unit}){
    

    function getCategorieById(id){
        var categorie="";
        categories.map((catg)=>{
          if(catg.id_category === id) categorie = catg.name;
        })
        return categorie;
    }

    return(
        <>
        {
            products.map((product,id) => (
                <TableRow key={"product_col"+id}>
                    <TableCell align="left">
                        <div style={{display:"flex",alignItems:"center"}}>
                           <span className='product_cell_img_container'>
                                <img src={product.product_pics.length!==0?`data:image/png;base64,${product.product_pics[0]}`:empty} />
                            </span>
                            <span className='product_cell_title_container'>
                                <span id="title">{product.title}</span>
                                <div className='product_cell_categorie_container'>
                                     <span>Famille d'achat : </span>{getCategorieById(product.id_category)}
                                </div> 
                            </span> 
                        </div>
                        
                    </TableCell>
                    <TableCell align="left">
                      <Form.Control
                          className="amount_container"
                          autoComplete="new-password"
                          type="number"
                          placeholder='0'
                          value={product.product_price}
                          onChange={(e)=>set_price_by_unit(e.target.value,id)}
                          readOnly={true}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Form.Control
                          className="amount_container"
                          autoComplete="new-password"
                          type="number"
                          placeholder='0'
                          value={product.product_promo}
                          onChange={(e)=>set_promo_by_unit(e.target.value,id)}
                          readOnly={true}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Form.Control
                          className="amount_container"
                          autoComplete="new-password"
                          type="number"
                          value={product.product_amount}
                          onChange={(e)=>set_amount_by_unit(e.target.value,id)}
                          readOnly={true}
                      />
                    </TableCell>
                    <TableCell align="left">
                        <Form.Control
                            className="amount_container"
                            autoComplete="new-password"
                            type="number"
                            value={
                              (
                                product.product_price!=='' && !isNaN(product.product_price) 
                                    ?(product.product_amount*(product.product_price - ((product.product_promo!=='' && !isNaN(product.product_promo))?(product.product_price*product.product_promo)/100:0)))
                                    :0
                              )
                            }
                            readOnly
                        />
                    </TableCell>
                </TableRow>                
             ))
        }
        </>
    );
}



function Order_details({selected_products_details,request_code,id_supplier,selected_suppliers,setSelected_suppliers,order_key,order_number}) {


    const classes = useStyles();
    const [categories,setCategories]=useState([]);
    const [products,setProducts]=useState(null);

    const [total_ht,setTotal_ht]=useState(0);
    const [vat,setvat]=useState(0);
    const [total_ttc,setTotal_ttc]=useState(0);
    const [shippingCosts,setshippingCosts]=useState(0);
    const [discount,setdiscount]=useState(0);

    const [open_reason_modal,setOpen_reason_modal]=useState(null);

    const [wait_submitting,setWait_submitting] = useState(false);


    useEffect(()=>{
        get_categories().then((response)=>{
            setCategories(response.data);
        }).catch((error)=>{
          //console.log(error.response);
        });

        //console.log(selected_products_details);
        async function set_vat_value(val){
          setvat(val);
        }
        async function set_shipping_value(val){
          setshippingCosts(val);
        }
        async function set_discount_value(val){
          setdiscount(val);
        }
        async function set_products_value(vat,shippingCosts,discount,val){
          setProducts(val);
          //console.log(val);
          set_total_amountHT(vat,shippingCosts,discount,val);
        }

        let products = selected_products_details.products;
        if(Array.isArray(products)){
          set_vat_value(selected_products_details.vat);
          set_shipping_value(selected_products_details.shippingCosts);
          set_discount_value(selected_products_details.discount);
          set_products_value(selected_products_details.vat,selected_products_details.shippingCosts,selected_products_details.discount,products);  
        }else{
          set_products_value(0,0,0,[]);  
        }
        
    },[]);


    function set_price_by_unit(price,id){
      let tmp_array = [ ...products ];
      tmp_array[id].product_price = price<0?0:parseInt(price);
      setProducts(tmp_array);
      set_total_amountHT(vat,shippingCosts,discount,tmp_array)
    }

    function set_promo_by_unit(promo,id){
      let tmp_array = [ ...products ];
      tmp_array[id].product_promo = promo<0?0:parseInt(promo);
      setProducts(tmp_array);
      set_total_amountHT(vat,shippingCosts,discount,tmp_array)
    }

    function set_amount_by_unit(amount,id){
      let tmp_array = [ ...products ];
      tmp_array[id].product_amount = amount<0?0:parseInt(amount);
      setProducts(tmp_array);
      set_total_amountHT(vat,shippingCosts,discount,tmp_array)
    }

    async function set_total_amountHT(vat,shippingCosts,discount,products){
      let tmp_total = 0;
      products.map((product) => {
        tmp_total += (product.product_price!=='' && !isNaN(product.product_price) ?(product.product_amount * (product.product_price - ((product.product_promo!=='' && !isNaN(product.product_promo))?(product.product_price*product.product_promo)/100:0))):0);
      });
      setTotal_ht(tmp_total);
      set_total_amountTTC(vat,shippingCosts,discount,tmp_total)
    }


    async function set_total_amountTTC(vat,shippingCosts,discount,total_ht){
      setvat(vat<0?0:parseInt(vat));
      setshippingCosts(shippingCosts<0?0:parseInt(shippingCosts));
      setdiscount(discount<0?0:parseInt(discount));
      let tmp_vat = ((total_ht*(vat!=="" && !isNaN(vat)?vat:0))/100);
      let tmp_shippingCosts = ((total_ht*(shippingCosts!=="" && !isNaN(shippingCosts)?shippingCosts:0))/100);
      let tmp_discount = ((total_ht*(discount!=="" && !isNaN(discount)?discount:0))/100);
      let tmp_total = total_ht + tmp_vat + tmp_shippingCosts - tmp_discount;
      setTotal_ttc(tmp_total.toFixed(2));
    }

    
    function well_received(id_supplier,request_code){
      swal({
          title: "Etes-vous sûr ?",
          text: "Une fois que vous avez soumis votre réponse, vous ne pourrez plus la modifier !",
          icon: "warning",
          buttons: true,
          dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          setWait_submitting(true);
          let formData = new FormData();
          formData.append('id_supplier',id_supplier);
          formData.append('request_code',request_code);
          formData.append('state',"bien reçu");
          requester_change_order_state(formData).then((response)=>{
              //console.log(response.data);
              let tmp_products = [ ...selected_suppliers ];
              tmp_products[order_key].order_state = "bien reçu";
              setSelected_suppliers(tmp_products);
              setWait_submitting(false);
              swal("votre réponse a été enregistrée avec succès et le service sera bientôt notifié !", {
                  icon: "success",
                  });
          }).catch((error) => {
              //console.log(error.response);
              setWait_submitting(false);
          })
        }
      });
  }


  function order_conforme(id_supplier,request_code){
      swal({
          title: "Etes-vous sûr ?",
          text: "Une fois que vous avez soumis votre réponse, vous ne pourrez plus la modifier !",
          icon: "warning",
          buttons: true,
          dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          setWait_submitting(true);
          let formData = new FormData();
          formData.append('id_supplier',id_supplier);
          formData.append('request_code',request_code);
          formData.append('state',"conforme");
          requester_change_order_state(formData).then((response)=>{
              //console.log(response.data);
              let tmp_products = [ ...selected_suppliers ];
              tmp_products[order_key].order_state = "conforme";
              setSelected_suppliers(tmp_products);
              setWait_submitting(false);
              swal("votre réponse a été enregistrée avec succès et le service sera bientôt notifié !", {
                  icon: "success",
                  });
          }).catch((error) => {
              //console.log(error.response);
              setWait_submitting(false);
          })
        }
      });
  }

  
  function order_no_conforme(id_supplier,request_code){
    let formData = new FormData();
    formData.append('id_supplier',id_supplier);
    formData.append('request_code',request_code);
    formData.append('state',"non conforme");
    setOpen_reason_modal(formData);
}


//////////////////////
/////////////////////////////////////////////////////////
///////////////////////////////////

  return (
    <>
    {
      products===null
      ?<Loading_animation_view />
      :<div className="order_plus_details_container">
         <TableContainer className="order_plus_details_table_container">
            <Table className={classes.table}>
                <TableHead>
                <TableRow>
                    <TableCell align="left">
                        Produit
                    </TableCell>
                    {
                        columns.map((column) => (
                            <TableCell key={column.id} align={column.align}>{column.label}</TableCell>
                        ))
                    }
                </TableRow>
                </TableHead>
                <TableBody>
                {
                    products.length!==0
                    ?
                    <>
                        <Products categories={categories} products={products} set_price_by_unit={set_price_by_unit} set_promo_by_unit={set_promo_by_unit} 
                                set_amount_by_unit={set_amount_by_unit} />
                    </>
                    :<div style={{margin:"10px",fontSize:".75rem",width:'100%', textAlign:"center",alignContent:"center"}}>Aucun produit disponible</div>
                }
                </TableBody> 
            </Table>
        </TableContainer> 
        <div className='total_amount_container'>
          <TableContainer className="total_amount_table_container">
              <Table className={classes.table}>
                  <TableBody>
                  <TableRow>
                        <TableCell align={"left"}>Total HT (MAD)</TableCell>
                        <TableCell align={"right"}>{numberWithSpaces(total_ht)+" MAD"}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align={"left"}>Taux de TVA (%)</TableCell>
                        <TableCell align={"right"}>
                            <Form.Control
                                className="amount_container"
                                autoComplete="new-password"
                                type="number"
                                placeholder='0'
                                value={vat}
                                readOnly={true}
                                onChange={(e)=>{set_total_amountTTC(e.target.value,shippingCosts,discount,total_ht);}}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align={"left"}>Frais d’expédition (%)</TableCell>
                        <TableCell align={"right"}>
                        <Form.Control
                                className="amount_container"
                                autoComplete="new-password"
                                type="number"
                                placeholder='0'
                                value={shippingCosts}
                                readOnly={true}
                                onChange={(e)=>{set_total_amountTTC(vat,e.target.value,discount,total_ht);}}
                            />
                          </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align={"left"}>Remise (%)</TableCell>
                        <TableCell align={"right"}>
                          <Form.Control
                                className="amount_container"
                                autoComplete="new-password"
                                type="number"
                                placeholder='0'
                                value={discount}
                                readOnly={true}
                                onChange={(e)=>{set_total_amountTTC(vat,shippingCosts,e.target.value,total_ht);}}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align={"left"}>Total TTC (MAD)</TableCell>
                        <TableCell align={"right"}>{numberWithSpaces(total_ttc)+" MAD"}</TableCell>
                    </TableRow>
                  </TableBody> 
              </Table>
          </TableContainer> 
        </div>
        <div className="proceed_button_container">
          {
              selected_products_details.order_state!==null
              ?<>
                    {
                         selected_products_details.order_state!=="annulée" &&  selected_products_details.order_state!=="conforme"
                        ?<>
                            {
                                selected_products_details.order_state!=="bien reçu" 
                                ?<Button variant="contained" className={wait_submitting || selected_products_details.order_state!=="en cours de livraison" ?"submit_but valide_but disabled_button":"submit_but valide_but"} 
                                        startIcon={<DoneIcon className='my_icon' />} onClick={() =>well_received(id_supplier,request_code)}> 
                                        recevoir la commande
                                  </Button>
                                :<>
                                  <Button variant="contained" className={wait_submitting || products.length===0?"submit_but cancel_but disabled_button":"submit_but cancel_but"} 
                                          startIcon={<RemoveDoneIcon className='my_icon' />} onClick={() =>order_no_conforme(id_supplier,request_code)} > 
                                       Commande non conforme
                                  </Button>
                                  <Button variant="contained" className={wait_submitting || products.length===0?"submit_but valide_but disabled_button":"submit_but valide_but"} 
                                          startIcon={<DoneAllIcon className='my_icon' />} onClick={() =>order_conforme(id_supplier,request_code)}  > 
                                       Commande conforme
                                  </Button>
                                </>
                            }
                        </>
                        :<></>
                    }
              </>
              :<></>
            }
        </div>
      </div>
    }

    {
      open_reason_modal!==null
      ?<Set_refuse_reason open_reason_modal={open_reason_modal} setOpen_reason_modal={setOpen_reason_modal}
                  selected_suppliers={selected_suppliers} setSelected_suppliers={setSelected_suppliers} order_key={order_key} />
      :<></>
    }

    <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={wait_submitting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </>
  );
}

export default Order_details;
