import axios from "axios"
import './request_interceptor'


const end_point1 = "https://com.portailachats.ma/api/commissionService/";
//const end_point1 = "http://localhost:8000/api/commissionService/";




////////////////////////////////////////////////////
////
////////////////////////////////////////////////////

export const create_commission = async(data) => {
    return await axios.post(end_point1+'create_commission',data);
}

export const update_commission = async(id_commission,data) => {
    return await axios.post(end_point1+'update_commission/'+id_commission,data);
}

export const get_commissions = async() => {
    return await axios.get(end_point1+'get_commissions');
} 

export const get_commission_by_offre = async(id_offer) => {
    return await axios.get(end_point1+'get_commissions_by_id_offre/'+id_offer);
} 

export const get_commissions_by_id_auditor = async(departement,id_auditor,filterCategorie,searchFilter,searchValue,page) => {
    return await axios.get(end_point1+'get_commissions_by_id_auditor/'+departement+"/"+id_auditor+'/'+filterCategorie+'/'+searchFilter+'/'+searchValue+"?page="+page);
} 

export const get_commissions_by_id_service = async(id_service,filterCategorie,searchFilter,searchValue,page) => {
    return await axios.get(end_point1+'get_commissions_by_id_service/'+id_service+'/'+filterCategorie+'/'+searchFilter+'/'+searchValue+"?page="+page);
} 

export const validate_commission_by_id_auditor = async(id_auditor,data) => {
    return await axios.post(end_point1+'validate_commission_by_id_auditor/'+id_auditor,data);
} 

export const refuse_commission_by_id_auditor = async(id_auditor,data) => {
    return await axios.post(end_point1+'refuse_commission_by_id_auditor/'+id_auditor,data);
} 

export const notify_commission_membre = async(departement,data) => {
    return await axios.post(end_point1+'notify_commission_membre/'+departement,data);
} 



//////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////MainPage


export const getlatest_commissions = async(time_range) => {
    return await axios.get(end_point1+'latest_commissions/'+time_range);
}
