import '../../../../assets/css/request_details.css'
import './off_catalogue_request_details.css'
import React, { useState , useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import Backdrop from '@mui/material/Backdrop';
import swal from 'sweetalert';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckIcon from '@material-ui/icons/Check';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import View_refuse_reason from '../view_refuse_reason/view_refuse_reason';
import Rating from '@mui/material/Rating';

import { Accordion } from 'react-bootstrap';

import Loading_animation_view from '../../../loading_animation_view/loading_animation_view';
import { get_applicant_request_details, service_change_off_catalogue_state_request, 
    notify_initially_suppliers_with_articles, add_quote_to_orderRequest, service_change_order_state } from '../../../../services/purchase_service';
import { get_order_productsInfos, download_orderRequest_quote } from '../../../../services/for_allServices';
import Set_supplier from './set_suppliers/set_supplier';
import Set_refuse_reason from './set_refuse_reason/set_refuse_reason';
import Order_details from './order_details/order_details';
import Rating_modal from './rating_modal';


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    input: {
        display: 'none',
      },
  }));
  

function Request_detail({title,details}){
    return (
      <div className="requester_details_container">
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    <div className='accordion_header_content'> <span>{title}</span> </div>
                </Accordion.Header>
                <Accordion.Body>
                <div className="order_form_details_container">   
                 <div className="order_form_infos_container">
                    <div className='order_form_infos_title'> 1 - Infos personnel</div>
                    <Row className="mb-12">
                            <Form.Group className="col-6">
                                <label>Nom</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Nom *"
                                    value={details.requester_lastName}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group className="col-6">
                                <label>Prénom</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Prénom *"
                                    value={details.requester_firstName}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group className="col-6">
                                <label>Département</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Département *"
                                    value={details.requester_departement}
                                    readOnly
                                />           
                            </Form.Group>
                            <Form.Group className="col-6"> </Form.Group>
                            <Form.Group className="col-6">
                                <label>Email</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="email"
                                    placeholder="Email *"
                                    value={details.requester_email}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group className="col-6">
                                <label>Numéro de téléphone</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Numéro de téléphone *"
                                    value={details.requester_phoneNumber}
                                    readOnly
                                />
                            </Form.Group>
                    </Row>
                    <div className='order_form_infos_title'>2 - Infos de livraison</div>
                    <Row className="mb-12">
                            <Form.Group className="col-6">
                                <label>Pays</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Pays*"
                                    value={details.delivery_country}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group className="col-6">
                                <label>Ville</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Ville *"
                                    value={details.delivery_city}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group className="col-6">
                                <label>Adresse</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Adresse *"
                                    value={details.delivery_address}
                                    readOnly
                                />           
                            </Form.Group>
                            <Form.Group className="col-6">
                                <label>Code postal</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Code postal *"
                                    value={details.delivery_postCode}
                                    readOnly
                                />           
                            </Form.Group>

                            <Form.Group className="col-6">
                                <label>Date de livraison souhaitée</label>
                                <Form.Control
                                    type="date"
                                    min={(new Date()).toISOString().split('T')[0]}
                                    placeholder="Date de livraison souhaitée *"
                                    value={details.delivery_supposedDate.split(' ')[0]}
                                    readOnly
                                />
                            </Form.Group>
                    </Row>
                    <div className='order_form_infos_title'>3 - Autres informations</div>
                    <Row className="mb-12">
                            <Form.Group className="col-md-12">
                                <label>Description de la demande</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    as="textarea"
                                    rows={5}
                                    placeholder="Description de la demande *"
                                    value={details.off_catalogue}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group className="col-md-12">
                                <label>Objet de la demande</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    as="textarea" rows="3" 
                                    placeholder="Objet de la demande *"
                                    value={details.object}
                                    readOnly
                                />
                            </Form.Group>
                      </Row>
                    </div>
                </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
     </div>
    )
}

function state(request_state){
    return(
      <>
        {
         request_state === null || request_state === "demander à être changée"
         ?<Button variant="contained" className="status_waiting">{request_state?request_state:'En cours'}</Button>
         :request_state === "refusée" || request_state === "validation refusée" || request_state === "annulée" || request_state === "refusée par le fournisseur" || request_state === "non retenue" || request_state === "non conforme"
            ?<Button variant="contained" className="status_deny">{request_state}</Button>
            :request_state === "sous réserve de validation"
              ?<Button variant="contained" className="status_waiting">{request_state}</Button>
              :<Button variant="contained" className="status_success">{request_state}</Button>
        }
      </>
  
    );
  }


function Accordian_order_detail({request_code,supplier,id,upload_quote,download_quote,setSelected_suppliers,selected_suppliers,well_received,order_conforme,order_no_conforme,setOpen_rating_modal}){

    const [open_reason_modal,setOpen_reason_modal]=useState(null);

    return (
    <div>
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                <div className='order_accordion_header'>
                        <div  className='accordion_header_content'>
                            <span>{"Commande n°"+(id+1)+" / "}</span>
                            <span className="supplier_infos">{supplier.parentCompany+" -- "+supplier.fullName}</span> 
                            <div class="rating_final_score_container" onClick={(e)=>{ e.stopPropagation();setOpen_rating_modal({"request_code":request_code,"id_supplier":supplier.id,"id":id,"ratings":supplier.ratings})}}>
                                <Rating name="customized-10" value={(supplier.rating_score?(supplier.rating_score/5):0)} precision={0.1} max={1} readOnly/>
                                <span class="rating_final_score">{supplier.rating_score?supplier.rating_score:0}/5</span>
                            </div>
                        </div>
                        <div className='accordion_header_actions'>
                            <div  style={{display:'flex'}} >
                                <Tooltip title="Télecharger le devis" placement="bottom" >
                                    <span style={{marginRight:'8px'}}  onClick={(e)=>download_quote(e,supplier.id)} >
                                        <Fab size="small"  style={{backgroundColor:'#efefef',color:'black'}} className={supplier.order_state!=null ?"round_button":"round_button disabled_button"} >
                                            <GetAppRoundedIcon className="my_icon" />
                                        </Fab>
                                    </span>
                                </Tooltip>
                            </div>
                            <div className='action_button_container' >
                                {
                                    supplier.order_state!=="annulée" &&  supplier.order_state!=="conforme" && (supplier.order_state!==null && supplier.order_state!=="demander à être changée") && supplier.order_state!=="sous réserve de validation"
                                    ?<>
                                        {
                                            supplier.order_state!=="bien reçu" 
                                            ?<Tooltip title="la commande est reçue" placement="top" >
                                                <span style={{color:'rgb(228, 88, 32)'}}
                                                                   className={ supplier.order_state==="en cours de livraison"?"checkIcon":"checkIcon disabled_button"}>
                                                        <DoneIcon className="my_icon" style={{cursor:'pointer'}} onClick={(e) =>well_received(e,supplier.id,request_code,id)} />
                                                </span>
                                            </Tooltip>
                                            :<>
                                                <Tooltip title="Commande non conforme" placement="top" >
                                                    <span style={{color:'#e92a44',marginRight:'6px'}}  onClick={(e) =>order_no_conforme(e,supplier.id,request_code,id)}>
                                                        <RemoveDoneIcon style={{cursor:'pointer'}} className="my_icon closeSharpIcon" />
                                                    </span>
                                                </Tooltip>
                                                <Tooltip title="Commande conforme" placement="top" >
                                                    <span style={{color:'#18ac67'}}  onClick={(e) =>order_conforme(e,supplier.id,request_code,id)} >
                                                        <DoneAllIcon style={{cursor:'pointer'}} className="my_icon checkIcon"  />
                                                    </span>
                                                </Tooltip>
                                            </>
                                        }
                                    </>
                                    :<></>
                                }
                            </div> 
                            <div className='request_state_container'>
                                {state(supplier.order_state)}
                                <>
                                    {
                                    supplier.order_state === "sous réserve de validation" || supplier.order_state === "refusée" || supplier.order_state === "non conforme" 
                                    || supplier.order_state === "validation refusée" || supplier.order_state === "annulée" || supplier.order_state === "refusée par le fournisseur"
                                    ?<span className='view_reason_button' onClick={(e) => { e.stopPropagation(); setOpen_reason_modal(supplier.service_refuse_reason && supplier.order_state !== "refusée par le fournisseur" && supplier.order_state !== "refusée" && supplier.order_state !== "sous réserve de validation"?supplier.service_refuse_reason:supplier.refuse_reason)}}>Motif</span>
                                    :<></>
                                    }
                                </>
                            </div>
                        </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <Order_details selected_products_details={supplier} request_code={request_code} id_supplier={supplier.id} selected_suppliers={selected_suppliers}  
                                setSelected_suppliers={setSelected_suppliers} order_key={id} order_number={id+1} />
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>

        {
            open_reason_modal!==null
            ?<View_refuse_reason open_reason_modal={open_reason_modal} setOpen_reason_modal={setOpen_reason_modal} />
            :<></>
        }
        
    </div>
    )
}

function Order_detail({request_code,supplier,id,upload_quote,download_quote,accept_supplier_offer,refuse_supplier_offer,setOpen_rating_modal}){
    const classes = useStyles();
    const [open_reason_modal,setOpen_reason_modal]=useState(null);

    return (
        <div className="accordion supplier_accordion">
            <div eventKey="0" className='accordion-button'>
                        <div className='accordion_header_content'>
                            <span>{"Consultation n°"+(id+1)+" / "}</span>
                            <span className="supplier_infos">{supplier.parentCompany+" -- "+supplier.fullName}</span> 
                            <div class="rating_final_score_container" onClick={(e)=>{ e.stopPropagation();setOpen_rating_modal({"request_code":request_code,"id_supplier":supplier.id,"id":id,"ratings":supplier.ratings})}}>
                                <Rating name="customized-10" value={(supplier.rating_score?(supplier.rating_score/5):0)} precision={0.1} max={1} readOnly/>
                                <span class="rating_final_score">{supplier.rating_score?supplier.rating_score:0}/5</span>
                            </div>
                        </div>
                        <div className='accordion_header_actions'>
                            <div style={{display:'flex'}} >
                                <Tooltip title="Importer le devis" placement="bottom" >
                                    <span style={{marginRight:'8px'}}>
                                        <label htmlFor={'uploadContract'+supplier.id} style={{margin:'0'}}>
                                            <Fab size="small" variant="contained" component='span'>
                                                <CloudUploadIcon className="my_icon" />
                                            </Fab>  
                                            <input accept='application/*' className={classes.input} name={'uploadContract'+supplier.id}
                                                id={'uploadContract'+supplier.id} multiple type='file' onChange={(e)=>upload_quote(e.target.files[0],supplier.id,id)} />
                                        </label>
                                    </span>
                                </Tooltip>
                                <Tooltip title="Télecharger le devis" placement="bottom" >
                                    <span style={{marginRight:'8px'}}  onClick={(e)=>download_quote(e,supplier.id)} >
                                        <Fab size="small" color='default' className={supplier.order_state!=null ?"":"disabled_button"} >
                                            <GetAppRoundedIcon className="my_icon"/>
                                        </Fab>
                                    </span>
                                </Tooltip>
                                <>
                                    {
                                        supplier.order_state!=="devis ajouté" && supplier.order_state!=="refusée"  && supplier.order_state!=="non retenue"
                                        ?<></>
                                        :<>
                                            <Tooltip title="Refuser l'offre" placement="top" >
                                                <span style={{color:'#e92a44',marginRight:'3px'}} onClick={() => refuse_supplier_offer(supplier.id,id)}>
                                                    <CloseSharpIcon style={{cursor:'pointer'}} className="my_icon closeSharpIcon" />
                                                </span>
                                            </Tooltip>
                                            <Tooltip title="Retenir l'offre" placement="top" >
                                                <span style={{color:'#18ac67',marginRight:'9px'}} onClick={() => accept_supplier_offer(supplier.id,id)} >
                                                    <CheckIcon style={{cursor:'pointer'}} className="my_icon checkIcon" />
                                                </span>
                                            </Tooltip>
                                        </>
                                    }
                                </>
                            </div>
                            <div className='request_state_container'>
                                {state(supplier.order_state)}
                                <>
                                    {
                                    supplier.order_state === "sous réserve de validation" || supplier.order_state === "refusée" || supplier.order_state === "validation refusée" || supplier.order_state === "annulée" || supplier.order_state === "non retenue"
                                    ?<span className='view_reason_button' onClick={(e) => { e.stopPropagation(); setOpen_reason_modal(supplier.service_refuse_reason?supplier.service_refuse_reason:supplier.refuse_reason)}}>Motif</span>
                                    :<></>
                                    }
                                </>
                            </div>
                        </div>
            </div>
            {
                open_reason_modal!==null
                ?<View_refuse_reason open_reason_modal={open_reason_modal} setOpen_reason_modal={setOpen_reason_modal} />
                :<></>
            }
        </div>
    )
}


function Currently_added_suppliers({supplier,id,removeSupplier,selected_suppliers_length}){
    const classes = useStyles();
    const [open_reason_modal,setOpen_reason_modal]=useState(null);

    return (
        <div className="accordion supplier_accordion">
            <div eventKey="0" className='accordion-button'>
                <div className='accordion_header_content'>
                    <span>{"Consultation n°"+(selected_suppliers_length+id+1)+" / "}</span>
                    <span className='supplier_infos'>{supplier.parentCompany+" -- "+supplier.fullName}</span> 
                </div>
                <div className='accordion_header_actions'>
                    <RemoveIcon className="my_icon" onClick={() => removeSupplier(id)} />
                </div>        
            </div>
            {
                open_reason_modal!==null
                ?<View_refuse_reason open_reason_modal={open_reason_modal} setOpen_reason_modal={setOpen_reason_modal} />
                :<></>
            }
        </div>
    )
}

function Off_catalogue_request_details({request_code,setRequest_code}){

    const [suppliers,setSuppliers]=useState([]);
    const [selected_suppliers,setSelected_suppliers]=useState(null);

    const [details,setDetails]=useState(null);
    const [wait_submitting,setWait_submitting]=useState(false);

    const [order_count,setOrder_count]=useState(1);
    const [open_rating_modal,setOpen_rating_modal]=useState(null);

    const [open_supplier_modal,setOpen_supplier_modal]=useState(false);
    const [open_reason_modal,setOpen_reason_modal]=useState(null);

    const [notify,setNotify]=useState(false);
    const classes = useStyles();


    useEffect(()=>{
        setDetails(null);
        async function get_orders_products(tmp_selected_suppliers,request_code){
            var tmp_array = [];
            for(let id_supplier in tmp_selected_suppliers){
                let tmp_products_bysupplier = {};
                let products = JSON.parse(tmp_selected_suppliers[id_supplier].products)["products"];
                if(products){
                    let selected_products_tmp_array = [];
                    products.map((selected_product) => {
                        selected_products_tmp_array.push(selected_product.product_code);
                    })    
                    let formData = new FormData();
                    formData.append('products_codes',JSON.stringify(selected_products_tmp_array));
                    formData.append('request_code',request_code);
                    formData.append('id_supplier',tmp_selected_suppliers[id_supplier].id);
                    await get_order_productsInfos(formData).then((response)=>{
                        let selected_products_details_tmp_array = [];
                        let data = response.data;
                        //console.log(data);
                        for(const id_supplier_name in data){
                            data[id_supplier_name].products.map((element,id) => {
                                products.map((selected_product,id) => {
                                    if(selected_product.product_code === element.product_code){
                                            element.product_amount = element.product_amount?element.product_amount:selected_product.product_amount;
                                    } 
                                })
                                selected_products_details_tmp_array.push(element);
                            });
                            tmp_products_bysupplier = { ...tmp_selected_suppliers[id_supplier], ...data[id_supplier_name] };
                            tmp_products_bysupplier["order_state"] = response.data[id_supplier_name].order_state;
                            tmp_products_bysupplier["service_accord"] = response.data[id_supplier_name].service_accord;
                            tmp_products_bysupplier["products"] = selected_products_details_tmp_array;
                        }
                    }).catch((error)=>{
                        //console.log(error.response);
                    });
                }else{
                    tmp_products_bysupplier = tmp_selected_suppliers[id_supplier];
                }
                tmp_array.push(tmp_products_bysupplier);
            }
            setSelected_suppliers(tmp_array);
        } 

        const formData = new FormData();
        formData.append('request_code',request_code);
        get_applicant_request_details(formData).then((response)=>{
            //console.log(response.data);
            setDetails(response.data);
            get_orders_products(response.data.selected_suppliers,request_code);
        }).catch((error)=>{
            //console.log(error.response);
        })
    },[notify]);



    function accept_to_treateOrder(request_code){
        swal({
            title: "Etes-vous sûr ?",
            text: "Une fois que vous avez soumis votre réponse, vous ne pourrez plus la modifier !",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
            setWait_submitting(true);
            let formData = new FormData();
            formData.append('request_code',request_code);
            formData.append('state',"en cours de traitement");
            service_change_off_catalogue_state_request(formData).then((response)=>{
                //console.log(response.data);
                setWait_submitting(false);
                let tmp = { ...details };
                tmp.request_state = "en cours de traitement";
                setDetails(tmp);
                //console.log(tmp);
                swal("votre réponse a été enregistrée avec succès !", {
                        icon: "success",
                });
            }).catch((error=>{
                //console.log(error.response);
                setWait_submitting(false);
            }))
          }
        })
    }

    function refuse_to_treateOrder(request_code){
        let formData = new FormData();
        formData.append('request_code',request_code);
        formData.append('state',"refus de traitement");
        setOpen_reason_modal(formData);
    }

    function notify_suppliers(){
        swal({
            title: "Etes-vous sûr ?",
            text: "Une fois que vous avez soumis votre réponse, vous ne pourrez plus la modifier !",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
            setWait_submitting(true);
            let formData = new FormData();
            formData.append('request_code',request_code);
            formData.append('suppliers',JSON.stringify(suppliers));
            formData.append('articles',details.products);
            notify_initially_suppliers_with_articles(formData).then((response)=>{
                setWait_submitting(false);
                //console.log(response.data);
                setSuppliers([]);
                setNotify(!notify);
                swal("votre réponse a été enregistrée avec succès, et les fournisseurs seront informés !", {
                    icon: "success",
                });
            }).catch((error)=>{
                setWait_submitting(false);
                //console.log(error);
            })
          }
        });
    }

    function upload_quote(file,id_supplier,id){
        setWait_submitting(true);
        let formData = new FormData();
        //console.log(id_supplier);
        formData.append('request_code',request_code);
        formData.append('id_supplier',id_supplier);
        formData.append('quote_file',file);
        add_quote_to_orderRequest(formData).then((response)=>{
            setWait_submitting(false);
            let tmp_array = [ ...selected_suppliers ];
            tmp_array[id].order_state = "devis ajouté";
            setSelected_suppliers(tmp_array);
        }).catch((error)=>{
            setWait_submitting(false);
            //console.log(error.response);
        })
    }

    function accept_supplier_offer(id_supplier,id){
        swal({
            title: "Etes-vous sûr ?",
            text: "Une fois que vous avez soumis votre réponse, vous ne pourrez plus la modifier !",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
            setWait_submitting(true);
            let formData = new FormData();
            //console.log(id_supplier);
            formData.append('request_code',request_code);
            formData.append('id_supplier',id_supplier);
            formData.append('state',"retenue");
            service_change_order_state(formData).then((response)=>{
                setWait_submitting(false);
                let tmp_array = [ ...selected_suppliers ];
                tmp_array[id].order_state = "retenue";
                setSelected_suppliers(tmp_array);
                swal("votre réponse a été enregistrée avec succès !", {
                    icon: "success",
                });
            }).catch((error)=>{
                setWait_submitting(false);
                //console.log(error.response);
            })
          }
        });
    }

    function refuse_supplier_offer(id_supplier,id){
        let formData = new FormData();
        formData.append('request_code',request_code);
        formData.append('id_supplier',id_supplier);
        formData.append('state',"non retenue");
        formData.append('id',id);
        setOpen_reason_modal(formData);
    }

    function download_quote(e,id_supplier){
        e.stopPropagation();
        let formData = new FormData();
        formData.append('request_code',request_code);
        formData.append('id_supplier',id_supplier);
        download_orderRequest_quote(formData).then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            let file_extension = '';
            const disposition = response.headers['content-disposition'];
            if (disposition && disposition.includes('filename')) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    file_extension = matches[1].replace(/['"]/g, '').split(".")[1];
                }
            }
            fileLink.setAttribute('download', request_code[0]+request_code[1]+"_"+id_supplier+"_devis."+file_extension);
            document.body.appendChild(fileLink);
            fileLink.click();
        }).catch(err=>{
            //console.log(err.response);
        });
    }

    function removeSupplier(id){
        let tmp_array = [ ...suppliers ];
        tmp_array.splice(id,1);
        setSuppliers(tmp_array);
    }

    function set_supplier_rating_score_result(id,ratings,rating_score){
        let tmp_array = [ ...selected_suppliers ];
        tmp_array[id].rating_score = rating_score;
        tmp_array[id].ratings = ratings;
        setSelected_suppliers(tmp_array);
    }

    function well_received(e,id_supplier,request_code,id){
        e.stopPropagation();
        swal({
            title: "Etes-vous sûr ?",
            text: "Une fois que vous avez soumis votre réponse, vous ne pourrez plus la modifier !",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
            setWait_submitting(true);
            let formData = new FormData();
            formData.append('id_supplier',id_supplier);
            formData.append('request_code',request_code);
            formData.append('state',"bien reçu");
            service_change_order_state(formData).then((response)=>{
                //console.log(response.data);
                const tmp_order_state = response.data.order_state;
                let tmp_array = [ ...selected_suppliers ];
                tmp_array[id].order_state = tmp_order_state;
                setSelected_suppliers(tmp_array);
                setWait_submitting(false);
                swal("votre réponse a été enregistrée avec succès et le service sera bientôt notifié !", {
                    icon: "success",
                    });
            }).catch((error) => {
                //console.log(error.response);
                setWait_submitting(false);
            })
          }
        });
    }


    function order_conforme(e,id_supplier,request_code,id){
        e.stopPropagation();
        swal({
            title: "Etes-vous sûr ?",
            text: "Une fois que vous avez soumis votre réponse, vous ne pourrez plus la modifier !",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
            setWait_submitting(true);
            let formData = new FormData();
            formData.append('id_supplier',id_supplier);
            formData.append('request_code',request_code);
            formData.append('state',"conforme");
            service_change_order_state(formData).then((response)=>{
                //console.log(response.data);
                const tmp_order_state = response.data.order_state;
                let tmp_array = [ ...selected_suppliers ];
                tmp_array[id].order_state = tmp_order_state;
                setSelected_suppliers(tmp_array);
                setWait_submitting(false);
                swal("votre réponse a été enregistrée avec succès et le service sera bientôt notifié !", {
                    icon: "success",
                    });
            }).catch((error) => {
                //console.log(error.response);
                setWait_submitting(false);
            })
          }
        });
    }


    function order_no_conforme(e,id_supplier,request_code,id){
        e.stopPropagation();
        let formData = new FormData();
        formData.append('id_supplier',id_supplier);
        formData.append('request_code',request_code);
        formData.append('state',"non conforme");
        formData.append('id',id);
        setOpen_reason_modal(formData);
    }
    

    return (
        <Dialog disableEnforceFocus fullScreen open={request_code?true:false} onClose={() => setRequest_code(null)} className="my_full_dialog contract_full_dialog" > 
            <AppBar className={classes.appBar}  >
                <Toolbar style={{backgroundColor:'#343a40'}} >
                  <div className="request_order_toolbar_main_container">
                    <div className="request_order_toolbar_left page_title">
                        <IconButton edge="start" color="inherit" onClick={() => setRequest_code(null)} aria-label="close" >
                            <CloseIcon className="my_icon"  />
                        </IconButton>
                        <span> Details de demande : {request_code} </span>
                    </div>
                    {
                        details!==null && (details.request_state===null || details.request_state==="refus de traitement")
                        ?<div className="request_order_toolbar_right">
                            <Tooltip title="Refuser de la traiter" placement="bottom" >
                                <span>
                                <Button className={ !wait_submitting ? "submit_but notify_requester_button cancel_but" : "submit_but notify_requester_button cancel_but disabled_button"}
                                        onClick={() => refuse_to_treateOrder(request_code)}>
                                    Refuser
                                </Button>
                                </span>
                            </Tooltip>
                            <Tooltip title="Accepter de la traiter" placement="bottom" >
                                <span>
                                <Button className={ !wait_submitting ? "submit_but notify_requester_button valide_but" : "submit_but notify_requester_button valide_but disabled_button"}
                                        onClick={() => accept_to_treateOrder(request_code)}>
                                    Accepter
                                </Button>
                                </span>
                            </Tooltip>
                        </div>
                        :<></>
                    }
                  </div>
                </Toolbar>
            </AppBar>

          {
            details!==null && selected_suppliers!=null
            ?<div className="off_catalogue_requestDetails_container request_details_container">
                <div style={{width:'100%',display:'flex',justifyContent:'space-between',marginBottom:'7px'}}>
                  <div>
                    <span>
                        <Button onClick={notify_suppliers} className={ suppliers.length!==0 && !wait_submitting ? "submit_but notify_requester_button" : "submit_but notify_requester_button disabled_button"}>
                            Notifier les fournisseurs
                        </Button>
                    </span>
                   </div>
                   <div className="add_icon_container">
                    <Tooltip title="Ajouter les fournisseurs" placement="bottom" >
                        <span className={ details.request_state==="en cours de traitement" ? "" : "disabled_button"} onClick={() => setOpen_supplier_modal(true)}>
                            <AddIcon className="my_icon" />
                        </span>
                     </Tooltip>
                  </div> 
                </div>
                <Request_detail 
                    title="Détails" 
                    details={details} />
                <>
                    {
                        selected_suppliers.map((supplier,id)=>(
                            supplier.order_state===null ||  supplier.order_state==="refusée" ||  supplier.order_state==="devis ajouté" || supplier.order_state==="non retenue"
                            ?<Order_detail request_code={request_code} supplier={supplier} id={id} key={"supplierid"+id} upload_quote={upload_quote} download_quote={download_quote} 
                                    accept_supplier_offer={accept_supplier_offer} refuse_supplier_offer={refuse_supplier_offer} setOpen_rating_modal={setOpen_rating_modal} />
                            :<Accordian_order_detail supplier={supplier} id={id} key={"supplierid"+id} download_quote={download_quote} 
                                    request_code={request_code} setSelected_suppliers={setSelected_suppliers} selected_suppliers={selected_suppliers} 
                                    well_received={well_received} order_conforme={order_conforme} order_no_conforme={order_no_conforme} setOpen_rating_modal={setOpen_rating_modal} />
                        ))
                    }
                </>
                <>
                    {
                         suppliers.map((supplier,id)=>(
                            <Currently_added_suppliers supplier={supplier} id={id} key={"id-"+id} selected_suppliers_length={selected_suppliers.length} removeSupplier={removeSupplier} />
                        ))
                    }
                </>
                <>
                <div className="articles_container">
                    {
                        JSON.parse(details.products).articles.map((article,id)=>(
                            <div className='articles_view_container' key={"articles"+id}>
                                <div className="article_view_header_container">
                                    <span>Article n°{id+1}</span>
                                </div>
                                <div>{article}</div>
                            </div>
                        ))
                    }
                </div>
                </>
             </div>
            :<Loading_animation_view />
          }
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={wait_submitting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        
        {
            open_supplier_modal!==false
            ?<Set_supplier open_supplier_modal={open_supplier_modal} setOpen_supplier_modal={setOpen_supplier_modal} 
                            existent_suppliers={suppliers} selected_suppliers={selected_suppliers} setExistent_suppliers={setSuppliers} />
            :<></>
        }

        {
            open_reason_modal!=null
            ?<Set_refuse_reason open_reason_modal={open_reason_modal} setOpen_reason_modal={setOpen_reason_modal} 
                            details={details} setDetails={setDetails} selected_suppliers={selected_suppliers} setSelected_suppliers={setSelected_suppliers} />
            :<></>
        }

        {
            open_rating_modal!=null
            ?<Rating_modal open_rating_modal={open_rating_modal} setOpen_rating_modal={setOpen_rating_modal} set_supplier_rating_score_result={set_supplier_rating_score_result}/>
            :<></>
        }

     </Dialog>
    )
}

export default Off_catalogue_request_details;
