import './docs_list.css';
import React, { useEffect,useState } from 'react';
import { useHistory } from "react-router-dom";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from "react-js-pagination";
import { Fab } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@mui/material/Tooltip';

import {makeStyles} from '@material-ui/core/styles';
import Loading_animation_view from '../../loading_animation_view/loading_animation_view'
import Docs_creator from './docs_creator/docs_creator';
import { get_docs } from '../../../services/company_admin_service';




const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

const columns = [
    { id: 'doc', label: "Document", minWidth: 0 },
    { id: 'doc_type', label: "Type de document", minWidth: 0 },
    { id: 'doc_workflow', label: "Workflow", minWidth: 0 },
    { id: 'doc_permission', label: "Permission", minWidth: 0 },
    { id: 'doc_validation', label: "Validation", minWidth: 0 },
    { id: 'doc_state', label: "Archivée", minWidth: 0 },
  ];
  

function state(doc_state){
    return(
        <>
        {
            doc_state === 1
            ?<Button variant="contained" className="status_deny">{"archivée"}</Button>
            :<Button variant="contained" className="status_success">{"non archivée"}</Button>
        }
        </>

    );
}


const users = ["Fournisseur","Service d'achat","Service métier","Service de finance"];
var reg = new RegExp(/^\d+$/);

function Docs({docs,update_doc,dont_archive,archive}){
    
    return(
        <>
        {
            docs.map((doc,id) => (
                <TableRow key={"doc"+id}>
                    <TableCell align="left">
                        <div style={{width:'fit-content'}}>
                            {doc.doc_name}
                            <div style={{width:'100%',display:'flex'}}>
                                <Tooltip title="modifier le contrat" placement="top" >
                                    <span className={"editIcon"}  onClick={() => update_doc(doc)} >
                                            <EditIcon className="my_icon" style={{cursor:'pointer'}} />
                                    </span>
                                </Tooltip>
                            </div>
                        </div>
                    </TableCell>
                    <TableCell align="left">
                        {doc.doc_type}
                    </TableCell>
                    <TableCell align="left">
                        {doc.workflows}
                    </TableCell>
                    <TableCell align="left">
                        {
                            (doc.permission).split(",").map((user)=>(
                                users[user]+" / "
                            ))
                        }
                    </TableCell>
                    <TableCell align="left">
                        {
                            (doc.validation)?(doc.validation).split(",").map((user)=>(
                                reg.test(user)?users[user]+" / ":user+" / "
                            ))
                            :<></>
                        }
                    </TableCell>
                    <TableCell align="left">
                        <div className='documentsStatus_valide'>
                            <div>
                                <div><CheckIcon className="checkIcon" onClick={()=>dont_archive(doc.id)} /></div>
                                <div><CloseSharpIcon className="closeSharpIcon" onClick={()=>archive(doc.id)} /></div>
                            </div>
                            {state(doc.archived)}
                        </div>
                    </TableCell>
                </TableRow>                
             ))
        }
        </>
    );
}



function Docs_list() {

  const classes = useStyles();
  const [url_updated , setUrl_updated] = useState(null);
  let history = useHistory();

  const [doc_state,setdoc_state] = useState("default");

  const [docs,setdocs]=useState(null);
  const [waiting, setWaiting] = useState(false);

  const [update_doc_modal, setupdate_doc_modal] = useState(null);
  const [add_doc_modal, setadd_doc_modal] = useState(false);
  const [doc_added, setdoc_added] = useState(null);

    const [currentPage,setCurrentPage]=useState(1);
    const [dataPerPage,setdataPerPage] =useState(6);
    const [data_count,setdata_count]=useState(0);
    
  useEffect(()=>{
    setdata_count(0);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let doc_state_tmp = "default";

    async function load_data(){
        if(urlParams.get('contrat')){
            setdoc_state(urlParams.get('contrat'));
            doc_state_tmp = urlParams.get('contrat');
        }
        get_docs(doc_state_tmp).then((response)=>{
            //console.log(response.data);
            setdata_count(response.data.total);
            setdocs(response.data.data);
        }).catch((error)=>{
            //console.log(error.response);
        })
    }

    load_data();
  },[doc_added,url_updated,history.location]);

  function update_doc(subTypes){
    setupdate_doc_modal(subTypes);
    setadd_doc_modal(true);
  }

  function select_doc_stat(value){
    setdoc_state(value);
    let path = window.location.href.split('?')[0]
    window.location.href = path+"?contrat="+value;
  }

  function dont_archive(doc_id){

  }

  function archive(doc_id){

  }

  async function change_page(page){
    await setCurrentPage(page);
    let path = history.location.pathname;
    let params = history.location.search;
    let search_params = params.split('&');
    let tmp_params = path;
    if(!params) history.push(path+"?page="+page);
    else{
      for(let i in search_params){
        if(search_params[i].includes("page=")){
          if(i==0) tmp_params=tmp_params+"?page="+page;
          else tmp_params=tmp_params+"&page="+page;
        }else{
          if(i==0) tmp_params=tmp_params+search_params[i];
          else tmp_params=tmp_params+"&"+search_params[i];
        }
      }
      if(!tmp_params.includes("page=")) tmp_params=tmp_params+"&page="+page;
      history.push(tmp_params);
    }
    await setUrl_updated(page);
  }

  return (
    <>
    {
        docs==null
        ?<Loading_animation_view />
        :<div className='docs_list_container'>
            <div className='docs_list_container_header'>
                    <div className='title_container'>
                        <label>Documents</label>
                        <Button variant="contained" startIcon={<AddIcon />} className={waiting ? "submit_but disabled_button":"submit_but"} onClick={() => setadd_doc_modal(true)}>
                            Ajouter
                        </Button>
                    </div>
                    <div className='select_container'>
                        <FormControl className={classes.formControl} style={{width:"225px"}} >
                            <InputLabel>Etat de document</InputLabel>
                                <Select required
                                    value={doc_state}
                                    onChange={e => {
                                        select_doc_stat(e.target.value);
                                    }} name="Etat" >
                                    <MenuItem value={"default"} disabled>
                                        Etat de document
                                    </MenuItem>
                                    <MenuItem value={"recent"}>Récent</MenuItem>
                                    <MenuItem value={"old"}>Ancien</MenuItem>
                                </Select>
                        </FormControl>
                    </div>
            </div>
                    <TableContainer className="docs_list_table">
                            <Table className={classes.table} aria-label="sticky table">
                                <TableHead>
                                <TableRow>
                                    {
                                        columns.map((column) => (
                                            <TableCell key={column.id} align="left">{column.label}</TableCell>
                                        ))
                                    }
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    Object.keys(docs).length!==0
                                    ?
                                    <>
                                        <Docs docs={docs} update_doc={update_doc} dont_archive={dont_archive} archive={archive} />
                                    </>
                                    :<div style={{margin:"10px",fontSize:".8rem",width:'100%', textAlign:"center",alignContent:"center"}}>Aucun document disponible</div>
                                }
                                </TableBody> 
                            </Table>
                        </TableContainer>
                        {
                            Object.keys(docs).length!==0
                                ?<div className="pagination_container">
                                    <Pagination
                                        activePage={Number(currentPage)}
                                        itemsCountPerPage={dataPerPage}
                                        totalItemsCount={Number(data_count)}
                                        pageRangeDisplayed={4}
                                        onChange={change_page.bind(this)}
                                    />
                                </div>
                                :<></>
                            }
            </div>
        }

        {
            add_doc_modal!==false
            ?<Docs_creator add_doc_modal={add_doc_modal} setadd_doc_modal={setadd_doc_modal} docs={docs} setdocs={setdocs} />
            :<></>
        }
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={waiting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </>
  );
}

export default Docs_list;