import './delivery_supposed_date_modal.css'
import React, { useState } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import CancelIcon from '@material-ui/icons/Cancel';
import SendIcon from '@mui/icons-material/Send';
import Backdrop from '@mui/material/Backdrop';
import InputAdornment from '@material-ui/core/InputAdornment';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import swal from 'sweetalert';
import CircularProgress from '@mui/material/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';
import { supplier_change_order_state } from '../../../../../../services/supplier_service';


const useStyles = makeStyles((theme) => ({
    button: { margin: theme.spacing(1),},
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
  }));


function Delivery_supposed_date_modal({open_deliveryDate_modal,setOpen_deliveryDate_modal,setOrder_state,products_bysupplier,setProducts_bysupplier,order_key}){

  const classes = useStyles();
  const [errorText,setErrorText] = useState("");
  const [waiting, setWaiting] = useState(false);

  const [supposed_date,setSupposed_date]=useState('');


  function submit_refuse_reason() {
    setErrorText("");
    if(supposed_date===''){
        setErrorText("Champ vide !");
      }else{
        swal({
            title: "Etes-vous sûr ?",
            text: "Une fois que vous avez soumis votre réponse, vous ne pourrez plus la modifier !",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
          setWaiting(true);
          open_deliveryDate_modal.append('supposed_delivery_date',supposed_date);
            supplier_change_order_state(open_deliveryDate_modal).then((response)=>{
                setOpen_deliveryDate_modal(null);
                setWaiting(false);
                let tmp_products = { ...products_bysupplier };
                tmp_products[order_key].service_accord = true;
                tmp_products[order_key].order_state = "en cours de livraison";
                setProducts_bysupplier(tmp_products);
                setOrder_state("en cours de livraison");
                swal("votre réponse a été enregistrée avec succès et le service sera bientôt notifié !", {
                        icon: "success",
                  });
              }).catch((error)=>{
                //console.log(error.response);
                setWaiting(false);
              });
        }
      });
      }      
   }

     

    return (
      <Dialog  fullWidth={true} maxWidth={'md'} open={open_deliveryDate_modal!=null?true:false} onClose={()=>setOpen_deliveryDate_modal(null)} aria-labelledby="form-dialog-title" >
        <DialogTitle id="form-dialog-title">Date estimée d'arrivée de la commande</DialogTitle>
        <DialogContent>
          <div>
              <div style={{width:"100%",marginTop:"5px"}} >
                <TextField
                    type="date"
                    value={supposed_date}
                    label="Date estimée de la livraison"  min={(new Date()).toLocaleDateString()}  className="input_form" 
                    InputProps={{ startAdornment: ( <InputAdornment position="start"> <EventAvailableIcon className="my_icon"  /> </InputAdornment> )}} onChange={(e)=>setSupposed_date(e.target.value)}
                />
              </div>
          </div>
          
     </DialogContent>
        <DialogActions>
          <div className='dialogBottom'>
            <div>
                {
                    errorText == "" 
                    ?<></>
                    :<p className={errorText ? "col-md-10 error" : "col-md-10 valid-champ"}>
                            {errorText}
                        </p>
                }
            </div>
            <div>
              <Button variant="contained" className="submit_but cancel_but" startIcon={<CancelIcon className="my_icon" />} onClick={()=>setOpen_deliveryDate_modal(null)} >
                  Annuler
              </Button>
              <Button variant="contained" className="submit_but valide_but" startIcon={<SendIcon className="my_icon" />} onClick={submit_refuse_reason} >
                  Envoyer
              </Button> 
            </div>
          </div>
        </DialogActions>

        <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waiting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

      </Dialog>
    )
}

export default Delivery_supposed_date_modal
