import React, { useState} from 'react'

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CancelIcon from '@material-ui/icons/Cancel';
import InputLabel from '@material-ui/core/InputLabel';
import AddIcon from '@material-ui/icons/Add';
import {makeStyles} from '@material-ui/core/styles';
import Form from 'react-bootstrap/Form'




const useStyles = makeStyles((theme) => ({
    button: { margin: theme.spacing(1),},
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
  }));


function New_node_modal({open_node_modal,setopen_node_modal,load_node}){

    const classes = useStyles();
  

    const [state,setState]=useState("");
    const [description,setdescription]=useState("");

    const [errorText,setErrorText] = useState("");

    function propose_commission() {    
        setErrorText("");
        if(state===""){
            setErrorText("Champ invalid !");
        }else{
            load_node(open_node_modal,state,description);
        }
    }

    return (
        <Dialog className='new_node_modal' fullWidth={true} maxWidth="sm" open={open_node_modal?true:false} onClose={()=>setopen_node_modal(null)} aria-labelledby="form-dialog-title" >            
            <DialogTitle id="form-dialog-title">Nouveau noeud</DialogTitle>
            <DialogContent>
            <label htmlFor='reason'>État</label>
                    <Form.Group name="reason" style={{width: '100%',height: '100%',position: 'relative',padding:"0px"}} >
                        <Form.Control as="input" className="inputform" placeholder='État*' value={state} onChange={(e)=>setState(e.target.value)} />
                    </Form.Group>
                    
                <div  style={{margin:"15px 0px 15px 0px"}}>
                    <label htmlFor='reason'>Description (facultatif)</label>
                    <Form.Group name="reason" style={{width: '100%',height: '100%',position: 'relative',padding:"0px"}} >
                        <Form.Control as="textarea" placeholder='Description (facultatif)' rows={6} value={description} onChange={(e)=>setdescription(e.target.value)} />
                    </Form.Group>
                </div>
            </DialogContent>
            <DialogActions>
            <div className='dialogBottom'>
                <div>
                    {
                        <p className={errorText ? "col-md-10 error" : "col-md-10 valid-champ"}>
                                {errorText ? errorText : ""}
                            </p>
                    }
                </div>
                <div>
                   <Button variant="contained" className="submit_but cancel_but" startIcon={<CancelIcon className="my_icon" />} onClick={()=>setopen_node_modal(null)} >
                        Annuler
                    </Button>

                    <Button variant="contained" className="submit_but valide_but" startIcon={<AddIcon className="my_icon" />} onClick={propose_commission} >
                        Ajouter
                    </Button>
                
                </div>
            </div>      
                
            </DialogActions>
        </Dialog>
    )
}

export default New_node_modal
