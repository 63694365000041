import './drag_drop_files_zone.css'
import React from "react";
import { useDropzone } from "react-dropzone";




function My_dropzone({ onDrop, import_reason, open }){
  const { getRootProps, getInputProps, isDragActive, isDragReject, acceptedFiles } =
  useDropzone({
    onDrop,
    maxFiles:3,
    accept: {
      'application/*':  ['vnd.ms-excel','vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
      'text/*': ['.csv','.xls','.xlsx'],
    }
  });
  return (
      <div {...getRootProps({ className: import_reason?isDragReject?"dropzone dropzone_error":"dropzone":"dropzone disabled_button" })} >
        <input {...getInputProps()} />
        {
          isDragActive 
          ? isDragReject
           ?<p> Les seuls formats de fichiers acceptés sont *.csv et *.xls* </p>
           :<p className="dropzone-content">
              déposez-les ici
            </p>
          :<p> Faites glisser et déposez vos fichiers ici (*.csv, *.xls*) </p>
        }

        <button type="button" onClick={open} className="submit_but upload_button">
            Importez vos fichiers
        </button>
      </div>
  );

}
export default My_dropzone;