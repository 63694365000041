import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import PersonIcon from '@material-ui/icons/Person';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import CodeIcon from '@mui/icons-material/Code';
import HomeIcon from '@mui/icons-material/Home';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PhoneIcon from '@mui/icons-material/Phone';

function Plus_details_dialog({openDetails,handleCloseDetails,chosenSubmission,emailService}){


    return (
        <Dialog fullWidth={true} maxWidth={'md'} open={openDetails} onClose={handleCloseDetails} aria-labelledby="form-dialog-title" >
          <DialogContent>
            <div className="plus_details_dialog_container">
            <TextField  label="Maison mère" value={chosenSubmission.parentCompany} className="header_form"
                  InputProps={{ startAdornment: ( <InputAdornment position="start"> <HomeIcon className="my_icon" /> </InputAdornment> ), }}
                  disabled
              />
              <TextField  label="Adresse" value={chosenSubmission.address+'-'+chosenSubmission.city+'-'+chosenSubmission.country} className="header_form"
                  InputProps={{ startAdornment: ( <InputAdornment position="start"> <PersonPinCircleIcon className="my_icon" /> </InputAdornment> ), }}
                  disabled
              />
              <TextField  label="cnss" type="number"value={chosenSubmission.cnss} className="header_form"
                  InputProps={{ startAdornment: ( <InputAdornment position="start"> <CodeIcon className="my_icon" /> </InputAdornment> ), }}
                  disabled
              />
              <TextField  label="téléphone " value={chosenSubmission.phoneNumber} className="header_form"
                  InputProps={{ startAdornment: ( <InputAdornment position="start"> <PhoneIcon className="my_icon" /> </InputAdornment> ), }}
                  disabled
              />
              <TextField  label="Identifiant fiscal" value={chosenSubmission.fiscalIdentifier} className="header_form"
                  InputProps={{ startAdornment: ( <InputAdornment position="start"> <CodeIcon className="my_icon" /> </InputAdornment> ), }}
                  disabled
              />
              <TextField  label="Num de patente"  value={chosenSubmission.licenseNumber} className="header_form"
                  InputProps={{ startAdornment: ( <InputAdornment position="start"> <CodeIcon className="my_icon" /> </InputAdornment> ), }}
                  disabled
              />
              <TextField  label="Num ICE " value={chosenSubmission.iceNumbner} className="header_form"
                  InputProps={{ startAdornment: ( <InputAdornment position="start"> <CodeIcon className="my_icon" /> </InputAdornment> ), }}
                  disabled
              />
              {/* <TextField  label="Année de constitution"  value={chosenSubmission.anneeConstitution} className="header_form"
                  InputProps={{ startAdornment: ( <InputAdornment position="start"> <DateRangeIcon className="my_icon" /> </InputAdornment> ), }}
                  disabled
              /> */}
              
            </div>
          
          </DialogContent>
        </Dialog>  
    );
}

export default Plus_details_dialog;
