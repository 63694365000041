import axios from "axios"
import './request_interceptor'



const end_point1 = "https://com.portailachats.ma/api/widgets_views_service/";
//const end_point1 = "http://localhost:8000/api/widgets_views_service/";





////////////////////////////////////////////////////
////
////////////////////////////////////////////////////

export const set_widgets_view_byId_login = async(data) => {
    return await axios.post(end_point1+'set_widgets_view_byId_login',data);
} 

export const get_widgets_view_byId_login = async(id_login) => {
    return await axios.get(end_point1+'get_widgets_view_byId_login/'+id_login);
} 

