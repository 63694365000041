import axios from "axios"
import './request_interceptor'

const end_point1 = "https://com.portailachats.ma/api/services/";
//const end_point1 = "http://localhost:8000/api/services/";





////////////////////////////////////////////////////
//// logins
////////////////////////////////////////////////////

export const login_request = async(data) => {
    return await axios.post(end_point1+'login',data);
}

export const logout_request = async(id) => {
    return await axios.post(end_point1+'logout/'+id);
}

export const get_email_by_idUser = async(id_user) => {
    return await axios.get(end_point1+'get_email_by_idUser/'+id_user);
}


////////////////////////////////////////////////////
//// company
////////////////////////////////////////////////////

export const get_company_logo = async() => {
    return await axios.get(end_point1+'get_company_logo');
}


////////////////////////////////////////////////////
//// ServiceIdentity
////////////////////////////////////////////////////


export const get_serviceIdentity_profileImg = async(id_service) => {
    return await axios({
        url: end_point1+'get_serviceIdentity_profileImg/'+id_service,
        method:'POST',
          responseType: 'blob',
          headers: {
            'Content-Type': 'image/*',
          },
      });
} 


export const update_serviceIdentity_infos = async(data) => {
    return await axios.post(end_point1+'update_serviceIdentity_infos',data);
} 

export const update_serviceIdentity_profileImg = async(data) => {
    return await axios.post(end_point1+'update_serviceIdentity_profileImg',data);
} 

export const get_some_purchaseServiceInfos_by_idSubmission = async(id_submission) => {
    return await axios.get(end_point1+'get_some_purchaseServiceInfos_by_idSubmission/'+id_submission);
}

export const get_contracts_types = async() => {
    return await axios.get(end_point1+'get_contracts_types');
}


////////////////////////////////////////////////////
//// bills
////////////////////////////////////////////////////


export const download_bills_docs = async(data) => {
    return await axios({
        url: end_point1+'download_bills_docs',
        method:'POST',
          data:data,
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/*',
          },
      });
}

export const services_bills_accord_withEmail = async(data) => {
    return await axios.post(end_point1+'services_bills_accord_withEmail',data);
}

export const services_requests_orders_bills_accord_withEmail = async(data) => {
    return await axios.post(end_point1+'services_requests_orders_bills_accord_withEmail',data);
}

export const get_allPurchaseServices = async() => {
    return await axios.get(end_point1+'get_allPurchaseServices');
}

export const get_allFinancesService = async() => {
    return await axios.get(end_point1+'get_allFinancesService');
}

export const get_user_emails_sent = async(id_login,searchFilete,page) => {
    return await axios.get(end_point1+'get_user_emails_sent/'+id_login+"/"+searchFilete+"?page="+page);
}

export const search_users_byname_or_email_forServ = async() => {
    return await axios.get(end_point1+'search_users_byname_or_email_forServ');
}

export const send_email_from_emails_menu = async(data) => {
    return await axios.post(end_point1+'send_email_from_emails_menu',data);
}



////////////////////////////////////////////////////
//// applicant_requests
////////////////////////////////////////////////////

export const requester_change_order_state = async(data) => {
    return await axios.post(end_point1+'requester_change_order_state',data);
}


////////////////////////////////////////////////////
//// suppliers
////////////////////////////////////////////////////


export const get_suppliers_by_category = async(filterCategorie) => {
    return await axios.get(end_point1+'get_suppliers_by_category/'+filterCategorie);
}

export const get_suppliers = async() => {
    return await axios.get(end_point1+'get_suppliers');
}


////////////////////////////////////////////////////
//// products
////////////////////////////////////////////////////

export const get_requester_purchase_requests = async(data,purchaseRequest_type,searchFilter,searchValue,page) => {
    return await axios.post(end_point1+'get_requester_purchase_requests/'+purchaseRequest_type+'/'+searchFilter+'/'+searchValue+"?page="+page,data);
}

export const get_products = async(filterCategorie,filterSupplier,searchValue,page) => {
    return await axios.get(end_point1+'get_products/'+filterCategorie+"/"+filterSupplier+"/"+searchValue+"?page="+page);
}

export const get_products_byCode = async(product_code) => {
    return await axios.post(end_point1+'get_products_byCode',product_code);
}

export const get_order_productsInfos = async(product_code) => {
    return await axios.post(end_point1+'get_order_productsInfos',product_code);
}


export const get_supplier_products = async(id_supplier,filterCategorie,searchValue,page) => {
    return await axios.get(end_point1+'get_supplier_products/'+id_supplier+"/"+filterCategorie+"/"+searchValue+"?page="+page);
}

export const add_purchase_request = async(request_infos) => {
    return await axios.post(end_point1+'add_purchase_request',request_infos);
}

export const edit_purchase_request = async(request_infos) => {
    return await axios.post(end_point1+'edit_purchase_request',request_infos);
}

export const add_off_catalogue_purchase_request = async(request_infos) => {
    return await axios.post(end_point1+'add_off_catalogue_purchase_request',request_infos);
}

export const add_SelectedSupplier_to_purchase_request = async(request_infos) => {
    return await axios.post(end_point1+'add_SelectedSupplier_to_purchase_request',request_infos);
}

export const download_order_file = async(data) => {
    return await axios({
        url: end_point1+'download_order_file',
        method:'POST',
        data:data,
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/*',
        },
      });
}


export const accord_request_orders_by_requester = async(data) => {
    return await axios.post(end_point1+'accord_request_orders_by_requester',data);
}

export const accord_order_file_by_supplier = async(data) => {
    return await axios.post(end_point1+'accord_order_file_by_supplier',data);
}

export const download_orderRequest_quote = async(data) => {
    return await axios({
        url: end_point1+'download_orderRequest_quote',
        method:'POST',
          data:data,
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/*',
          },
      });
}


////////////////////////////////////////////////////
//// docs
////////////////////////////////////////////////////

export const get_ao_docs = async(wf,id) => {
    return await axios.get(end_point1+'get_wf_docs/'+wf+"/"+id);
}

export const import_doc = async(data) => {
    return await axios.post(end_point1+'import_doc',data);
}

export const download_doc = async(data) => {
    return await axios({
        url: end_point1+'download_doc',
        method:'POST',
          data:data,
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/*',
          },
      });
}


export const validate_doc = async(data) => {
    return await axios.post(end_point1+'validate_doc',data);
}
