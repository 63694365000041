import axios from "axios"
import './request_interceptor'


const end_point1 = "https://com.portailachats.ma/api/auditorService/";
//const end_point1 = "http://localhost:8000/api/auditorService/";



////////////////////////////////////////////////////
////
////////////////////////////////////////////////////

export const get_auditors = async() => {
    return await axios.get(end_point1+'get_auditors');
} 

export const get_auditors_for_service = async() => {
    return await axios.get(end_point1+'get_auditors_for_service');
} 

export const get_auditors_by_departement = async(departement) => {
    return await axios.get(end_point1+'get_auditors_by_departement/'+departement);
} 

export const get_auditor_by_id = async(id_auditor) => {
    return await axios.get(end_point1+'get_auditor_byId/'+id_auditor);
} 

export const get_auditor_byIdLogin = async(id_auditor) => {
    return await axios.get(end_point1+'get_auditor_byIdLogin/'+id_auditor);
} 

export const update_auditor = async(id_auditor,data) => {
    return await axios.post(end_point1+'update_auditor/'+id_auditor,data);
} 

export const archive_auditor = async(id_auditor,data) => {
    return await axios.put(end_point1+'archive_auditor/'+id_auditor,data);
} 



//////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////MainPage



export const get_offers_opening_time = async(id,departement) => {
    return await axios.get(end_point1+'get_offers_opening_time/'+id+'/'+departement);
}