import '../send_email/send_email.css'
import React,{useEffect, useState} from 'react'

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import SendIcon from '@material-ui/icons/Send';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Multiselect from 'multiselect-react-dropdown';
import {makeStyles} from '@material-ui/core/styles';
import Fab from '@mui/material/Fab';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CLoseIcon from '@material-ui/icons/Close';
import swal from 'sweetalert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';


const useStyles = makeStyles((theme) => ({
    button: { margin: theme.spacing(1),},
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
  }));
 


  function getFileName(file) {
    const name = file.name;
    const lastDot = name.lastIndexOf('.');

    const fileName = name.substring(0, lastDot);
    const ext = name.substring(lastDot + 1);
    return fileName+"."+ext;
}


function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2)+" "+sizes[i];
 }


function Email_details({open_email_details,setOpen_email_details}){
    
    const classes = useStyles();
    let service=JSON.parse(localStorage.getItem('user-info'))

    const [selected_recipients,setSelected_Recipients] = useState(null);
    const [object,setObject] = useState(open_email_details.object);
    const [editorContent, seteditorContent] = useState(open_email_details.content);
    const [waiting,setWaiting] = useState(false);

    const [attached_files,setAttached_files] = useState((open_email_details.attached_files).split(','));

    useEffect(()=>{
        async function load_data(){
            let tmp_recipients =  (open_email_details.recipients).split(',');
            let tmp_arr = [];
            tmp_recipients.map((tmp_rec)=>{
                tmp_arr.push({
                    "name":tmp_rec,
                    'id':tmp_rec
                })
            })
            await setSelected_Recipients(tmp_arr);
        }

        load_data();

    },[])


    /* function submit(){
       swal({
            title: "Etes-vous sûr ?",
            text: "!!!!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                setWaiting(true);
                let formData = new FormData();
                formData.append('id_login',service.id);
                formData.append('object',object);
                formData.append('content',editorContent);
                formData.append('attached_files',attached_files);
                attached_files.map((attached_file)=>{
                    formData.append('attached_files[]',attached_file);
                })
                selected_recipients.map((recipient)=>{
                    formData.append('recipients[]',recipient.id);
                })
                send_email_from_emails_menu(formData).then((response)=>{
                    setWaiting(false);
                    swal("Message envoyé avec succès !", {
                        icon: "success",
                        buttons: true,
                    }).then((willDelete) => {
                        if (willDelete) {
                    }})
                    setUrl_updated(response.data);
                    setOpen_email_details(false);
                }).catch((error)=>{
                    setWaiting(false);
                    //console.log(error.response);
                })
        }})
    } */

    return (
        <Dialog className="send_email_container" fullWidth={true} maxWidth="md" open={open_email_details} onClose={()=>setOpen_email_details(null)} aria-labelledby="form-dialog-title" >            
            <DialogTitle id="form-dialog-title">{open_email_details.object}</DialogTitle>
            <DialogContent>
            <div className="form">
                <Row className="mb-12">
                    <Form.Group className="col-md-12">
                        <Multiselect
                            selectedValues={selected_recipients?selected_recipients:[]}
                            disable={true}
                            placeholder="Destinataires"
                            displayValue="name"
                            emptyRecordMsg="Aucune option"
                        />
                    </Form.Group>

                    <Form.Group className="col-md-12">
                        <Form.Control
                            className="disabled_button"
                            type="text"
                            placeholder="Objet"
                            value={object}
                        />
                    </Form.Group>
                    
                    <div className="text_edit_cont">
                         <SunEditor
                            lang="fr"
                            width="100%"
                            height="100%"
                            disable={true}
                            defaultValue={editorContent}
                            setOptions={{
                                buttonList: []
                            }}
                            /> 
                    </div>

                    <div className="files_container">
                        {
                            attached_files.map((file,id)=>(
                                <div className='file_container' >
                                    <div className="file_infos">{file}</div>
                                </div>
                            ))
                        }
                    </div>
                </Row>
            </div>
            </DialogContent>
           {/*  <DialogActions>
            <div className='dialogBottom'>
                <div>
                    <Button variant="contained" className="submit_but valide_but" onClick={submit} startIcon={<SendIcon className="my_icon" />} >
                        Envoyer
                    </Button>
                
                </div>
            </div>      
            </DialogActions> */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waiting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Dialog>
    )
}

export default Email_details
