import React from 'react'

import './modal_dates_proposition.css'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';

import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import {makeStyles} from '@material-ui/core/styles';
import Form from 'react-bootstrap/Form'




const useStyles = makeStyles((theme) => ({
    button: { margin: theme.spacing(1),},
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
  }));



function Modal_dates_proposition({view_auditor_commission_suggestion,setView_auditor_commission_suggestion,departement}){

    return (
        <Dialog className="dates_proposition_dialog" fullWidth={true} maxWidth="md" open={view_auditor_commission_suggestion!=null?true:false} onClose={()=>setView_auditor_commission_suggestion(null)} aria-labelledby="form-dialog-title" >            
            <DialogTitle id="form-dialog-title">Intervalle de disponibilité</DialogTitle>
            <DialogContent>
                <div className="plus_details_dialog_container">
                        <TextField
                            readOnly
                            type="text"
                            label="Début" value={
                                departement==="jurdique"
                                    ?(new Date(view_auditor_commission_suggestion.legal_available_startDate)).toLocaleString()
                                    :departement==="finance"
                                        ?(new Date(view_auditor_commission_suggestion.finance_available_startDate)).toLocaleString()
                                        :(new Date(view_auditor_commission_suggestion.technical_available_startDate)).toLocaleString()
                                
                                }  className="header_form" 
                            InputProps={{ startAdornment: ( <InputAdornment position="start"> <EventAvailableIcon className="my_icon" /> </InputAdornment> )}}
                       />
                        <TextField
                            readOnly
                            type="text"
                            className="header_form" label="Fin" 
                            value={
                                departement==="jurdique"
                                    ?(new Date(view_auditor_commission_suggestion.legal_available_endDate)).toLocaleString()
                                    :departement==="finance"
                                        ?(new Date(view_auditor_commission_suggestion.finance_available_endDate)).toLocaleString()
                                        :(new Date(view_auditor_commission_suggestion.technical_available_endDate)).toLocaleString()
                                
                                } 
                            InputProps={{ startAdornment: ( <InputAdornment position="start"> <EventAvailableIcon className="my_icon" /> </InputAdornment> )}}
                       />
                        
                </div>
                <div style={{margin:"20px 0px 10px 0px"}}>
                    <InputLabel htmlFor='reason'>Motif</InputLabel>
                    <Form.Group name="reason" style={{width: '100%',height: '100%',position: 'relative',padding:"0px"}} >
                        <Form.Control as="textarea" placeholder='Motif...' rows={9} 
                            value={
                                departement==="jurdique"
                                    ?view_auditor_commission_suggestion.legal_refuse_raison
                                    :departement==="finance"
                                        ?view_auditor_commission_suggestion.financial_refuse_raison
                                        :view_auditor_commission_suggestion.technical_refuse_raison
                                
                                } />
                    </Form.Group>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default Modal_dates_proposition
