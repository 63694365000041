import './applicant_requests.css';

import React, { useState , useEffect } from 'react';
import { useHistory } from "react-router-dom";

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Fab from '@mui/material/Fab';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Pagination from "react-js-pagination";

import Loading_animation_view from '../../loading_animation_view/loading_animation_view'

import { get_supplier_requests } from '../../../services/supplier_service';
import View_refuse_reason from './view_refuse_reason/view_refuse_reason';
import Request_details from './request_details/request_details';



function state(request_state){
    return(
      <>
        {
         request_state === null
         ?<Button variant="contained" className="status_waiting">En cours</Button>
         :request_state === "refusée" || request_state === "validation refusée" || request_state === "non retenue" || request_state === "refusée par le fournisseur"  || request_state === "non conforme" || request_state === "annulée"
            ?<Button variant="contained" className="status_deny">{request_state}</Button>
            :request_state === "sous réserve de validation"
              ?<Button variant="contained" className="status_waiting">{request_state}</Button>
              :<Button variant="contained" className="status_success">{request_state}</Button>
        }
      </>
  
    );
  }

const headCells = [
  { id: 'code', numeric: true, disablePadding: false, label: 'Code de demande',align:"left" },
  { id: 'fullName', numeric: true, disablePadding: false, label: "Demandeur",align:"left" },
  { id: 'product_number', numeric: true, disablePadding: false, label: 'Nombre des articles',align:"left" },
  { id: 'desired_delivery_date', numeric: true, disablePadding: false, label: 'Date de livraison souhaitée' ,align:"left"},
  { id: 'delivery_date', numeric: true, disablePadding: false, label: 'Date de livraison',align:"left" },
  { id: 'state', numeric: true, disablePadding: false, label: 'Status de la commande',align:"center" },
  { id: 'details', numeric: true, disablePadding: false, label: 'Détails',align:"center" },
];


function Applicant_requests() {

  let supplier =  JSON.parse(localStorage.getItem('user-info'))
  let history = useHistory();
  const [url_updated , setUrl_updated] = useState(null);

  const [applicants_request, setApplicants_request] = useState(null);

  const [open_reason_modal,setOpen_reason_modal]=useState(null);

  const [request_type,setRequest_type]=useState("avec_catalogue");
  const [search_value,setsearch_value]=useState("");
  const [search_filter,setsearch_filter]=useState('all');

  const [request_code,setRequest_code]=useState(null);

  const [currentPage,setCurrentPage]=useState(1);
  const [dataPerPage,setdataPerPage] =useState(6);
  const [data_count,setdata_count]=useState(0);

  useEffect(()=>{
    setdata_count(0);
    setApplicants_request(null);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    var tmp_requestType ="avec_catalogue";
    var tmp_searchFilter ="all";
    var tmp_searchValue = null;

    async function load_data(){
      if(urlParams.get('type')){
        tmp_requestType = urlParams.get('type');
      }
      if(urlParams.get('filtre')){
        tmp_searchFilter = urlParams.get('filtre');
      }if(urlParams.get('valeur')){
        tmp_searchValue = urlParams.get('valeur');
      }
      await setsearch_filter(tmp_searchFilter);
      await setsearch_value(tmp_searchValue);
      await setRequest_type(tmp_requestType);
       let page = (urlParams.get('page')?urlParams.get('page'):1);
        await setCurrentPage(page);

      let formData = new FormData();
      formData.append('id_supplier',supplier.id);
      await get_supplier_requests(formData,tmp_requestType,tmp_searchFilter,tmp_searchValue,page).then((response)=> {
        //console.log(response.data);
          setdata_count(response.data.total);
          setApplicants_request(response.data.data);
      }).catch((error)=>{
        //console.log(error.response);
      });
    }
    load_data();

  },[url_updated,history.location]);
  


  function search(){
    let path = history.location.pathname;
    history.push(path+"?type="+request_type+"&filtre="+search_filter+"&valeur="+search_value);
    setUrl_updated(search_value);
  }

  function set__type(value){
    let path = history.location.pathname;
    history.push(path+"?type="+value); 
    setUrl_updated(value);
  }

  function set__filter(value){
      if(value==='all'){
        let path = history.location.pathname;
        history.push(path+"?type="+request_type+"&filtre="+value); 
        setUrl_updated(value);
      }
      setsearch_filter(value);
  }

  async function change_page(page){
    await setCurrentPage(page);
    let path = history.location.pathname;
    let params = history.location.search;
    let search_params = params.split('&');
    let tmp_params = path;
    if(!params) history.push(path+"?page="+page);
    else{
      for(let i in search_params){
        if(search_params[i].includes("page=")){
          if(i==0) tmp_params=tmp_params+"?page="+page;
          else tmp_params=tmp_params+"&page="+page;
        }else{
          if(i==0) tmp_params=tmp_params+search_params[i];
          else tmp_params=tmp_params+"&"+search_params[i];
        }
      }
      if(!tmp_params.includes("page=")) tmp_params=tmp_params+"&page="+page;
      history.push(tmp_params);
    }
    await setUrl_updated(page);
  }

  return (
    <>
      {   
        applicants_request==null
        ?<Loading_animation_view />
        : <div className="applicant_request_view" >
          <div className='three_forms_header_container' >
             <div className='page_title'>Commandes</div>
              <div className='search_filter_container'>
                  <FormControl  className="header_form" >
                    <InputLabel id="demo-simple-select-helper-label">Type de demande</InputLabel>
                      <Select value={request_type}  onChange={(e)=>{set__type(e.target.value)}}>
                          <MenuItem value="avec_catalogue">Avec catalogue</MenuItem>
                          <MenuItem value="sans_catalogue">Sans catalogue</MenuItem>
                      </Select>
                    </FormControl>

                  <FormControl className="header_form">
                    <InputLabel id="demo-simple-select-helper-label">Filtre</InputLabel>
                      <Select value={search_filter}  onChange={(e)=>{set__filter(e.target.value)}}>
                        <MenuItem value="all">Aucun</MenuItem>
                        <MenuItem value='demandeur'>Nom du demandeur</MenuItem>
                      </Select>
                    </FormControl>

                      <Grid container spacing={1} alignItems="flex-start" className={search_filter==="all"?"search_bar disabled_button":"search_bar" } >
                        <Grid item>
                          <SearchIcon onClick={search} className={search_value===""?"my_icon disabled_button":"my_icon" } style={{cursor:"pointer"}}  />
                        </Grid>
                        <Grid>
                          <TextField label="Chercher" value={search_value} onChange={(e)=>{setsearch_value(e.target.value)}}/>
                        </Grid>
                      </Grid>
              </div>
        </div>
            <TableContainer>
              <Table className="submittions_table" >
                <TableHead>
                  <TableRow>
                      {
                          headCells.map((column) => (
                              <TableCell key={column.id} align={column.align}>{column.label}</TableCell>
                          ))
                      }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    applicants_request.length!==0
                    ?applicants_request.map((row, index) => (
                          <TableRow tabIndex={-1} key={index}>
    
                            <TableCell align="left" component="th" scope="row">
                              {row.request_infos[0].request_code}
                            </TableCell>
                            <TableCell align="left">{row.request_infos[0].requester_firstName+" "+row.request_infos[0].requester_lastName}</TableCell>
                            <TableCell align="left">{row.request_products}</TableCell>
                            <TableCell align="left">
                                {(new Date(row.request_infos[0].delivery_supposedDate)).toLocaleString().split(/[,| ]/)[0]}
                            </TableCell>
                            <TableCell align="left">
                                {
                                    row.request_infos[0].delivery_date?(new Date(row.request_infos[0].delivery_date)).toLocaleString().split(/[,| ]/)[0]
                                    :'Pas encore'
                                
                                }
                            </TableCell>
                            <TableCell align="center">
                                <div className='request_state_container'>
                                    {state(row.request_infos[0].order_state)}
                                    <>
                                      {
                                        row.request_infos[0].order_state === "sous réserve de validation" || row.request_infos[0].order_state === "validation refusée" || row.request_infos[0].order_state === "refusée" || row.request_infos[0].order_state === "annulée" || row.request_infos[0].order_state === "refusée par le fournisseur"  || row.request_infos[0].order_state  === "non conforme"
                                        ?<span className='view_reason_button' onClick={() => setOpen_reason_modal(row.request_infos[0].service_refuse_reason && row.request_infos[0].order_state !== "refusée" && row.request_infos[0].order_state !== "sous réserve de validation" && row.request_infos[0].order_state !== "refusée par le fournisseur" ?row.request_infos[0].service_refuse_reason:row.request_infos[0].refuse_reason)}>Motif</span>
                                        :<></>
                                      }
                                    </>
                                </div>
                            </TableCell>
                            <TableCell align="center">
                            <VisibilityIcon  className="my_icon editIcon" onClick={()=>setRequest_code(row.request_infos[0].request_code)}/>
                            </TableCell>
                          </TableRow>
                        ))
                      :<div style={{margin:"10px",fontSize:'.75rem', textAlign:"center",alignContent:"center"}}>Aucune demande trouvée</div>
                    }
                </TableBody>
              </Table>
            </TableContainer>
            {
                applicants_request.length!==0
                  ?<div className="pagination_container">
                      <Pagination
                          activePage={Number(currentPage)}
                          itemsCountPerPage={dataPerPage}
                          totalItemsCount={Number(data_count)}
                          pageRangeDisplayed={4}
                          onChange={change_page.bind(this)}
                      />
                  </div>
                  :<></>
              }
        </div>
      }

      {
        open_reason_modal!=null
          ?<View_refuse_reason open_reason_modal={open_reason_modal} setOpen_reason_modal={setOpen_reason_modal} />
          :<></>
      }

      {
        request_code!==null
        ?<Request_details request_code={request_code} id_supplier={supplier.id} setRequest_code={setRequest_code} /> 
        :<></>
      }
    </>
    
   
  );
}
export default Applicant_requests;
