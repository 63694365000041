import './contracts_list.css';
import React, { useEffect,useState } from 'react';
import { useHistory } from "react-router-dom";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from "react-js-pagination";
import { Fab } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@mui/material/Tooltip';

import {makeStyles} from '@material-ui/core/styles';
import Loading_animation_view from '../../loading_animation_view/loading_animation_view'
import { archive_contract, get_contracts_for_cadmin } from '../../../services/company_admin_service';
import Add_contract_modal from './add_contract_modal/add_contract_modal';




const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

const columns = [
    { id: 'contract', label: "Contrat", minWidth: 0 },
    { id: 'contract_stat', label: "Sous types", minWidth: 0 },
  ];
  

function state(contract_state){
    return(
        <>
        {
            contract_state === 1
            ?<Button variant="contained" className="status_deny">{"archivée"}</Button>
            :<Button variant="contained" className="status_success">{"non archivée"}</Button>
        }
        </>

    );
}


function Contracts({contracts,dont_archive,archive,update_contract}){
    
    return(
        <>
        {
            Object.keys(contracts).map((contract_key,id) => (
                <TableRow key={"contract"+id}>
                    <TableCell align="left">
                        <div style={{width:'fit-content'}}>
                            {contract_key}
                            <div style={{width:'100%',display:'flex'}}>
                                <Tooltip title="modifier le contrat" placement="top" >
                                    <span className={"editIcon"}  onClick={() => update_contract(contracts[contract_key])} >
                                            <EditIcon className="my_icon" style={{cursor:'pointer'}} />
                                    </span>
                                </Tooltip>
                            </div>
                        </div>
                    </TableCell>
                    <TableCell align="center">
                        {
                            contracts[contract_key].map((cnt,cnt_id)=>(
                                <div className='contract_subtypes_cont' key={cnt.id}>
                                   <div className='contract_subtypes'>{cnt.contract_subtype}</div>
                                   <div className='documentsStatus_valide'>
                                        <div>
                                            <div><CheckIcon className="checkIcon" onClick={()=>dont_archive(cnt_id,contract_key,cnt.id)} /></div>
                                            <div><CloseSharpIcon className="closeSharpIcon" onClick={()=>archive(cnt_id,contract_key,cnt.id)} /></div>
                                        </div>
                                        {state(cnt.archived)}            
                                    </div> 
                                </div>
                                
                            ))
                        }
                        
                    </TableCell>
                </TableRow>                
             ))
        }
        </>
    );
}



function Contracts_list() {

  const classes = useStyles();
  const [url_updated , setUrl_updated] = useState(null);
  let history = useHistory();

  const [contract_state,setcontract_state] = useState("default");

  const [contracts,setcontracts]=useState(null);
  const [waiting, setWaiting] = useState(false);

  const [update_contract_modal, setupdate_contract_modal] = useState(null);
  const [add_contract_modal, setadd_contract_modal] = useState(false);
  const [contract_added, setcontract_added] = useState(null);

    const [currentPage,setCurrentPage]=useState(1);
    const [dataPerPage,setdataPerPage] =useState(6);
    const [data_count,setdata_count]=useState(0);
    
  useEffect(()=>{
    setcontracts(null);
    setdata_count(0);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let contract_state_tmp = "default";

    async function load_data(){
        if(urlParams.get('contrat')){
            setcontract_state(urlParams.get('contrat'));
            contract_state_tmp = urlParams.get('contrat');
        }
        let page = (urlParams.get('page')?urlParams.get('page'):1);
        await setCurrentPage(page);
        await get_contracts_for_cadmin(contract_state_tmp,page).then((response)=>{
            //console.log(response.data);
            setcontracts(response.data.data);
            setdata_count(response.data.total);
        }).catch((error)=>{
            //console.log(error.response);
        })
    }

    load_data();
    

  },[contract_added,url_updated,history.location]);


  function dont_archive(id,contract_type,id_contract){
    setWaiting(true);
    let formData = new FormData();
    formData.append('id_contract',id_contract);
    formData.append('archive',0);
    archive_contract(formData).then((response)=>{
        let tmp_arr = { ...contracts };
        tmp_arr[contract_type][id].archived = 0;
        setcontracts(tmp_arr);
        setWaiting(false);
    }).catch((error)=>{
        //console.log(error.response);
        setWaiting(false);
    })
  }

  function archive(id,contract_type,id_contract){
    setWaiting(true);
    let formData = new FormData();
    formData.append('id_contract',id_contract);
    formData.append('archive',1);
    archive_contract(formData).then((response)=>{
        let tmp_arr = { ...contracts };
        tmp_arr[contract_type][id].archived= 1;
        setcontracts(tmp_arr);
        setWaiting(false);
    }).catch((error)=>{
        //console.log(error.response);
        setWaiting(false);
    })
  }


  function update_contract(subTypes){
    setupdate_contract_modal(subTypes);
    setadd_contract_modal(true);
  }

  function select_contract_stat(value){
    setcontract_state(value);
    let path = window.location.href.split('?')[0]
    window.location.href = path+"?contrat="+value;
  }


  async function change_page(page){
    await setCurrentPage(page);
    let path = history.location.pathname;
    let params = history.location.search;
    let search_params = params.split('&');
    let tmp_params = path;
    if(!params) history.push(path+"?page="+page);
    else{
      for(let i in search_params){
        if(search_params[i].includes("page=")){
          if(i==0) tmp_params=tmp_params+"?page="+page;
          else tmp_params=tmp_params+"&page="+page;
        }else{
          if(i==0) tmp_params=tmp_params+search_params[i];
          else tmp_params=tmp_params+"&"+search_params[i];
        }
      }
      if(!tmp_params.includes("page=")) tmp_params=tmp_params+"&page="+page;
      history.push(tmp_params);
    }
    await setUrl_updated(page);
  }

  return (
    <>
    {
        contracts==null
        ?<Loading_animation_view />
        :<div className='contracts_list_container'>
            <div className='contracts_list_container_header'>
                    <div className='title_container'>
                        <label>Contrats</label>
                        <Button variant="contained" startIcon={<AddIcon />} className={waiting ? "submit_but disabled_button":"submit_but"} onClick={() => setadd_contract_modal(true)}>
                            Ajouter
                        </Button>
                    </div>
                    <div className='select_container'>
                        <FormControl className={classes.formControl} style={{width:"225px"}} >
                            <InputLabel>Etat de contrat</InputLabel>
                                <Select required
                                    value={contract_state}
                                    onChange={e => {
                                        select_contract_stat(e.target.value);
                                    }} name="Etat" >
                                    <MenuItem value={"default"} disabled>
                                        Etat de contrat
                                    </MenuItem>
                                    <MenuItem value={"recent"}>Récent</MenuItem>
                                    <MenuItem value={"old"}>Ancien</MenuItem>
                                    <MenuItem value={"archived"}>Archivée</MenuItem>
                                    <MenuItem value={"no_archived"}>Non Archivée</MenuItem>
                                </Select>
                        </FormControl>
                    </div>
            </div>
                    <TableContainer className="contracts_list_table">
                            <Table className={classes.table} aria-label="sticky table">
                                <TableHead>
                                <TableRow>
                                    {
                                        columns.map((column) => (
                                            <TableCell key={column.id} align="left">{column.label}</TableCell>
                                        ))
                                    }
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    Object.keys(contracts).length!==0
                                    ?
                                    <>
                                        <Contracts contracts={contracts} dont_archive={dont_archive} archive={archive} update_contract={update_contract} />
                                    </>
                                    :<div style={{margin:"10px",fontSize:".8rem",width:'100%', textAlign:"center",alignContent:"center"}}>Aucun type de contrat disponible</div>
                                }
                                </TableBody> 
                            </Table>
                        </TableContainer>
                        {
                            Object.keys(contracts).length!==0
                                ?<div className="pagination_container">
                                    <Pagination
                                        activePage={Number(currentPage)}
                                        itemsCountPerPage={dataPerPage}
                                        totalItemsCount={Number(data_count)}
                                        pageRangeDisplayed={4}
                                        onChange={change_page.bind(this)}
                                    />
                                </div>
                                :<></>
                            }
            </div>
        }

        {
            add_contract_modal!==false
            ?<Add_contract_modal add_contract_modal={add_contract_modal} setadd_contract_modal={setadd_contract_modal} 
                    setcontract_added={setcontract_added} update_contract_modal={update_contract_modal} setupdate_contract_modal={setupdate_contract_modal} />
            :<></>
        }
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={waiting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </>
  );
}

export default Contracts_list;