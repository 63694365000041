import './companies_list.css';
import React, { useEffect,useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import {makeStyles} from '@material-ui/core/styles';
import Loading_animation_view from '../../loading_animation_view/loading_animation_view'
import { get_tenants, archive_tenant } from '../../../services/gadmin_service';




const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

const columns = [
    { id: 'companie', label: "Nom d'entreprise", minWidth: 0 },
    { id: 'created_at', label: "Date d'ajout", minWidth: 0 },
    { id: 'status', label: "Statut de l'entreprise", minWidth: 0 }
  ];
  

function state(companie_state){
    return(
        <>
        {
            companie_state === 1
            ?<Button variant="contained" className="status_deny">{"archivée"}</Button>
            :<Button variant="contained" className="status_success">{"non archivée"}</Button>
        }
        </>

    );
}

function Companies({companies,dont_archive,archive}){
    
    return(
        <>
        {
            companies.map((companie,id) => (
                <TableRow key={"companie"+id}>
                    <TableCell align="left">{companie.name}</TableCell>
                    <TableCell align="left">{(new Date(companie.created_at)).toLocaleString().split(' ')[0]}</TableCell>
                    <TableCell align="center">
                        <div className='documentsStatus_valide'  >
                          <div>
                            <div><CheckIcon className="checkIcon" onClick={()=>dont_archive(id,companie.name)} /></div>
                            <div><CloseSharpIcon className="closeSharpIcon" onClick={()=>archive(id,companie.name)} /></div>
                          </div>
                          {state(companie.archived)}            
                        </div>
                    </TableCell>
                </TableRow>                
             ))
        }
        </>
    );
}



function Companies_list() {

  const classes = useStyles();
  const [companie_state,setcompanie_state] = useState("default");
  const [companies,setcompanies]=useState(null);
  const [waiting, setWaiting] = useState(false);


  useEffect(()=>{
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let companie_state_tmp = "default"
    if(urlParams.get('ent')){
        setcompanie_state(urlParams.get('ent'));
        companie_state_tmp = urlParams.get('ent');
    }
    get_tenants(companie_state_tmp).then((response)=>{
        //console.log(response.data);
        setcompanies(response.data);
    }).catch((error)=>{
        //console.log(error.response);
    })
  },[]);



  function select_conpanie_state(value){
    setcompanie_state(value);
    let path = window.location.href.split('?')[0]
    window.location.href = path+"?ent="+value;
  }

  function dont_archive(id,company_name){
    setWaiting(true);
    let formData = new FormData();
    formData.append('company_name',company_name);
    formData.append('archive',0);
    archive_tenant(formData).then((response)=>{
        //console.log(response.data);
        let tmp_arr = [ ...companies ];
        tmp_arr[id].archived = 0;
        setcompanies(tmp_arr);
        setWaiting(false);
    }).catch((error)=>{
        //console.log(error.response);
        setWaiting(false);
    })
  }

  function archive(id,company_name){
    setWaiting(true);
    let formData = new FormData();
    formData.append('company_name',company_name);
    formData.append('archive',1);
    archive_tenant(formData).then((response)=>{
        //console.log(response.data);
        let tmp_arr = [ ...companies ];
        tmp_arr[id].archived = 1;
        setcompanies(tmp_arr);
        setWaiting(false);
    }).catch((error)=>{
        //console.log(error.response);
        setWaiting(false);
    })
  }


  return (
    <>
    {
        companies==null
        ?<Loading_animation_view />
        :<div className='companies_list_container'>
            <div className='companies_list_container_header'>
                    <div className='title_container'><label>Entreprises</label></div>
                    <div className='select_container'>
                    <FormControl className={classes.formControl} style={{width:"225px"}} >
                            <InputLabel>Etat de l'entreprise</InputLabel>
                                <Select required
                                    value={companie_state}
                                    onChange={e => {
                                        select_conpanie_state(e.target.value);
                                    }} name="Etat" >
                                    <MenuItem value={"default"} disabled>
                                        Etat de l'entreprise
                                    </MenuItem>
                                    <MenuItem value={"recent"}>Récent</MenuItem>
                                    <MenuItem value={"old"}>Ancien</MenuItem>
                                    <MenuItem value={"archived"}>Archivée</MenuItem>
                                    <MenuItem value={"no_archived"}>Non Archivée</MenuItem>
                                </Select>
                        </FormControl>
                    </div>
            </div>
                    <TableContainer className="companies_list_table" component={Paper}>
                            <Table className={classes.table} aria-label="sticky table">
                                <TableHead>
                                <TableRow>
                                    {
                                        columns.map((column) => (
                                            <TableCell key={column.id} align="left">{column.label}</TableCell>
                                        ))
                                    }
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    companies.length!==0
                                    ?
                                    <>
                                        <Companies companies={companies} dont_archive={dont_archive} archive={archive} />
                                    </>
                                    :<div style={{margin:"10px",fontSize:".8rem",width:'100%', textAlign:"center",alignContent:"center"}}>Aucune entreprise disponible</div>
                                }
                                </TableBody> 
                            </Table>
                        </TableContainer>
            </div>
        }
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={waiting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </>
  );
}

export default Companies_list;