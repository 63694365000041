import React, { useState, useEffect } from 'react'
import './add_proposal.css'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import PaymentIcon from '@material-ui/icons/Payment';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import LabelIcon from '@material-ui/icons/Label';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import DownloadIcon from '@mui/icons-material/Download';
import Fab from '@mui/material/Fab';


import { download_offer_files } from '../../../../services/purchase_service';
import { get_suppliers_by_category } from '../../../../services/for_allServices';
import Set_supplier from '../set_suppliers/set_supplier';


const useStyles = makeStyles((theme) => ({
    button: { margin: theme.spacing(1), },
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
}));



function setDateTime(date) {
    let day = date.toISOString();
    let day_comp = day.split(":");
    return day_comp[0] + ":" + day_comp[1];
}


function getFileName(event) {
    const name = event.target.files[0].name;
    const lastDot = name.lastIndexOf('.');

    const fileName = name.substring(0, lastDot);
    const ext = name.substring(lastDot + 1);
    return fileName + "." + ext;
}



function Update_proposal({ handleClose, open, chosenOffer, update, dateToday, clickOffre, offers, setOffers, profilInfo, categories }) {

    const classes = useStyles();
    let user = JSON.parse(localStorage.getItem('user-info'));

    const [formData, setFormData] = useState(null);
    const [valide, setValid] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [nextStep, setNextStep] = useState(false);

    const [waiting,setWaiting] = useState(false);

    const [ObjectEdit, setObjectEdit] = useState("");
    const [offer_dateEdit, setoffer_dateEdit] = useState("");
    const [limit_dateEdit, setlimit_dateEdit] = useState("");
    const [openOffer_dateAdd, setopenOffer_dateEdit] = useState("");
    const [EstimationEdit, setEstimationEdit] = useState("");
    const [DirectionEdit, setDirectionEdit] = useState("");
    const [suppliersEdit, setsuppliersEdit] = useState("");

    const [workBookEdit, setworkBookEdit] = useState(null);
    const [rcEdit, setrcEdit] = useState(null);
    const [complementDocsEdit, setcomplementDocsEdit] = useState(null);

    const [workBookLoaded, setworkBookLoaded] = useState("");
    const [rcLoaded, setrcLoaded] = useState("");
    const [complementDocsLoaded, setcomplementDocsLoaded] = useState("");

    const [workBookFile, setworkBookFile] = useState("");
    const [rcFile, setrcFile] = useState("");
    const [complementDocsFile, setcomplementDocsFile] = useState("");

    const [categorie, setcategorie] = useState(null);
    const [suppliers, setSuppliers] = useState([]);

    const [errorText, setErrorText] = useState("");

    useEffect(() => {

        if (chosenOffer.workBook !== "null") setworkBookFile(chosenOffer.workBook);
        if (chosenOffer.rc !== "null") setrcFile(chosenOffer.rc);
        if (chosenOffer.complementDocs !== "null") setcomplementDocsFile(chosenOffer.complementDocs);

        setObjectEdit(chosenOffer.object);
        setoffer_dateEdit(chosenOffer.offer_date);
        setlimit_dateEdit(chosenOffer.limit_date);
        setopenOffer_dateEdit(chosenOffer.openOffer_date);
        setEstimationEdit(chosenOffer.estimation);
        setDirectionEdit(chosenOffer.direction);
        setcategorie(chosenOffer.purchase_family)

    }, [])

    function validated_elem() {
        return submitted && valide;
    }

    async function update_proposal() {
        setSubmitted(true);
        setValid(true);
        setErrorText("");

        if (ObjectEdit === "" || offer_dateEdit === "" || limit_dateEdit === ""
            || EstimationEdit === "" || DirectionEdit === "" || categorie == "") {
            setErrorText("Champs invalides !");
            setValid(false);
        } else {
            let tmp_form = new FormData();
            tmp_form.append('offer_id', chosenOffer.offer_id);
            tmp_form.append('id_service', user.id);
            tmp_form.append('tutorName', profilInfo.fullName);
            tmp_form.append('object', ObjectEdit);
            tmp_form.append('offer_date', offer_dateEdit);
            tmp_form.append('limit_date', limit_dateEdit);
            tmp_form.append('openOffer_date', openOffer_dateAdd);
            tmp_form.append('estimation', EstimationEdit);
            tmp_form.append('direction', DirectionEdit);
            tmp_form.append('purchase_family', categorie);
            if (workBookLoaded != null) tmp_form.append('workBook', workBookEdit);
            if (rcLoaded != null) tmp_form.append('rc', rcEdit);
            if (complementDocsLoaded != null) tmp_form.append('docsComplementary', complementDocsEdit);

            setFormData(tmp_form);
            if (categorie == chosenOffer.purchase_family) setsuppliersEdit(chosenOffer.suppliers);
            get_suppliers_by_category(categorie).then((response) => {
                //console.log(response.data);
                setSuppliers(response.data);
                if (response.data.length == 0) {
                    setErrorText("Aucun fournisseur disponible !");
                    setValid(false);
                } else setNextStep(true);
            }).catch((error) => {
                //console.log(error.response);
            });
        }

    }

    function download(choice) {
        if (workBookLoaded != "" && choice == "workBook") {
            var fileLink = document.createElement('a');
            fileLink.href = workBookLoaded;
            fileLink.setAttribute('download', workBookFile);
            document.body.appendChild(fileLink);
            fileLink.click();
        } else {
            if (rcLoaded != "" && choice == "rc") {
                var fileLink = document.createElement('a');
                fileLink.href = rcLoaded;
                fileLink.setAttribute('download', rcFile);
                document.body.appendChild(fileLink);
                fileLink.click();
            } else {
                if (complementDocsLoaded != "" && choice == "docsComplementary") {
                    var fileLink = document.createElement('a');
                    fileLink.href = complementDocsLoaded;
                    fileLink.setAttribute('download', complementDocsFile);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                } else {
                    const formData = new FormData();
                    setWaiting(true);
                    formData.append('offer_id', chosenOffer.offer_id);
                    formData.append('choice', choice);
                    download_offer_files(formData).then((response) => {
                        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        var fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        let file_extension = '';
                        const disposition = response.headers['content-disposition'];
                        if (disposition && disposition.includes('filename')) {
                            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            const matches = filenameRegex.exec(disposition);
                            if (matches != null && matches[1]) {
                                file_extension = matches[1].replace(/['"]/g, '').split(".")[1];
                            }
                        }
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', chosenOffer.offer_id + choice +"."+file_extension);
                        document.body.appendChild(fileLink);
                        fileLink.click();
                        setWaiting(false);
                    }).catch((error) => {
                        //console.log(error.response);
                        setWaiting(false);
                    })
                }
            }
        }
    }


    function upload_workBook(event) {
        setworkBookEdit(event.target.files[0]);
        setworkBookFile(getFileName(event));
        setworkBookLoaded(URL.createObjectURL(event.target.files[0]));
    }

    function upload_result(event) {
        setrcEdit(event.target.files[0]);
        setrcFile(getFileName(event));
        setrcLoaded(URL.createObjectURL(event.target.files[0]));
    }

    function upload_complementDocs(event) {
        setcomplementDocsEdit(event.target.files[0]);
        setcomplementDocsFile(getFileName(event));
        setcomplementDocsLoaded(URL.createObjectURL(event.target.files[0]));
    }


    return (
        <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose} aria-labelledby="form-dialog-title" className="update_porpose" >
            <DialogTitle id="form-dialog-title">Modifier l'appel d'offre </DialogTitle>
            <DialogContent>
                <div className="inputs_container">
                    <TextField label="Objet" defaultValue={chosenOffer.object} className="input_form"
                        InputProps={{ startAdornment: (<InputAdornment position="start"> <LabelIcon className="my_icon" /> </InputAdornment>), }} onChange={(e) => setObjectEdit(e.target.value)}
                    />
                    <TextField label="Estimation (MAD) " type="number" defaultValue={chosenOffer.estimation} className="input_form"
                        InputProps={{ startAdornment: (<InputAdornment position="start"> <PaymentIcon className="my_icon" /> </InputAdornment>), }} onChange={(e) => setEstimationEdit(e.target.value)}
                    />
                    <FormControl className="input_form" >
                        <InputLabel>Famille D'achat</InputLabel>

                        <Select onChange={(e) => { setcategorie(e.target.value) }} defaultValue={chosenOffer.purchase_family}>
                            <MenuItem value="null" disabled>
                                <em>None</em>
                            </MenuItem>
                            {
                                categories.map((categorie) => (
                                    <MenuItem key={categorie.id_category} value={categorie.id_category}>{categorie.name}</MenuItem>
                                ))
                            }
                        </Select>

                        <FormHelperText>choisissez votre Famille d'achat</FormHelperText>
                    </FormControl>
                </div>
                <div className="inputs_container">
                    <TextField
                        type="datetime-local"
                        label="Date offre" defaultValue={chosenOffer.offer_date} className="input_form"
                        InputProps={{ startAdornment: (<InputAdornment position="start"> <EventAvailableIcon className="my_icon" /> </InputAdornment>), }} onChange={(e) => setoffer_dateEdit(e.target.value)}
                    />

                    <TextField
                        type="datetime-local"
                        label="Date limite" defaultValue={chosenOffer.limit_date} className="input_form"
                        InputProps={{ startAdornment: (<InputAdornment position="start"> <EventBusyIcon className="my_icon" /> </InputAdornment>), }} onChange={(e) => setlimit_dateEdit(e.target.value)}
                    />
                    {
                        (limit_dateEdit) != ""
                            ? <TextField
                                disabled={limit_dateEdit == null ? true : false}
                                type="datetime-local"
                                defaultValue={openOffer_dateAdd} label="Date d'ouverture des plis" className="input_form" inputProps={{ min: setDateTime(new Date(limit_dateEdit)) }}
                                InputProps={{ startAdornment: (<InputAdornment position="start"> <EventBusyIcon className="my_icon" /> </InputAdornment>) }} onChange={(e) => setopenOffer_dateEdit(e.target.value)}
                            />
                            : <TextField
                                disabled={true}
                                type="datetime-local"
                                label="Date d'ouverture des plis" className="input_form"
                                InputProps={{ startAdornment: (<InputAdornment position="start"> <EventBusyIcon className="my_icon" /> </InputAdornment>) }} onChange={(e) => setopenOffer_dateEdit(e.target.value)}
                            />
                    }
                </div>

                <div className="inputs_container">
                    <FormControl className="input_form" >
                        <InputLabel>Direction</InputLabel>
                        <Select onChange={(e) => { setDirectionEdit(e.target.value) }} defaultValue={chosenOffer.direction}>
                            <MenuItem value="null" disabled>
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value="Direction juridique">Direction financiere</MenuItem>
                            <MenuItem value="Direction financiere">Direction juridique</MenuItem>
                            <MenuItem value="Direction informatique">Direction informatique </MenuItem>
                        </Select>
                        <FormHelperText>choisissez votre Direction</FormHelperText>
                    </FormControl>
                </div>

                <div className='inputs_container'>
                    <div className='documentsDownload'>
                        <label htmlFor="workBook" style={{ marginRight: '7px' }}>
                            <Fab size="small" component='span' ><CloudUploadIcon className="my_icon" /> </Fab>
                            <input accept="application/*" className={classes.input} name="workBook" id="workBook" multiple type="file" onChange={(e) => upload_workBook(e)} />
                        </label>
                        {
                            workBookFile != ""
                                ? <Fab size="small" onClick={() => download('workBook')} ><DownloadIcon className="my_icon" /> </Fab>
                                : <Fab size="small" className="disabled_button" ><DownloadIcon className="my_icon" /></Fab>
                        }
                        <span className='documentsTitle'>Cahier De Charge </span>
                    </div>

                    <div className='documentsDownload'>
                        <label htmlFor="rc" style={{ marginRight: '7px' }}>
                            <Fab size="small" component='span'><CloudUploadIcon className="my_icon" /> </Fab>
                            <input accept="application/*" className={classes.input} name="rc" id="rc" multiple type="file" onChange={(e) => upload_result(e)} />
                        </label>
                        {
                            rcFile != ""
                                ? <Fab size="small" onClick={() => download('rc')} ><DownloadIcon className="my_icon" /> </Fab>
                                : <Fab size="small" className="disabled_button" ><DownloadIcon className="my_icon" /></Fab>
                        }
                        <span className='documentsTitle'>RC</span>
                    </div>

                    <div className='documentsDownload'>
                        <label htmlFor="complementDocs" style={{ marginRight: '7px' }}>
                            <Fab size="small" component='span' ><CloudUploadIcon className="my_icon" /> </Fab>
                            <input accept="application/*" className={classes.input} name="complementDocs" id="complementDocs" multiple type="file" onChange={(e) => upload_complementDocs(e)} />
                        </label>
                        {
                            complementDocsFile != ""
                                ? <Fab size="small" onClick={() => download('docsComplementary')} ><DownloadIcon className="my_icon" /> </Fab>
                                : <Fab size="small" className="disabled_button" ><DownloadIcon className="my_icon" /></Fab>
                        }
                        <span className='documentsTitle'> Docs complémentaires </span>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <div className='dialogBottom'>
                    <div>
                        {
                            validated_elem()
                                ? <></>
                                : <p className={submitted ? "col-md-10 error" : "col-md-10 valid-champ"}>
                                    {submitted ? errorText : ""}
                                </p>
                        }
                    </div>
                    <div>
                        <Button variant="contained" className="submit_but cancel_but" startIcon={<CancelIcon className="my_icon" />} onClick={handleClose} >
                            Annuler
                        </Button>
                        <Button variant="contained" className="submit_but valide_but" startIcon={<AddIcon className="my_icon" />} onClick={update_proposal} >
                            Suivant
                        </Button>
                    </div>
                </div>
            </DialogActions>
            {
                nextStep == true ?
                    <Set_supplier suppliersEdit={suppliersEdit} handleClose={() => { setNextStep(false); handleClose(); }} update={update} open={nextStep} formData={formData} setFormData={setFormData} setOffers={setOffers} offers={offers} clickOffre={clickOffre} suppliers={suppliers} />
                    : <></>
            }
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waiting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Dialog>
    )
}

export default Update_proposal
