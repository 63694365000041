import axios from "axios"
import './request_interceptor'

const end_point1 = "https://com.portailachats.ma/api/";
//const end_point1 = "http://localhost:8000/api/";



////////////////////////////////////////////////////
//// SupplierIdentity
////////////////////////////////////////////////////


export const get_supplierIdentity_profileImg = async(id_service) => {
    return await axios({
        url: end_point1+'servicePrincipale/get_supplierIdentity_profileImg/'+id_service,
        method:'POST',
          responseType: 'blob',
          headers: {
            'Content-Type': 'image/*',
          },
      });
} 


export const update_supplierIdentity_profileImg = async(data) => {
    return await axios.post(end_point1+'servicePrincipale/update_supplierIdentity_profileImg',data);
} 

export const update_supplierIdentity_infos = async(data) => {
    return await axios.post(end_point1+'servicePrincipale/update_supplierIdentity_infos',data);
} 

export const getSupplier_categories = async(id_supplier) => {
    return await axios.get(end_point1+'servicePrincipale/getSupplier_categories/'+id_supplier);
} 


////////////////////////////////////////////////////
////
////////////////////////////////////////////////////

export const verify_supplier = async(id_supplier) => {
    return await axios.get(end_point1+'servicePrincipale/verifierFournisseurValider/'+id_supplier);
} 


export const load_supplier_infos = async(id_supplier) => {
    return await axios.get(end_point1+'pagePrincipale/profil/'+id_supplier);
} 

export const upload_supplier_docs = async(data) => {
    return await axios.post(end_point1+'pagePrincipale/upload_supplier_docs',data);
} 


export const getSupplier_infos_byId = async(id_supplier) => {
    return await axios.get(end_point1+'pagePrincipale/profil/'+id_supplier);
}

export const sendMsg = async(data) => {
    return await axios.post(end_point1+'servicePrincipale/envoyerMsg',data);
}


////////////////////////////////////////////////////
//// proposal
////////////////////////////////////////////////////

export const supplier_offer_request = async(data) => {
    return await axios.post(end_point1+'pagePrincipale/supplier_offer_request',data);
}


export const offers_calls_by_supplier = async(id_supplier,filterCategorie,searchFilter,searchValue,page) => {
    return await axios.get(end_point1+'servicePrincipale/offers_calls_by_supplier/'+id_supplier+'/'+filterCategorie+'/'+searchFilter+'/'+searchValue+"?page="+page);
}


export const update_supplier_offer_request_docs = async(data) => {
    return await axios.post(end_point1+'pagePrincipale/update_supplier_offer_request_docs',data);
}


export const supplier_submissions = async(id_supplier,filterCategorie,searchFilter,searchValue,page) => {
    return await axios.get(end_point1+'pagePrincipale/supplier_submissions/'+id_supplier+'/'+filterCategorie+'/'+searchFilter+'/'+searchValue+"?page="+page);
}



////////////////////////////////////////////////////
//// OfferContract
////////////////////////////////////////////////////

export const getContrats_by_supplier = async(id_supplier,filter,page) => {
    if(filter) return await axios.get(end_point1+'servicePrincipale/getContrats_by_supplier/'+id_supplier+"/"+filter+"?page="+page);
    else return await axios.get(end_point1+'servicePrincipale/getContrats_by_supplier/'+id_supplier+"/recent"+"?page="+page);
}

export const accept_contract_terms = async(data) => {
    return await axios.post(end_point1+'servicePrincipale/accept_contract_terms_bysupplier',data);
}

export const notify_service_with_contractContent = async(data) => {
    return await axios.post(end_point1+'servicePrincipale/notify_service_with_contractContent',data);
}


////////////////////////////////////////////////////
//// bills
////////////////////////////////////////////////////

export const submit_new_bill = async(data) => {
    return await axios.post(end_point1+'servicePrincipale/submit_new_bill',data);
}

export const submit_new_order_bill = async(data) => {
    return await axios.post(end_point1+'servicePrincipale/submit_new_order_bill',data);
}

export const getSupplier_market = async(id_supplier,filterCategorie,searchFilter,searchValue,page) => {
    return await axios.get(end_point1+'servicePrincipale/getSupplier_market/'+id_supplier+'/'+filterCategorie+'/'+searchFilter+'/'+searchValue+"?page="+page);
}

export const notify_finance_service = async(data)  => {
    return await axios.post(end_point1+'servicePrincipale/notify_finance_service',data);
}

export const upload_bill_docs = async(data) => {
    return await axios.post(end_point1+'servicePrincipale/upload_bill_docs',data);
}

export const getSupplier_orders_bills = async(data,filter,searchValue,page) => {
    return await axios.post(end_point1+'servicePrincipale/getSupplier_orders_bills/'+filter+'/'+searchValue+"?page="+page,data);
}


////////////////////////////////////////////////////
//// products
////////////////////////////////////////////////////

export const add_product = async(data) => {
    return await axios.post(end_point1+'servicePrincipale/add_product',data);
}

export const update_product = async(data) => {
    return await axios.post(end_point1+'servicePrincipale/update_product',data);
}

export const delete_product_byCode = async(product_code) => {
    return await axios.delete(end_point1+'servicePrincipale/delete_product_byCode/'+product_code);
}

export const get_products_byCode_forSupplier = async(data) => {
    return await axios.post(end_point1+'servicePrincipale/get_products_byCode_forSupplier',data);
}

export const get_supplier_requests = async(data,requestType,searchFilter,searchValue,page) => {
    return await axios.post(end_point1+'servicePrincipale/get_supplier_requests/'+requestType+'/'+searchFilter+'/'+searchValue+"?page="+page,data);
}

export const get_supplier_applicant_request_details = async(data) => {
    return await axios.post(end_point1+'servicePrincipale/get_supplier_applicant_request_details',data);
}

export const supplier_accord_order = async(data) => {
    return await axios.post(end_point1+'servicePrincipale/supplier_accord_order',data);
}

export const supplier_change_order_state = async(data) => {
    return await axios.post(end_point1+'servicePrincipale/supplier_change_order_state',data);
}

export const get_order_infos_for_file = async(data) => {
    return await axios.post(end_point1+'servicePrincipale/get_order_infos_for_file',data);
}


////////////////////////////////////////////////////
//// import files
////////////////////////////////////////////////////


export const get_imported_files = async(data) => {
    return await axios.post(end_point1+'servicePrincipale/get_imported_files',data);
}

export const load_catalogue_fromFiles = async(data) => {
    return await axios.post(end_point1+'servicePrincipale/load_catalogue_fromFiles',data);
}

//////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////MainPage


export const latest_offers = async(id,time_range) => {
    return await axios.get(end_point1+'pagePrincipale/latest_offers/'+id+"/"+time_range);
}

export const count_allOffers_bySupplierCategories_widget = async(id,time_range) => {
    return await axios.get(end_point1+'pagePrincipale/count_allOffers_bySupplierCategories_widget/'+id+"/"+time_range);
}

export const latest_submissions = async(id,time_range) => {
    return await axios.get(end_point1+'pagePrincipale/latest_submissions/'+id+"/"+time_range);
}

export const charts_ofBillsAmount_payed_unpayed_byMonth = async(id,time_range) => {
    return await axios.get(end_point1+'pagePrincipale/charts_ofBillsAmount_payed_unpayed_byMonth/'+id+"/"+time_range);
}

export const delay_between_bills_expired_payed_dates = async(id,time_range,bills_src) => {
    return await axios.get(end_point1+'pagePrincipale/delay_between_bills_expired_payed_dates/'+id+"/"+time_range+"/"+bills_src);
}

export const contratsWdiget_by_supplier = async(id,time_range,filter) => {
    return await axios.get(end_point1+'pagePrincipale/contratsWdiget_by_supplier/'+id+"/"+time_range+"/"+filter);
}

export const suppliers_notes_widgetCharts = async(id,time_range) => {
    return await axios.get(end_point1+'pagePrincipale/suppliers_notes_widgetCharts/'+id+"/"+time_range);
}

export const billsWidget_by_supplier = async(id,time_range,bills_src,filter) => {
    return await axios.get(end_point1+'pagePrincipale/billsWidget_by_supplier/'+id+"/"+time_range+"/"+bills_src+"/"+filter);
}

export const count_all__supplierAwardedOffers_total_amount = async(id,time_range) => {
    return await axios.get(end_point1+'pagePrincipale/count_all__supplierAwardedOffers_total_amount/'+id+"/"+time_range);
}

export const count_all_supplierAwardedOffers_notClosed = async(id,time_range) => {
    return await axios.get(end_point1+'pagePrincipale/count_all_supplierAwardedOffers_notClosed/'+id+"/"+time_range);
}

export const count_orders_byState = async(id,time_range,request_type,filter) => {
    return await axios.get(end_point1+'pagePrincipale/count_orders_byState/'+id+"/"+time_range+"/"+request_type+"/"+filter);
}

export const count_total_amount_byState = async(id,time_range,request_type,filter) => {
    return await axios.get(end_point1+'pagePrincipale/count_total_amount_byState/'+id+"/"+time_range+"/"+request_type+"/"+filter);
}

export const chart_of_the_most_ordered_prds = async(id,time_range) => {
    return await axios.get(end_point1+'pagePrincipale/chart_of_the_most_ordered_prds/'+id+"/"+time_range);
}

