import React, { useState} from 'react'

import './modal_dates_proposition.css'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CancelIcon from '@material-ui/icons/Cancel';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';

import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import SendIcon from '@material-ui/icons/Send';
import {makeStyles} from '@material-ui/core/styles';
import Form from 'react-bootstrap/Form'

import swal from 'sweetalert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { refuse_commission_by_id_auditor } from '../../../../services/commission_service';




const useStyles = makeStyles((theme) => ({
    button: { margin: theme.spacing(1),},
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
  }));

function setDateTime(date){
    let day = date.toISOString();
    let day_comp = day.split(":");
    return day_comp[0]+":"+day_comp[1];
}
  

function get_auditor_by_id(auditors,id_auditor){
    var auditor_tmp = "membre";
    auditors.map((auditor) => {
        if(auditor.id == id_auditor) auditor_tmp = auditor;
    })

    return auditor_tmp;

}

function Modal_dates_proposition({selected_commission,open_setDate_propositionModal,
                        close_setDate_propositionModal,auditor_infos,offers_commissions,setOffers_commissions}){

    const classes = useStyles();

    const [valide, setValid] = useState(true);
    const [submitted, setSubmitted] = useState(false);    

    const [start_date,setstart_date]=useState("");
    const [end_date,setend_date]=useState("");
    const [reason,setReason]=useState("");

    const [waiting, setWaiting] = useState(false);
    const [errorText,setErrorText] = useState("");


    function validated_elem(){
        return submitted && valide;
    }

    function propose_commission() {    
        setSubmitted(true);
        setValid(true);
        setErrorText("");
        if(start_date=="" || end_date=="" || reason==""){
            setErrorText("Champs invalides !");
            setValid(false);
        }else{
            if((new Date(start_date)).getTime() > (new Date(end_date)).getTime()){
                setErrorText("la date de fin est inférieure à la date de début !");
                setValid(false);
            }else{
                swal({
                    title: "Etes-vous sûr ?",
                    text: "!!!!",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                .then((willDelete) => {
                  if (willDelete) {
                    setWaiting(true);
                    let formData = new FormData();
                    formData.append("available_start_date",start_date);
                    formData.append("available_end_date",end_date);
                    formData.append("reason",reason);
                    formData.append("id_commission",selected_commission.id);
                    formData.append("departement",auditor_infos.departement); 
                    refuse_commission_by_id_auditor(auditor_infos.id,formData).then((response) => {
                        setWaiting(false);
                        swal("Votre réponse a été sauvegardée avec succès !", {
                            icon: "success",
                            buttons: true,
                        }).then((willDelete) => {
                            if (willDelete) { 
                                let tmp_commissions = [...offers_commissions];
                                tmp_commissions[open_setDate_propositionModal]=response.data;
                                setOffers_commissions(tmp_commissions);
                        }}) 
                        close_setDate_propositionModal();
                    }).catch((error) => {
                        //console.log(error.response);
                        setWaiting(false);
                    })
                }})
            }
            
        }
    }

    return (
        <Dialog className="dates_proposition_dialog" fullWidth={true} maxWidth="md" open={open_setDate_propositionModal!=null?true:false} onClose={close_setDate_propositionModal} aria-labelledby="form-dialog-title" >            
            <DialogTitle id="form-dialog-title">Intervalle de disponibilité</DialogTitle>
            <DialogContent>
                <div className="plus_details_dialog_container">
                        <TextField
                            type="datetime-local"
                            className="header_form" label="Début" min={new Date()} inputProps={{min: setDateTime(new Date(selected_commission.proposed_meet_date1))}}
                            InputProps={{ startAdornment: ( <InputAdornment position="start"> <EventAvailableIcon className="my_icon"  /> </InputAdornment> )}} onChange={(e)=>setstart_date(e.target.value)}
                        />
                        {
                            (start_date) != "" 
                            ?<TextField
                                disabled = { start_date==null ? true : false}
                                type="datetime-local"
                                label="Fin" className="header_form" inputProps={{min: setDateTime(new Date(start_date))}}
                                InputProps={{ startAdornment: ( <InputAdornment position="start"> <EventBusyIcon  className="my_icon" /> </InputAdornment> )}} onChange={(e)=>setend_date(e.target.value)}
                            />
                            :<TextField
                                disabled = {true}
                                type="datetime-local"
                                className="header_form" label="Fin"
                                InputProps={{ startAdornment: ( <InputAdornment position="start"> <EventBusyIcon className="my_icon" /> </InputAdornment> )}} onChange={(e)=>setend_date(e.target.value)}
                            />
                        }
                        
                </div>
                <div  style={{margin:"20px 0px 10px 0px"}}>
                    <InputLabel htmlFor='reason'>Motif</InputLabel>
                    <Form.Group name="reason" style={{width: '100%',height: '100%',position: 'relative',padding:"0px"}} >
                        <Form.Control as="textarea" placeholder='Motif...' rows={9} value={reason} onChange={(e)=>setReason(e.target.value)} />
                    </Form.Group>
                </div>
            </DialogContent>
            <DialogActions>
            <div className='dialogBottom'>
                <div>
                    {
                        validated_elem() 
                        ?<></>
                        :<p className={submitted ? "col-md-10 error" : "col-md-10 valid-champ"}>
                                {submitted ? errorText : ""}
                            </p>
                    }
                </div>
                <div>
                   <Button variant="contained" className="submit_but cancel_but" startIcon={<CancelIcon className="my_icon" />} onClick={close_setDate_propositionModal} >
                        Annuler
                    </Button>

                    <Button variant="contained" className="submit_but valide_but" startIcon={<SendIcon className="my_icon" />} onClick={propose_commission} >
                        Envoyer
                    </Button>
                
                </div>
            </div>      
                
            </DialogActions>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waiting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Dialog>
    )
}

export default Modal_dates_proposition
