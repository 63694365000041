import '../../../../../../assets/css/catalogue_modal.css'
import './search_prd_modal.css'
import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Form from 'react-bootstrap/Form'
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import empty from '../../../../../empty.jpg'

import { get_product_by_infos } from '../../../../../../services/purchase_service';
import Loading_animation_view from '../../../../../loading_animation_view/loading_animation_view';
import Add_new_product_modal from './add_new_product/add_new_product';


function Search_prd_modal({open_search_prd_modal,setOpen_search_prd_modal,add_product_to_list,id_supplier}){

  const [waiting, setWaiting] = useState(false);

  const [product_code,setProduct_code]=useState('');
  const [open_add_prd_modal,setOpen_add_prd_modal]=useState(false);
  const [products,setProducts]=useState(null);

  function search_product(){
      setWaiting(true);
      let formData = new FormData();
      formData.append('product_code',product_code);
      formData.append('id_supplier',id_supplier);
      get_product_by_infos(formData).then((response) => {
        setWaiting(false);
        if(response.data) setProducts([response.data]);
        else setProducts([]);
      }).catch((error)=>{
        setWaiting(false);
        //console.log(error.response);
      })
  }

    return (
      <Dialog className="set_search_prd_container" fullWidth={true} maxWidth={'md'} open={open_search_prd_modal} onClose={()=>setOpen_search_prd_modal(false)} aria-labelledby="form-dialog-title" >
        <DialogContent style={{height:'100%',display:'flex',flexDirection:'column'}}>
            <div style={{display:'flex',margin:"15px auto 0px auto",alignItems:'flex-end',width:'fit-content'}} >
                <div>
                <Form.Group className="search_bar" style={{marginRight:'6px'}}>
                    <Form.Control
                        required
                        autoComplete="new-password"
                        type="text"
                        placeholder="Code de produit *"
                        value={product_code}
                        onChange={ e =>  {setProduct_code(e.target.value)}}
                    />           
                </Form.Group>
                </div>
                <div style={{paddingBottom:'4px',cursor:'pointer'}} >
                    <SearchIcon className={product_code===""?"my_icon disabled_button":"my_icon" } onClick={search_product} />
                </div>
            </div>
            <div className="search_content_container products_gallery_modal_container">
            <>
                {
                    waiting===true
                    ?<Loading_animation_view />
                    : <>
                        {
                                products!==null
                                ?<>
                                    {
                                        products.length !== 0
                                        ?<>
                                            <div className="products_contents_container">
                                                {
                                                    products.map((product,id) => {
                                                    return (
                                                        <React.Fragment key={id}>
                                                            <div className="main_card_container">
                                                                <div className="main_card_img_container">
                                                                    <img src={product.product_pics.length!==0?`data:image/png;base64,${product.product_pics[0]}`:empty}/>
                                                                </div>
                                                                <div className="main_card_infos_container">
                                                                    <div className="card_title">{product.title}</div>
                                                                    <div className="card_description">{product.description}</div>
                                                                    <div className="card_title">{product.product_price+" MAD"}</div>
                                                                    <div className="view_more_button_container">
                                                                        <Button variant="contained" className="submit_but edit_but" onClick={() => {add_product_to_list(product)}} >
                                                                            Ajouter le produit
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                
                                                        );
                                                    })
                                                }
                                            </div>
                                        </>:<div className='emptytable_msg_container'>
                                                Aucun produit disponible
                                                <div>
                                                    <Button variant="contained" className="submit_but" onClick={() => setOpen_add_prd_modal(true) }>
                                                             Créer nouveau produit
                                                    </Button>
                                                </div>
                                          </div>
                                    }
                                  </>
                                :<div className='emptytable_msg_container'>Saisir le code de produit</div>
                            }
                        </>
                }
            </>
            </div>
            {
                open_add_prd_modal!==false
                ?<Add_new_product_modal open_add_prd_modal={open_add_prd_modal} setOpen_add_prd_modal={setOpen_add_prd_modal} add_product_to_list={add_product_to_list} id_supplier={id_supplier} />
                :<></>
            }
       </DialogContent>
      </Dialog>
    )
}

export default Search_prd_modal
