import "./pre_post_conditions.css"
import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { lighten, makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from "@material-ui/core/Input";
import Form from 'react-bootstrap/Form'


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});


const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
    input: {
      display: 'none',
    },
    select: {
      "&:focus": {
        backgroundColor: "white"
      }
    },
    selectInput: {
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottomWidth: 0
      },
      "&:after": {
        borderBottomWidth: 0
      }
    },
    disabled: {},
    focused: {},
    error: {}
  }));

  
const columns = [
    { id: 'params', label: "Propriété", minWidth: 0 },
    { id: 'oper', label: "Opération", minWidth: 0 },
    { id: 'value', label: "Value", minWidth: 0 },
  ];
  
function Property({property}){

    const classes = useToolbarStyles();
    const selectInputClasses = {
      root: classes.selectInput,
      disabled: classes.disabled,
      focused: classes.focused,
      error: classes.error
    };


    return(
        <TableRow>
            <TableCell align="left">
                <FormControl variant="standard">
                    <Select required
                        value={"null"}
                        input={<Input classes={selectInputClasses} />}
                        inputProps={{ classes: { select: classes.select } }}
                        style={{backgroundColor:"inherit"}}
                        onChange={e => {
                            //console.log(e.target.value);
                        }} >
                        <MenuItem value={"null"}>Propriétés</MenuItem>
                        <MenuItem value={"ref"}>{"Referencements"}</MenuItem>
                        <MenuItem value={"ao"}>{"Appels d'offre"}</MenuItem>
                        <MenuItem value={"bills"}>{"Factures"}</MenuItem>
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell align="left">
                <FormControl variant="standard">
                    <Select required
                        value={"null"}
                        input={<Input classes={selectInputClasses} />}
                        inputProps={{ classes: { select: classes.select } }}
                        style={{backgroundColor:"inherit"}}
                        onChange={e => {
                            //console.log(e.target.value);
                        }} >
                        <MenuItem value={"null"}>Empty</MenuItem>
                        <MenuItem value={"ref"}>{"Referencements"}</MenuItem>
                        <MenuItem value={"ao"}>{"Appels d'offre"}</MenuItem>
                        <MenuItem value={"bills"}>{"Factures"}</MenuItem>
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell align="left">
                <Form.Control as="input" className="inputform" placeholder='Valeur' value={""} onChange={(e)=>console.log(e.target.value)} />
            </TableCell>
        </TableRow>
    );
}


function Pre_post_conditions() {
  const classes = useStyles();
  const [properties , setproperties] = useState([1]);


  function add_new_property_condition(){

  }


  return (
    <div>
        <div style={{marginBottom:'10px'}}>
            Cette section concerne les conditions préalables sur les propriétés liées à l'objet avant de passer à cet état.        </div>
        <div>
             <TableContainer className="property_list_table">
                    <Table className={classes.table} aria-label="sticky table">
                        <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                <div style={{width:'100%',display:'flex'}}>
                                    Propriété
                                    <span style={{marginLeft:'10px'}} onClick={()=>add_new_property_condition()} >
                                        <AddIcon className="my_icon addIcon" />
                                    </span>
                                </div>
                            </TableCell>
                            <TableCell align="left">Opération</TableCell>
                            <TableCell align="left">Valeur</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        <>
                            {
                                properties.map((property,id) => (
                                    <Property key={id} property={property} />
                                ))
                            }
                        </>
                        </TableBody> 
                    </Table>
                </TableContainer>
            </div>
        </div>
  );
}

export default Pre_post_conditions;