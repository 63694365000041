import axios from "axios";

  
axios.interceptors.request.use(
    function(config) {
        config.headers["Content-Type"] = "application/json";
        config.headers["withCredentials"] = true;
        if(JSON.parse(localStorage.getItem('user-info'))){
            config.headers["company"] = JSON.parse(localStorage.getItem('user-info')).company;  
            config.headers["Authorization"] = "Bearer "+JSON.parse(localStorage.getItem('user-info')).token;  
        }
        return config;
    },
    function(error) {
        return Promise.reject(error);
    }
);

     
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status === 401) {
        //console.log("401");
        localStorage.clear();
        window.dispatchEvent(new Event("storage"));
    }

    if (error.response.status === 500) {
       
        if(error.response.data.hasOwnProperty("message") && error.response.data.message == "Unauthenticated."){
            window.location.reload();
            localStorage.clear();
            window.dispatchEvent(new Event("storage"));
        }
    }
    return Promise.reject(error);
});