import "./docs_perms.css"
import React, {useMemo, useState, useEffect} from 'react';
import Form from 'react-bootstrap/Form'
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Select from '@material-ui/core/Select';
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    indeterminateColor: {
      color: "#f50057"
    },
    selectAllText: {
      fontWeight: 500
    },
    selectedAll: {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)"
      }
    },
    input: {
        display: 'none',
      },
      container: {
        maxHeight: 440,
      },
      select: {
        "&:focus": {
          backgroundColor: "white",
          borderBottomWidth: 0
        }
      },
      selectInput: {
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottomWidth: 0
        },
        "&:focus": {
            backgroundColor: "white",
            borderBottomWidth: 0
          },
        "&:not($disabled):not($focused):not($error):before": {
          borderBottomWidth: 0,
          borderWidth:1
        }
      },
      disabled: {},
      focused: {},
      error: {}
  }));


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center"
    },
    variant: "menu"
  };




export default function Docs_WFTypes({ wf_selected, setwf_selected }) {
    const classes = useStyles();

    const [wf_types, setwf_types] = useState([
        {
            id:"ref",
            wf_type:"Referencements"
        },
        {
            id:"ao",
            wf_type:"Appels d'offre"
        },
        {
            id:"daac",
            wf_type:"Demandes des achats avec catalogue"
        },
        {
            id:"dasc",
            wf_type:"Demandes des achats sans catalogue"
        },
        {
            id:"fac",
            wf_type:"Factures"
        }
    ]);
    const [selected, setSelected] = useState([]);
    const [selectedId, setSelectedId] = useState([]);


    useEffect(()=>{
        let tmp_array = [];
        wf_types.map((wf_type) => {
            wf_selected.map((permission_wf_typeid)=>{
                if(wf_type.id === permission_wf_typeid) tmp_array.push(wf_type.wf_type);
            });
        });
        setSelected(tmp_array);
        setSelectedId(wf_selected);
    },[])

    function wf_type_id_by_name(sld_wf_types){
        let id=[];
        wf_types.map((wf_type) => {
         sld_wf_types.map((sld_wf_type) => {
            if(wf_type.wf_type === sld_wf_type) id.push(wf_type.id);
          });
        });
        return id;
      }

    const handleChange = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
            let tmp_array = [];
            let tmp_id_array = [];
            wf_types.map((wf_type) => {
                tmp_array.push(wf_type.wf_type);
                tmp_id_array.push(wf_type.id);
            })
            setSelected(selected.length === wf_types.length ? [] : tmp_array);
            setSelectedId(selected.length === wf_types.length ? [] : tmp_id_array);
            setwf_selected(selected.length === wf_types.length ? [] : tmp_id_array);
            return;
        }
        setSelected(value);
        setSelectedId(wf_type_id_by_name(value));
        setwf_selected(wf_type_id_by_name(value));
    };

    const isAllSelected = useMemo(() => wf_types.length > 0 && selected.length === wf_types.length,[selected]);


  return (
    <div className="docs_perms">
        <div style={{marginBottom:'10px'}}>
            Par défaut, le document n'est lié à aucun workflow, les workflows ajoutés dans la liste ci-dessous seront liés à ce document.   
        </div>
        <div>
      <Form.Group className="col-md-12">
            <Select
                labelId="mutiple-select-label"
                multiple
                value={selected}
                onChange={handleChange}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                className="form-control form-select form-select-control"
            >
            <MenuItem
                value="all"
                primaryText="all"
                classes={{
                    root: isAllSelected ? classes.selectedAll : ""
                }}
                >
                <ListItemIcon>
                    <Checkbox
                    classes={{ indeterminate: classes.indeterminateColor }}
                    checked={isAllSelected}
                    indeterminate={
                        selected.length > 0 && selected.length < wf_types.length
                    }
                    />
                </ListItemIcon>
                <ListItemText
                    classes={{ primary: classes.selectAllText }}
                    primary="Tout sélectionner"
                />
            </MenuItem>

            {
                wf_types.map((wf_type)=>(
                    <MenuItem key={wf_type.wf_type} value={wf_type.wf_type} primaryText={wf_type.wf_type}>
                        <ListItemIcon>
                            <Checkbox checked={selected.indexOf(wf_type.wf_type) > -1} />
                        </ListItemIcon>
                        <ListItemText primary={wf_type.wf_type} />
                    </MenuItem> 
                ))
            }
            
        </Select>        
    </Form.Group>
      </div>
    </div>
  );
}
