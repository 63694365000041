import './off_catalogue_request_details.css'
import React, { useState , useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import Tooltip from '@mui/material/Tooltip';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import View_refuse_reason from '../view_refuse_reason/view_refuse_reason';
import swal from 'sweetalert';

import { Accordion } from 'react-bootstrap';

import Loading_animation_view from '../../../loading_animation_view/loading_animation_view';
import { get_applicant_request_details } from '../../../../services/purchase_service';
import { get_order_productsInfos, download_orderRequest_quote, download_order_file, requester_change_order_state } from '../../../../services/for_allServices';
import Set_refuse_reason from './set_refuse_reason/set_refuse_reason';
import Order_details from './order_details/order_details';



const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    input: {
        display: 'none',
      },
  }));
  

function Request_detail({title,details}){
    return (
      <div>
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    <div className='accordion_header_content'> {title} </div>
                </Accordion.Header>
                <Accordion.Body>
                <div className="order_form_details_container">   
                 <div className="order_form_infos_container">
                    <div className='order_form_infos_title'> 1 - Infos personnel</div>
                    <Row className="mb-12">
                            <Form.Group className="col-6">
                                <label>Nom</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Nom *"
                                    value={details.requester_lastName}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group className="col-6">
                                <label>Prénom</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Prénom *"
                                    value={details.requester_firstName}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group className="col-6">
                                <label>Département</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Département *"
                                    value={details.requester_departement}
                                    readOnly
                                />           
                            </Form.Group>
                            <Form.Group className="col-6"> </Form.Group>
                            <Form.Group className="col-6">
                                <label>Email</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="email"
                                    placeholder="Email *"
                                    value={details.requester_email}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group className="col-6">
                                <label>Numéro de téléphone</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Numéro de téléphone *"
                                    value={details.requester_phoneNumber}
                                    readOnly
                                />
                            </Form.Group>
                    </Row>
                    <div className='order_form_infos_title'>2 - Infos de livraison</div>
                    <Row className="mb-12">
                            <Form.Group className="col-6">
                                <label>Pays</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Pays*"
                                    value={details.delivery_country}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group className="col-6">
                                <label>Ville</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Ville *"
                                    value={details.delivery_city}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group className="col-6">
                                <label>Adresse</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Adresse *"
                                    value={details.delivery_address}
                                    readOnly
                                />           
                            </Form.Group>
                            <Form.Group className="col-6">
                                <label>Code postal</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Code postal *"
                                    value={details.delivery_postCode}
                                    readOnly
                                />           
                            </Form.Group>

                            <Form.Group className="col-6">
                                <label>Date de livraison souhaitée</label>
                                <Form.Control
                                    type="date"
                                    min={(new Date()).toISOString().split('T')[0]}
                                    placeholder="Date de livraison souhaitée *"
                                    value={details.delivery_supposedDate.split(' ')[0]}
                                    readOnly
                                />
                            </Form.Group>
                    </Row>
                    <div className='order_form_infos_title'>3 - Autres informations</div>
                    <Row className="mb-12">
                            <Form.Group className="col-md-12">
                                <label>Description de la demande</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    as="textarea"
                                    rows={5}
                                    placeholder="Description de la demande *"
                                    value={details.off_catalogue}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group className="col-md-12">
                                <label>Objet de la demande</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    as="textarea" rows="3" 
                                    placeholder="Objet de la demande *"
                                    value={details.object}
                                    readOnly
                                />
                            </Form.Group>
                      </Row>
                    </div>
                </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
     </div>
    )
}

function state(request_state){
    return(
      <>
        {
         request_state === null || request_state === "demander à être changée"
         ?<Button variant="contained" className="status_waiting">{request_state?request_state:'En cours'}</Button>
         :request_state === "refusée" || request_state === "validation refusée" || request_state === "annulée" || request_state === "refusée par le fournisseur" || request_state === "non retenue" || request_state === "non conforme"
            ?<Button variant="contained" className="status_deny">{request_state}</Button>
            :request_state === "sous réserve de validation"
              ?<Button variant="contained" className="status_waiting">{request_state}</Button>
              :<Button variant="contained" className="status_success">{request_state}</Button>
        }
      </>
  
    );
  }


function Accordian_order_detail({request_code,supplier,order_key,download_quote,setSelected_suppliers,selected_suppliers,well_received,order_conforme,order_no_conforme}){

    const [open_reason_modal,setOpen_reason_modal]=useState(null);

    return (
    <div>
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    <div className='order_accordion_header'>
                        <div className='accordion_header_content'>
                            <span>{"Commande n°"+(order_key+1)+" / "}</span>
                            <span className="supplier_infos">{supplier.parentCompany+" -- "+supplier.fullName}</span>
                        </div>
                        <div className='accordion_header_actions'>
                            <div className='action_button_container' >
                                {
                                    supplier.order_state!=="annulée" &&  supplier.order_state!=="conforme"
                                    ?<>
                                        {
                                            supplier.order_state!=="bien reçu" 
                                            ?<Tooltip title="la commande est reçue" placement="top" >
                                                <span style={{color:'rgb(228, 88, 32)'}}
                                                                   className={ supplier.order_state==="en cours de livraison"?"checkIcon":"checkIcon disabled_button"}>
                                                        <DoneIcon className="my_icon" style={{cursor:'pointer'}} onClick={(e) =>well_received(e,supplier.id,request_code,order_key)} />
                                                </span>
                                            </Tooltip>
                                            :<>
                                                <Tooltip title="Commande non conforme" placement="top" >
                                                    <span style={{color:'#e92a44',marginRight:'6px'}}  onClick={(e) =>order_no_conforme(e,supplier.id,request_code,order_key)}>
                                                        <RemoveDoneIcon style={{cursor:'pointer'}} className="my_icon closeSharpIcon" />
                                                    </span>
                                                </Tooltip>
                                                <Tooltip title="Commande conforme" placement="top" >
                                                    <span style={{color:'#18ac67'}}  onClick={(e) =>order_conforme(e,supplier.id,request_code,order_key)} >
                                                        <DoneAllIcon style={{cursor:'pointer'}} className="my_icon checkIcon"  />
                                                    </span>
                                                </Tooltip>
                                            </>
                                        }
                                    </>
                                    :<></>
                                }
                            </div> 
                            <div className='request_state_container'>
                                {state(supplier.order_state)}
                                <>
                                    {
                                    supplier.order_state === "sous réserve de validation" || supplier.order_state === "refusée" 
                                    || supplier.order_state === "validation refusée" || supplier.order_state === "annulée" || supplier.order_state === "refusée par le fournisseur" || supplier.order_state === "non conforme"
                                    ?<span className='view_reason_button' onClick={(e) => { e.stopPropagation(); setOpen_reason_modal(supplier.service_refuse_reason && supplier.order_state !== "refusée par le fournisseur" && supplier.order_state !== "refusée"?supplier.service_refuse_reason:supplier.refuse_reason)}}>Motif</span>
                                    :<></>
                                    }
                                </>
                            </div>
                        </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <Order_details selected_products_details={supplier} request_code={request_code} id_supplier={supplier.id} selected_suppliers={selected_suppliers}  
                                setSelected_suppliers={setSelected_suppliers} order_key={order_key} order_number={order_key+1} />
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>

        {
            open_reason_modal!==null
            ?<View_refuse_reason open_reason_modal={open_reason_modal} setOpen_reason_modal={setOpen_reason_modal} />
            :<></>
        }
        
    </div>
    )
}


function Off_catalogue_request_details({request_code,setRequest_code}){

    const [suppliers,setSuppliers]=useState([]);
    const [selected_suppliers,setSelected_suppliers]=useState(null);

    const [details,setDetails]=useState(null);
    const [wait_submitting,setWait_submitting]=useState(false);

    const [open_reason_modal,setOpen_reason_modal]=useState(null);
    const [order_key,setorder_key]=useState(null);

    const [notify,setNotify]=useState(false);
    const classes = useStyles();


    useEffect(()=>{
        setDetails(null);
        async function get_orders_products(tmp_selected_suppliers,request_code){
            var tmp_array = [];
            for(let id_supplier in tmp_selected_suppliers){
                let tmp_products_bysupplier = {};
                let products = JSON.parse(tmp_selected_suppliers[id_supplier].products)["products"];
                if(products){
                    let selected_products_tmp_array = [];
                    products.map((selected_product) => {
                        selected_products_tmp_array.push(selected_product.product_code);
                    })    
                    let formData = new FormData();
                    formData.append('products_codes',JSON.stringify(selected_products_tmp_array));
                    formData.append('request_code',request_code);
                    formData.append('id_supplier',tmp_selected_suppliers[id_supplier].id);
                    await get_order_productsInfos(formData).then((response)=>{
                        let selected_products_details_tmp_array = [];
                        let data = response.data;
                        //console.log(data);
                        for(const id_supplier_name in data){
                            data[id_supplier_name].products.map((element,id) => {
                                products.map((selected_product,id) => {
                                    if(selected_product.product_code === element.product_code){
                                            element.product_amount = element.product_amount?element.product_amount:selected_product.product_amount;
                                    } 
                                })
                                selected_products_details_tmp_array.push(element);
                            });
                            tmp_products_bysupplier = { ...tmp_selected_suppliers[id_supplier], ...data[id_supplier_name] };
                            tmp_products_bysupplier["order_state"] = response.data[id_supplier_name].order_state;
                            tmp_products_bysupplier["service_accord"] = response.data[id_supplier_name].service_accord;
                            tmp_products_bysupplier["products"] = selected_products_details_tmp_array;
                        }
                    }).catch((error)=>{
                        //console.log(error.response);
                    });
                }else{
                    tmp_products_bysupplier = tmp_selected_suppliers[id_supplier];
                }
                tmp_array.push(tmp_products_bysupplier);
            }
            setSelected_suppliers(tmp_array);
        } 

        const formData = new FormData();
        formData.append('request_code',request_code);
        get_applicant_request_details(formData).then((response)=>{
            //console.log(response.data);
            setDetails(response.data);
            get_orders_products(response.data.selected_suppliers,request_code);
        }).catch((error)=>{
            //console.log(error.response);
        })
    },[notify]);



    function download_quote(e,id_supplier){
        e.stopPropagation();
        let formData = new FormData();
        formData.append('request_code',request_code);
        formData.append('id_supplier',id_supplier);
        download_orderRequest_quote(formData).then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            let file_extension = '';
            const disposition = response.headers['content-disposition'];
            if (disposition && disposition.includes('filename')) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    file_extension = matches[1].replace(/['"]/g, '').split(".")[1];
                }
            }
            fileLink.setAttribute('download', request_code[0]+request_code[1]+"_"+id_supplier+"_devis."+file_extension);
            document.body.appendChild(fileLink);
            fileLink.click();
        }).catch(err=>{
            //console.log(err.response);
        });
    }

    function removeSupplier(id){
        let tmp_array = [ ...suppliers ];
        tmp_array.splice(id,1);
        setSuppliers(tmp_array);
    }

    function well_received(e,id_supplier,request_code,id){
        e.stopPropagation();
        swal({
            title: "Etes-vous sûr ?",
            text: "Une fois que vous avez soumis votre réponse, vous ne pourrez plus la modifier !",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
            setWait_submitting(true);
            let formData = new FormData();
            formData.append('id_supplier',id_supplier);
            formData.append('request_code',request_code);
            formData.append('state',"bien reçu");
            requester_change_order_state(formData).then((response)=>{
                //console.log(response.data);
                let tmp_array = [ ...selected_suppliers ];
                tmp_array[id].order_state = "bien reçu";
                setSelected_suppliers(tmp_array);
                setWait_submitting(false);
                swal("votre réponse a été enregistrée avec succès et le service sera bientôt notifié !", {
                    icon: "success",
                    });
            }).catch((error) => {
                //console.log(error.response);
                setWait_submitting(false);
            })
          }
        });
    }


    function order_conforme(e,id_supplier,request_code,id){
        e.stopPropagation();
        swal({
            title: "Etes-vous sûr ?",
            text: "Une fois que vous avez soumis votre réponse, vous ne pourrez plus la modifier !",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
            setWait_submitting(true);
            let formData = new FormData();
            formData.append('id_supplier',id_supplier);
            formData.append('request_code',request_code);
            formData.append('state',"conforme");
            requester_change_order_state(formData).then((response)=>{
                //console.log(response.data);
                let tmp_array = [ ...selected_suppliers ];
                tmp_array[id].order_state = "conforme";
                setSelected_suppliers(tmp_array);
                setWait_submitting(false);
                swal("votre réponse a été enregistrée avec succès et le service sera bientôt notifié !", {
                    icon: "success",
                    });
            }).catch((error) => {
                //console.log(error.response);
                setWait_submitting(false);
            })
          }
        });
    }


    function order_no_conforme(e,id_supplier,request_code,id){
        e.stopPropagation();
        let formData = new FormData();
        formData.append('id_supplier',id_supplier);
        formData.append('request_code',request_code);
        formData.append('state',"non conforme");
        setOpen_reason_modal(formData);
        setorder_key(id);
    }

    
    return (
        <Dialog disableEnforceFocus fullScreen open={request_code?true:false} onClose={() => setRequest_code(null)} className="my_full_dialog contract_full_dialog">
            <AppBar className={classes.appBar}  >
                <Toolbar style={{backgroundColor:'#343a40'}} >
                    <div className='page_title'>
                        <IconButton edge="start" color="inherit" onClick={() => setRequest_code(null)} aria-label="close" >
                            <CloseIcon className="my_icon" />
                        </IconButton>
                        <span> Details de demande : {request_code} </span>
                    </div>
                </Toolbar>
            </AppBar>

          {
            details!==null && selected_suppliers!=null
            ?<div className="off_catalogue_requestDetails_container request_details_container">
                <Request_detail 
                    title="Détails" 
                    details={details} />
                <>
                    {
                        selected_suppliers.map((supplier,id)=>(
                            supplier.order_state!==null &&  supplier.order_state!=="non retenue" &&  supplier.order_state!=="retenue" &&  supplier.order_state!=="devis ajouté"
                            ?<Accordian_order_detail supplier={supplier} key={id}  order_key={id} download_quote={download_quote} 
                                    request_code={request_code} setSelected_suppliers={setSelected_suppliers} 
                                    selected_suppliers={selected_suppliers} well_received={well_received} order_conforme={order_conforme} order_no_conforme={order_no_conforme} />
                            :<></>
                        ))
                    }
                </>
                <>
                <div className="articles_container">
                    {
                        JSON.parse(details.products).articles.map((article,id)=>(
                            <div className='articles_view_container' key={"articles"+id}>
                                <div className="article_view_header_container">
                                    <span>Article n°{id+1}</span>
                                </div>
                                <div>{article}</div>
                            </div>
                        ))
                    }
                </div>
                </>
             </div>
            :<Loading_animation_view />
          }
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={wait_submitting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>

        {
            open_reason_modal!==null && order_key!=null
            ?<Set_refuse_reason open_reason_modal={open_reason_modal} setOpen_reason_modal={setOpen_reason_modal}
                            selected_suppliers={selected_suppliers} setSelected_suppliers={setSelected_suppliers} order_key={order_key} setorder_key={setorder_key} />
            :<></>
        }
     </Dialog>
    )
}

export default Off_catalogue_request_details;
