import '../offersChart_byCategorie/offersChart_byCategorie.css'
import * as React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

function random_hex_color_code(random_number){
    let n = (random_number * 0xffffff * 10000000).toString(16);
    return '#' + n.slice(0, 6);
  };

function Categorie_chart({suppliers_number,categorie,percent,color}){
    return(
        <div className="categorie_container">
            <div className="offersNumber_body" style={{backgroundColor:color,color:color}}><span>{suppliers_number}</span></div>
            <div className="offersCategorie_body">{categorie}</div>
            <div className="offersCategoriePercent_body" style={{color:color}}><LinearProgressWithLabel value={percent} color="inherit" /></div>
        </div>
    )
}

const SuppliersCharts_byCategorie  = ({categories,data}) => {

    function getCategorieById(id){
        var categorie="";
        categories.map((catg)=>{
            if(catg.id_category == id) categorie = catg.name;
        })
        return categorie;
    }

  return (
    <div className="offersChart_categorie_container">
        <div className="offersChart_byCategorie_header_container">
            <div className="offersNumber_header">#Fourn.</div>
            <div className="offersCategorie_header">Famille d'achat</div>
            <div className="offersCategoriePercent_header">Pourcentage des fournisseurs par Familles d'achat</div>
        </div>
        <div className="offersChart_byCategorie_body_container">
            {
                data.suppliers_categories.map((suppliers_categorie,id)=>(
                    <React.Fragment key={"suppliers_categories"+id}>
                         <Categorie_chart suppliers_number={suppliers_categorie.total} categorie={getCategorieById(suppliers_categorie.id_category)} percent={data.count!==0?suppliers_categorie.total*100/data.count:0} color={random_hex_color_code((data.suppliers_categories.length-id+1)*15037.25)}  />
                    </React.Fragment>
                ))
            }
        </div>
    </div>
  );
  
}


export default React.memo(SuppliersCharts_byCategorie);
