import './worflows_creator.css';
import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  MarkerType
} from 'react-flow-renderer';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from "@material-ui/core/Input";
import New_node_modal from './new_node_modal';

import CustomNode from './custom_elements/customNode';
import CustomConnectionLine from './custom_elements/CustomConnectionLine';

import 'reactflow/dist/style.css';
import './custom_elements/custom_elements.css';
import Wf_modal_params from './wf_modals_container/wf_modal_params';
import { get_offersCalls_props } from '../../../services/company_admin_service';

const initialNodes = [
  {
    id: '0',
    type: 'input',
    data: { label: 'Start' },
    position: { x: 250, y: 5 },
  },
];


const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
    input: {
      display: 'none',
    },
    select: {
      "&:focus": {
        backgroundColor: "white"
      }
    },
    selectInput: {
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottomWidth: 0
      },
      "&:after": {
        borderBottomWidth: 0
      }
    },
    disabled: {},
    focused: {},
    error: {}
  }));

  const connectionLineStyle = {
    strokeWidth: 3,
    stroke: 'black',
  };
  

const Worflows_creator = () => {
  let edges_len = 0;

  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [workflow_src, setworkflow_src] = useState("null");

  const [position, setposition] = useState(null);

  const [open_node_modal, setopen_node_modal] = useState(false);
  const [chosen_node, setchosen_node] = useState(null);


  useEffect(() => {
    get_offersCalls_props().then((response)=>{
      //console.log(response.data)
    }).catch((error)=>{
      //console.log(error.response)
    })
  },[])


  const onConnect = useCallback((params) => {
    //console.log(edges_len);
    const new_edge = {
      id: edges_len++,
      target: `${params.target}`,
      source: `${params.source}`,
      type: 'smoothstep',
      markerEnd: {
        type: MarkerType.ArrowClosed,
      },
    };
    //console.log(new_edge);
    setEdges((eds) => addEdge(new_edge, eds))
  }, []);


  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);


  const nodeTypes = useMemo(() => ({
     custom: CustomNode,
    }),[]);

  
  function open_conf_modal(id,label,description){
    const newNode = {
      id: id,
      label: label, 
      description: description
    };
    setchosen_node(newNode);
  }

  
  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData('application/reactflow');

      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) {
        return;
      }

      setposition(reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      }))

      setopen_node_modal(type);
    },
    [reactFlowInstance]
  );


  const load_node = async (type,state,description)=>{
    const tmp_id = nodes.length;
    const newNode = {
        id: (tmp_id).toString(),
        type: 'custom',
        position,
        data: { label: state, description: description, open_conf_modal:open_conf_modal  },
    };
    await setNodes((nds) => nds.concat(newNode));
    if(tmp_id === 1){
      const new_edge = {
        id: edges_len++,
        target: '1',
        source: '0',
        type: 'smoothstep',
        markerEnd: {
          type: MarkerType.ArrowClosed,
        },
      };
      //console.log(new_edge);
      await setEdges((eds) => addEdge(new_edge, eds))
    }
    setopen_node_modal(null);
  }

  return (
    <div className="dndflow">
      <ReactFlowProvider>
        <Sidebar workflow_src={workflow_src} setworkflow_src={setworkflow_src} />
        <div className="reactflow-wrapper" ref={reactFlowWrapper}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onInit={setReactFlowInstance}
            onDrop={onDrop}
            onDragOver={onDragOver}
            fitView
            nodeTypes={nodeTypes}
            connectionLineComponent={CustomConnectionLine}
            connectionLineStyle={connectionLineStyle}
          >
            <Controls />
          </ReactFlow>
        </div>
      </ReactFlowProvider>
      {
        open_node_modal!==null
        ?<New_node_modal open_node_modal={open_node_modal} setopen_node_modal={setopen_node_modal} load_node={load_node} />
        :<></>
      }

      {
        chosen_node!==null
        ?<Wf_modal_params chosen_node={chosen_node} setchosen_node={setchosen_node} />
        :<></>
      }
    </div>
  );
};

export default Worflows_creator;


function Sidebar({workflow_src,setworkflow_src}){

    const classes = useToolbarStyles();
    const selectInputClasses = {
      root: classes.selectInput,
      disabled: classes.disabled,
      focused: classes.focused,
      error: classes.error
    };


    const onDragStart = (event, nodeType) => {
      event.dataTransfer.setData('application/reactflow', nodeType);
      event.dataTransfer.effectAllowed = 'move';
    };
  
    return (
      <aside>
        <div className="description">
            <div className='select_container' >
            <FormControl variant="standard" className="header_form">
                    <Select required
                        value={workflow_src}
                        input={<Input classes={selectInputClasses} />}
                        inputProps={{ classes: { select: classes.select } }}
                        style={{backgroundColor:"inherit"}}
                        onChange={e => {
                            setworkflow_src(e.target.value);
                        }} >
                        <MenuItem value={"null"}>Workflows</MenuItem>
                        <MenuItem value={"ref"}>{"Referencements"}</MenuItem>
                        <MenuItem value={"ao"}>{"Appels d'offre"}</MenuItem>
                        <MenuItem value={"bills"}>{"Factures"}</MenuItem>
                    </Select>
                </FormControl>
            </div>
        </div>
        <div className="nodes_cont">
            <div className="dndnode" onDragStart={(event) => onDragStart(event, 'default')} draggable>
                Nouv. noued
            </div>
        </div>
        
      </aside>
    );
  }
  