import axios from "axios"
import './request_interceptor'



const end_point1 = "https://com.portailachats.ma/api/eadmin/";
//const end_point1 = "http://localhost:8000/api/eadmin/";



////////////////////////////////////////////////////
////
////////////////////////////////////////////////////

export const company_admin_profil = async(id_admin) => {
    return await axios.get(end_point1+'company_admin_profil/'+id_admin);
} 

export const update_company_admin_profil = async(data) => {
    return await axios.post(end_point1+'update_company_admin_profil',data);
} 

export const create_service = async(data) => {
    return await axios.post(end_point1+'create_service',data);
} 

export const create_auditor = async(data) => {
    return await axios.post(end_point1+'create_auditor',data);
} 

export const get_services = async(service_role,filter,page) => {
    return await axios.get(end_point1+'get_services/'+service_role+'/'+filter+"?page="+page);
} 

export const get_auditors_for_cadmin = async(auditors_departement,filter,page) => {
    return await axios.get(end_point1+'get_auditors/'+auditors_departement+'/'+filter+"?page="+page);
} 

export const update_service_req = async(data) => {
    return await axios.post(end_point1+'update_service',data);
}

export const archive_service = async(data) => {
    return await axios.post(end_point1+'archive_service',data);
} 

export const archive_auditor = async(data) => {
    return await axios.post(end_point1+'archive_auditor',data);
} 

export const add_categorie = async(data) => {
    return await axios.post(end_point1+'add_categorie',data);
} 

export const get_categories = async(filter,page) => {
    return await axios.get(end_point1+'get_categories/'+filter+"?page="+page);
} 

export const archive_categorie = async(data) => {
    return await axios.post(end_point1+'archive_categorie',data);
} 

export const add_contract = async(data) => {
    return await axios.post(end_point1+'add_contract',data);
} 

export const get_contracts_for_cadmin = async(filter,page) => {
    return await axios.get(end_point1+'get_contracts_for_cadmin/'+filter+'?page='+page);
} 

export const archive_contract = async(data) => {
    return await axios.post(end_point1+'archive_contract',data);
} 



////////////////////////////////////////////////////
//// Workflows
////////////////////////////////////////////////////

export const get_offersCalls_props = async() => {
    return await axios.get(end_point1+'get_offersCalls_props');
} 


////////////////////////////////////////////////////
//// doc
////////////////////////////////////////////////////

export const create_doc = async(data) => {
    return await axios.post(end_point1+'create_doc',data);
} 

export const get_docs = async(filter) => {
    return await axios.get(end_point1+'get_docs/'+filter);
} 