import "./custom_elements.css"
import React, { useState, useRef, useCallback } from 'react';
import { Handle, Position, useStore } from 'react-flow-renderer';

const connectionNodeIdSelector = ((state) => {
    return state.connectionNodeId;
})

export default function CustomNode({ id, data }) {
  const connectionNodeId = useStore(connectionNodeIdSelector);
  const isTarget = connectionNodeId && connectionNodeId !== id;

  const targetHandleStyle = { zIndex: isTarget ? 3 : 1 };
  return (
    <div onClick={() => data.open_conf_modal(id,data.label,data.description)}>
      <div
        className="customNodeBody"
        style={{
          borderStyle: 'solid',
          backgroundColor: '#ccd9f6',
        }}
      >
        <Handle
          className="targetHandle"
          style={{ zIndex: 2 }}
          position={Position.Bottom}
          type="source"
        />

        <Handle
          className="targetHandle"
          style={{ zIndex: 2 }}
          position={Position.Left}
          type="source"
        />
        <Handle
          className="targetHandle"
          style={targetHandleStyle}
          position={Position.Top}
          type="target"
        />
        <span className="customNode_label">{data.label}</span>
      </div>
    </div>
  );
}
