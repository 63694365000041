import './add_company.css';
import React, { useState } from 'react';
import { Row,InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import swal from 'sweetalert';
import Button from '@material-ui/core/Button';
import AddIcon from '@mui/icons-material/Add';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { create_new_tenant } from '../../../services/gadmin_service';

function Add_company(){

    let user=JSON.parse(localStorage.getItem('admin-info'))

    //////form inputs
    const [company_name,setCompany_name]=useState('');
    const [email,setEmail]=useState('');

    const [businessRegister	,setbusinessRegister]= useState("");
    const [fiscal,setfiscal]= useState("");
    const [licenseNumber,setlicenseNumber]= useState("");
    const [iceNumbner,setIceNumbner]= useState("");

    const [wait_submitting,setWait_submitting] = useState(false);
    const [errorText,setErrorText] = useState(null);
    const [errorEmail,setErrorEmail] = useState(null);


//////////////////////////////
/////////////email validation
    function invalid_email_pattern(email){
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if(email!==""){
            if (!pattern.test(email)) {
                return true ;
              }else{
                return false ;
              }
        }else return true;
    }

////////////////////////////

/////////////////////////////
/////////////////////////////Submit
    function submit() {
        setErrorText(null);
        setErrorEmail(null);
        if(email==="" || company_name==="" || businessRegister==="" || fiscal==="" || licenseNumber==="" || iceNumbner===""){
            setErrorText("Champs invalides !");
        }else{
            if(invalid_email_pattern(email)){
                setErrorText("Email invalid !");
                setErrorEmail("Email invalid !");
            }else{
                swal({
                    title: "Etes-vous sûr ?",
                    text: "!!!",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                .then((willDelete) => {
                  if (willDelete) {
                    setWait_submitting(true);
                    let formData = new FormData();
                    formData.append('company_name',company_name);
                    formData.append('company_email',email);
                    formData.append('database',company_name.replace(" ", ""));
                    formData.append('businessRegister',businessRegister);
                    formData.append('fiscalIdentifier',fiscal);
                    formData.append('licenseNumber',licenseNumber);
                    formData.append('iceNumbner',iceNumbner);
                    create_new_tenant(formData).then((response) => {
                        setWait_submitting(false);
                        swal("L'entreprise a été ajoutée avec succès !", {
                            icon: "success",
                            buttons: true,
                            }).then((willDelete) => {
                                if (willDelete) {
                                    window.location.reload()
                                }
                            })
                    }).catch((error) => {
                        //console.log(error.response);
                        let error_data = error.response.data;
                        if(error_data.errors){
                            if(error_data.errors.company_name) setErrorText("Entreprise déjà existante !");
                        }
                        setWait_submitting(false);
                    });
                  }
                })
            }
        }
        
    }

///////////////////////////////////////



/////////////////////////////////////////////////

    return (
        <div className="add_company_form">
        <h2>Nouvelle Entreprise</h2>
        <div className="form" >
                {
                    <p className={errorText || errorEmail ? "col-md-12 error" : "col-md-12 valid-champ"}>
                            {errorText || errorEmail}
                    </p>
                }

                <Row className="mb-12">
                    <Form.Group className="col-md-6">
                        <label>Nom de l'entreprise</label>
                        <Form.Control
                            required
                            autoComplete="new-password"
                            type="text"
                            placeholder="Nom d'entreprise *"
                            value={company_name}
                            onChange={ e =>  {setCompany_name(e.target.value)}}
                            className={ errorText && company_name==="" ? "is-invalid":""}
                        />
                    </Form.Group>
                    
                    <Form.Group className="col-md-6">
                        <label>Email</label>
                        <InputGroup hasValidation>
                            <Form.Control
                                required
                                autoComplete="new-password"
                                type="text"
                                placeholder="E-mail *"
                                value={email}
                                onChange={ e =>  {setEmail(e.target.value);}}
                                className={ errorText && errorEmail  ? "is-invalid":""}
                            />
                            <InputGroup.Text  className={ errorText && errorEmail ? "invalid-inputgroup" : "valid-inputgroup"}> 
                                <FontAwesomeIcon icon={faEnvelope} />
                            </InputGroup.Text>        
                    </InputGroup>
                    </Form.Group>
                </Row>
             <Row className="mb-12">
                <Form.Group className="col-md-6">
                    <label>Registre du commerce (Ou équivalent)</label>
                    <Form.Control
                        required
                        autoComplete="new-password"
                        type="text"
                        placeholder="Registre du commerce (Ou équivalent) *"
                        value={businessRegister}
                        onChange={ e =>  {setbusinessRegister(e.target.value)}}
                        className={ errorText && businessRegister==="" ? "is-invalid":""}
                    />
                </Form.Group>

                <Form.Group className="col-md-6">
                    <label>Identifiant fiscal (Ou équivalent) </label>
                    <Form.Control
                        required
                        autoComplete="new-password"
                        type="text"
                        placeholder="Identifiant fiscal (Ou équivalent) *"
                        value={fiscal}
                        onChange={ e =>  {setfiscal(e.target.value)}}
                        className={ errorText && fiscal==="" ? "is-invalid":""}
                    />
                </Form.Group>
              </Row>
              
              <Row className="mb-12">
                <Form.Group className="col-md-6">
                    <label>N° Patente (Ou équivalent) </label>
                    <Form.Control
                        required
                        autoComplete="new-password"
                        type="number"
                        placeholder="N° Patente (Ou équivalent) *"
                        value={licenseNumber}
                        onChange={ e =>  {setlicenseNumber(e.target.value)}}
                        className={ errorText && licenseNumber==="" ? "is-invalid":""}
                    />
                </Form.Group>

                <Form.Group className="col-md-6">
                    <label>N° ICE (Ou équivalent)</label>
                    <Form.Control
                        required
                        autoComplete="new-password"
                        type="number"
                        placeholder="N° ICE (Ou équivalent) *"
                        value={iceNumbner}
                        onChange={ e =>  {setIceNumbner(e.target.value)}}
                        className={ errorText && iceNumbner==="" ? "is-invalid":""}
                    />
                </Form.Group>

            </Row> 
            <Row style={{display:'flex',justifyContent:"flex-end"}}>
                <Button variant="contained" startIcon={<AddIcon />} className={wait_submitting ? "submit_but disabled_button":"submit_but"} onClick={submit}>
                            Ajouter
                </Button>
            </Row>
            
        </div>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={wait_submitting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
    
}

export default Add_company
