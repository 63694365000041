import '../../../../assets/css/catalogue_modal.css'
import '../../../../assets/css/products_gallery.css'
import './products_gallery.css'
import React, { useState , useEffect } from 'react';
import { useHistory } from "react-router-dom";

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CloseIcon from '@mui/icons-material/Close';
import empty from '../../../empty.jpg'
import Pagination from "react-js-pagination";


import Loading_animation_view from '../../../loading_animation_view/loading_animation_view';
import { get_categories } from '../../../../services/purchase_service';
import { get_products, get_suppliers_by_category } from '../../../../services/for_allServices';




function Products_gallery ({setUrl_updated}){

  const [products,setProducts] = useState(null);
  let history = useHistory();

  const [categories,setCategories]=useState([]);
  const [suppliers,setSuppliers]=useState([]);

  const [filter_categorie,setFilter_categorie]=useState("all");
  const [filter_supplier,setFilter_supplier]=useState("null");
  const [search_value,setsearch_value]=useState("");


  const [currentPage,setCurrentPage]=useState(1);
  const [dataPerPage,setdataPerPage] =useState(9);
  const [data_count,setdata_count]=useState(0);

 
  useEffect(()=>{
    setProducts(null);
    setdata_count(0);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    var tmp_searchValue = "null";
    var tmp_filterSupplier = "null";
    var tmp_filterCategorie = "all";
    
    async function load_data(){
      if( urlParams.get('valeur')){
        tmp_searchValue = urlParams.get('valeur');
      }if(urlParams.get('categorie')){
        tmp_filterCategorie = urlParams.get('categorie');
      }
      if(urlParams.get('fournisseur')){
        tmp_filterSupplier = urlParams.get('fournisseur');
      }
      await setFilter_supplier(tmp_filterSupplier);
      await setsearch_value(tmp_searchValue==="null"?null:tmp_searchValue);
      await setFilter_categorie(tmp_filterCategorie);
          
  
      await get_categories().then((response)=>{
          setCategories(response.data);
      }).catch((error)=>{
          //console.log(error.response);
      });

      let page = (urlParams.get('page')?urlParams.get('page'):1);
        await setCurrentPage(page);
  
      if(tmp_filterCategorie !== "all"){
        await get_suppliers_by_category(tmp_filterCategorie).then((response)=>{
          setSuppliers(response.data);
          if(tmp_filterSupplier==="null" && response.data.length!==0 ){
            tmp_filterSupplier = response.data[0].id;
            setFilter_supplier(response.data[0].id);
          }
           get_products(tmp_filterCategorie,tmp_filterSupplier,tmp_searchValue,page).then((response)=>{
              setProducts(response.data.data);
              setdata_count(response.data.total)
          }).catch((error)=>{
              //console.log(error.response);
          });
        }).catch((error)=>{
          //console.log(error.response);
        })
      }else{
        await setProducts([]);
      }
    }

    load_data();
    
  },[]);
  
  
  function search(){
    let path = history.location.pathname;
    if(filter_categorie!=="all") history.push(path+"?categorie="+filter_categorie+"&fournisseur="+filter_supplier+"&valeur="+search_value);
    else history.push(path+"?valeur="+search_value);
    setUrl_updated(search_value);
  }

  function reset_search(){
    let path = history.location.pathname;
    if(filter_categorie!=="all"){
      if(filter_supplier!==suppliers[0].id) history.push(path+"?categorie="+filter_categorie+"&fournisseur="+filter_supplier);
      else history.push(path+"?categorie="+filter_categorie);
    } 
    else history.push(path);
    setUrl_updated("reset");
  }


  function set__categorie(value){
    let path = history.location.pathname;
    if(value!=="all") history.push(path+"?categorie="+value);
    else history.push(path+"?categorie=all");
    setUrl_updated(value);
  }

  function set__supplier(value){
      let path = history.location.pathname;
      history.push(path+"?categorie="+filter_categorie+"&fournisseur="+value);
      setFilter_supplier(value);
      setUrl_updated(value);
  }

  function view_details(product_code){
    history.push("/serviceMetier/produit/"+product_code);
  }


  async function change_page(page){
    await setCurrentPage(page);
    let path = history.location.pathname;
    let params = history.location.search;
    let search_params = params.split('&');
    let tmp_params = path;
    if(!params) history.push(path+"?page="+page);
    else{
      for(let i in search_params){
        if(search_params[i].includes("page=")){
          if(i==0) tmp_params=tmp_params+"?page="+page;
          else tmp_params=tmp_params+"&page="+page;
        }else{
          if(i==0) tmp_params=tmp_params+search_params[i];
          else tmp_params=tmp_params+"&"+search_params[i];
        }
      }
      if(!tmp_params.includes("page=")) tmp_params=tmp_params+"&page="+page;
      history.push(tmp_params);
    }
    await setUrl_updated(page);
  }



  return (
    <>
      {
        products===null
        ?<Loading_animation_view />
        :<div className="products_gallery_modal_container">
           <div className="products_gallery_header_container">
            <div className='search_filter_container'>
                 <FormControl variant="standard" className="header_form" >
                    <InputLabel id="demo-simple-select-helper-label">Famille d'achat</InputLabel>
                    <Select required value={filter_categorie} 
                        onChange={e => {set__categorie(e.target.value) }} >
                        
                        <MenuItem value={"all"} disabled>Familles d'achat</MenuItem>
                        {
                        categories.map((categorie,id)=>(
                          <MenuItem value={categorie.id_category} key={id} >{categorie.name}</MenuItem>
                        ))
                        }
                        
                    </Select>
                </FormControl>
                <FormControl variant="standard" className={filter_categorie==="all" || suppliers.length===0 ?"header_form disabled_button":"header_form" } >
                    <InputLabel id="demo-simple-select-helper-label">Fournisseurs disponible</InputLabel>
                    <Select required value={filter_supplier} 
                        onChange={e => {set__supplier(e.target.value) }} >
                        
                        <MenuItem value={"null"} disabled>Fournisseur -- Maison mère</MenuItem>
                        {
                        suppliers.map((supplier,id)=>(
                            <MenuItem value={supplier.id} key={id} >{supplier.parentCompany+" -- "+supplier.fullName}</MenuItem>
                        ))
                        }
                        
                    </Select>
                </FormControl>
                <Grid container spacing={1} alignItems="flex-start" className="search_bar">
                    <Grid item>
                        <SearchIcon onClick={search} className={search_value===""?"my_icon disabled_button":"my_icon" } style={{cursor:"pointer"}}  />
                    </Grid>
                    <Grid>
                        <TextField label="Chercher" className={filter_supplier==="null"?"disabled_button":"" }  value={search_value} onChange={(e)=>{setsearch_value(e.target.value)}} />
                    </Grid>
                    <Grid item style={{marginLeft:"5px"}}>
                        <CloseIcon onClick={reset_search} className={search_value===""?"my_icon disabled_button":"my_icon" }  style={{cursor:"pointer"}}  />
                    </Grid>
                </Grid>
                </div>
            </div>
            <div className="products_gallery_modal_contents_container products_gallery_contents_container">
                {
                    products.length !== 0
                    ?<>
                    <div className="products_contents_container">
                        {
                            products.map((val,id) => {
                            return (
                                <React.Fragment key={id}>
                                    <div className="main_card_container">
                                        <div className="main_card_img_container">
                                            <img src={val.product_pic!==""?`data:image/png;base64,${val.product_pic}`:empty}/>
                                        </div>
                                        <div className="main_card_infos_container">
                                            <div className="card_title">{val.title}</div>
                                            <div className="card_description">{val.description}</div>
                                            <div className="view_more_button_container">
                                                <Button variant="contained" className="submit_but edit_but" onClick={() => {view_details(val.product_code)}} >
                                                    Plus de détails
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                        
                                );
                            })
                        }
                    </div>
                    <div className="pagination_container">
                          <Pagination
                              activePage={Number(currentPage)}
                              itemsCountPerPage={dataPerPage}
                              totalItemsCount={Number(data_count)}
                              pageRangeDisplayed={4}
                              onChange={change_page.bind(this)}
                          />
                      </div>
                    </>:filter_categorie!=="all"
                          ?<div className='emptytable_msg_container'>Aucun produit disponible</div>
                          :<div className='emptytable_msg_container'>Sélectionner votre Famille d'achat</div>
                }
            </div>
          </div>
      }
      </>
  )

}

export default Products_gallery;
