import './send_email.css'
import React,{useState} from 'react'

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import SendIcon from '@material-ui/icons/Send';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Multiselect from 'multiselect-react-dropdown';
import {makeStyles} from '@material-ui/core/styles';
import Fab from '@mui/material/Fab';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CLoseIcon from '@material-ui/icons/Close';
import swal from 'sweetalert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import { search_users_byname_or_email_forServ, send_email_from_emails_menu } from '../../../services/for_allServices';


const useStyles = makeStyles((theme) => ({
    button: { margin: theme.spacing(1),},
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
  }));
 


  function getFileName(file) {
    const name = file.name;
    const lastDot = name.lastIndexOf('.');

    const fileName = name.substring(0, lastDot);
    const ext = name.substring(lastDot + 1);
    return fileName+"."+ext;
}


function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2)+" "+sizes[i];
 }


function Send_email_modal({opend_sd_em_modal,setOpend_sd_em_modal,setUrl_updated,available_recipients}){
    
    const classes = useStyles();
    let service=JSON.parse(localStorage.getItem('user-info'))

    const [recipients,setRecipients] = useState([]);
    const [selected_recipients,setSelected_Recipients] = useState([]);
    const [object,setObject] = useState(null);
    const [editorContent, seteditorContent] = useState(null);

    const [attached_files,setAttached_files] = useState([]);
    const [size_attached_files,setSizeattached_files] = useState(0);

    const [errorText,setErrorText] = useState(null);
    const [errorEmail,setErrorEmail] = useState(null);

    const [waiting,setWaiting] = useState(false);

    function invalid_email_pattern(email){
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if(email!==""){
            if (!pattern.test(email)) {
                return true ;
              }else{
                return false ;
              }
        }else return true;
    }


    function search_value(value){
        var pattern = new RegExp('([0-9a-zA-Z-_.@ ]*|^)'+value+'([0-9a-zA-Z-_.@ ]|$)','i');
        let tmp_array = [];
        let tmp_value={};
        available_recipients.map((available_recipient) => {
            if (pattern.test(available_recipient.email) || pattern.test(available_recipient.fullName)) {
                tmp_value = {};
                if(tmp_array.length<5){
                    tmp_value['name'] = available_recipient.fullName+" ( "+available_recipient.email+" )";
                    tmp_value['id'] = available_recipient.email;
                    tmp_array.push(tmp_value);  
                }
                
            }
        })
        return tmp_array;
    }

    async function search_users_byname_or_email(e){
        await setRecipients([]);
        if(e!==''){

           if(!invalid_email_pattern(e)){
                await setRecipients([{
                    'name':e,
                    'id':e
                }])
            }else{
                setRecipients(search_value(e));
           }
        }
    }

    async function onSelect_recipients(e){
        await setSelected_Recipients([]);
        await setSelected_Recipients(e);
        await setRecipients([]);
    }

    async function onRemove_recipients(e){
        await setSelected_Recipients([]);
        await setSelected_Recipients(e);
    }


    const handleEditorChange = (content) => {
        seteditorContent(content);
       // if(content!="<p><br></p>" && content!="<p></p>")
    }

    function isContentExist(){
        if(editorContent!="<p></p>" && editorContent!="<p><br></p>" && editorContent!=null) return true;
        else return false;
      }

    function attach_file(e){
        if(e.target.files.length!==0){
               let tmp_size = size_attached_files;
                let file_size_mb = (e.target.files[0].size)/(1024*1024);
                tmp_size+=file_size_mb;
                if(tmp_size>5) setErrorText('Taille maximale 5MB !');
                else{
                    setSizeattached_files(tmp_size);
                    let tmp_attached_files = [ ...attached_files ];
                    tmp_attached_files.push(e.target.files[0]);
                    setAttached_files(tmp_attached_files);  
                }             
          }
    }
      
  
    async function delete_file(id){
        let tmp_size = size_attached_files;
        let file_size_mb = (attached_files[id].size)/(1024*1024);
        tmp_size-=file_size_mb;
        //console.log(tmp_size);
        await setSizeattached_files(tmp_size);
        let tmp_attached_files = [ ...attached_files ];
        tmp_attached_files.splice(id,1);
        await setAttached_files(tmp_attached_files);
    }


    function submit(){
        if(object==="" || !isContentExist() || selected_recipients.length===0){
            setErrorText("Champs invalid !");
        }else{
            swal({
                title: "Etes-vous sûr ?",
                text: "!!!!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then((willDelete) => {
                if (willDelete) {
                    setWaiting(true);
                    let formData = new FormData();
                    formData.append('id_login',service.id);
                    formData.append('object',object);
                    formData.append('content',editorContent);
                    formData.append('attached_files',attached_files);
                    attached_files.map((attached_file)=>{
                        formData.append('attached_files[]',attached_file);
                    })
                    selected_recipients.map((recipient)=>{
                        formData.append('recipients[]',recipient.id);
                    })

                    //console.log(formData.get("recipients[]"));
                    send_email_from_emails_menu(formData).then((response)=>{
                        setWaiting(false);
                        swal("Message envoyé avec succès !", {
                            icon: "success",
                            buttons: true,
                        }).then((willDelete) => {
                            if (willDelete) {
                        }})
                        setUrl_updated(response.data);
                        setOpend_sd_em_modal(false);
                    }).catch((error)=>{
                        setWaiting(false);
                        //console.log(error.response);
                    })
                }})
        
        }
    }

    return (
        <Dialog className="send_email_container" fullWidth={true} maxWidth="md" open={opend_sd_em_modal} onClose={()=>setOpend_sd_em_modal(false)} aria-labelledby="form-dialog-title" >            
            <DialogTitle id="form-dialog-title">Nouveau message</DialogTitle>
            <DialogContent>
            <div className="form">
                <Row className="mb-12">
                    {
                        <p className={errorText || errorEmail ? "col-md-12 error" : "col-md-12 valid-champ"}>
                                {errorText || errorEmail}
                        </p>
                    }
                    <Form.Group className="col-md-12">
                        <Multiselect
                            options={recipients}
                            onSelect={(e) => onSelect_recipients(e) }
                            onRemove={(e) => onRemove_recipients(e) }
                            placeholder="Destinataires"
                            onSearch={(e) => search_users_byname_or_email(e)}
                            displayValue="name"
                            emptyRecordMsg="Aucune option"
                        />
                    </Form.Group>

                    <Form.Group className="col-md-12">
                        <Form.Control
                            required
                            autoComplete="new-password"
                            type="text"
                            placeholder="Objet"
                            value={object}
                            onChange={ e =>  {setObject(e.target.value)}}
                            className={ errorText && object==="" ? "is-invalid":""}
                        />
                    </Form.Group>
                    
                    <div className="text_edit_cont">
                         <SunEditor
                            lang="fr"
                            width="100%"
                            height="100%"
                            setOptions={{
                                buttonList: [
                                [
                                'undo', 'redo',
                                'bold', 'underline', 'italic', 'strike',
                                'font', 'fontSize',
                                'fontColor', 'hiliteColor',
                                'outdent', 'indent',
                                'align', 'list', 'lineHeight'
                                ]
                            ]
                            }}
                            onChange={handleEditorChange}
                            /> 
                    </div>

                    <div className="files_container">
                        {
                            attached_files.map((file,id)=>(
                                <div className='file_container' >
                                    <div className="file_infos">{getFileName(file)+' ('+bytesToSize(file.size)+')'}</div>
                                    <div className="close_icon"><CLoseIcon className="my_icon" onClick={()=>delete_file(id)} /></div>
                                </div>
                            ))
                        }
                    </div>
                </Row>
            </div>
            </DialogContent>
            <DialogActions>
            <div className='dialogBottom'>
                <div>
                    <label htmlFor="complementDocs"  style={{marginRight:'7px'}}>
                            <Fab size="small" component='span' ><AttachFileIcon className="my_icon"/> </Fab>
                            <input className={classes.input} name="complementDocs"
                                id="complementDocs" multiple type="file" onChange={(e)=>attach_file(e)} />
                    </label>
                    <Button variant="contained" className="submit_but valide_but" onClick={submit} startIcon={<SendIcon className="my_icon" />} >
                        Envoyer
                    </Button>
                
                </div>
            </div>      
                
            </DialogActions>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waiting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </Dialog>
    )
}

export default Send_email_modal
