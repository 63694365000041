import '../../../assets/css/navBar.css';
import { useHistory } from "react-router-dom";
import {Navbar,Form,NavDropdown} from 'react-bootstrap';
import React, {useState,useEffect } from 'react';
import pa_logo from './PA_logo.jpeg';
import defaultProfil_pic from './defaultProfil_pic.png';

import Avatar from '@material-ui/core/Avatar';
import { logout_request } from '../../../services/for_allServices';


function NavBar({menu_color,company_logo}) {
  let history = useHistory();
  let user=JSON.parse(localStorage.getItem('user-info'))

  const [info,setInfo]=useState(null);
  const [userprofile , setUserprofile] = useState(null)
 
  useEffect(()=>{
    setInfo(user);
    setUserprofile(defaultProfil_pic);
  },[]);


  
  useEffect(()=>{
      
      async function load_data(){
        await setInfo(user);
        await setUserprofile(defaultProfil_pic);
      }
      load_data();
  },[]);


  async function logout(){
    await logout_request(user.id).then((response)=>{
      localStorage.clear();
      window.dispatchEvent(new Event("storage"));
    }).catch((error)=>{
      //console.log(error.response);
    })
    history.push("/");
  }

  async function view_profil(){
    let path = (history.location.pathname).split('/')[1];
    history.push('/'+path+"/fiche_identite");
  }

  return (
      <div id='myTopnav' className='service_topnav topnav' style={{width:"100%"}}>
        <Navbar bg="dark" variant="dark" style={{width:"100%",margin:'0px'}}>
        <div className="navbar_content_container"  style={{ background:menu_color }}>
          <div className="company_logo_container">
              {
                  company_logo!==null
                  ?<>
                    <img src={  company_logo!==""?`data:image/*;base64,${company_logo}`:pa_logo } />
                   </>
                   :<></> 
              }
          </div>
            <div className="selection">
              <Form inline className='buttons'>
                  <div className='user_name_container'>
                  <NavDropdown title={info!=null?info.fullName:""} >
                    <NavDropdown.Item onClick={view_profil} >Profil</NavDropdown.Item>
                    <NavDropdown.Item onClick={logout}>Se déconnecter</NavDropdown.Item>
                  </NavDropdown>
                  <Avatar src={userprofile} className="my_avatar" />
                  </div>
                </Form>
            </div>
           
        </div>
        
      </Navbar>
    </div>


  );
}

export default NavBar;
