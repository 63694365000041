
import './suppliers_notes_charts.css'
import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const Suppliers_notes_charts = ({data}) => {
    const [content_charged,setContent_charged]=useState(false);
    const colors = ['#ffa726','#33b66e'];
    const [chart_data,setChart_data]=useState(
     [
        {
            series: [],
            options: {
              chart: {
                type: 'radialBar',
                events: {
                  mounted: (chart) => {
                    chart.windowResizeHandler();
                  }
                }
              },
              fill: {
                colors:'#f33640f3'
              },
              plotOptions: {
                radialBar: {
                    hollow: {
                        margin: 0,
                        size: '65%',
                        background: '#fff',
                        image: undefined,
                        imageOffsetX: 0,
                        imageOffsetY: 0,
                        position: 'front'
                    },
                  dataLabels: {
                    show: true,
                    name: {
                      offsetY: 0,
                      show: true,
                      color: '#888',
                      fontWeight:'700',
                    },
                    value: {
                      formatter: function(val) {
                        return (parseInt(val)/100 * 5).toFixed(2);
                      },
                      color: '#111',
                      fontWeight:'700',
                      show: true,
                      offsetY: 4,
                    }
                  }
                }
              },
              labels: ['Score Financier'],
            }
        },
        {
            series: [0],
            options: {
              chart: {
                type: 'radialBar',
                events: {
                  mounted: (chart) => {
                    chart.windowResizeHandler();
                  }
                }
              },
              fill: {
                colors:'#f33640f3'
              },
              plotOptions: {
                radialBar: {
                    hollow: {
                        margin: 0,
                        size: '65%',
                        background: '#fff',
                        image: undefined,
                        imageOffsetX: 0,
                        imageOffsetY: 0,
                        position: 'front'
                    },
                  dataLabels: {
                    show: true,
                    name: {
                        offsetY: 0,
                        show: true,
                      color: '#888',
                      fontWeight:'700',
                    },
                    value: {
                      formatter: function(val) {
                        return (parseInt(val)/100 * 5).toFixed(2);
                      },
                      color: '#111',
                      show: true,
                      offsetY: 4,
                      fontWeight:'700',
                    }
                  }
                }
              },
              labels: ['Score Technique'],
            }
        },
        {
            series: [0],
            options: {
              chart: {
                type: 'radialBar',
                events: {
                  mounted: (chart) => {
                    chart.windowResizeHandler();
                  }
                }
              },
              fill: {
                colors:'#ffa726'
              },
              plotOptions: {
                radialBar: {
                    hollow: {
                        margin: 0,
                        size: '65%',
                        background: '#fff',
                        image: undefined,
                        imageOffsetX: 0,
                        imageOffsetY: 0,
                        position: 'front'
                    },
                  dataLabels: {
                    show: true,
                    name: {
                        offsetY: 0,
                        show: true,
                      color: '#888',
                      fontWeight:'700',
                    },
                    value: {
                      formatter: function(val) {
                        return val;
                      },
                      color: '#111',
                      show: true,
                      offsetY: 4,
                      fontWeight:'700'
                    }
                  }
                }
              },
              labels: ['Qualité de service'],
            }
        }
    ]
    );
        
    useEffect(()=>{
        //console.log(data);
        let tmp_data = [...chart_data];
        if(data.length!==0){
          if(data[0].technical_score<4 && data[0].technical_score>2)  tmp_data[0].options.fill.colors=colors[0]
          if(data[0].technical_score>=4)  tmp_data[0].options.fill.colors=colors[1]
          tmp_data[0].series[0] = (data[0].technical_score/5) * 100;
          if(data[0].finance_score>2 && data[0].finance_score<4)  tmp_data[1].options.fill.colors=colors[0]
          if(data[0].finance_score>=4)  tmp_data[1].options.fill.colors=colors[1]
          tmp_data[1].series[0] = (data[0].finance_score/5) * 100; 
        }else{
          tmp_data[0].series[0] = 0;
          tmp_data[1].series[0] = 0; 
        }
        setChart_data(tmp_data);
        setContent_charged(true);
    },[])
  
  return (
    <div className="radialCharts_offersState_container" >
        {
          content_charged!==false
          ?<>
            <div className="radialCharts_container">
                <ReactApexChart 
                    options={chart_data[0].options} 
                    series={chart_data[0].series} 
                    type="radialBar"
                    width="98%"
                    height="98%"/>
            </div>
            <div className="radialCharts_container">
                  <ReactApexChart 
                      options={chart_data[1].options} 
                      series={chart_data[1].series} 
                      type="radialBar"
                      width="98%"
                      height="98%"/>
            </div>
            
            <div className="radialCharts_container">
                <ReactApexChart 
                    options={chart_data[2].options} 
                    series={chart_data[2].series} 
                    type="radialBar"
                    width="98%"
                    height="98%"/>
            </div>

           </>
          :<></>
        }
    </div>
  );

}

export default React.memo(Suppliers_notes_charts);