import React, { useState } from "react";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Widget_count_elements from "../widget_count_elements/widget_count_elements";



const columns = [
  { id: 'code', numeric: true, disablePadding: false, label: 'Code de demande',align:"left" },
  { id: 'desired_delivery_date', numeric: true, disablePadding: false, label: 'Date de livraison souhaitée' ,align:"left"},
  { id: 'delivery_date', numeric: true, disablePadding: false, label: 'Date de livraison',align:"left" },
  { id: 'delay', numeric: true, disablePadding: false, label: 'Délai',align:"center" },
];
  
  



function state(request_state){
  return(
    <>
      {
       request_state === null || request_state === "demander à être changée" || request_state === "en cours de traitement"
       ?<Button variant="contained" className="status_waiting">{request_state?request_state:'En cours'}</Button>
       :request_state === "refusée" || request_state === "annulée" || request_state ===  "refus de traitement" || request_state ===  "non conforme"
          ?<Button variant="contained" className="status_deny">{request_state}</Button>
          :<Button variant="contained" className="status_success">{request_state}</Button>
      }
    </>

  );
}


  function Requests({requests}){
    
    return(
        <>
        {
            requests.map((row,idx) => (
                <TableRow tabIndex={-1} key={idx}>
                    <TableCell align="left" component="th" scope="row">
                        {row.request_infos[0].request_code}
                    </TableCell>
                    <TableCell align="left">
                        {(new Date(row.request_infos[0].delivery_supposedDate)).toLocaleString().split(/[,| ]/)[0]}
                    </TableCell>
                    <TableCell align="left">
                        {
                            row.request_infos[0].delivery_date?(new Date(row.request_infos[0].delivery_date)).toLocaleString().split(/[,| ]/)[0]
                            :<span style={{opacity:".8"}} >Pas encore</span>
                        
                        }
                    </TableCell>
                    <TableCell align="center">
                        <div className='request_state_container'>
                            {(row.request_delay)}
                        </div>
                    </TableCell>
                </TableRow>               
             ))
        }
        </>
    );
}


function Delay_between_delivery_supposedDate({data}){

  const [show_elemnts,setShowing_elements] = useState(false);

      

  return (
      <>
      {
              show_elemnts!==true
                ?<Widget_count_elements setShowing_elements={setShowing_elements} elements_number={data.avg+" jrs"} fontColor="#51a5cf" progress={data.progress} />
                :<TableContainer className="delay_of_market_widget" onDoubleClick={() => setShowing_elements(false)} style={{cursor:'pointer'}} 
                        onTouchStartCapture={(e)=>{e.stopPropagation();setShowing_elements(false)}} >
                    <Table aria-label="sticky table">
                        <TableHead>
                        <TableRow>
                            {
                                columns.map((column) => (
                                    <TableCell key={column.id} align="left">{column.label}</TableCell>
                                ))
                            }
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        <>
                            <Requests requests={data.data} />
                        </>
                        </TableBody> 
                    </Table>
                </TableContainer>
        }
      </>
    );
}

export default React.memo(Delay_between_delivery_supposedDate);