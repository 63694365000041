import axios from "axios"
import './request_interceptor'


const end_point1 = "https://com.portailachats.ma/api/services/charts/";
//const end_point1 = "http://localhost:8000/api/services/charts/";

////////////////////////////////////////////////////
//// bills
////////////////////////////////////////////////////


export const get_ChartsData_offers_number_byMonth = async(categorie,time_range) => {
    return await axios.get(end_point1+'get_ChartsData_offers_number_byMonth/'+categorie+"/"+time_range);
}

