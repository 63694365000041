import './charts_of_the_bestSuppliers.css'
import React from 'react';
import Loading_animation_view from '../../../loading_animation_view/loading_animation_view';

  
  
const Chart_of_the_bestSuppliers_for_orders = ({data})=>{


  return (
    <div className='charts_of_the_bestSuppliers_container'>
     <div className='container'>
       {
         data===null
         ?<Loading_animation_view />
         :<>
            <div className="categorie_container">
                <div className="order_of_the_bestSuppliers" style={{backgroundColor:"rgb(243 184 62)"}} ><span>{data[0].count}</span></div>
                <div className="name_of_the_bestSuppliers" style={{backgroundColor: "#b8c6db",backgroundImage: "linear-gradient(220deg, rgb(243 184 62) 0%, rgb(246 222 172) 74%)"}}>{data[0].supplier_name}</div>
            </div>
            <div className="categorie_container">
                <div className="order_of_the_bestSuppliers" style={{backgroundColor:"#b8c6db"}} ><span>{data[1].count}</span></div>
                <div className="name_of_the_bestSuppliers" style={{backgroundColor: "#b8c6db",backgroundImage: "linear-gradient(220deg, #b8c6db 0%, #eff7fa 74%)"}}>{data[1].supplier_name}</div>
            </div>
            <div className="categorie_container">
                <div className="order_of_the_bestSuppliers" style={{backgroundColor:"#CD7F32"}} ><span>{data[2].count}</span></div>
                <div className="name_of_the_bestSuppliers" style={{backgroundColor: "#b8c6db",backgroundImage: "linear-gradient(220deg, #CD7F32 0%, rgb(246 211 177) 74%)"}}>{data[2].supplier_name}</div>
            </div>
         </>
       }
     </div>        
     </div>        
  );
}


export default React.memo(Chart_of_the_bestSuppliers_for_orders)