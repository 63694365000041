import '../../../../assets/css/config_widgets.css'
import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from '@material-ui/icons/Close';



export default function Config_widget({add_widget,remain_widgets,setRemain_Widgets}) {

  return (
    <Dialog fullWidth={true} maxWidth="lg" className='config_widget_container' open={remain_widgets!=null?true:false} centered>   
       <DialogTitle>
          <div className='dialog_title_container'>
             <div className='dialog_title'>
                    Ajouter d'autres widgets
             </div>
             <div className='dialog_icon'>
                <CloseIcon style={{cursor: "pointer"}} onClick={()=>setRemain_Widgets(null)} />
            </div>
           </div>
       </DialogTitle>
    
        <DialogContent style={{width:"100%"}}>
            <div className='remains_widgets_container'>
                {
                    remain_widgets.map((remain_widget,index) =>(
                        <div className='widget_container' id={remain_widget.id} onClick={ () => add_widget(remain_widget)}>
                            <div className='widget_icon'>
                                {remain_widget.icon}
                            </div>
                            <div className='widget_title'>
                                {remain_widget.itemTitle}
                            </div>
                        </div>
                    ))


                }
            </div>

            
        </DialogContent>
    </Dialog>
      
  );
}