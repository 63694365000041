import '../../assets/css/state_details.css'
import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';


function state(stat){
    return(
      <>
        {
         stat === ""
         ?<Button variant="contained" className="status_waiting">en cours</Button>
         :stat === 0
            ?<Button variant="contained" className="status_deny">non validé</Button>
            :<Button variant="contained" className="status_success">validé</Button>
        }
      </>
  
    );
  }
  const users = ["Fournisseur","Service d'achat","Service métier","Service de finance"];
  var reg = new RegExp(/^\d+$/);

function State_details({doc_validation,setdoc_validation}){

    return (
      <Dialog className="view_state_details_container" fullWidth={true} maxWidth={'md'} open={doc_validation!=null?true:false} onClose={()=>setdoc_validation(null)} aria-labelledby="form-dialog-title" >
        <DialogTitle id="form-dialog-title">L'état du document</DialogTitle>
        <DialogContent>
          <div>
              <div className='docs_reasons_textFields_container' >
                {
                  doc_validation.map((validation,id)=>{
                    return (<div className='container_11' >
                      <div className="bill_state_byService">
                          <div>{reg.test(validation.email) || validation.email===""?users[parseInt(validation.id)]:validation.email}</div>
                          <div>{state(validation.accord)}</div>
                      </div>
                      <TextField
                          label="Motif du rejet"
                          value={validation.reason===""?"...":validation.reason}
                          variant='outlined'
                          multiline
                          rows={7}
                          style={{width:"100%"}}
                          inputProps={
                            { readOnly: true, }
                          }
                          disabled={validation.reason===""?true:false}
                      /> 
                    </div>)
                  })
                }
                  
              </div>

          </div>
        </DialogContent>
      </Dialog>
    )
}

export default State_details
