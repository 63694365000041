import '../../../../assets/css/all_services_profil.css'
import React, { useState,useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Form from 'react-bootstrap/Form'
import defaultProfil_pic from './defaultProfil_pic.png'
import { get_supplierIdentity_profileImg} from '../../../../services/supplier_service'
import { get_categories } from '../../../../services/purchase_service'

function Identifiantsupplier({ handleClose, show, supplier }) {

  const [userprofile , setUserprofile] = useState(null)
  //const [categories,setCategories]=useState(null);

  useEffect(()=>{

   /* get_categories().then((response)=> {
      //console.log(response.data);
      setCategories(response.data);
    }).catch((error)=>{
      //console.log(error.response);
    });*/

    get_supplierIdentity_profileImg(supplier.id).then((response)=>{
      setUserprofile(URL.createObjectURL(response.data));
    }).catch((error)=>{
      //console.log(error.response);
      setUserprofile(defaultProfil_pic);
    });

  },[]);

  /*function getCategorieById(id){
    var categorie="";
    categories.map((catg)=>{
      if(catg.id_category === id) categorie = catg.name;
    })
    return categorie;
  }*/

  return (
    <Dialog className="all_services_identity_container" fullWidth={true} maxWidth={'lg'}  open={show} onClose={handleClose} centered>
      <DialogContent>
        <div className="all_services_identity_infos_container">
            <div className="all_services_identity_infos">
                <div className="srv_identity_img">
                    <div>
                        <img src={userprofile || defaultProfil_pic} alt=''  />
                    </div>
                    <div></div>
                </div>
                {/* <div className="srv_identity_element">
                    <span>Famille d'achat</span>
                    <span>
                        <Form.Control
                            required
                            value={categories!==null?getCategorieById(supplier.purchase_family):""} 
                            autoComplete="new-password"
                            type="text"
                            placeholder="Famille d'achat"
                            disabled
                        />   
                    </span>
                </div> */}
                <div className="srv_identity_element">
                    <span>Nom complet</span>
                    <span>
                        <Form.Control
                            required
                            value={supplier.fullName} 
                            autoComplete="new-password"
                            type="text"
                            placeholder="Nom complet *"
                            disabled
                        />   
                    </span>
                </div>
                <div className="srv_identity_element">
                    <span>CNSS</span>
                    <span>
                        <Form.Control
                            required
                            value={supplier.cnss} 
                            autoComplete="new-password"
                            type="text"
                            placeholder="CNSS *"
                            disabled
                        />   
                    </span>
                </div>
                <div className="srv_identity_element">
                <span>Pays</span>
                <span>
                    <Form.Control
                            required
                            value={supplier.country} 
                            autoComplete="new-password"
                            type="text"
                            placeholder="Pays *"
                            disabled
                        />   
                </span>
                </div>
                <div className="srv_identity_element">
                <span>Ville</span>
                <span>
                    <Form.Control
                            required
                            value={supplier.city}
                            autoComplete="new-password"
                            type="text"
                            placeholder="VIlle *"
                            disabled
                        />   
                </span>
                </div>
                <div className="srv_identity_element">
                <span>Adresse</span>
                <span>
                    <Form.Control
                            required
                            value={supplier.address}
                            autoComplete="new-password"
                            type="text"
                            placeholder="Adresse *"
                            disabled
                        />   
                </span>
                </div>
                <div className="srv_identity_element">
                <span>Code Postal</span>
                <span>
                    <Form.Control
                            required
                            value={supplier.postcode}
                            autoComplete="new-password"
                            type="text"
                            placeholder="Code Postal *"
                            disabled
                        />   
                </span>
                </div>
                <div className="srv_identity_element">
                <span>Maison-mère</span>
                <span>
                    <Form.Control
                            required
                            value={supplier.parentCompany}
                            autoComplete="new-password"
                            type="text"
                            placeholder="Maison-mère *"
                            disabled
                        />   
                </span>
                </div>
                {/* <div className="srv_identity_element">
                <span>Année de constitution</span>
                <span>
                    <Form.Control
                            required
                            value={supplier.anneeConstitution}
                            autoComplete="new-password"
                            type="text"
                            placeholder="Année de constitution *"
                            disabled
                        />   
                </span>
                </div> */}
                <div className="srv_identity_element">
                <span>Téléphone</span>
                <span>
                    <Form.Control
                            required
                            value={supplier.phoneNumber}
                            autoComplete="new-password"
                            type="text"
                            placeholder="Téléphone *"
                            disabled
                        />   
                </span>
                </div>
                <div className="srv_identity_element">
                <span>Registre du commerce <br/> (Ou équivalent)</span>
                <span>
                    <Form.Control
                            required
                            value={supplier.businessRegister}
                            autoComplete="new-password"
                            type="text"
                            placeholder="Registre du commerce (Ou équivalent) *"
                            disabled
                        />   
                </span>
                </div>
                <div className="srv_identity_element">
                <span>Identifiant fiscal <br/>  (Ou équivalent)</span>
                <span>
                    <Form.Control
                            required
                            value={supplier.fiscalIdentifier}
                            autoComplete="new-password"
                            type="text"
                            placeholder="Identifiant fiscal (Ou équivalent) *"
                            disabled
                        />   
                </span>
                </div>
                <div className="srv_identity_element">
                <span>N° Patente  <br/>  (Ou équivalent)</span>
                <span>
                    <Form.Control
                            required
                            value={supplier.licenseNumber}
                            autoComplete="new-password"
                            type="text"
                            placeholder="N° Patente (Ou équivalent) *"
                            disabled
                        />   
                </span>
                </div>
                <div className="srv_identity_element">
                <span>N° ICE  <br/> (Ou équivalent)</span>
                <span>
                    <Form.Control
                            required
                            value={supplier.iceNumbner}
                            autoComplete="new-password"
                            type="text"
                            placeholder="N° ICE (Ou équivalent) *"
                            disabled
                        />   
                </span>
                </div>
                <div className="srv_identity_element"></div>
            </div>
        </div>
        </DialogContent>
    </Dialog>
  );
}

export default Identifiantsupplier;
