import '../../../../../assets/css/order_details.css';
import React, { useEffect,useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import swal from 'sweetalert';
import Button from '@material-ui/core/Button';
import Form from 'react-bootstrap/Form'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import empty from '../../../../empty.jpg'
import { pdf } from '@react-pdf/renderer';
import Order_form_header_pdf from './order_form_pdf/order_form_header_pdf';

import {makeStyles} from '@material-ui/core/styles';
import Loading_animation_view from '../../../../loading_animation_view/loading_animation_view';
import { get_categories } from '../../../../../services/purchase_service';
import { supplier_accord_order, supplier_change_order_state, get_order_infos_for_file } from '../../../../../services/supplier_service';
import Set_refuse_reason from './set_refuse_reason/set_refuse_reason';
import Delivery_supposed_date_modal from './delivery_supposed_date_modal/delivery_supposed_date_modal';


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

const columns = [
    { id: 'product', label: "Produit", minWidth: 0,align: 'left'  },
    { id: 'unit_amount', label: "Prix unitaire (MAD)", minWidth: 0,align: 'left'  },
    { id: 'product_promo', label: "Promo (%)", minWidth: 0,align: 'left'  },
    { id: 'count', label: "Quantité", minWidth: 0 ,align: 'left' },
    { id: 'amount', label: "Prix total (MAD)", minWidth: 0,align: 'left'  }
  ];
  
  function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function Products({categories,products,set_price_by_unit,set_promo_by_unit,set_product_amount,disable_updates}){
    

    function getCategorieById(id){
        var categorie="";
        categories.map((catg)=>{
          if(catg.id_category === id) categorie = catg.name;
        })
        return categorie;
    }

    return(
        <>
        {
            products.map((product,id) => (
                <TableRow key={"product_col"+id}>
                    <TableCell align="left">
                        <div style={{display:"flex",alignItems:"center"}}>
                           <span className='product_cell_img_container'>
                                  <img src={product.product_pics.length!==0?`data:image/png;base64,${product.product_pics[0]}`:empty} />
                            </span>
                            <span className='product_cell_title_container'>
                                <span id="title">{product.title}</span>
                                <div className='product_cell_categorie_container'>
                                     <span>Famille d'achat : </span>{getCategorieById(product.id_category)}
                                </div> 
                            </span> 
                        </div>
                        
                    </TableCell>
                    <TableCell align="left">
                      <Form.Control
                          className="amount_container"
                          autoComplete="new-password"
                          type="number"
                          placeholder='0'
                          value={product.product_price}
                          onChange={(e)=>set_price_by_unit(e.target.value,id)}
                          readOnly={disable_updates}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Form.Control
                          className="amount_container"
                          autoComplete="new-password"
                          type="number"
                          placeholder='0'
                          value={product.product_promo}
                          onChange={(e)=>set_promo_by_unit(e.target.value,id)}
                          readOnly={disable_updates}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Form.Control
                          className="amount_container"
                          autoComplete="new-password"
                          type="number"
                          value={product.product_amount}
                          onChange={(e)=>set_product_amount(e.target.value,id)}
                          readOnly={disable_updates}
                      />
                    </TableCell>
                    <TableCell align="left">
                        <Form.Control
                            className="amount_container"
                            autoComplete="new-password"
                            type="number"
                            value={
                              (
                                product.product_price!=='' && !isNaN(product.product_price) 
                                    ?parseFloat((product.product_amount*(product.product_price - ((product.product_promo!=='' && !isNaN(product.product_promo))?(product.product_price*product.product_promo)/100:0))).toFixed(2))
                                    :0
                              )
                            }
                            readOnly
                        />
                    </TableCell>
                </TableRow>                
             ))
        }
        </>
    );
}





function Order_details({id_supplier,selected_products_details,request_code,init_products,products_bysupplier,setProducts_bysupplier,order_key}) {

    const classes = useStyles();
    const [categories,setCategories]=useState([]);
    const [products,setProducts]=useState(null);
    const [total_ht,setTotal_ht]=useState(0);
    const [vat,setvat]=useState(0);
    const [total_ttc,setTotal_ttc]=useState(0);
    const [shippingCosts,setshippingCosts]=useState(0);
    const [discount,setdiscount]=useState(0);

    const [open_reason_modal,setOpen_reason_modal]=useState(null);
    const [order_state,setOrder_state]=useState(null);
    const [order_number,setOrder_number]=useState(null);
    const [service_accord,setService_accord]=useState(null);
    const [disable_updates,setDisable_updates]=useState(false);
    const [open_deliveryDate_modal,setOpen_deliveryDate_modal]=useState(null);

    const [content_changed,setcontent_changed]=useState([0,0,0]);

    const [wait_submitting,setWait_submitting] = useState(false);

    useEffect(()=>{

        get_categories().then((response)=>{
            setCategories(response.data);
        }).catch((error)=>{
          //console.log(error.response);
        });

        //console.log(selected_products_details);
        async function set_vat_value(val){
          setvat(val);
        }
        async function set_shipping_value(val){
          setshippingCosts(val);
        }
        async function set_discount_value(val){
          setdiscount(val);
        }
        async function set_products_value(vat,shippingCosts,discount,val){
          setProducts(val);
          set_total_amountHT(vat,shippingCosts,discount,val);
        }

        //console.log(selected_products_details);
        setService_accord(selected_products_details.service_accord);
        setOrder_state(selected_products_details.order_state?selected_products_details.order_state:"null");

        setDisable_updates(selected_products_details.order_state?
                  selected_products_details.order_state!==null && selected_products_details.order_state!=="refusée" 
                  && selected_products_details.order_state!=="en cours de preparation"  && selected_products_details.order_state!=="validation refusée" 
                  ?true:false:false);

        setOrder_number(selected_products_details.order_number);
        set_vat_value(selected_products_details.vat);
        set_shipping_value(selected_products_details.shippingCosts);
        set_discount_value(selected_products_details.discount);
        set_products_value(selected_products_details.vat,selected_products_details.shippingCosts,selected_products_details.discount,selected_products_details.products);

    },[]);


    function set_price_by_unit(price,id){
      let tmp_array = [ ...products ];
      tmp_array[id].product_price = price<0?0:parseInt(price);
      //console.log(tmp_array[id].product_price,init_products.products[id].product_price)
      setProducts(tmp_array);
      if(tmp_array[id].product_price === init_products.products[id].product_price){
        let tmp_changes = [ ...content_changed ];
        tmp_changes[0]=0;
        setcontent_changed(tmp_changes);
      }else{
        let tmp_changes = [ ...content_changed ];
        tmp_changes[0]=1;
        setcontent_changed(tmp_changes);
      }
      set_total_amountHT(vat,shippingCosts,discount,tmp_array)
    }

    function set_promo_by_unit(promo,id){
      let tmp_array = [ ...products ];
      tmp_array[id].product_promo = promo<0?0:parseInt(promo);
      setProducts(tmp_array);
      set_total_amountHT(vat,shippingCosts,discount,tmp_array)
    }

    function set_product_amount(amount,id){
      let tmp_array = [ ...products ];
      tmp_array[id].product_amount = amount<0?0:parseInt(amount);
      setProducts(tmp_array);
      if(tmp_array[id].product_amount === init_products.products[id].product_amount){
          let tmp_changes = [ ...content_changed ];
          tmp_changes[1]=0;
          setcontent_changed(tmp_changes);
      }
      else{
        let tmp_changes = [ ...content_changed ];
        tmp_changes[1]=1;
        setcontent_changed(tmp_changes);
      }
      set_total_amountHT(vat,shippingCosts,discount,tmp_array)
    }


    async function set_total_amountHT(vat,shippingCosts,discount,products){
      let tmp_total = 0;
      products.map((product) => {
        tmp_total += (product.product_price!=='' && !isNaN(product.product_price) ?(product.product_amount * (product.product_price - ((product.product_promo!=='' && !isNaN(product.product_promo))?(product.product_price*product.product_promo)/100:0))):0);
      });
      setTotal_ht(parseFloat(tmp_total.toFixed(2)));
      set_total_amountTTC(vat,shippingCosts,discount,tmp_total)
    }


    async function set_total_amountTTC(vat,shippingCosts,discount,total_ht){
      setvat(vat<0?0:parseInt(vat));
      setshippingCosts(shippingCosts<0?0:parseInt(shippingCosts));
      setdiscount(discount<0?0:parseInt(discount));
      let tmp_vat = ((total_ht*(vat!=="" && !isNaN(vat)?vat:0))/100);
      let tmp_shippingCosts = ((total_ht*(shippingCosts!=="" && !isNaN(shippingCosts)?shippingCosts:0))/100);
      let tmp_discount = ((total_ht*(discount!=="" && !isNaN(discount)?discount:0))/100);
      let tmp_total = total_ht + tmp_vat + tmp_shippingCosts - tmp_discount;
      //console.log((shippingCosts!=="" && !isNaN(shippingCosts))?shippingCosts:0);
      setTotal_ttc(tmp_total.toFixed(2));
    }

    function accept_order(order_state){
      swal({
          title: "Etes-vous sûr ?",
          text: "Une fois que vous avez soumis votre réponse, vous ne pourrez plus la modifier !",
          icon: "warning",
          buttons: true,
          dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          async function sendit(){
            setWait_submitting(true);
            var order_data = null;
              let products_tmp = [];
              products.map((product) => {
                products_tmp.push({
                        "product_code" : product.product_code,
                        "title" : product.title,
                        "product_promo" : !isNaN(product.product_promo)?product.product_promo:0,
                        "product_price" : !isNaN(product.product_price)?product.product_price:0,
                        "product_amount": !isNaN(product.product_amount)?product.product_amount:0,
                      })
                });
                let order_infos = {
                  "products" : products_tmp,
                  "discount" : !isNaN(discount)?discount:0,
                  "shippingCosts" : !isNaN(shippingCosts)?shippingCosts:0,
                  "vat" : !isNaN(vat)?vat:0,
                  "total_ht" : !isNaN(total_ht)?total_ht:0,
                  "total_ttc" : !isNaN(total_ttc)?total_ttc:0
                }
            let formData = new FormData();
            if(order_state!=="fournisseur notifié"){
              let formData2 = new FormData();
              formData2.append('request_code',request_code);
              formData2.append('id_supplier',id_supplier);
              await get_order_infos_for_file(formData2).then((response)=>{
                //console.log(response.data);
                order_data = response.data;
                order_data['products'] = order_infos["products"];
                order_data['total_ht'] = !isNaN(total_ht)?total_ht:0;
                order_data['vat'] = order_infos["vat"];
                order_data['total_ttc'] = !isNaN(total_ttc)?total_ttc:0;
                order_data['shippingCosts'] = order_infos["shippingCosts"];
                order_data['discount'] = order_infos["discount"];
              }).catch((error)=>{
                //console.log(error.response);
              });
              const blob = await pdf((
                  <Order_form_header_pdf order_data={order_data} />
              )).toBlob();
            
              if(order_state!=="fournisseur notifié"){
                formData.append('products',JSON.stringify(order_infos));
                formData.append('order_blob',blob); 
              }
            }
            formData.append('request_code',request_code);
            formData.append('id_supplier',id_supplier);
            formData.append('accord',true);
            formData.append('order_number',order_number);
            formData.append('products',JSON.stringify(order_infos));
            supplier_accord_order(formData).then((response)=>{
              //console.log(response.data);
              setOrder_state(response.data.order_state);
              let tmp_products = { ...products_bysupplier };
              tmp_products[order_key].service_accord = true;
              tmp_products[order_key].order_state = response.data.order_state;
              setProducts_bysupplier(tmp_products);
              setWait_submitting(false);
              swal("votre réponse a été enregistrée avec succès et le service sera bientôt notifié !", {
                icon: "success",
                });
            }).catch((error)=>{
              //console.log(error.response);
              setWait_submitting(false);
            })
          }
          sendit();
        }
      });
    }


    function refuse_order(){
      let formData = new FormData();
      formData.append('request_code',request_code);
      formData.append('id_supplier',id_supplier);
      formData.append('accord',false);
      setOpen_reason_modal(formData);
    }


    function submit_to_view_changes(){
      let products_tmp = [];
      products.map((product) => {
        products_tmp.push({
                "product_code" : product.product_code,
                "title" : product.title,
                "product_promo" : !isNaN(product.product_promo)?product.product_promo:0,
                "product_price" : !isNaN(product.product_price)?product.product_price:0,
                "product_amount": !isNaN(product.product_amount)?product.product_amount:0,
              })
        });
        let order_infos = {
          "products" : products_tmp,
          "discount" : !isNaN(discount)?discount:0,
          "shippingCosts" : !isNaN(shippingCosts)?shippingCosts:0,
          "vat" : !isNaN(vat)?vat:0,
          "total_ht" : !isNaN(total_ht)?total_ht:0,
          "total_ttc" : !isNaN(total_ttc)?total_ttc:0
        }
      let formData = new FormData();
      formData.append('request_code',request_code);
      formData.append('id_supplier',id_supplier);
      formData.append('products',JSON.stringify(order_infos));
      formData.append('state',"sous réserve de validation");
      setOpen_reason_modal(formData);
    }

    function prepare_order(){
      swal({
          title: "Etes-vous sûr ?",
          text: "Une fois que vous avez soumis votre réponse, vous ne pourrez plus la modifier !",
          icon: "warning",
          buttons: true,
          dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
        setWait_submitting(true);
        let formData = new FormData();
        formData.append('request_code',request_code);
        formData.append('id_supplier',id_supplier);
        formData.append('state',"en cours de preparation");
        supplier_change_order_state(formData).then((response)=>{
          setOpen_reason_modal(null);
          setWait_submitting(false);
          let tmp_products = { ...products_bysupplier };
          tmp_products[order_key].service_accord = true;
          tmp_products[order_key].order_state = "en cours de preparation";
          setOrder_state("en cours de preparation");
          setProducts_bysupplier(tmp_products);
          swal("votre réponse a été enregistrée avec succès et le service sera bientôt notifié !", {
            icon: "success",
            });
        }).catch((error)=>{
          //console.log(error.response);
          setWait_submitting(false);
        });
        }
      });
    }

    async function prepare_for_delivery(){
      let formData2 = new FormData();
      formData2.append('request_code',request_code);
      formData2.append('id_supplier',id_supplier);
      formData2.append('state',"en cours de livraison");
      setOpen_deliveryDate_modal(formData2);
    }


//////////////////////
/////////////////////////////////////////////////////////
///////////////////////////////////

  return (
    <>
    {
      products===null
      ?<Loading_animation_view />
      :<div className="order_plus_details_container">
         <TableContainer className="order_plus_details_table_container">
            <Table className={classes.table}>
                <TableHead>
                <TableRow>
                    {
                        columns.map((column) => (
                            <TableCell key={column.id} align={column.align}>{column.label}</TableCell>
                        ))
                    }
                </TableRow>
                </TableHead>
                <TableBody>
                {
                    selected_products_details.length!==0
                    ?
                    <>
                        <Products categories={categories} products={products} set_price_by_unit={set_price_by_unit} set_promo_by_unit={set_promo_by_unit} 
                            set_product_amount={set_product_amount} disable_updates={disable_updates} />
                    </>
                            
                    :<div style={{margin:"10px",fontSize:".75rem",width:'100%', textAlign:"center",alignContent:"center"}}>Aucun produit disponible</div>
                }
                </TableBody> 
            </Table>
        </TableContainer> 
        <div className='total_amount_container'>
          <TableContainer className="total_amount_table_container">
              <Table className={classes.table}>
                  <TableBody>
                  <TableRow>
                        <TableCell align={"left"}>Total HT (MAD)</TableCell>
                        <TableCell align={"right"}>{numberWithSpaces(total_ht)+" MAD"}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align={"left"}>Taux de TVA (%)</TableCell>
                        <TableCell align={"right"}>
                            <Form.Control
                                className="amount_container"
                                autoComplete="new-password"
                                type="number"
                                placeholder='0'
                                value={vat}
                                onChange={(e)=>{set_total_amountTTC(e.target.value,shippingCosts,discount,total_ht);}}
                                readOnly={disable_updates}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align={"left"}>Frais d’expédition (%)</TableCell>
                        <TableCell align={"right"}>
                        <Form.Control
                                className="amount_container"
                                autoComplete="new-password"
                                type="number"
                                placeholder='0'
                                value={shippingCosts}
                                readOnly={disable_updates}
                                onChange={(e)=>{
                                  if(parseInt((e.target.value!=="" && !isNaN(e.target.value))?e.target.value:0) === init_products.shippingCosts){
                                    let tmp_changes = [ ...content_changed ];
                                    tmp_changes[2]=0;
                                    setcontent_changed(tmp_changes);
                                  }
                                  else{
                                    let tmp_changes = [ ...content_changed ];
                                    tmp_changes[2]=1;
                                    setcontent_changed(tmp_changes);
                                  }
                                  set_total_amountTTC(vat,e.target.value,discount,total_ht);
                                }}
                            />
                          </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align={"left"}>Remise (%)</TableCell>
                        <TableCell align={"right"}>
                          <Form.Control
                                className="amount_container"
                                autoComplete="new-password"
                                type="number"
                                placeholder='0'
                                value={discount}
                                onChange={(e)=>{set_total_amountTTC(vat,shippingCosts,e.target.value,total_ht);}}
                                readOnly
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align={"left"}>Total TTC (MAD)</TableCell>
                        <TableCell align={"right"}>{numberWithSpaces(total_ttc)+" MAD"}</TableCell>
                    </TableRow>
                  </TableBody> 
              </Table>
          </TableContainer> 
        </div>
        <div className="proceed_button_container">
          {
            order_state!==null && order_state!=="facture réglée"
            ?<>
              {
                order_state!=="acceptée" && order_state!=="sous réserve de validation" && order_state!=="en cours de preparation" 
                && order_state!=="en cours de livraison" && order_state!=="validée pour la livraison" && order_state!=="conforme" && order_state!=="non conforme"
                ?<>
                    <Button variant="contained" className={wait_submitting?"submit_but cancel_but disabled_button":"submit_but cancel_but"} onClick={refuse_order}> 
                            Refuser
                    </Button>
                    {
                      content_changed.includes(1)===true
                      ?<Button variant="contained" className={wait_submitting?"submit_but disabled_button":"submit_but"} onClick={submit_to_view_changes}> 
                              Validation avec réserve
                      </Button>
                      :<Button variant="contained" className={wait_submitting?"submit_but valide_but disabled_button":"submit_but valide_but"} onClick={() => accept_order(order_state)}> 
                              Accepter
                      </Button>
                    }
                </>
                :<>
                  {
                      order_state==="en cours de preparation"
                      ?<Button variant="contained" className={"submit_but"} onClick={prepare_for_delivery}> 
                              livrer
                      </Button>
                      :order_state==="en cours de livraison"
                      ?<></>
                      :<Button variant="contained" className={(wait_submitting || ((service_accord!==1 && (order_state==="sous réserve de validation" || order_state==="acceptée")) || order_state!=="validée pour la livraison" )) && order_state!=="non conforme"  ?"submit_but disabled_button":"submit_but"} onClick={prepare_order}> 
                                Préparation de la commande
                        </Button>
                    }
                </>
              }
            </>
            :<></>
          }
          
            
        </div>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={wait_submitting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    }

    {
      open_reason_modal!==null
      ?<Set_refuse_reason open_reason_modal={open_reason_modal} setOpen_reason_modal={setOpen_reason_modal} setOrder_state={setOrder_state} 
            products_bysupplier={products_bysupplier} setProducts_bysupplier={setProducts_bysupplier} order_key={order_key} />
      :<></>
    }
    {
      open_deliveryDate_modal!==null
      ?<Delivery_supposed_date_modal open_deliveryDate_modal={open_deliveryDate_modal} setOpen_deliveryDate_modal={setOpen_deliveryDate_modal}
                   setOrder_state={setOrder_state} products_bysupplier={products_bysupplier} setProducts_bysupplier={setProducts_bysupplier} order_key={order_key} />
      :<></>
    }
    </>
  );
}

export default Order_details;
