import '../../../assets/css/all_services_profil.css'
import React, { useState,useEffect, useCallback } from 'react';
import Form from 'react-bootstrap/Form'
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import SaveIcon from '@mui/icons-material/Save';
import swal from 'sweetalert';
import {makeStyles} from '@material-ui/core/styles';
import defaultProfil_pic from './defaultProfil_pic.png';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { get_categories } from '../../../services/purchase_service';
import { getSupplier_infos_byId, get_supplierIdentity_profileImg,update_supplierIdentity_profileImg,update_supplierIdentity_infos } from '../../../services/supplier_service'

import Loading_animation_view from '../../loading_animation_view/loading_animation_view'

const useStyles = makeStyles((theme) => ({
  button: { margin: theme.spacing(1),},
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));


function Supplier_profil() {

  const classes = useStyles();

  const [serviceInfo,setserviceInfo]=useState(null);

  const [categories,setCategories]=useState(null);

  const [categorie,setCategorie]=useState(null);
  const [fullName,setfullName]= useState("");
  
  const [cnss,setcnss]= useState("");
  const [country,setcountry]= useState("");
  const [city,setcity]= useState("");
  const [address,setaddress]= useState("");
  const [postcode	,setpostcode]= useState("");

  const [parentCompany,setparentCompany]= useState("");
  //const [AnneConst,setAnneConst]= useState("");
  const [phoneNumber,setphoneNumber]= useState("");
  const [RegCommenrce	,setRegCommenrce]= useState("");
  const [Fiscal,setFiscal]= useState("");
  const [licenseNumber,setlicenseNumber]= useState("");
  const [ICE,setICE]= useState("");

  const [disabled , setDisabled] =useState(true);
  const [userprofile , setUserprofile] = useState(null)

  const [error,setError]=useState(null);
  const [editing,setediting]=useState(false);
  const [waiting, setWaiting] = useState(false);

  let user = JSON.parse(localStorage.getItem('user-info'));

  useEffect(()=>{
    get_categories().then((response)=> {
      setCategories(response.data);
    }).catch((error)=>{
      //console.log(error.response);
    });


    getSupplier_infos_byId(user.id).then((response)=> {
        setCategorie((response.data.values[0].purchase_family).split(','));
        setfullName(response.data.values[0].fullName);
        setcnss(response.data.values[0].cnss);
        setcountry(response.data.values[0].country);
        setcity(response.data.values[0].city);
        setaddress(response.data.values[0].address);
        setpostcode(response.data.values[0].postcode);
        setparentCompany(response.data.values[0].parentCompany);
       // setAnneConst((new Date(response.data.values[0].created_at)).toLocaleString().split(/[,| ]/)[0]);
        setphoneNumber(response.data.values[0].phoneNumber);
        setRegCommenrce(response.data.values[0].businessRegister);
        setFiscal(response.data.values[0].fiscalIdentifier);
        setlicenseNumber(response.data.values[0].licenseNumber);
        setICE(response.data.values[0].iceNumbner);
        setserviceInfo(response.data.values[0]);
    });

    get_supplierIdentity_profileImg(user.id).then((response)=>{
      setUserprofile(URL.createObjectURL(response.data));
    }).catch((error)=>{
      //console.log(error.response);
      setUserprofile(defaultProfil_pic);
    });

  },[]);

  const getCategorieById = useCallback(()=>{
    var tmp_categorie="";
    categories.map((catg)=>{
      categorie.map((id)=>{
        if(catg.id_category === parseInt(id)){
          if(tmp_categorie==="") tmp_categorie = tmp_categorie+catg.name;
          else tmp_categorie = tmp_categorie+", "+catg.name;
        }
      })
    })
    return tmp_categorie;
  },[categories,categorie]);


  function update_alertMsg(){
    setError(null);
    if(cnss==="" || country==="" || city==="" || address==="" || postcode==="" || parentCompany===""
     || phoneNumber==="" || RegCommenrce==="" || Fiscal==="" || licenseNumber==="" || ICE===""){
        setError("Champs invalids !")
    }else{
      swal({
          title: "Etes-vous sûr ?",
          text: "Une fois sauvegardées, vous ne pourrez plus récupérer les données précédentes !",
          icon: "warning",
          buttons: true,
          dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          update_infos();
          setDisabled(!disabled);
        }
      });
    }
  }

  function updateProfil_pics(file){
    setWaiting(true);
    const formData = new FormData();
    formData.append('id',user.id);
    formData.append('profile_img',file);
    update_supplierIdentity_profileImg(formData).then((response)=>{
      //console.log(response.data);
      setWaiting(false);
      setUserprofile(URL.createObjectURL(file));
    }).catch((error) => {
      //console.log(error.response);
    })
  }


   async function update_infos() {
      setWaiting(true);
      const formData = new FormData();
      formData.append('id',user.id);
      formData.append('fullName',fullName);
      formData.append('country',country);
      formData.append('cnss',cnss);
      formData.append('city',city);
      formData.append('address',address);
      formData.append('postcode',postcode);
      formData.append('parentCompany',parentCompany);
      //formData.append('anneeConstitution',AnneConst);
      formData.append('phoneNumber',phoneNumber);
      formData.append('businessRegister',RegCommenrce);
      formData.append('fiscalIdentifier',Fiscal);
      formData.append('licenseNumber',licenseNumber);
      formData.append('iceNumbner',ICE);
      update_supplierIdentity_infos(formData).then((response)=>{
        setWaiting(false);
        setediting(false);
        swal("Votre fiche d'identité a été modifiée avec succès !", {
          icon: "success",
        });
      }).catch((error)=>{
        //console.log(error.response);
      })
  }


  return (
    <div className="all_services_identity_container">
      {
        serviceInfo===null || userprofile===null
        ?<Loading_animation_view />
        :<div className="all_services_identity_infos_container">
          <div className="all_services_identity_infos">
            <div className="srv_identity_img">
                <div >
                  <img src={userprofile} alt=''  />
                </div>
                <div>
                    <input accept=".jpg,.png,.jpeg" className={classes.input} name="profil_img" id="profil_img" multiple type="file" onChange={(e)=>updateProfil_pics(e.target.files[0])} />
                    <label htmlFor="profil_img">
                      <span className="submit_but edit_but"  >
                        <EditIcon className="my_icon" /> Image de profil
                      </span>
                        
                    </label>
                </div>
            </div>
            <div className="srv_identity_element">
                <span>Famille d'achat</span>
                <span>
                    <Form.Control
                          required
                          value={getCategorieById()} 
                          autoComplete="new-password"
                          type="text"
                          placeholder="Famille d'achat"
                      />   
                </span>
            </div>
            <div className="srv_identity_element">
                <span>Nom complet</span>
                <span>
                    <Form.Control
                          required
                          value={fullName} 
                          autoComplete="new-password"
                          type="text"
                          placeholder="Nom complet *"
                          disabled = { disabled }
                          onChange={(e)=>setfullName(e.target.value)} 
                      />   
                </span>
            </div>
            <div className="srv_identity_element">
                <span>CNSS</span>
                <span>
                    <Form.Control
                          required
                          value={cnss} 
                          autoComplete="new-password"
                          type="text"
                          placeholder="CNSS *"
                          disabled = { disabled }
                          onChange={(e)=>setcnss(e.target.value)} 
                      />   
                </span>
            </div>
            <div className="srv_identity_element">
              <span>Pays</span>
              <span>
                  <Form.Control
                        required
                        value={country} 
                        autoComplete="new-password"
                        type="text"
                        placeholder="Pays *"
                        disabled = { disabled }
                        onChange={(e)=>setcountry(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>Ville</span>
              <span>
                  <Form.Control
                        required
                        value={city}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Ville *"
                        disabled = { disabled }
                        onChange={(e)=>setcity(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>Adresse</span>
              <span>
                  <Form.Control
                        required
                        value={address}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Adresse *"
                        disabled = { disabled }
                        onChange={(e)=>setaddress(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>Code Postal</span>
              <span>
                  <Form.Control
                        required
                        value={postcode}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Code Postal *"
                        disabled = { disabled }
                        onChange={(e)=>setpostcode(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>Maison-mère</span>
              <span>
                  <Form.Control
                        required
                        value={parentCompany}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Maison-mère *"
                        disabled = { true }
                        onChange={(e)=>setparentCompany(e.target.value)} 
                    />   
              </span>
            </div>
            {/* <div className="srv_identity_element">
              <span>Date de constitution</span>
              <span>
                  <Form.Control
                        required
                        value={AnneConst}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Date de constitution *"
                        disabled = { disabled }
                        onChange={(e)=>setAnneConst(e.target.value)} 
                    />   
              </span>
            </div> */}
            <div className="srv_identity_element">
              <span>Téléphone</span>
              <span>
                  <Form.Control
                        required
                        value={phoneNumber}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Téléphone *"
                        disabled = { disabled }
                        onChange={(e)=>setphoneNumber(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>Registre du commerce <br/> (Ou équivalent)</span>
              <span>
                  <Form.Control
                        required
                        value={RegCommenrce}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Registre du commerce (Ou équivalent) *"
                        disabled = { disabled }
                        onChange={(e)=>setRegCommenrce(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>Identifiant fiscal <br/>  (Ou équivalent)</span>
              <span>
                  <Form.Control
                        required
                        value={Fiscal}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Identifiant fiscal (Ou équivalent) *"
                        disabled = { disabled }
                        onChange={(e)=>setFiscal(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>N° Patente  <br/>  (Ou équivalent)</span>
              <span>
                  <Form.Control
                        required
                        value={licenseNumber}
                        autoComplete="new-password"
                        type="text"
                        placeholder="N° Patente (Ou équivalent) *"
                        disabled = { disabled }
                        onChange={(e)=>setlicenseNumber(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>N° ICE  <br/> (Ou équivalent)</span>
              <span>
                  <Form.Control
                        required
                        value={ICE}
                        autoComplete="new-password"
                        type="text"
                        placeholder="N° ICE (Ou équivalent) *"
                        disabled = { disabled }
                        onChange={(e)=>setICE(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element"></div>

          </div>
          <div className="all_services_identity_infos_config" >
              <div>
                  {
                      error==null
                      ?<></>
                      :<p className={error ? "col-md-10 error" : "col-md-10 valid-champ"}>
                              {error ? error : ""}
                          </p>
                  }
               </div>
              <div>
                <Button variant="contained" className="submit_but edit_but" startIcon={<EditIcon className="my_icon" />} onClick={() => {setDisabled(!disabled);setediting(true)}} >
                    Editer
                </Button>
                <Button variant="contained" className={editing===true?"submit_but valide_but":"submit_but valide_but disabled_button"} startIcon={<SaveIcon className="my_icon" />} onClick={update_alertMsg} >
                    Sauvegarder
                </Button> 
              </div>
             
          </div>
      </div>
      }
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={waiting}
      >
          <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default Supplier_profil;