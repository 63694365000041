import '../../../../assets/css/all_services_profil.css'
import React, { useState,useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Form from 'react-bootstrap/Form'
import swal from 'sweetalert';
import {makeStyles} from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import SaveIcon from '@mui/icons-material/Save';
import defaultProfil_pic from './defaultProfil_pic.png'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { get_supplierIdentity_profileImg, update_supplierIdentity_profileImg, update_supplierIdentity_infos} from '../../../../services/supplier_service'
import { get_categories } from '../../../../services/purchase_service'

const useStyles = makeStyles((theme) => ({
  button: { margin: theme.spacing(1),},
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));


function Identity_supplier({ handleClose, show, supplier }) {

  const classes = useStyles();

  let user = JSON.parse(localStorage.getItem('user-info'));
 /* const [categories,setCategories]=useState(null);
  const [categorie,setCategorie]=useState(null);
  */
  const [fullName,setfullName]= useState("");

  const [cnss,setcnss]= useState("");
  const [country,setcountry]= useState("");
  const [city,setcity]= useState("");
  const [address,setaddress]= useState("");
  const [postcode	,setpostcode]= useState("");
  const [NomSociete,setNomSociete]= useState("");
  const [parentCompany,setparentCompany]= useState("");
 // const [AnneConst,setAnneConst]= useState("");
  const [phoneNumber,setphoneNumber]= useState("");
  const [RegCommenrce	,setRegCommenrce]= useState("");
  const [Fiscal,setFiscal]= useState("");
  const [licenseNumber,setlicenseNumber]= useState("");
  const [ICE,setICE]= useState("");
  const [disabled , setDisabled] =useState(true);

  const [userprofile , setUserprofile] = useState(null)

  const [error,setError]=useState(null);
  const [editing,setediting]=useState(false);
  const [waiting, setWaiting] = useState(false);

  useEffect(()=>{

    //setCategorie(supplier.purchase_family);
    setfullName(supplier.fullName);
    setcnss(supplier.cnss);
    setcountry(supplier.country);
    setcity(supplier.city);
    setaddress(supplier.address);
    setpostcode(supplier.postcode);
    setparentCompany(supplier.parentCompany);
    //setAnneConst(supplier.anneeConstitution);
    setphoneNumber(supplier.phoneNumber);
    setRegCommenrce(supplier.businessRegister);
    setFiscal(supplier.fiscalIdentifier);
    setlicenseNumber(supplier.licenseNumber);
    setICE(supplier.iceNumbner);

    /*get_categories().then((response)=> {
      //console.log(response.data);
      setCategories(response.data);
    }).catch((error)=>{
      //console.log(error.response);
    });*/

    get_supplierIdentity_profileImg(supplier.id).then((response)=>{
      setUserprofile(URL.createObjectURL(response.data));
    }).catch((error)=>{
      //console.log(error.response);
      setUserprofile(defaultProfil_pic);
    });

  },[]);

  /*function getCategorieById(id){
    var categorie="";
    categories.map((catg)=>{
      if(catg.id_category === id) categorie = catg.name;
    })
    return categorie;
  }*/

  function update_alertMsg(){
    setError(null);
    if(cnss==="" || country==="" || city==="" || address==="" || postcode==="" || parentCompany===""
     || phoneNumber==="" || RegCommenrce==="" || Fiscal==="" || licenseNumber==="" || ICE===""){
        setError("Champs invalids !")
    }else{
      swal({
        title: "Etes-vous sûr ?",
        text: "Une fois sauvegardées, vous ne pourrez plus récupérer les données précédentes !",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          save();
          setDisabled(!disabled);
        }
      });
    }
  }


  function updateProfil_pics(file){
    setWaiting(true);
    const formData = new FormData();
    formData.append('id',user.id);
    formData.append('profile_img',file);
    update_supplierIdentity_profileImg(formData).then((response)=>{
      //console.log(response.data);
      setWaiting(false);
      setUserprofile(URL.createObjectURL(file));
    }).catch((error) => {
      //console.log(error.response);
    })
  }



  async function save() {
    setWaiting(true);
    const formData = new FormData();
     formData.append('id',supplier.id);
     formData.append('country',country);
     formData.append('cnss',cnss);
     formData.append('city',city);
     formData.append('address',address);
     formData.append('postcode',postcode);
     formData.append('fullName',fullName);
     formData.append('parentCompany',parentCompany);
    // formData.append('anneeConstitution',AnneConst);
     formData.append('phoneNumber',phoneNumber);
     formData.append('businessRegister',RegCommenrce);
     formData.append('fiscalIdentifier',Fiscal);
     formData.append('licenseNumber',licenseNumber);
     formData.append('iceNumbner',ICE);
     update_supplierIdentity_infos(formData).then((response)=>{
      setediting(false);
      setWaiting(false);
      setediting(false);
      swal("Votre fiche d'identité a été modifiée avec succès !", {
        icon: "success",
      });
    }).catch((error)=>{
      //console.log(error.response);
    })
  }


  return (
    <Dialog className="all_services_identity_container" fullWidth={true} maxWidth={'lg'}  open={show} onClose={handleClose} centered>
    <DialogContent>
    <div className="all_services_identity_infos_container">
          <div className="all_services_identity_infos">
            <div className="srv_identity_img">
                <div >
                  <img src={userprofile} alt=''  />
                </div>
                <div>
                    <input accept=".jpg,.png,.jpeg" className={classes.input} name="profil_img" id="profil_img" multiple type="file" onChange={(e)=>updateProfil_pics(e.target.files[0])} />
                    <label htmlFor="profil_img">
                      <span className="submit_but edit_but" >
                        <EditIcon className="my_icon" /> Image de profil
                      </span>
                        
                    </label>
                </div>
            </div>
            {/* <div className="srv_identity_element">
                <span>Famille d'achat</span>
                <span>
                    <Form.Control
                          required
                          value={categorie!==null && categories!==null?getCategorieById(categorie):""} 
                          autoComplete="new-password"
                          type="text"
                          placeholder="Famille d'achat"
                          disabled
                      />   
                </span>
            </div> */}
            <div className="srv_identity_element">
                <span>Nom complet</span>
                <span>
                    <Form.Control
                          required
                          value={fullName} 
                          autoComplete="new-password"
                          type="text"
                          placeholder="Nom complet *"
                          disabled = { disabled }
                          onChange={(e)=>setfullName(e.target.value)} 
                      />   
                </span>
            </div>
            <div className="srv_identity_element">
                <span>CNSS</span>
                <span>
                    <Form.Control
                          required
                          value={cnss} 
                          autoComplete="new-password"
                          type="text"
                          placeholder="CNSS *"
                          disabled = { disabled }
                          onChange={(e)=>setcnss(e.target.value)} 
                      />   
                </span>
            </div>
            <div className="srv_identity_element">
              <span>Pays</span>
              <span>
                  <Form.Control
                        required
                        value={country} 
                        autoComplete="new-password"
                        type="text"
                        placeholder="Pays *"
                        disabled = { disabled }
                        onChange={(e)=>setcountry(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>Ville</span>
              <span>
                  <Form.Control
                        required
                        value={city}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Ville *"
                        disabled = { disabled }
                        onChange={(e)=>setcity(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>Adresse</span>
              <span>
                  <Form.Control
                        required
                        value={address}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Adresse *"
                        disabled = { disabled }
                        onChange={(e)=>setaddress(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>Code Postal</span>
              <span>
                  <Form.Control
                        required
                        value={postcode}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Code Postal *"
                        disabled = { disabled }
                        onChange={(e)=>setpostcode(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>Maison-mère</span>
              <span>
                  <Form.Control
                        required
                        value={parentCompany}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Maison-mère *"
                        onChange={(e)=>setparentCompany(e.target.value)} 
                        disabled
                    />   
              </span>
            </div>
            {/* <div className="srv_identity_element">
              <span>Année de constitution</span>
              <span>
                  <Form.Control
                        required
                        value={AnneConst}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Année de constitution *"
                        disabled = { disabled }
                        onChange={(e)=>setAnneConst(e.target.value)} 
                    />   
              </span>
            </div> */}
            <div className="srv_identity_element">
              <span>Téléphone</span>
              <span>
                  <Form.Control
                        required
                        value={phoneNumber}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Téléphone *"
                        disabled = { disabled }
                        onChange={(e)=>setphoneNumber(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>Registre du commerce <br/> (Ou équivalent)</span>
              <span>
                  <Form.Control
                        required
                        value={RegCommenrce}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Registre du commerce (Ou équivalent) *"
                        disabled = { disabled }
                        onChange={(e)=>setRegCommenrce(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>Identifiant fiscal <br/>  (Ou équivalent)</span>
              <span>
                  <Form.Control
                        required
                        value={Fiscal}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Identifiant fiscal (Ou équivalent) *"
                        disabled = { disabled }
                        onChange={(e)=>setFiscal(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>N° Patente  <br/>  (Ou équivalent)</span>
              <span>
                  <Form.Control
                        required
                        value={licenseNumber}
                        autoComplete="new-password"
                        type="text"
                        placeholder="N° Patente (Ou équivalent) *"
                        disabled = { disabled }
                        onChange={(e)=>setlicenseNumber(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>N° ICE  <br/> (Ou équivalent)</span>
              <span>
                  <Form.Control
                        required
                        value={ICE}
                        autoComplete="new-password"
                        type="text"
                        placeholder="N° ICE (Ou équivalent) *"
                        disabled = { disabled }
                        onChange={(e)=>setICE(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element"></div>

          </div>
          <div className="all_services_identity_infos_config" >
              <div>
                  {
                      error==null
                      ?<></>
                      :<p className={error ? "col-md-10 error" : "col-md-10 valid-champ"}>
                              {error ? error : ""}
                          </p>
                  }
               </div>
              <div>
                <Button variant="contained" className="submit_but edit_but" startIcon={<EditIcon className="my_icon" />} onClick={() => {setDisabled(!disabled);setediting(true)}} >
                    Editer
                </Button>
                <Button variant="contained" className={editing===true?"submit_but valide_but":"submit_but valide_but disabled_button"} startIcon={<SaveIcon className="my_icon" />} onClick={update_alertMsg} >
                    Sauvegarder
                </Button> 
              </div>
             
          </div>
      </div>
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={waiting}
      >
          <CircularProgress color="inherit" />
      </Backdrop>
      </DialogContent>
  </Dialog>
  );
}

export default Identity_supplier;
