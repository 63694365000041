import './add_product_modal.css'
import React, { useState} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';
import Form from 'react-bootstrap/Form';
import TextField from '@material-ui/core/TextField';
import swal from 'sweetalert';
import CancelIcon from '@material-ui/icons/Cancel';
import PaymentIcon from '@material-ui/icons/Payment';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import AddIcon from '@material-ui/icons/Add';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { add_product } from '../../../../services/supplier_service';


const useStyles = makeStyles((theme) => ({
    button: { margin: theme.spacing(1),},
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    }
  }));

function Add_product_modal({categories,supplier,open_addProduct_modal,handleClose_addProduct,setList_updated_with_success,setProducts}){

    const classes = useStyles();

    const [errorText,setErrorText] = useState("");
    const [valide, setValid] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [formData, setFormData] = useState(null);

    const [title,settitle]=useState('');
    const [product_code,setProduct_code]=useState('');
    const [price,setprice]=useState('');
    const [description,setdescription]=useState('');
    const [Categorie,SetCategorie]=useState('null');
    
    const [pics,setPics]=useState([]);
    const [pics_url,setPics_url]=useState([]);

    const [waiting,setWaiting] = useState(false)

  function validated_elem(){
      return submitted && valide;
  }

  function addProduct_nextStep() {
    setSubmitted(true);
    setValid(true);
    setErrorText("");
    if(title==='' || product_code==='' || price==='' || description==='' || Categorie==="null"){
      setValid(false);
      setErrorText("Champs invalides !");
    }else{
        const tmp_form = new FormData();
        tmp_form.append('title',title);
        tmp_form.append('product_code',product_code);
        tmp_form.append('description',description);
        tmp_form.append('id_supplier',supplier.id);
        tmp_form.append('price',price);
        tmp_form.append('id_category',Categorie);
        setFormData(tmp_form);
      }    
    }


    function addProduct() {
      setSubmitted(true);
      setValid(true);
      setErrorText("");
      if(pics.length===0){
        setValid(false);
        setErrorText("Ajouter au moins une photo !");
      }else{
          pics.map((pic)=>{
            formData.append('pics[]',pic);
          })
          setWaiting(true);
          add_product(formData).then((response)=>{
            setProducts(null);
            setList_updated_with_success(response.data);
            setWaiting(false);
            handleClose_addProduct();
            swal("Votre produit a été sauvegardée avec succès !", {
              icon: "success",
              });
          }).catch((error) => {
            let error_data = error.response.data;
            //console.log(error.response,error_data.errors.product_code);
            if(error_data.errors){
              if(error_data.errors.product_code) setErrorText("Le code produit existe déjà !");
            }
            setWaiting(false);
          });
        }    
      }

    function add_pics_to_product(e){
      if(e.target.files.length!==0){
        let tmp_pics = [ ...pics ];
        let tmp_pics_url = [ ...pics_url ];
        tmp_pics_url.push(URL.createObjectURL(e.target.files[0]));
        tmp_pics.push(e.target.files[0]);
        setPics(tmp_pics);
        setPics_url(tmp_pics_url);
      }
    }

    function delete_pic(id){
      let tmp_pics = [ ...pics ];
      let tmp_pics_url = [ ...pics_url ];
      tmp_pics_url.splice(id,1);
      tmp_pics.splice(id,1);
      setPics(tmp_pics);
      setPics_url(tmp_pics_url);
    }
     

    return (
      <Dialog fullWidth={true} maxWidth={'md'} open={open_addProduct_modal} onClose={handleClose_addProduct} aria-labelledby="form-dialog-title" >
        <DialogTitle id="form-dialog-title">Ajouter votre Produit</DialogTitle>
        <DialogContent>
          {
            formData!==null
            ?<>
              <Form.Label>Déposer vos photos</Form.Label>
              <div className="add_product_modal_pics">
                    {
                        pics.length!==0
                        ?pics.map((pic,id)=>(
                          <div id="Cadre" className="myClass">
                                <img id="image" src={ pics_url[id] } style={{width: "100%", height: "100%"}} />
                                <div id="boutton0" className="blocm" onClick = { (e) => delete_pic(id)}>
                                      <div></div>
                                      <div></div>
                                </div>
                          </div>
                        ))
                        :<></>
                    }
                    <label id="input_contenair" className="files">
                           <div id="input_contenair_enf1"></div>
                           Clique ici
                             <Form.Control 
                               id="file" 
                               type="file"
                               style={{display: "none"}} 
                               accept=".png.,.jpg,.jpeg"
                               onChange={ e =>  {
                                add_pics_to_product(e);
                               }}
                           />
                           
                       </label>
            </div>
            </>
            :<div className="add_product_modal_infos">

                  <div>
                    <TextField className={classes.margin} label="Titre" style={{width:"100%",margin:"0px 0px 20px 0px"}} value={title}
                      InputProps={{ startAdornment: ( <InputAdornment position="start"> <BookmarkIcon className="my_icon" /> </InputAdornment> ), }} onChange={(e)=>settitle(e.target.value)} />
                  </div>
                  <div className="inputs_container">
                    <TextField label="Code de produit"  className="input_form" value={product_code}
                      InputProps={{ startAdornment: ( <InputAdornment position="start"> <BookmarkIcon className="my_icon" /> </InputAdornment> ), }} onChange={(e)=>setProduct_code(e.target.value)} />
                  
                    <TextField  label="Prix (DH) " type="number" className="input_form" value={price}
                        InputProps={{ startAdornment: ( <InputAdornment position="start"> <PaymentIcon className="my_icon" /> </InputAdornment> ), }} onChange={(e)=>setprice(e.target.value)} />

                    <FormControl className="input_form" >
                      <InputLabel id="demo-simple-select-helper-label">Famille d'achat</InputLabel>
                      <Select value={Categorie}  onChange={(e)=>{SetCategorie(e.target.value)}}>
                        <MenuItem value="null" disabled selected>
                            Famille d'achat
                        </MenuItem>
                        {categories.map((val,index,) => {
                          return (<MenuItem key={index} value={val.id_category}>{val.name}</MenuItem>)
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  

                  <div style={{width:"100%",margin:"20px 0px 10px 0px"}} >
                  <TextField
                      id="outlined-multiline-flexible"
                      label="Description"
                      variant='outlined'
                      multiline
                      rows={8}
                      value={description}
                      onChange={(e)=>setdescription(e.target.value)} 
                      style={{width:"100%"}}
                    />
                  </div>
              </div>
          }
        </DialogContent>
        <DialogActions>
          <div className='dialogBottom'>
            <div>
                {
                    errorText === ""
                    ?<></>
                    :<p className={errorText ? "col-md-10 error" : "col-md-10 valid-champ"}>
                            {errorText}
                        </p>
                }
            </div>
            {
              formData!==null
              ?<div>
                  <Button variant="contained" className="submit_but cancel_but" startIcon={<NavigateBeforeIcon className="my_icon" />}
                       onClick={()=>{setFormData(null); setPics([]); setPics_url([])}} >
                      Précedent
                  </Button>
                  <Button variant="contained" className={waiting===true?"submit_but valide_but disabled_button":"submit_but valide_but"} startIcon={<AddIcon className="my_icon" />} onClick={addProduct} >
                      Ajouter
                  </Button> 
                </div>
              :<div>
                <Button variant="contained" className="submit_but cancel_but" startIcon={<CancelIcon className="my_icon" />} onClick={handleClose_addProduct} >
                    Annuler
                </Button>
                <Button variant="contained" className="submit_but valide_but" startIcon={<NavigateNextIcon className="my_icon" />} onClick={addProduct_nextStep} >
                    Suivant
                </Button> 
              </div>
            } 
          </div>
        </DialogActions>

        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={waiting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>

      </Dialog>
    )
}

export default Add_product_modal
