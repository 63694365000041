import '../../../../assets/css/bills.css'
import React, {useEffect,useState} from 'react';
import { useHistory } from "react-router-dom";

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import Button from '@material-ui/core/Button';
import DownloadIcon from '@mui/icons-material/Download';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Input from "@material-ui/core/Input";
import Checkbox from '@mui/material/Checkbox';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Pagination from "react-js-pagination";

import { count_bill, getAll_financeService_market, paye_bill, archive_bill, notify_business_service_toView_bills } from '../../../../services/finance_service';
import { download_bills_docs } from '../../../../services/for_allServices';

import Add_infos_toBill from '../add_infos_toBill/add_infos_toBill';
import Set_refuse_reason from "../set_refuse_reason/set_refuse_reason";
import State_details from '../state_details/state_details';
import Loading_animation_view from '../../../loading_animation_view/loading_animation_view';
import { get_categories } from '../../../../services/purchase_service';

const useStyles = makeStyles((theme) => ({
  button: { margin: theme.spacing(1),},
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    width: '100%',
  },
  input: {
    display: 'none',
  },
  container: {
    maxHeight: 440,
  },
  select: {
    "&:focus": {
      backgroundColor: "white"
    }
  },
  selectInput: {
    "&:hover:not($disabled):not($focused):not($error):before": {
      borderBottomWidth: 0
    },
    "&:after": {
      borderBottomWidth: 0
    }
  },
  disabled: {},
  focused: {},
  error: {}
}));



function setState(bill_state,financeService_accord,purchaseService_accord,businessService_accord){
  return(
    <>
      <Tooltip title="" placement="bottom" >
          <span style={{marginLeft:'2%'}}>
              {
                financeService_accord==="0" || purchaseService_accord==="0" || businessService_accord==="0"
                ?<Button className="status_deny" >{"refusée"}</Button>
                :bill_state==="comptabilisée"
                  ?<Button className="status_success" >{bill_state}</Button>
                    :bill_state==="à valider" && (purchaseService_accord===null || businessService_accord===null)
                    ?<Button className="status_success" >{bill_state}</Button>
                      :bill_state==="mis en règlement"
                      ?<Button className="status_success" >{bill_state}</Button>
                      :bill_state==="réglée"
                      ?<Button className="status_success" >{bill_state}</Button>
                      :bill_state==="archivée"
                        ?<Button className="status_success" >{bill_state}</Button>
                        :<Button className="status_waiting" >{"en cours"}</Button>
              }
          </span>
      </Tooltip>
    </>
  );
}


function verify_if_able_to_submit(bill){
  if( bill.bill_state==="comptabilisée" || bill.bill_state==="à valider"  ){
    if( bill.bill_state==="comptabilisée" ) return true;
    if(bill.purchaseService_accord==="1" && bill.businessService_accord==="1") return false;
    else{
      if(bill.purchaseService_accord===null || bill.businessService_accord===null) return false;
      else return true;
    }
  }
  return false;
}

function verify_if_able_to_count(bill){
  if((bill.bill_state==null || bill.bill_state==="refusée") && (bill.amount_without_tax!==null && bill.expiration_date!==null)) return true;
  return false;
}

function verify_if_able_to_refuse(bill_state){
  if(bill_state==null || bill_state==="refusée") return true;
  return false;
}


function verify_if_able_to_seeDetails(bill){
  if(bill.bill_state==="à valider" || bill.bill_state==="refusée" ) return true;
  return false;
}

function verify_if_able_to_pay(bill_state,expiration_date){
  if(bill_state==="mis en règlement" ) return true;
  return false;
}

function verify_if_able_to_archive(bill_state){
  if(bill_state==="réglée") return true;
  return false;
}


function verify_if_all_bills_areChecked(array_selected_bills,id){
  let checked = true ;
  array_selected_bills[id].map((selected)=>{
    if(selected === null) checked = false;
  });
  return checked;
}

function check_if_bills_selected(array_selected_bills){
  let selected = 0 ;
  array_selected_bills.map((bill_selected)=>{
    if(bill_selected !== null) selected++;
  });
  if(selected >= 2) return true;
  else return false;
}


function verify_if_able_to_submit_billsSelected(array_selected_bills){
  var able = true ;
  array_selected_bills.map((bill_selected)=>{
    if(bill_selected !== null){
      if( bill_selected==="null" || bill_selected==="réglée" || bill_selected==="à valider"  || bill_selected=== "archivée" ) able=false;
    }
  });
  return able;
}

function verify_if_able_to_count_billsSelected(array_selected_bills,bills){
  var able = true ;
  array_selected_bills.map((bill_selected,id)=>{
    if(bill_selected !== null){
      if( (bill_selected==="null" || bill_selected==="refusée") && (bills[id].amount_without_tax===null || bills[id].expiration_date===null)) able=false;
    }
  });
  return able;
}


function verify_if_able_to_refuse_billsSelected(array_selected_bills){
  var able = false ;
  array_selected_bills.map((bill_selected,id)=>{
    if(bill_selected !== null){
      if( (bill_selected==="null" || bill_selected==="refusée")) able=true;
    }
  });
  return able;
}


function verify_if_able_to_pay_billsSelected(array_selected_bills){
  var able = true ;
  array_selected_bills.map((bill_selected)=>{
    if(bill_selected !== null){
      if( bill_selected!=="mis en règlement" ) able=false;
    }
  });
  return able;
}

function verify_if_able_to_archive_billsSelected(array_selected_bills){
  var able = true ;
  array_selected_bills.map((bill_selected)=>{
    if(bill_selected !== null){
      if( bill_selected!=="réglée" ) able=false;
    }
  });
  return able;
}


function numberWithSpaces(x) {
  if(x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  else return x;
}



///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


function Bill_column({bill,id,id2,addInfos_to_bill,setAction_on_bill,array_selected_bills,setArray_selected_bills}){

  const classes = useStyles();
  const selectInputClasses = {
    root: classes.selectInput,
    disabled: classes.disabled,
    focused: classes.focused,
    error: classes.error
  };
  
  const [view_state_details,setview_state_details] = useState(null);

  function check_bill(checked,bill_state,array_selected_bills,id,id2){
    let tmp_array = [...array_selected_bills];
    if(checked===false) tmp_array[id][id2] = null;
    else tmp_array[id][id2] = bill_state==null?"null":bill_state;
    setArray_selected_bills(tmp_array);
  }

  function download_billDocs(id_submission,bill_number,choice) {
    const formData = new FormData();
    formData.append('id_submission',id_submission);
    formData.append('bill_number',bill_number);
    formData.append('choice',choice);
    download_bills_docs(formData).then((response) => {
       var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        let file_extension = '';
        const disposition = response.headers['content-disposition'];
        if (disposition && disposition.includes('filename')) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                file_extension = matches[1].replace(/['"]/g, '').split(".")[1];
            }
        }
        fileLink.setAttribute('download', id_submission+"_"+choice+bill_number+"."+file_extension);
        document.body.appendChild(fileLink);
        fileLink.click();
    }).catch(err=>{
      //console.log(err,err.response);
    });
  }

  return (
        <div className='bill_container'>
          <div className='bill_infos_container'>
            <div className='bill_infos'>
              <Tooltip title="Choisir la facture" placement="bottom">
                  <span style={{marginRight:'7px'}}>
                      <Checkbox className="my_icon"  color="default" checked={array_selected_bills[id][id2]!==null?true:false}
                       onClick={(e) => check_bill(e.target.checked,bill.bill_state,array_selected_bills,id,id2)} />
                  </span>
              </Tooltip>
              <span className='download_bill_button' onClick={()=>download_billDocs(bill.id_submission,bill.bill_number,"bill_content")} >
                  <Fab size="small" color="default" >
                      <DownloadIcon className="my_icon"  />
                  </Fab>
              </span>
              <Tooltip title="Saisir les informations" placement="bottom">
                  <span className='download_bill_button'>
                      <Fab size="small" color="info" onClick={() => addInfos_to_bill(bill.id_submission,[bill.bill_number],id)} >
                          <MoreHorizIcon className="my_icon"  />
                      </Fab>
                  </span>
              </Tooltip>
              <div className='bill_name'>Facture N°{bill.bill_number}</div>
            </div>
            <div className='bill_amount'>
              {
                bill.amount_without_tax!==null
                ?<>{numberWithSpaces(bill.amount_without_tax)+" MAD"}</>
                :<span style={{opacity:".8"}} >non défini</span>
              }
            </div>
            <div className='bill_treatedDate'>
              {
                bill.expiration_date!==null
                ?(new Date(bill.expiration_date)).toLocaleString().split(/[,| ]/)[0]
                :<span style={{opacity:".8"}} >non défini</span>
              }
            </div>
            <div className='bill_treatedDate'>
              {(new Date(bill.limit_date)).toLocaleString().split(/[,| ]/)[0]}
            </div>
            <div className='select_container' >
                  <FormControl variant="standard" style={{width:"125px",marginLeft:"4px",marginRight:"8px"}} >
                            <Select required value={"null"} className={ array_selected_bills[id][id2] && !check_if_bills_selected(array_selected_bills[id]) ?"":"disabled_button"}
                                  onChange={e => {
                                      setAction_on_bill(e.target.value,bill.id_submission,bill.bill_number,id);
                                  }}
                                  input={<Input classes={selectInputClasses} />}
                                  inputProps={{ classes: { select: classes.select } }}>
                                <MenuItem value={"null"} disabled>Action</MenuItem>
                                <MenuItem value={"to_validate"} disabled={!verify_if_able_to_submit(bill)}>Soumis pour validation</MenuItem>
                                <MenuItem value={"count"} disabled={!verify_if_able_to_count(bill)}>Comptabiliser</MenuItem>
                                <MenuItem value={"refuse"} disabled={!verify_if_able_to_refuse(bill.bill_state)}>Refuser</MenuItem>
                                <MenuItem value={"paye"} disabled={!verify_if_able_to_pay(bill.bill_state,bill.expiration_date)}>Régler</MenuItem>
                                <MenuItem value={"archive"} disabled={!verify_if_able_to_archive(bill.bill_state)}>Archiver</MenuItem>
                            </Select>
                    </FormControl>
                </div>
            <div className='bill_state_container'>
              <div className='bill_state'>{ setState(bill.bill_state,bill.financeService_accord,bill.purchaseService_accord,bill.businessService_accord) }
                {
                  verify_if_able_to_seeDetails(bill)
                  ?<div className="refuse_raison_button" 
                        onClick={()=>setview_state_details(
                                  [
                                    [bill.financeService_refuse_reason,bill.purchaseService_refuse_reason,bill.businessService_refuse_reason],
                                    [bill.financeService_accord,bill.purchaseService_accord,bill.businessService_accord]
                                  ]
                                )}>
                    Details
                  </div>
                  :<div></div>
                }  
              </div>
               
            </div>
          </div>

          <div className='bill_compDocs'>
              <div className='bill_compDoc'>
                  <span style={{marginRight:'7px'}} onClick={()=>download_billDocs(bill.id_submission,bill.bill_number,"reception_pv")} >
                      <Fab size="small" color="default" >
                          <DownloadIcon className="my_icon"  />
                      </Fab>
                  </span>
                  <span className='docs_name'>PV de réception</span>
              </div>
              <div className='bill_compDoc'>
                  <span style={{marginRight:'7px'}} onClick={()=>download_billDocs(bill.id_submission,bill.bill_number,"delivery_pv")} >
                      <Fab size="small" color="default" >
                          <DownloadIcon className="my_icon"  />
                      </Fab>
                  </span>
                  <span className='docs_name'>PV de livraison</span>
              </div>
          </div>
          {
            view_state_details!==null
            ?<State_details view_state_details={view_state_details} setview_state_details={setview_state_details} />
            :<></>
          }
      </div>
  );
}



function Markets_list({markets,setAction_on_bill,check_All,addInfos_to_bill,setAction_on_bills_selected,array_selected_bills,setArray_selected_bills}){
  
  const classes = useStyles();
  const selectInputClasses = {
    root: classes.selectInput,
    disabled: classes.disabled,
    focused: classes.focused,
    error: classes.error
  };


  return(
      <>
      {
          markets.map((market,id) => (
              <TableRow key={"market"+id}>
                  <TableCell align="left">{market.submission_id}</TableCell>
                  <TableCell align="left">{market.supplier_name}</TableCell>
                  <TableCell align="left">{market.offer_object}</TableCell>
                  <TableCell align="left">{market.offer_amount+" MAD"}</TableCell>
                  <TableCell align="left">
                    <div>
                    {
                          (market.bills).length!==0
                          ?<>
                            <div className='bill_container_titles'>
                              <div className='bill_infos_container'>
                                <div className='bill_infos'>
                                    <Tooltip title="Choisir tous les factures" placement="bottom">
                                        <span style={{marginRight:'7px'}}>
                                            <Checkbox className="my_icon"  color="default" 
                                                checked={verify_if_all_bills_areChecked(array_selected_bills,id)}
                                                onClick={(e) => check_All(e.target.checked,market.bills,array_selected_bills,id)} />
                                        </span>
                                    </Tooltip>
                                    <div className='bill_name'>Toutes les factures</div>
                                </div>
                                <div className='bill_amount'> Montant <br /> hors Tax</div>
                                <div className='bill_treatedDate'>Date  <br /> d'échéance</div>
                                <div className='bill_treatedDate'>Date <br />limite</div>
                                <div className='select_container' >
                                    <Tooltip title="Traitement en masse" placement="left">
                                      <span>
                                      <FormControl variant="standard" style={{width:"125px",marginLeft:"4px",marginRight:"8px"}} className={check_if_bills_selected(array_selected_bills[id]) ?"":"disabled_button"} >
                                                <Select required value={"null"} 
                                                      onChange={e => {
                                                        setAction_on_bills_selected(e.target.value,market.submission_id,market.bills,array_selected_bills,id);
                                                      }}
                                                      input={<Input classes={selectInputClasses} />}
                                                      inputProps={{ classes: { select: classes.select } }}
                                                      >
                                                    <MenuItem value={"null"} disabled>Action</MenuItem>
                                                    <MenuItem value={"to_validate"} disabled={!verify_if_able_to_submit_billsSelected(array_selected_bills[id])}>Soumis pour validation</MenuItem>
                                                    <MenuItem value={"count"} disabled={!verify_if_able_to_count_billsSelected(array_selected_bills[id],market.bills)}>Comptabiliser</MenuItem>
                                                    <MenuItem value={"refuse"} disabled={!verify_if_able_to_refuse_billsSelected(array_selected_bills[id])}>Refuser</MenuItem>
                                                    <MenuItem value={"paye"} disabled={!verify_if_able_to_pay_billsSelected(array_selected_bills[id])}>Régler</MenuItem>
                                                    <MenuItem value={"archive"} disabled={!verify_if_able_to_archive_billsSelected(array_selected_bills[id])}>Archiver</MenuItem>
                                                </Select>
                                        </FormControl>
                                        </span>
                                      </Tooltip>
                                    </div>
                                <div className='bill_state'>Status</div>
                              </div>
                              <div className='bill_compDocs'>
                                    <span className='docs_name'>Docs complémentaires</span>
                              </div>
                            </div>
                            {
                              market.bills.map((bill,id2) => (
                                  <Bill_column bill={bill} id={id} id2={id2} addInfos_to_bill={addInfos_to_bill} key={"market_bill"+id+id2}
                                   setAction_on_bill={setAction_on_bill} array_selected_bills={array_selected_bills} setArray_selected_bills={setArray_selected_bills} />
                              ))
                            }
                          </>
                          :<></>
                        }
                        
                        <div className='add_bill_container'>
                            {
                              (market.bills).length==0
                              ?<span>Aucune facture déposée</span>
                              :<span></span>
                            }
                        </div>  
                    </div>
                  </TableCell>
              </TableRow>                
          ))
      }
      </>
  );
}



//////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////

function Markets_bills({filter_bills_src,url_updated , setUrl_updated}) {

  let service=JSON.parse(localStorage.getItem('user-info'))
  let history = useHistory();

  const [markets, setMarkets] = useState(null);
  const [array_selected_bills,setArray_selected_bills] = useState(null);

  const [chosenSubmission,setChosenSubmission] = useState(null);
  const [chosenBills_numbers,setChosenBills_numbers] = useState(null);
  const [open_reason_modal,setOpen_reason_modal] = useState(null);
  const [open_addInfo_toBill_modal,setOpen_addInfo_toBill_modal] = useState(null);

  const [categories,setCategories]=useState([]);

  const [filter_categorie,setFilter_categorie]=useState("all");
  const [search_value,setsearch_value]=useState("");
  const [search_filter,setsearch_filter]=useState('all');

  const [waiting, setWaiting] = useState(false);  

  const [currentPage,setCurrentPage]=useState(1);
  const [dataPerPage,setdataPerPage] =useState(5);
  const [data_count,setdata_count]=useState(0);

  useEffect(()=>{
    setMarkets(null);
    setArray_selected_bills(null);
    setdata_count(0);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    var tmp_searchFilter ="all";
    var tmp_searchValue = null;
    var tmp_filterCategorie = "all";

    async function load_data(){
      if(urlParams.get('filtre') && urlParams.get('valeur')){
        tmp_searchFilter = urlParams.get('filtre');
        tmp_searchValue = urlParams.get('valeur');
      }if(urlParams.get('categorie')){
        tmp_filterCategorie = urlParams.get('categorie');
      }
      await setsearch_filter(tmp_searchFilter);
        await setsearch_value(tmp_searchValue);
        await setFilter_categorie(tmp_filterCategorie);

      await get_categories().then((response)=>{
        setCategories(response.data);
      }).catch((error)=>{
        //console.log(error.response);
      });

      let page = (urlParams.get('page')?urlParams.get('page'):1);
        await setCurrentPage(page);

      await  getAll_financeService_market(service.id,tmp_filterCategorie,tmp_searchFilter,tmp_searchValue,page).then((response)=> {
        setdata_count(response.data.total);
        let tmp_array = [];
        response.data.data.map((market)=>{
          let bills_selection = [];
          for(let i=0; i < market.bills.length ; i++){
            bills_selection.push(null);
          }
          tmp_array.push(bills_selection);
        });
        setArray_selected_bills(tmp_array);
        setMarkets(response.data.data);
      })
      .catch(err=>{
        //console.log(err,err.response);
      });
    }
    
    load_data();

  },[]);


  function addInfos_to_bill(id_submission,bill_number,id){
    setOpen_addInfo_toBill_modal(id);
    setChosenBills_numbers([bill_number]);
    setChosenSubmission(id_submission);
  }
  

  function verify_amount(market_id,bill_id,amount){
    let tmp_market_infos = markets[market_id];
    let tmp_bills = tmp_market_infos.bills;
    let market_amount = tmp_market_infos.offer_amount;
    let sum_bills_amount = 0;
    for(let elem of tmp_bills){
      if(elem.bill_number == bill_id) sum_bills_amount += amount;
      else sum_bills_amount += elem.amount_without_tax;
    }
    if(sum_bills_amount>market_amount) return "Vous avez dépasser le montant du marché !";
    else return true;
  }


  function check_All(checked,bills,array_selected_bills,id){
    let tmp_selected_list = [];
    bills.map((bill)=>{
      if(checked===false) tmp_selected_list.push(null);
      else tmp_selected_list.push(bill.bill_state);
    });
    let tmp_array = [...array_selected_bills];
    tmp_array[id] = tmp_selected_list;
    setArray_selected_bills(tmp_array);
  }


  function setAction_on_bills_selected(action,id_submission,bills,array_selected_bills,id){
    let bills_numbers = [];
    array_selected_bills[id].map((selected,id)=>{
      if(selected!==null) bills_numbers.push(id+1);
    })
    //console.log(bills_numbers);
    setChosenBills_numbers(bills_numbers);
    setChosenSubmission(id_submission);
    let formData = new FormData();
    formData.append("bills_numbers",JSON.stringify(bills_numbers));
    formData.append("id_submission",id_submission);
    if(action==="count"){
      setWaiting(true);
      count_bill(formData).then((response)=>{
        let tmp_array = [...markets];
        tmp_array[id].bills = response.data;
        setMarkets(tmp_array);
        setWaiting(false);
      }).catch((error)=>{
        //console.log(error.response);
        setWaiting(false);
      })
    }
    if(action==="refuse"){
      setOpen_reason_modal(id);
    }
    if(action==="to_validate"){
      setWaiting(true);
      formData.append('id_financeServ',service.id);
      notify_business_service_toView_bills(formData).then((response)=>{
          //console.log(response.data);
          let tmp_array = [...markets];
          tmp_array[id].bills = response.data;
          setMarkets(tmp_array);
          setWaiting(false);
      }).catch((error)=>{
          //console.log(error.response);
          setWaiting(false);
      })
    }
    if(action==="paye"){
      setWaiting(true);
      paye_bill(formData).then((response)=>{
        let tmp_array = [...markets];
        tmp_array[id].bills = response.data;
        setMarkets(tmp_array);
        setWaiting(false);
      }).catch((error)=>{
        //console.log(error.response);
        setWaiting(false);
      });
    }
    if(action==="archive"){
      setWaiting(true);
      archive_bill(formData).then((response)=>{
        let tmp_array = [...markets];
        tmp_array[id].bills = response.data;
        setMarkets(tmp_array);
        setWaiting(false);
      }).catch((error)=>{
        //console.log(error.response);
        setWaiting(false);
      });
    }
    check_All(false,bills,array_selected_bills,id);

  }

  function setAction_on_bill(action,id_submission,bill_number,id){
    setChosenBills_numbers([bill_number]);
    setChosenSubmission(id_submission);
    let formData = new FormData();
    formData.append("bills_numbers",JSON.stringify([bill_number]));
    formData.append("id_submission",id_submission);
    if(action==="count"){
      setWaiting(true);
      count_bill(formData).then((response)=>{
        let tmp_array = [...markets];
        tmp_array[id].bills = response.data;
        setMarkets(tmp_array);
        setWaiting(false);
      }).catch((error)=>{
        //console.log(error.response);
        setWaiting(false);
      })
    }
    if(action==="refuse"){
      setOpen_reason_modal(id);
    }
    if(action==="to_validate"){
      setWaiting(true);
      formData.append('id_financeServ',service.id);
      notify_business_service_toView_bills(formData).then((response)=>{
          let tmp_array = [...markets];
          tmp_array[id].bills = response.data;
          setMarkets(tmp_array);
          setWaiting(false);
      }).catch((error)=>{
          //console.log(error.response);
          setWaiting(false);
      })
    }
    if(action==="paye"){
      setWaiting(true);
      paye_bill(formData).then((response)=>{
        let tmp_array = [...markets];
        tmp_array[id].bills = response.data;
        setMarkets(tmp_array);
        setWaiting(false);
      }).catch((error)=>{
        //console.log(error.response);
        setWaiting(false);
      });
    }
    if(action==="archive"){
      setWaiting(true);
      archive_bill(formData).then((response)=>{
        let tmp_array = [...markets];
        tmp_array[id].bills = response.data;
        setMarkets(tmp_array);
        setWaiting(false);
      }).catch((error)=>{
        //console.log(error.response);
        setWaiting(false);
      });
    }
    check_All(false,markets[id].bills,array_selected_bills,id);

  }

  function search(){
    let path = history.location.pathname;
    if(filter_categorie!=="all") history.push(path+"?src="+filter_bills_src+"&categorie="+filter_categorie+"&filtre="+search_filter+"&valeur="+search_value);
    else history.push(path+"?src="+filter_bills_src+"&filtre="+search_filter+"&valeur="+search_value);
    setUrl_updated(search_value);
  }

  function set__categorie(value){
    setFilter_categorie(value);
    let path = history.location.pathname;
    if(value!=="all") history.push(path+"?src="+filter_bills_src+"&categorie="+value);
    else history.push(path+"?src="+filter_bills_src+"&categorie=all");
    setUrl_updated(value);
  }

  function set__filter(value){
    if(value==="all"){
      setsearch_filter(value);
      let path = history.location.pathname;
      if(filter_categorie!=="all") history.push(path+"?src="+filter_bills_src+"&categorie="+filter_categorie+"&filtre="+value);
      else history.push(path+"?src="+filter_bills_src+"&filtre="+value);
      setUrl_updated(value);
    }else setsearch_filter(value);
  }


  function set_bills_src(value){
    let path = history.location.pathname;
    history.push(path+"?src="+value);
    setUrl_updated(value);
  }

  async function change_page(page){
    await setCurrentPage(page);
    let path = history.location.pathname;
    let params = history.location.search;
    let search_params = params.split('&');
    let tmp_params = path;
    if(!params) history.push(path+"?page="+page);
    else{
      for(let i in search_params){
        if(search_params[i].includes("page=")){
          if(i==0) tmp_params=tmp_params+"?page="+page;
          else tmp_params=tmp_params+"&page="+page;
        }else{
          if(i==0) tmp_params=tmp_params+search_params[i];
          else tmp_params=tmp_params+"&"+search_params[i];
        }
      }
      if(!tmp_params.includes("page=")) tmp_params=tmp_params+"&page="+page;
      history.push(tmp_params);
    }
    await setUrl_updated(page);
  }



  return (
    <>
    {
        markets==null
        ?<Loading_animation_view />
        :<div className='purchase_bills_container'>
          <div className='bills_src_header'>
            <FormControl variant="standard" className="header_form"  >
                <InputLabel id="demo-simple-select-helper-label">Factures/paiement</InputLabel>
                <Select required value={filter_bills_src} 
                        onChange={e => {set_bills_src(e.target.value) }} >
                        <MenuItem value={"marchés"} >{"Marchés"}</MenuItem>
                        <MenuItem value={"commandes"} >{"Bons de commande"}</MenuItem>
                </Select>
            </FormControl>
            </div>
            <div className='three_forms_header_container'>
              <div className='page_title'>Marchés</div>
              <div className='search_filter_container'>
                <FormControl variant="standard" className="header_form"  >
                      <InputLabel id="demo-simple-select-helper-label">Famille d'achat</InputLabel>
                      <Select required value={filter_categorie} 
                            onChange={e => {set__categorie(e.target.value) }} >
                            
                            <MenuItem value={"all"}>Toutes les familles d'achat</MenuItem>
                            {
                            categories.map((categorie,id)=>(
                                <MenuItem value={categorie.id_category} key={id} >{categorie.name}</MenuItem>
                            ))
                            }
                            
                      </Select>
                  </FormControl>

                  <FormControl className="header_form" >
                    <InputLabel id="demo-simple-select-helper-label">Filtre</InputLabel>
                      <Select value={search_filter}  onChange={(e)=>{set__filter(e.target.value)}}>
                        <MenuItem value="all">Aucun</MenuItem>
                        <MenuItem value='Code'>Code de marché</MenuItem>
                        <MenuItem value='Object'>Objet d'appel</MenuItem>
                      </Select>
                    </FormControl>

                      <Grid container spacing={1} alignItems="flex-start" className={search_filter==="all"?"search_bar disabled_button":"search_bar" } >
                        <Grid item>
                          <SearchIcon onClick={search} className={search_value===""?"my_icon disabled_button":"my_icon" } style={{cursor:"pointer"}}  />
                        </Grid>
                        <Grid>
                          <TextField label="Chercher" value={search_value} onChange={(e)=>{setsearch_value(e.target.value)}}/>
                        </Grid>
                      </Grid>
              </div>
            </div>
            <TableContainer className="bills_table_finance_container bills_table_container">
              <Table aria-label="sticky table">
                <TableHead>
                  <TableRow>
                      <TableCell>Code de marché</TableCell>
                      <TableCell>Fournisseur</TableCell>
                      <TableCell>Objet d'appel</TableCell>
                      <TableCell>Montant</TableCell>
                      <TableCell>Factures</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    markets.length!==0
                    ?<Markets_list markets={markets} addInfos_to_bill={addInfos_to_bill} setAction_on_bills_selected={setAction_on_bills_selected} 
                          check_All={check_All} array_selected_bills={array_selected_bills} setArray_selected_bills={setArray_selected_bills}
                          setAction_on_bill={setAction_on_bill} />
                      :<div style={{margin:"10px",fontSize:".75rem",width:'100%', textAlign:"center",alignContent:"center"}}>Aucune facture disponible</div>
                    }
                </TableBody>
              </Table>
            </TableContainer>
            {
              markets.length!==0
                ?<div className="pagination_container">
                      <Pagination
                          activePage={Number(currentPage)}
                          itemsCountPerPage={dataPerPage}
                          totalItemsCount={Number(data_count)}
                          pageRangeDisplayed={4}
                          onChange={change_page.bind(this)}
                      />
                  </div>
                  :<></>
            }
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waiting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        
       </div>
      }

          {
            open_addInfo_toBill_modal!==null
            ?<Add_infos_toBill open_addInfo_toBill_modal={open_addInfo_toBill_modal} setOpen_addInfo_toBill_modal={setOpen_addInfo_toBill_modal} 
                id_submission={chosenSubmission} bills_numbers={chosenBills_numbers} markets={markets} verify_amount={verify_amount}
                setMarkets={setMarkets} />
            :<></>
          }

          {
            open_reason_modal!==null
              ?<Set_refuse_reason open_reason_modal={open_reason_modal} setOpen_reason_modal={setOpen_reason_modal} 
                      id_submission={chosenSubmission} bills_numbers={chosenBills_numbers} markets={markets}
                      setMarkets={setMarkets} />
              :<></>
          }

    </>
  );
}

export default Markets_bills;
