import '../../../assets/css/navVerical.css'
import '../../../assets/css/responsive_navVertical.css'
import React, { useState, useEffect } from 'react';
import { Link,useHistory } from 'react-router-dom';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Tooltip from '@mui/material/Tooltip';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ContactlessIcon from '@material-ui/icons/Contactless';
import LanguageIcon from '@material-ui/icons/Language';
import DashboardIcon from '@material-ui/icons/Dashboard';
import StoreIcon from '@mui/icons-material/Store';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import SendIcon from '@mui/icons-material/Send';

import logoKertys from '../../img/logoKertys.png';
import { get_company_logo, logout_request } from '../../../services/for_allServices';
import NavBar from '../../service_navBar/navBar';




function Responsive_navVertical({company_nav_logo,logout,nav_color,Component,menu_color}){

  function show_sideBar(menu_sm){
    var sidebar = menu_sm.nextSibling;
    let service_menu_holder = document.getElementById("service_menu_holder");
    if(sidebar && sidebar.className.includes("responsive_service_content")){
        menu_sm.classList.toggle('open');
        sidebar.classList.toggle('show');
    }
}

function close_sidebar(){
  let menu_sm = document.getElementById("service_menu_holder");
  let sidebar = menu_sm.nextSibling;
  if(sidebar && sidebar.className.includes("responsive_service_content")){
        menu_sm.classList.remove('open');
        sidebar.classList.remove('show');
    }
}

  return(
    <>
    <div className="responsive_navVertical">
          <div id="service_menu_holder" onClick={(event) => show_sideBar(event.target)} style={{background:menu_color}}>
              <div onClick={(event) => show_sideBar(event.target.parentNode)} style={{background:nav_color}}></div>
              <div onClick={(event) => show_sideBar(event.target.parentNode)} style={{background:nav_color}}></div>
              <div onClick={(event) => show_sideBar(event.target.parentNode)} style={{background:nav_color}}></div>
          </div>
          <div className="responsive_service_content" style={{background:nav_color}}>
            <Link className="company_nav_logo_container" onTouchEnd={close_sidebar} to="/serviceMetier">
                {
                    company_nav_logo!==null
                    ?<><img src={company_nav_logo!==""?`data:image/*;base64,${company_nav_logo}`:logoKertys } /></>
                    :<></>
                }
            </Link>
            
            <div className='menu_items'>
              <div>
                    <Link className={window.location.pathname==="/serviceMetier/tableauDeBord"?"menu_item menu_item_selected":"menu_item"} onTouchEnd={close_sidebar} to="/serviceMetier/tableauDeBord">
                      <div><DashboardIcon /><span className="title_menu_item">Tableau de bord </span> </div>
                    </Link>
                    <Link className={window.location.pathname==="/serviceMetier/Fiche_Identite"?"menu_item menu_item_selected":"menu_item"} onTouchEnd={close_sidebar} to="/serviceMetier/Fiche_Identite">
                      <div><AccountCircleIcon/><span className="title_menu_item">Fiche d'identité</span> </div>
                    </Link>
                    <Link className={window.location.pathname==="/serviceMetier/facture"?"menu_item menu_item_selected":"menu_item"} onTouchEnd={close_sidebar} to="/serviceMetier/facture">
                      <div><ContactlessIcon /><span className="title_menu_item">Factures / Paiements</span> </div>
                    </Link>
                    <Link className={window.location.pathname==="/serviceMetier/demande_achat"?"menu_item menu_item_selected":"menu_item"} onTouchEnd={close_sidebar} to="/serviceMetier/demande_achat">
                      <div><StoreIcon /><span className="title_menu_item">Ajouter une demande d'achat </span> </div>
                    </Link>
                    <Link className={window.location.pathname==="/serviceMetier/demandes"?"menu_item menu_item_selected":"menu_item"} onTouchEnd={close_sidebar} to="/serviceMetier/demandes">
                      <div><RequestPageIcon /><span className="title_menu_item">Demandes d'achats </span> </div>
                    </Link>
                    <Link to="/serviceMetier/envoyer_email" onTouchEnd={close_sidebar}className={window.location.pathname==="/serviceMetier/envoyer_email"?"menu_item menu_item_selected":"menu_item"}>
                        <div ><SendIcon /> <span className="title_menu_item">Envoyer un email</span> </div>
                    </Link>
                  <div className="menu_item" onClick={() => window.location.href="https://www.kertys.com"}>
                    <div><LanguageIcon/><span className="title_menu_item">https://www.kertys.com</span> </div>
                  </div>
              </div>
                
              <div className="menu-connection">
                <div className="menu_item" onClick={logout}>
                  <div><ExitToAppIcon/> <span className="title_menu_item">Logout</span></div>
                </div>
              </div>
            </div>
          </div>
      </div>
        <div className="responsive_service_body">
            {
              Component 
              ? <>
                  <Component /> 
              </>
              :<></>
            }
        </div>
      </>
  )
}



function NavVertical({Component}) {
  let history = useHistory();
  let user = JSON.parse(localStorage.getItem('user-info'))

  const [responsive_menu,setResponsive_menu]=useState(null);


  const [company_nav_logo,setcompany_nav_logo]=useState(null);
  const [company_logo,setcompany_logo]=useState(null);

  const [ nav_color,setNav_color ] = useState('');
  const [ menu_color,setMenu_color ] = useState('');

  useEffect(()=>{
    async function check_width(){
      if(window.innerWidth > 1010){
        setResponsive_menu(false);
      }else{
        setResponsive_menu(true);
      }
    }

    async function load_data(){
      await check_width();
      await setNav_color(user.company_color.split(',')[0]);
      await setMenu_color(user.company_color.split(',')[1]);
      await get_company_logo().then((response)=>{
        setcompany_nav_logo(response.data.company_nav_logo);
        setcompany_logo(response.data.company_logo);
      }).catch((error)=>{
        //console.log(error.response);
      })
    }
    load_data();
  },[]);

  
      async function logout(){
        await logout_request(user.id).then((response)=>{
          localStorage.clear();
          window.dispatchEvent(new Event("storage"));
        }).catch((error)=>{
          //console.log(error.response);
        })
        history.push("/");
    }

    const load_component = React.useMemo(() => {
      return (
        <>
        {
                  Component 
                  ? <>
                      <Component /> 
                  </>
                  :<></>
                }
          </>
      )
    },[Component])

  return (
  <>
    {
    responsive_menu!=null
    ?<><NavBar menu_color={menu_color} company_logo={company_logo} />
      <div className="nav_menu_container">
      {
        responsive_menu!==true
        ?<><div className="service_content show"  style={{background:nav_color}}>
              
              <Link className="company_nav_logo_container" to="/serviceMetier">
                  {
                      company_nav_logo!==null
                      ?<><img src={company_nav_logo!==""?`data:image/*;base64,${company_nav_logo}`:logoKertys } /></>
                      :<></>
                  }
              </Link>
              
              <div className='menu_items'>
                <div>
                  <Tooltip title="Tableau de bord" placement="right" arrow>
                    <Link className={window.location.pathname==="/serviceMetier/tableauDeBord"?"menu_item menu_item_selected":"menu_item"} to="/serviceMetier/tableauDeBord">
                      <div><DashboardIcon /></div>
                    </Link>
                  </Tooltip>
                  <Tooltip title="Fiche d'identité" placement="right" arrow>
                    <Link className={window.location.pathname==="/serviceMetier/Fiche_Identite"?"menu_item menu_item_selected":"menu_item"} to="/serviceMetier/Fiche_Identite">
                      <div><AccountCircleIcon/></div>
                    </Link>
                  </Tooltip>
                  <Tooltip title="Factures / Paiements" placement="right" arrow>
                    <Link className={window.location.pathname==="/serviceMetier/facture"?"menu_item menu_item_selected":"menu_item"} to="/serviceMetier/facture">
                      <div><ContactlessIcon /></div>
                    </Link>
                  </Tooltip>
                  <Tooltip title="Ajouter une demande d'achat" placement="right" arrow>
                    <Link className={window.location.pathname==="/serviceMetier/demande_achat"?"menu_item menu_item_selected":"menu_item"} to="/serviceMetier/demande_achat">
                      <div><StoreIcon /></div>
                    </Link>
                  </Tooltip>
                  <Tooltip title="Demandes d'achats" placement="right" arrow>
                    <Link className={window.location.pathname==="/serviceMetier/demandes"?"menu_item menu_item_selected":"menu_item"} to="/serviceMetier/demandes">
                      <div><RequestPageIcon /></div>
                    </Link>
                  </Tooltip>
                  <Tooltip title="Envoyer un email" placement="right" arrow>
                    <Link className={window.location.pathname==="/serviceMetier/envoyer_email"?"menu_item menu_item_selected":"menu_item"} to="/serviceMetier/envoyer_email" >
                      <div><SendIcon /></div>
                    </Link>
                  </Tooltip>
                  <Tooltip title="https://www.kertys.com" placement="right" arrow>
                    <div className="menu_item" onClick={() => window.location.href="https://www.kertys.com"}>
                      <div><LanguageIcon/></div>
                    </div>
                  </Tooltip>
    
                </div>
                  
                <div className="menu-connection">
                <Tooltip title="Logout" placement="right" arrow>
                <div className="menu_item" onClick={logout}>
                    <div><ExitToAppIcon/></div>
                  </div>
                  </Tooltip>
                </div>
                
              </div>
            </div>
            
            <div className="service_body">
              {load_component}
          </div>
          </>
            :<Responsive_navVertical company_nav_logo={company_nav_logo} logout={logout} nav_color={nav_color} Component={Component} menu_color={menu_color} />
        }
        </div>
      </>
      :<></>
    }
    </>  
    
  );
}

export default NavVertical;
