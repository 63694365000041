import React, { useState , useEffect } from 'react'
import { useHistory } from "react-router-dom";

import './suppliers_references.css'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@mui/material/Tooltip';
import NotesIcon from '@mui/icons-material/Notes';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import ChatIcon from '@mui/icons-material/Chat';
import Fab from '@mui/material/Fab';
import DownloadIcon from '@mui/icons-material/Download';
import Stack from '@mui/material/Stack';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import swal from 'sweetalert';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Pagination from "react-js-pagination";

import Set_comments from './set_comments/set_comments';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { archivate_supplier, get_categories ,refusing_balance_sheet, refusing_rc, validating_balance_sheet, validating_rc,
  refusing_compte, validating_compte, valider_fournisseur, get_referencing, download_supplier_reference_docs, count_unread_msg_service_supplier, load_chat_service_supplier} from '../../../services/purchase_service';
import { getSupplier_infos_byId } from '../../../services/supplier_service';

import Loading_animation_view from '../../loading_animation_view/loading_animation_view';
import Identifiantsupplier from './identity_supplier_modal/identity_supplier_modal';
import ChatView_Service from '../chatView/chatView';




const status = ['En Cours','Refusé','Accepté'];


const columns = [
  { id: 'fullName', label: 'fullName', minWidth: 0 },
  { id: 'parentCompany', label: "Maison mère", minWidth: 0 },
  { id: 'purchase_family', label: "Famille d'achat", minWidth: 0 },
  { id: 'Documents', label: "Documents", minWidth: 0 , align: 'left'},
  { id: 'Compte', label: "Compte", minWidth:  0, align: 'left' },
  { id: 'comment', label: 'comment', minWidth:  0, align: 'center' },
  { id: 'chat', label: 'chat', minWidth:  0, align: 'center' },
  { id: 'treatement_date', label: "Date de traitement", minWidth:  0, align: 'left' },
  { id: 'Archiver', label: "Status referencement", minWidth:  0, align: 'left' }
];


function setStatus(stat){
  return(
    <>
      {
       stat == status[0]
       ?<Button variant="contained" className="status_waiting">{status[0]}</Button>
       :stat == status[1]
          ?<Button variant="contained" className="status_deny">{status[1]}</Button>
          :<Button variant="contained" className="status_success">{status[2]}</Button>
      }
    </>

  );
}

function verify_if_able_to_acceptSupplier(balance_sheetchoice,RCchoice,validated){
  return balance_sheetchoice==="Accepté" && RCchoice==="Accepté" && validated==="Accepté";
}

function Suppliers_references (){
  let history = useHistory();
  const [url_updated , setUrl_updated] = useState(null);

  let user=JSON.parse(localStorage.getItem('user-info'))
  const id_service = user.id;

  const [fournisseur,setFournisseur]=useState(null);

  const [show,setShow]=useState(false);

  const [Data,setData]=useState(null);
  const [categories,setCategories]=useState([]);
  const [RCchoice,setRCchoice]=useState([]);
  const [balance_sheetchoice,setbalance_sheetchoice]=useState([]);
  const [Commchoice,setCommchoice]=useState([]);

  const [validated,setValidated]=useState([]);
  const [archived,setArchived]=useState([]);
  
  const [searchValue,setSearchValue]=useState('');
  const [filter,setFilter]=useState("status");

  const [open_comment_modal,setOpen_comment_modal]=useState(null);

  const [currentPage,setCurrentPage]=useState(1);
  const [dataPerPage,setdataPerPage] =useState(5);
  const [data_count,setdata_count]=useState(0);

  const [show_msg,setShow_msg]=useState(false);
  const [chats,setChats]=useState(null);
  const [msg_non_lue,setMsg_non_lue]=useState([]);
  const [id_supplier,setid_supplier]=useState(null);
  const [supplier_name,setsupplier_name]=useState(null);

  const [waiting, setWaiting] = useState(false);

  const [sortBy,setsortBy]=useState("null");
  const [sortOrder,setsortOrder]=useState("asc");

  async function count_unread_msg(if_supplier) {
      const response = await count_unread_msg_service_supplier(id_service,if_supplier).then((response)=>{
                return response.data;
              }).catch((error) => {
                //console.log(error.response);
              });
      return response;
  }

  useEffect(()=>{
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      setData(null);
      setdata_count(0);
    async function set_filter(){
      if(urlParams.get('referencement')){
        await setFilter(urlParams.get('referencement'));
      }
      if(urlParams.get('valeur')){
        await setSearchValue(urlParams.get('valeur'));
      }
    }
    async function load_data(){
      let page = (urlParams.get('page')?urlParams.get('page'):1);
      let filte_tmp = (urlParams.get('referencement')?urlParams.get('referencement'):filter);
      let searchvalue_tmp = (urlParams.get('valeur')?urlParams.get('valeur'):"null");
      await setCurrentPage(page)
      await get_categories().then((response)=> {
        setCategories(response.data);
      }).catch((error)=>{
        //console.log(error.response);
      });
      const result = await get_referencing(id_service,filte_tmp,searchvalue_tmp,page,sortBy,sortOrder).then(async (response)=> {
        ////console.log(response.data)
          await setdata_count(response.data.values.total);
          const array_archive=[];
          const array_validate=[];
          (response.data.values.data).map((supplier,id)=>{
            array_validate.push(statusCompte(supplier));
            array_archive.push(statusFournisseur(supplier));
          });
          await setArchived(array_archive);
          await setValidated(array_validate);
          const array_rc=[];
          const array_balance_sheet=[];
          const array_cmt=[];
          (response.data.suppliers_reference).map((reference)=>{
            array_rc.push(statusRC(reference));
            array_balance_sheet.push(statusbalance_sheet(reference));
            array_cmt.push(reference.comment);
          });
          setbalance_sheetchoice(array_balance_sheet);
          setRCchoice(array_rc);
          setCommchoice(array_cmt);
          //console.log("ee",response.data)
          return response.data;
        }).catch((error)=>{
          //console.log(error.response);
        }); 

      //console.log(result.values.data);
      for(const supplier in result.values.data){
        result.values.data[supplier].msg_non_lue = (await count_unread_msg(result.values.data[supplier].id));
      }
      setData(result.values.data);
    }

    set_filter();
    load_data();

  },[url_updated,history.location])

  
    function archieverCompte(id,id_supplier){
      swal({
        title: "Etes-vous sûr ?",
        text: "!!!!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        setWaiting(true);
        const arr=archived;
        arr[id]=status[1];
        const formData = new FormData();
        formData.append('id_supplier',id_supplier);
        formData.append('etatbalance_sheet',balance_sheetchoice[id]);
        formData.append('etatRC',RCchoice[id]);
        formData.append('etatFiche',validated[id]);
        formData.append('etatCompte',archived[id]);
        archivate_supplier(formData).then((response) => {
            swal("Le fournisseur sera bientôt notifié !", {
              icon: "success",
              buttons: true,
            }).then((willDelete) => {
                if (willDelete) {
            }})
            setArchived({ ...arr });
            setWaiting(false);
        }).catch(err=>{
            //console.log(err,err.response);
            setWaiting(false);
        });
      }})
    }


    function validerFournisseur(id,id_supplier){
      swal({
        title: "Etes-vous sûr ?",
        text: "!!!!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        setWaiting(true);
        const arr=archived;
        arr[id]=status[2];
        const formData = new FormData();
        formData.append('id_supplier',id_supplier);
        formData.append('etatbalance_sheet',balance_sheetchoice[id]);
        formData.append('etatRC',RCchoice[id]);
        formData.append('etatFiche',validated[id]);
        formData.append('etatCompte',archived[id]);
        valider_fournisseur(formData).then((response) => {
            swal("Le fournisseur sera bientôt notifié !", {
              icon: "success",
              buttons: true,
            }).then((willDelete) => {
                if (willDelete) {
            }})
            setArchived({ ...arr })
            setWaiting(false);
        }).catch(err=>{
            //console.log(err,err.response);
            setWaiting(false);
        });
      }})
    }



//////////////////////
///////////////////////////////////
    function viewSupplierIdentity(id_supplier){   
      setWaiting(true);   
      getSupplier_infos_byId(id_supplier)
          .then(response => {
            setFournisseur(response.data.values[0]);
            setWaiting(false);
            setShow(true);
          }).catch(error => {
              //console.log(error.response);
              setWaiting(false);
          })     
    }

    function hideModal(){
      setShow(false);
    }

//////////////////////
/////////////////////////////////////////////////////////
///////////////////////////////////
    
function set_comment(id){
  const formData = new FormData();
  formData.append('id',id);
  formData.append('id_supplier',Data[id].id);
  formData.append('id_service',user.id);
  formData.append('etatbalance_sheet',balance_sheetchoice[id]);
  formData.append('comment',Commchoice[id]);
  formData.append('etatRC',RCchoice[id]);
  formData.append('etatFiche',validated[id]);
  formData.append('etatCompte',archived[id]);
  setOpen_comment_modal(formData);
  //console.log(Data[id].msg_non_lue);
}


function refusebalance_sheet(id){
  setWaiting(true);   
  const formData = new FormData();
  formData.append('id_supplier',Data[id].id);
  formData.append('id_service',user.id);
  refusing_balance_sheet(formData).then((response)=>{
      //console.log(response.data);
      const arr=balance_sheetchoice;
      arr[id]=status[1];
      setbalance_sheetchoice({ ...arr });
      setWaiting(false);   
  }).catch((error) => {
      //console.log(error.response);
      setWaiting(false);   
  });
}

function validebalance_sheet(id){
  setWaiting(true);   
  const formData = new FormData();
  formData.append('id_supplier',Data[id].id);
  formData.append('id_service',user.id);
  validating_balance_sheet(formData).then((response)=>{
      //console.log(response.data);
      const arr=balance_sheetchoice;
      arr[id]=status[2];
      setbalance_sheetchoice({ ...arr });
      setWaiting(false);   
  }).catch((error) => {
      //console.log(error.response);
      setWaiting(false);   
  });
}

function refuseRC(id){
  setWaiting(true);   
  const formData = new FormData();
  formData.append('id_supplier',Data[id].id);
  formData.append('id_service',user.id);
  refusing_rc(formData).then((response)=>{
      //console.log(response.data);
      const arr=RCchoice;
      arr[id]=status[1];
      setRCchoice({ ...arr });
      setWaiting(false);   
  }).catch((error) => {
      //console.log(error.response);
      setWaiting(false);   
  });
}

function valideRC(id){
  setWaiting(true);   
  const formData = new FormData();
  formData.append('id_supplier',Data[id].id);
  formData.append('id_service',user.id);
  validating_rc(formData).then((response)=>{
      //console.log(response.data);
      const arr=RCchoice;
      arr[id]=status[2];
      setRCchoice({ ...arr });
      setWaiting(false);   
  }).catch((error) => {
      //console.log(error.response);
      setWaiting(false);   
  });
}

function refuseCompte(id){
  setWaiting(true);   
  const formData = new FormData();
  formData.append('id_supplier',Data[id].id);
  formData.append('id_service',user.id);
  refusing_compte(formData).then((response)=>{
      const arr=validated;
      arr[id]=status[1];
      setValidated({ ...arr });
      setWaiting(false);   
  }).catch((error) => {
      //console.log(error.response);
      setWaiting(false);   
  });
}

function valideCompte(id){
  setWaiting(true);   
  const formData = new FormData();
  formData.append('id_supplier',Data[id].id);
  formData.append('id_service',user.id);
  validating_compte(formData).then((response)=>{
      //console.log(response.data);
      const arr=validated;
      arr[id]=status[2];
      setValidated({ ...arr });
      setWaiting(false);   
  }).catch((error) => {
      //console.log(error.response);
      setWaiting(false);   
  });
}

function changeComm(id,value){
  const arr=Commchoice;
  arr[id]=value;
  setCommchoice({ ...arr });
  //console.log(Commchoice);
}


function statusRC(reference){
  if(reference.remarksRC==null){
      return status[0];
  }else{
    if(reference.RC===0) return status[1];
    else if(reference.RC===1) return status[2];
  }
}

function statusbalance_sheet(reference){
  if(reference.remarksBalance_sheet==null){
    return status[0];
  }else{
    if(reference.balance_sheet===0) return status[1];
    else if(reference.balance_sheet===1) return status[2];
  }
}

function statusCompte(supplier){
  if(supplier.new_user==0){
    return status[0];
  }else{
    if(supplier.validate===0) return status[1];
    else if(supplier.validate===1) return status[2];
  }
}

function statusFournisseur(supplier){
  if(supplier.archive==null){
    return status[0];
  }else{
    if(supplier.archive===0) return status[2];
    else if(supplier.archive===1) return status[1];
  }
}

function download(id,choice){
  setWaiting(true);
  const formData = new FormData();
  formData.append('id_supplier',Data[id].id);
  formData.append('choice',choice);
  download_supplier_reference_docs(formData).then((response) => {
    //console.log(response.data);
      var blob = new Blob([response.data]);
      var fileURL = window.URL.createObjectURL(blob);
      var fileLink = document.createElement('a');
      let file_extension = '';
      const disposition = response.headers['content-disposition'];
      if (disposition && disposition.includes('filename')) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
            file_extension = matches[1].replace(/['"]/g, '').split(".")[1];
        }
      }
      fileLink.href = fileURL;
      fileLink.setAttribute('download', "Supplier_"+Data[id].id+"_"+choice+"."+file_extension);
      document.body.appendChild(fileLink);
      fileLink.click();
      setWaiting(false);
    }).catch((error)=>{
      setWaiting(false);
      //console.log(error.response);
  });
}

function viewService_Supplier_chat(id_supplier,supplier_name,id){ 
  setWaiting(true);
  load_chat_service_supplier(id_service,id_supplier).then((response)=>{
    //console.log(response.data);
       setChats(response.data);
       response.data.map((chat)=>{
           if(chat.id_personne != id_service ){
              let tmp_data = [ ...Data ];
              tmp_data[id].msg_non_lue = 0;
              setData(tmp_data);
               return null;
           }
       });
       setWaiting(false);
       setid_supplier(id_supplier);
       setsupplier_name(supplier_name);
       setShow_msg(true);
   }).catch((error)=>{
       //console.log(error.response);
   });   
 }

 function onCloseDiscussion(){
  setShow_msg(false); 
  setChats([]);
  setid_supplier(null);
  setsupplier_name(null);
}

  const getCategorieById = (categorie)=>{
    var tmp_categorie="";
    categories.map((catg)=>{
      categorie.map((id)=>{
        if(catg.id_category === parseInt(id)){
          if(tmp_categorie==="") tmp_categorie = tmp_categorie+catg.name;
          else tmp_categorie = tmp_categorie+", "+catg.name;
        }
      })
    })
    return tmp_categorie;
  }

//////////////////////
/////////////////////////////////////////////////////////
///////////////////////////////////

function createData(fullName, parentCompany,purchase_family,Documents,Compte,comment,chat,treatement_date,Archiver) {
  return { fullName, parentCompany,purchase_family,Documents,Compte,comment,chat,treatement_date,Archiver};
}

const rows = Data && msg_non_lue!=null?[
  Data.map((o, i) => createData(Data[i].fullName,Data[i].parentCompany,getCategorieById((Data[i].purchase_family).split(',')),
    <Stack direction="row" spacing={1} alignItems="center" >
      <div className='documentsContainer'>
          <div style={{display:'flex',marginBottom:'10px'}}>
            <div className='documentsDownload'>
              <Fab size="small" color="default" onClick={()=>download(i,'balance_sheet')}><DownloadIcon className="my_icon"/></Fab>
              <span className='documentsTitle'>Bilan</span>
            </div>
            <div className='documentsStatus_valide' >
              <div>
                <div><CheckIcon className="checkIcon"  onClick={()=>validebalance_sheet(i)} /></div>
                <div><CloseSharpIcon className="closeSharpIcon"  onClick={()=>refusebalance_sheet(i)} /></div>
              </div>
                { setStatus(balance_sheetchoice[i])}
            </div>
          </div>


          <div style={{display:'flex',marginBottom:'10px'}}>
            <div className='documentsDownload'>
                <Fab size="small" color="default" onClick={()=>download(i,'RC')}><DownloadIcon className="my_icon" /></Fab>
                <span className='documentsTitle'>RC</span>
            </div>
              <div className='documentsStatus_valide'  >
                <div>
                  <div><CheckIcon className="checkIcon"  onClick={()=>valideRC(i)} /></div>
                  <div><CloseSharpIcon className="closeSharpIcon"  onClick={()=>refuseRC(i)} /></div>
                </div>
                  {setStatus(RCchoice[i])}                
            </div>
          </div>
      </div>
    </Stack>,

      <Stack direction="row" spacing={1} alignItems="center" >
        <div style={{display:'flex',width: '100%'}}>
          <div className='documentsDownload'>
            <Fab size="small" color="warning" onClick={() => viewSupplierIdentity(Data[i].id)} ><AssignmentIndIcon className="my_icon" /></Fab>
            <span className='documentsTitle'>Fiche</span>
          </div>
          <div className='documentsStatus_valide'  >
              <div>
                <div><CheckIcon className="checkIcon" onClick={()=>valideCompte(i)} /></div>
                <div><CloseSharpIcon className="closeSharpIcon" onClick={()=>refuseCompte(i)} /></div>
              </div>
              {setStatus(validated[i])}
          </div>
      </div>
      </Stack>,
      <>
        <Tooltip title="Message">
          <span>
            <Fab onClick={()=>set_comment(i)}  size="small" color="info" style={{color:"white"}}>
              <NotesIcon className="my_icon" />
            </Fab>
          </span>
        </Tooltip>
        </>,
      <>
        <Tooltip title="Chat">
          <span>
            <Fab onClick={()=>viewService_Supplier_chat(Data[i].id,Data[i].fullName,i)}  size="small" color="info" style={{backgroundColor:'#343a40',color:"white"}}>
              <ChatIcon className="my_icon" />
              {
                Data[i].msg_non_lue !== null && Data[i].msg_non_lue !== 0 
                ?<div id="text_non_lue"> {Data[i].msg_non_lue} </div>
                :<></>
              }
            </Fab>
          </span>
        </Tooltip>
        </>,
        <Stack direction="row">
            {
              Data[i].treatement_date != null
              ?<div style={{fontWeight:"660"}}>{new Date(Data[i].treatement_date).toLocaleDateString()}</div>
              :<div style={{color:"gray",fontWeight:"600"}}>Pas encore</div>
            }
        </Stack>,
        <Stack direction="row" spacing={1} alignItems="center" >
          <div className='referenceStatusContainer'>
            <div className='referenceStatusContainer2'>
              <div style={{display:"flex",justifyContent:"space-between"}}>
                <PersonAddIcon className={verify_if_able_to_acceptSupplier(balance_sheetchoice[i],RCchoice[i],validated[i])?"referencecheckIcon":"referencecheckIcon disabled_button"} onClick={()=>validerFournisseur(i,Data[i].id)} />
                <PersonAddDisabledIcon className="referencecloseSharpIcon" onClick={()=>archieverCompte(i,Data[i].id)} />
              </div>
              <div> 
                {setStatus(archived[i])}
              </div>
            </div>
          </div>
        </Stack>

    )
  )
]:[];


/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////


  function search(){
    let path = history.location.pathname;
    if(filter!=="status") history.push(path+"?referencement="+filter+"&valeur="+searchValue);
    else history.push(path+"?valeur="+searchValue);
    setUrl_updated(searchValue);
  }

  function setSelectedValue(value){
      setFilter(value);
      let path = history.location.pathname;
      history.push(path+"?referencement="+value);
      setsortBy("null");
      setUrl_updated(value);
  }

  ///////////////////////////
  /////////////////////////
  async function change_page(page){
    await setCurrentPage(page);
    let path = history.location.pathname;
    let params = history.location.search;
    let search_params = params.split('&');
    let tmp_params = path;
    if(!params) history.push(path+"?page="+page);
    else{
      for(let i in search_params){
        if(search_params[i].includes("page=")){
          if(i==0) tmp_params=tmp_params+"?page="+page;
          else tmp_params=tmp_params+"&page="+page;
        }else{
          if(i==0) tmp_params=tmp_params+search_params[i];
          else tmp_params=tmp_params+"&"+search_params[i];
        }
      }
      if(!tmp_params.includes("page=")) tmp_params=tmp_params+"&page="+page;
      history.push(tmp_params);
    }
    await setUrl_updated(page);
  }


  async function requestSort(cell,order){
    setWaiting(true);
    setsortBy(cell);
    let tmp_sortBy = cell;
    let tmp_sortOrder = "asc";
    if(sortBy!==cell){
      setsortOrder('asc');
    }
    else{
      order==="asc"?await setsortOrder("desc"):await setsortOrder("asc");
      order==="asc"?tmp_sortOrder="desc":tmp_sortOrder="asc";
    } 
    const result = await get_referencing(id_service,filter,searchValue?searchValue:"null",currentPage,tmp_sortBy,tmp_sortOrder).then((response)=> {
      setdata_count(response.data.values.total);
      return response.data;
    }).catch((error)=>{
      //console.log(error.response);
    });

    //console.log(result);
    for(const supplier in result.values.data){
      result.values.data[supplier].msg_non_lue = (await count_unread_msg(result.values.data[supplier].id));
    }
    setData(result.values.data);
    const array_archive=[];
    const array_validate=[];
    (result.values.data).map((supplier,id)=>{
      array_validate.push(statusCompte(supplier));
      array_archive.push(statusFournisseur(supplier));
    });
    setArchived(array_archive);
    setValidated(array_validate);
    const array_rc=[];
    const array_balance_sheet=[];
    const array_cmt=[];
    (result.suppliers_reference).map((reference)=>{
      array_rc.push(statusRC(reference));
      array_balance_sheet.push(statusbalance_sheet(reference));
      array_cmt.push(reference.comment);
    });
    setbalance_sheetchoice(array_balance_sheet);
    setRCchoice(array_rc);
    setCommchoice(array_cmt);
    setWaiting(false);

  }

    return (
      <>
        {
          Data==null
          ?<Loading_animation_view />
          :<div className="references_container" >
            <div className='references_header_container'>
              <div className='page_title'>Referencements</div>
              <div className='search_filter_container'>
                  <FormControl className="header_form" >
                      <InputLabel>Etat de referencement</InputLabel>
                          <Select required
                                value={filter}
                                onChange={e => {
                                    setSelectedValue(e.target.value);
                                }} name="Etat" >
                              <MenuItem value={"status"} disabled>
                                  Etat de referencement
                              </MenuItem>
                              <MenuItem value={"recent"}>Récent</MenuItem>
                              <MenuItem value={"ancien"}>Ancien</MenuItem>
                              <MenuItem value={"valider"}>Validé</MenuItem>
                              <MenuItem value={"refuser"}>Refusé</MenuItem>
                              <MenuItem value={"encours"}>En cours de traitement</MenuItem>
                          </Select>
                  </FormControl>
                  <Grid container spacing={1} alignItems="flex-start" className={"search_bar"} >
                    <Grid item style={{cursor:'pointer'}}>
                      <SearchIcon className={searchValue===""?"my_icon disabled_button":"my_icon" } onClick={search} />
                    </Grid>
                    <Grid>
                      <TextField id="input-with-icon-grid" label="Nom de Fournisseur" value={searchValue} style={{color:'blue'}} onChange={(e)=>{setSearchValue(e.target.value)}}/>
                    </Grid>
                </Grid>
              </div>
          </div>

          <TableContainer className="reference_table">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                     <TableSortLabel
                        active={sortBy === "name"}
                        direction={sortOrder}
                        onClick={() => requestSort("name",sortOrder)}
                      >
                          Nom Complet
                      </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                          active={sortBy === "company"}
                          direction={sortOrder}
                          onClick={() => requestSort("company",sortOrder)}
                        >
                          Maison mère
                      </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">Famille d'achat</TableCell>
                  <TableCell align="left">Documents</TableCell>
                  <TableCell align="left">Fiche d'identité</TableCell>
                  <TableCell align="left">Envoyer un mail</TableCell>
                  <TableCell align="left">Chat</TableCell>
                  <TableCell align="left">Date de Traitement</TableCell>
                  <TableCell align="right">Status referencement</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  rows[0].length!=0
                  ?rows[0].map((row,idx) => {
                    return (
                      <TableRow role="checkbox" tabIndex={-1} key={idx} >
                        {
                          columns.map((column,idx1) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {value}
                              </TableCell>
                            );
                          })
                        }
                      </TableRow>
                    );
                  })
                  :<div style={{margin:"10px", textAlign:"center",alignContent:"center",fontSize:'.75rem'}}>Aucun referencement</div>
                }
              </TableBody> 
            </Table>
          </TableContainer>
          {
                rows[0].length===0
                ?<></>
                :<div className="pagination_container">
                    <Pagination
                        activePage={Number(currentPage)}
                        itemsCountPerPage={dataPerPage}
                        totalItemsCount={Number(data_count)}
                        pageRangeDisplayed={4}
                        onChange={change_page.bind(this)}
                    />
                </div>
            }
          </div>
        }
        {
          fournisseur!==null && show
          ?<Identifiantsupplier show={show} handleClose={hideModal} supplier={fournisseur} />
          :<></>
        }

        {
          open_comment_modal!==null
          ?<Set_comments open_comment_modal={open_comment_modal}  setOpen_comment_modal={setOpen_comment_modal} commchoice={Commchoice} setCommchoice={setCommchoice} />
          :<></>
        }
        
              
        {   
            id_supplier!==null && (chats!=null && chats.length!=0) && show_msg==true
            ?<ChatView_Service open={show_msg} handleClose={onCloseDiscussion} chats={chats} id_service={id_service} id_supplier={id_supplier} supplier_name={supplier_name} />
            :<></>
        }

        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={waiting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
     </>
    )
}

export default Suppliers_references
