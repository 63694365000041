import './navVerical.css'
import React from 'react';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import DnsIcon from '@material-ui/icons/Dns';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Tooltip from '@mui/material/Tooltip';

import logoKertys from '../../img/logoKertys.png';
import NavBar from '../navBar/navBar';
import { logout_request } from '../../../services/gadmin_service';



function NavVertical({Component}) {
  let admin = JSON.parse(localStorage.getItem('admin-info'))


    window.addEventListener('resize', (event) => {
        let sidebar = document.getElementsByClassName("auditor_content")[0];
        let auditor_menu_holder = document.getElementById("auditor_menu_holder");
        if(auditor_menu_holder!=undefined){
            auditor_menu_holder.removeAttribute('style');
            document.getElementsByClassName('auditor_content')[0].classList.remove('show');
            if(auditor_menu_holder!=undefined) auditor_menu_holder.style.transform = 'translateX('+Number(sidebar.clientWidth+5)+ 'px)';
        }
    });


    window.addEventListener('load', (event) => {
        let auditor_menu_holder = document.getElementById("auditor_menu_holder");
        if(auditor_menu_holder!=undefined){
            auditor_menu_holder.removeAttribute('style');
            if(auditor_menu_holder!=undefined) auditor_menu_holder.style.transform = 'translateX(5px)';
            document.getElementsByClassName('auditor_content')[0].classList.remove('show');
        }
    })


    function logout(){
      logout_request(admin.id).then((response)=>{
        //console.log(response.data);
        localStorage.clear();
        window.location.href="/";
      }).catch((error)=>{
        //console.log(error.response);
      })
    }

  return (
  <>
    <NavBar/>
    <div className="menu_admin">
      <div className="service_content show">
          
          <div className="brand" onClick={() => window.location.href="/servicePrincipale"}>
              <img src={ logoKertys } />
          </div>
           
          <div className='menu_items'>
            <div>
              <Tooltip title="Ajouter une entreprise" placement="right" arrow>
                <div className={window.location.pathname==="/gadmin/nv_entreprise"?"menu_item menu_item_selected":"menu_item"} onClick={() => window.location.href="/gadmin/nv_entreprise"}>
                  <div><AddBusinessIcon /></div>
                </div>
              </Tooltip>
              <Tooltip title="Entreprise" placement="right" arrow>
                <div className={window.location.pathname==="/gadmin/entreprises" || window.location.pathname==="/gadmin"?"menu_item menu_item_selected":"menu_item"} onClick={() => window.location.href="/gadmin/entreprises"}>
                  <div><DnsIcon /></div>
                </div>
              </Tooltip>
            </div>
            <div className="menu-connection">
              <Tooltip title="Logout" placement="right" arrow>
                    <div className="menu_item" onClick={logout}>
                        <div><ExitToAppIcon/></div>
                      </div>
                </Tooltip>
            </div>
            </div>
        </div>
        <div className="service_body">
          {
            Component 
            ? <>
                <Component /> 
            </>
            :<></>
          }
      </div>
    </div>
    </>  
    
  );
}

export default NavVertical;
