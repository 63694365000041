import "./docs_validation.css"
import React, {useMemo, useState, useEffect} from 'react';
import Form from 'react-bootstrap/Form'
import Multiselect from 'multiselect-react-dropdown';

import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    indeterminateColor: {
      color: "#f50057"
    },
    selectAllText: {
      fontWeight: 500
    },
    selectedAll: {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)"
      }
    },
    input: {
        display: 'none',
      },
      container: {
        maxHeight: 440,
      },
      select: {
        "&:focus": {
          backgroundColor: "white",
          borderBottomWidth: 0
        }
      },
      selectInput: {
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottomWidth: 0
        },
        "&:focus": {
            backgroundColor: "white",
            borderBottomWidth: 0
          },
        "&:not($disabled):not($focused):not($error):before": {
          borderBottomWidth: 0,
          borderWidth:1
        }
      },
      disabled: {},
      focused: {},
      error: {}
  }));


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center"
    },
    variant: "menu"
  };




export default function Docs_validation({ doc_validation, setdoc_validation }) {
    const classes = useStyles();

    const [available_recipients,setavailable_recipients] = useState([  {
        id:0,
        name:"Fournisseur"
    },
    {
        id:1,
        name:"Service d'achat"
    },
    {
        id:2,
        name:"Service métier"
    },
    {
        id:3,
        name:"Service de finance"
    }]);

    const [recipients,setRecipients] = useState([  {
        id:0,
        name:"Fournisseur"
    },
    {
        id:1,
        name:"Service d'achat"
    },
    {
        id:2,
        name:"Service métier"
    },
    {
        id:3,
        name:"Service de finance"
    }]);
    const [selected_recipients,setSelected_Recipients] = useState([]);


    useEffect(()=>{
        let tmp_array = [];
    },[])

    function invalid_email_pattern(email){
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if(email!==""){
            if (!pattern.test(email)) {
                return true ;
              }else{
                return false ;
              }
        }else return true;
    }


    function search_value(value){
        var pattern = new RegExp('([0-9a-zA-Z-_.@ ]*|^)'+value+'([0-9a-zA-Z-_.@ ]|$)','i');
        let tmp_array = [];
        let tmp_value={};
        available_recipients.map((available_recipient) => {
            if (pattern.test(available_recipient.email) || pattern.test(available_recipient.fullName)) {
                tmp_value = {};
                if(tmp_array.length<5){
                    tmp_value['name'] = available_recipient.fullName+" ( "+available_recipient.email+" )";
                    tmp_value['id'] = available_recipient.email;
                    tmp_array.push(tmp_value);  
                }
                
            }
        })
        return tmp_array;
    }

    async function search_users_byname_or_email(e){
        await setRecipients([]);
        if(e!==''){

           if(!invalid_email_pattern(e)){
            await setRecipients([{
                'name':e,
                'id':e
            }])
           }else{
            setRecipients(search_value(e));
           }
           
        }
    }

    async function onSelect_recipients(e){
        await setSelected_Recipients([]);
        await setSelected_Recipients(e);
        await setdoc_validation([]);
        let tmp_arr = [];
        e.map((sel) => {
          tmp_arr.push(sel.id);
        })
        await setdoc_validation(tmp_arr);
        await setRecipients(available_recipients)
    }

    async function onRemove_recipients(e){
        await setSelected_Recipients([]);
        await setSelected_Recipients(e);
        await setdoc_validation([]);
        let tmp_arr = [];
        e.map((sel) => {
          tmp_arr.push(sel.id);
        })
        await setdoc_validation(tmp_arr);
    }


  return (
    <div className="docs_perms">
      <div style={{marginBottom:'10px'}}>
        Par défaut, le document n'est validé par personne, les utilisateurs ajoutés dans la liste ci-dessous doivent le valider.
      </div>
      <div>
      <Form.Group className="col-md-12">
            <Multiselect
                options={recipients}
                onSelect={(e) => onSelect_recipients(e) }
                onRemove={(e) => onRemove_recipients(e) }
                placeholder=""
                onSearch={(e) => search_users_byname_or_email(e)}
                displayValue="name"
                emptyRecordMsg="Aucune option"
            />            
    </Form.Group>
      </div>
    </div>
  );
}
