import React, { useState,useEffect } from 'react'

import   './set_supplier.css'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CancelIcon from '@material-ui/icons/Cancel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@material-ui/icons/Add';

import {makeStyles} from '@material-ui/core/styles';
import { get_suppliers } from '../../../../../services/for_allServices';
import Loading_animation_view from '../../../../loading_animation_view/loading_animation_view';


  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
    },
    indeterminateColor: {
      color: "#f50057"
    },
    selectAllText: {
      fontWeight: 500
    },
    selectedAll: {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)"
      }
    }
  }));
  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 350
      }
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center"
    },
    variant: "menu"
  };
  

function Set_supplier({open_supplier_modal,setOpen_supplier_modal,existent_suppliers,selected_suppliers,setExistent_suppliers}){

    const classes = useStyles();

    const [valide, setValid] = useState(true);
    const [submitted, setSubmitted] = useState(false);
  
    const [suppliers,setSuppliers] = useState([]);
    const [selected, setSelected] = useState([]);
    const [selectedSuppliers, setSelectedSuppliers] = useState([]);

    const [waiting, setWaiting] = useState(false);

    useEffect(()=>{
      get_suppliers().then((response)=>{
        //console.log(response.data);
        setSuppliers(response.data);
        let tmp_suppliers=[];
        existent_suppliers.map((supplier) => {
          tmp_suppliers.push(supplier.fullName);
        });
        setSelected(tmp_suppliers);
      }).catch((error)=>{
        //console.log(error.response);
      })
    },[])


    function getsuppliers_perNames(noms){
      let tmp_suppliers=[];
      suppliers.map((supplier) => {
        noms.map((nom) => {
          if(supplier.fullName === nom) tmp_suppliers.push(supplier);
        });
      });
      return tmp_suppliers;
    }

    function validated_elem(){
        return submitted && valide;
    }

    const isAllSelected =
        suppliers.length > 0 && selected.length === suppliers.length;

    const handleChange = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
          let tmp_array = [];
          let tmp_suppliers_array = [ ...selectedSuppliers ];
          suppliers.map((supplier) => {
              tmp_array.push(supplier.fullName);
              tmp_suppliers_array.push(supplier);
          })
          setSelected(selected.length === suppliers.length ? [] : tmp_array);
          setSelectedSuppliers(tmp_suppliers_array);
          return;
        }
        setSelected(value);
        //console.log(value);
        setSelectedSuppliers(getsuppliers_perNames(value));
    };

    function verify_supplier_alreadyExist(existent_suppliers,id_supplier){
      let exist = false;
      existent_suppliers.map((existent_supplier) => {
          if(existent_supplier.id===id_supplier) exist = true;
      });
      return exist;
    }

    async function add_proposal() {   
        setWaiting(true); 
        setSubmitted(true);
        setValid(true);
        if (selected.length==0) {
          setWaiting(false); 
             setValid(false);
        }else{
          let tmp_aray = [ ...selectedSuppliers ];
          setExistent_suppliers(tmp_aray);
          setOpen_supplier_modal(false);
        }
     }

    

    return (
      <>
        <Dialog fullWidth={true} maxWidth={'md'} open={open_supplier_modal} onClose={() => setOpen_supplier_modal(false)} aria-labelledby="form-dialog-title" >
            <DialogTitle id="form-dialog-title">Liste des fournisseurs</DialogTitle>
            <DialogContent style={{overflow:"hidden"}}>
            {
              suppliers.length!==0
              ?<FormControl className="multi_form_select" >
                  <InputLabel id="mutiple-select-label">Fournisseurs</InputLabel>
                  <Select
                      labelId="mutiple-select-label"
                      multiple
                      value={selected}
                      onChange={handleChange}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                  >
                      <MenuItem
                        value="all"
                        classes={
                          {root: isAllSelected ? classes.selectedAll : ""}
                        }
                        className={existent_suppliers.length!==0 || selected_suppliers.length!==0 ?"disabled_button":''}
                        >
                        <ListItemIcon>
                            <Checkbox
                            classes={{ indeterminate: classes.indeterminateColor }}
                            checked={isAllSelected}
                            indeterminate={
                                selected.length > 0 && selected.length < suppliers.length
                            }
                            />
                        </ListItemIcon>
                        <ListItemText
                            classes={{ primary: classes.selectAllText }}
                            primary="Tout sélectionner"
                        />
                      </MenuItem>
                      {
                        suppliers.map((option) => (
                          <MenuItem key={option.id} value={option.fullName} className={verify_supplier_alreadyExist(selected_suppliers,option.id) || 
                              verify_supplier_alreadyExist(existent_suppliers,option.id)?"disabled_button":''}>
                              <ListItemIcon>
                                <Checkbox checked={selected.indexOf(option.fullName) > -1} />
                              </ListItemIcon>
                              <ListItemText primary={option.fullName+" -- "+option.parentCompany} />
                          </MenuItem>
                        ))
                      }
                  </Select>
                </FormControl>
                :<Loading_animation_view />
            }        
            </DialogContent>
        <DialogActions>
            <div className='dialogBottom'>
                <div>
                    {
                        validated_elem() 
                        ?<></>
                        :<p className={submitted ? "col-md-10 error" : "col-md-10 valid-champ"}>
                                {submitted ? "Sélectionner au moin un fournisseur !" : ""}
                            </p>
                    }
                </div>
              <div className='dialogSuppliersBottom'>
                  <Button variant="contained" className="submit_but cancel_but" startIcon={<CancelIcon className="my_icon" />} onClick={() => setOpen_supplier_modal(false)} >
                      Annuler
                  </Button>
                  <Button variant="contained" className="submit_but valide_but" startIcon={<AddIcon className="my_icon" />} onClick={add_proposal} >
                      Ajouter
                  </Button>
              </div>
            </div>
        </DialogActions>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={waiting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
     
    </>
    )
}

export default Set_supplier
