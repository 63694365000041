import './contracts_list.css';
import React, { useEffect,useState } from 'react';
import { useHistory } from "react-router-dom";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from '@mui/material/Fab';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import Pagination from "react-js-pagination";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';
import { download_contractPDF } from '../../../services/purchase_service'
import { getContrats_by_supplier } from '../../../services/supplier_service'

import Loading_animation_view from '../../loading_animation_view/loading_animation_view'
import Contract_content_view from './view_contract/view_contract';


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

const columns = [
    { id: 'Objet', label: "Objet d'appel", minWidth: 0 },
    { id: 'service', label: "Service", minWidth: 0 },
    { id: 'contract_type', label: "Type de contrat", minWidth: 0 },
    { id: 'contract_subType', label: "Sous types de contrat", minWidth: 0 },
    { id: 'sign_date', label: "Date de signature", minWidth: 0 },
    { id: 'contract_state', label: "Etat de contrat", minWidth: 0 }
  ];
  

 function setStatus(stat){
    return(
      <>
        <Tooltip title="" placement="bottom" >
            <span style={{marginLeft:'2%'}}>
                <Button className={ stat ? "status_success" : "status_waiting"}>
                    { stat ? "signé" : "En cours"}
                </Button>
            </span>
        </Tooltip>
      </>
    );
  }

  function check_contract_state(contract_subTypes){
    let unsigned = false;
    contract_subTypes.map((contract_subType) => {
        if(contract_subType.signed != 1 || contract_subType.signed==null) unsigned = true;
        else return setStatus(1);
    })
    return setStatus(!unsigned);
  }

function Contracts({contracts,view_signed_contracts}){
    
    return(
        <>
        {
            contracts.map((contract,id) => (
                <TableRow key={"contract"+id}>
                    <TableCell align="left">{contract.offer_object}</TableCell>
                    <TableCell align="left">{contract.service_name}</TableCell>
                    <TableCell align="left">{contract.contract_type}</TableCell>
                    <TableCell align="left">
                        <div className='subTypes_container'>
                            {
                               contract.contract_subTypes.map((contract_subType,id2) => (
                                    <div className='subType_container' key={"contract_subType"+id+id2} >
                                        <div className='subType'>
                                            <Tooltip title="Visualiser le contrat" placement="bottom">
                                                <span style={{marginRight:'6px'}}>
                                                    <Fab size="small" color="info" className={contract_subType.signed==1?"":"disabled_button"}
                                                        onClick={() => view_signed_contracts(contract_subType.id_submission,contract.contract_type,contract_subType)}>
                                                        <ContentPasteIcon className="my_icon" />
                                                    </Fab>
                                                </span>
                                            </Tooltip>
                                            {contract_subType.contract_subtype}
                                        </div>
                                        <div className='subType_state'>{setStatus(contract_subType.signed)}</div>
                                    </div>
                               ))
                            }
                        </div>
                    </TableCell>
                    <TableCell align="left">
                        <div className='subTypes_container'>
                            {
                               contract.contract_subTypes.map((contract_subType,id2) => (
                                    <div className='subType_sign_date_container' key={"sign_date"+id+id2} >
                                        { contract_subType.signed
                                                    ? new Date(contract_subType.updated_at).toLocaleString()
                                                    : "Pas encore"
                                        }
                                        <Fab size="small" color="info" style={{width:"0px !important"}}>
                                            <ContentPasteIcon style={{width:"px0 !important"}} />
                                        </Fab>
                                    </div>
                               ))
                            }
                        </div>
                    </TableCell>
                    <TableCell align="left">
                        {check_contract_state(contract.contract_subTypes)}
                    </TableCell>
                </TableRow>                
             ))
        }
        </>
    );
}



let purchase_service=JSON.parse(localStorage.getItem('user-info'))


function Contracts_list() {

  const classes = useStyles();
  let history = useHistory();
  const [url_updated , setUrl_updated] = useState(null);
  const [waiting,setWaiting] = useState(false);

  const [contract_state,setContract_state] = useState("default");
  const [contracts,setContracts]=useState(null);
  const [id_submission,setid_submission] = useState(null);
  const [contract_type,setcontract_type] = useState(null);
  const [contract_subtype,setContract_subtype]=useState("");
  const [view_subType_contract,setView_subType_contract]=useState(null);

  const [currentPage,setCurrentPage]=useState(1);
  const [dataPerPage,setdataPerPage] =useState(5);
  const [data_count,setdata_count]=useState(0);

  useEffect(()=>{
    setContracts(null);
    setdata_count(0);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    async function load_data(){
       if(urlParams.get('contrats')){
            await setContract_state(urlParams.get('contrats'));
        }
        let page = (urlParams.get('page')?urlParams.get('page'):1);
        await setCurrentPage(page);

        let contracts_filter = urlParams.get('contrats')?urlParams.get('contrats'):"recent";
        //console.log(contracts_filter);
        await getContrats_by_supplier(purchase_service.id,contracts_filter,page).then((response)=>{
            //console.log(response.data.data);
            setdata_count(response.data.total)
            setContracts(response.data.data);
        }).catch((error)=>{
            //console.log(error.response);
        }); 
    }
    load_data();

  },[url_updated,history.location]);


  function view_signed_contracts(id_submission,contract_type,contract_subType){
    //console.log(id_submission,contract_subType)
    setContract_subtype(contract_subType);
    setid_submission(id_submission);
    setcontract_type(contract_type);
    const formData = new FormData();
    formData.append('id_submission',id_submission);
    formData.append('contract_id',contract_subType.contract_id);
    setWaiting(true);
    download_contractPDF(formData).then((response_blob) => {
        var file = new Blob([response_blob.data], {type: 'application/pdf'});
        var fileURL = URL.createObjectURL(file);
        setView_subType_contract(fileURL);
        setWaiting(false);
    }).catch(err=>{
      //console.log(err.response);
    });
  }

  function select_contract_state(value){
    setContract_state(value);
    let path = history.location.pathname;
    history.push(path+"?contrats="+value);
    setUrl_updated(value);
  }


  
  async function change_page(page){
    await setCurrentPage(page);
    let path = history.location.pathname;
    let params = history.location.search;
    let search_params = params.split('&');
    let tmp_params = path;
    if(!params) history.push(path+"?page="+page);
    else{
      for(let i in search_params){
        if(search_params[i].includes("page=")){
          if(i==0) tmp_params=tmp_params+"?page="+page;
          else tmp_params=tmp_params+"&page="+page;
        }else{
          if(i==0) tmp_params=tmp_params+search_params[i];
          else tmp_params=tmp_params+"&"+search_params[i];
        }
      }
      if(!tmp_params.includes("page=")) tmp_params=tmp_params+"&page="+page;
      history.push(tmp_params);
    }
    await setUrl_updated(page);
  }



  return (
    <>
    {
        contracts==null
        ?<Loading_animation_view />
        :<div className='contracts_list_cadmin_container'>
            <div className='three_forms_header_container'>
                    <div className='page_title'>Contrats</div>
                    <div className='select_container'>
                    <FormControl className="header_form" >
                            <InputLabel>Etat de contrat</InputLabel>
                                <Select required
                                    value={contract_state}
                                    onChange={e => {
                                        select_contract_state(e.target.value);
                                    }} name="Etat" >
                                    <MenuItem value={"default"} disabled>
                                        Etat de contrat
                                    </MenuItem>
                                    <MenuItem value={"recent"}>Récent</MenuItem>
                                    <MenuItem value={"old"}>Ancien</MenuItem>
                                    <MenuItem value={"signed"}>Signé</MenuItem>
                                    <MenuItem value={"in_progress"}>En cours de traitement</MenuItem>
                                </Select>
                        </FormControl>
                    </div>
            </div>
                    <TableContainer className="contracts_list_table" >
                            <Table className={classes.table} aria-label="sticky table">
                                <TableHead>
                                <TableRow>
                                    {
                                        columns.map((column) => (
                                            <TableCell key={column.id} align="left">{column.label}</TableCell>
                                        ))
                                    }
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    contracts.length!==0
                                    ?
                                    <>
                                        <Contracts contracts={contracts} view_signed_contracts={view_signed_contracts} />
                                    </>
                                            
                                    :<div style={{margin:"10px",fontSize:".75rem",width:'100%', textAlign:"center",alignContent:"center"}}>Aucun contrat disponible</div>
                                }
                                </TableBody> 
                            </Table>
                        </TableContainer>
                    
                        {
                            contracts.length!==0
                                ?<div className="pagination_container">
                                    <Pagination
                                        activePage={Number(currentPage)}
                                        itemsCountPerPage={dataPerPage}
                                        totalItemsCount={Number(data_count)}
                                        pageRangeDisplayed={4}
                                        onChange={change_page.bind(this)}
                                    />
                                </div>
                                :<></>
                            }
                    {
                        view_subType_contract!=null
                        ?<Contract_content_view view_subType_contract={view_subType_contract} close_view={() => setView_subType_contract(null)} 
                            contract_subtype={contract_subtype} id_submission={id_submission} contract_type={contract_type} />
                        :<></>
                    }
            </div>
        }
        <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waiting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
    </>
  );
}

export default Contracts_list;
