import './charts_of_the_bestOffers.css'
import React from 'react';
import StarIcon from '@mui/icons-material/Star';

const Charts_of_the_bestOffers =  ({data}) => {

  return (
    <div className='charts_of_the_bestOffers_container'>
     <div className='container'>
        <div className="categorie_container">
            <div className="order_of_the_bestOffers" style={{backgroundColor:"rgb(243 184 62)"}} ><span><StarIcon className="my_icon" /></span></div>
            <div className="amount_of_the_bestOffers" style={{backgroundColor: "#b8c6db",backgroundImage: "linear-gradient(220deg, rgb(243 184 62) 0%, rgb(246 222 172) 74%)"}}>{data[0]+" MAD"}</div>
        </div>
        <div className="categorie_container">
            <div className="order_of_the_bestOffers" style={{backgroundColor:"#b8c6db"}} ><span><StarIcon className="my_icon" /></span></div>
            <div className="amount_of_the_bestOffers" style={{backgroundColor: "#b8c6db",backgroundImage: "linear-gradient(220deg, #b8c6db 0%, #eff7fa 74%)"}}>{data[1]+" MAD"}</div>
        </div>
        <div className="categorie_container">
            <div className="order_of_the_bestOffers" style={{backgroundColor:"#CD7F32"}} ><span><StarIcon className="my_icon" /></span></div>
            <div className="amount_of_the_bestOffers" style={{backgroundColor: "#b8c6db",backgroundImage: "linear-gradient(220deg, #CD7F32 0%, rgb(246 211 177) 74%)"}}>{data[2]+" MAD"}</div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(Charts_of_the_bestOffers)