import './color_palette_modal.css'
import React, { useEffect, useState} from 'react'
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import Button from '@material-ui/core/Button';
import swal from 'sweetalert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { update_company_admin_profil } from '../../../../services/company_admin_service';


const useStyles = makeStyles((theme) => ({
    button: { margin: theme.spacing(1),},
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    }
  }));

function Color_palette_modal({color_palette_modal,setcolor_palette_modal,nav_color,setNav_color,menu_color,setMenu_color}){

    let user = JSON.parse(localStorage.getItem('user-info'));

    const classes = useStyles();

    const [color, setColor] = useColor("hex", nav_color);
    const [menuCl, setmenuCl] = useColor("hex", menu_color);
    const [waiting,setWaiting] = useState(false)

    function set_nav_color(clr){
        setColor(clr)
        setNav_color(clr.hex)
    }
    function set_menu_color(clr){
      setmenuCl(clr)
      setMenu_color(clr.hex)
    }

    function submit(){
        setWaiting(true);
        let formData = new FormData();
        formData.append('id_login',user.id);
        formData.append('company_color',nav_color+","+menu_color);
        update_company_admin_profil(formData).then((response) => {
            setWaiting(false);
            let admin = localStorage.getItem('user-info');
            let admin_tmp = JSON.parse(admin);
            admin_tmp['company_color'] = nav_color+","+menu_color;
            localStorage.setItem('user-info',JSON.stringify(admin_tmp));
            swal("la couleur a été définie avec succès !", {
                icon: "success",
                buttons: true,
                }).then((willDelete) => {
                    if (willDelete) {
                        setcolor_palette_modal(false);
                    }
                })
        }).catch((error) => {
            //console.log(error.response);
            setWaiting(false);
        });
    }

    return (
      <Dialog fullWidth={true} maxWidth={'md'} open={color_palette_modal} onClose={()=>setcolor_palette_modal(false)} className='color_palette_modal' aria-labelledby="form-dialog-title" >
        <DialogTitle id="form-dialog-title">Définissez votre propre couleur de menu</DialogTitle>
        <DialogContent>
          <div className='color_palette_container'>
            <ColorPicker width={400} height={160} color={color} onChange={set_nav_color} hideHSV hideRGB dark />
            <ColorPicker width={400} height={160} color={menuCl} onChange={set_menu_color} hideHSV hideRGB dark />
          </div>
        </DialogContent>
        <DialogActions>
          <div className='dialogBottom'>
            <div>
                <Button variant="contained" className="submit_but cancel_but" startIcon={<CancelIcon />} onClick={()=>setcolor_palette_modal(false)} >
                    Annuler
                </Button>
                <Button variant="contained" className="submit_but valide_but" startIcon={<SaveIcon />} onClick={submit} >
                    Sauvegarder
                </Button>  
            </div>
               
          </div>
        </DialogActions>

        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={waiting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>

      </Dialog>
    )
}

export default Color_palette_modal
