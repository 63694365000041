import React, { useState } from 'react';
import { Route, Redirect, BrowserRouter as Router} from 'react-router-dom';
import { Switch } from 'react-router';
import './App.css'
import './assets/css/now-ui-kit.css'
import './assets/css/responsive_app.css'
import Authentication_page from './components/authentication_page/authentication_page';
import Save_email_awnser from './components/save_email_awnser_view/save_email_awnser_view'


import NavVertical_business from './components/business_service/navVertical/navVertical';
import Widget_contents_container from './components/business_service/app_widget/widget_contents_container'
import Service_profil from './components/service_profil/service_profil';
import Bills_business from './components/business_service/bills/bills';
import Add_purchase_request from './components/business_service/add_purchase_request/add_purchase_request';
import Product_details from './components/business_service/add_purchase_request/products_gallery/product_details/product_details';
import Card_products from './components/business_service/card_products/card_products';
import Applicant_requests_business from './components/business_service/applicant_requests/applicant_requests';

import Emails_sent from './components/emails_sent/emails_sent';

import NavVertical_purchase from './components/purchase_service/navVertical/navVertical';
import Offers_calls from './components/purchase_service/offers_calls/offers_calls'
import Offers_requests_view from './components/purchase_service/offers_requests/offers_requests_view'
import Litigations from './components/purchase_service/litigations/litigations';
import Bills_purchase from './components/purchase_service/bills/bills';
import Suppliers_references from './components/purchase_service/suppliers_references/suppliers_references';
import Suppliers from './components/purchase_service/suppliers/suppliers';
import CreateSupplier from './components/purchase_service/suppliers/createSupplier/createSupplier';
import Commissions from './components/purchase_service/commissions/commissions';
import Contracts_list from './components/purchase_service/contracts_list/contracts_list'
import Widget_contents_container_purchase from './components/purchase_service/app_widget/widget_contents_container';
import Applicant_requests_purchase from './components/purchase_service/applicant_requests/applicant_requests';
import Add_purchase_request_purchaseServ from './components/purchase_service/add_purchase_request/add_purchase_request';
import Product_details_purchaseServ from './components/purchase_service/add_purchase_request/products_gallery/product_details/product_details';
import Card_products_purchaseServ from './components/purchase_service/card_products/card_products';

import NavVertical_supplier from './components/supplier/navVertical/navVertical';
import Widget_contents_container_supplier from './components/supplier/app_widget/widget_contents_container'
import Supplier_profil from './components/supplier/supplier_profil/supplier_profil'
import My_submissions from './components/supplier/offers_requests/offers_requests'
import Offers_calls_supplier from './components/supplier/offers_calls/offers_calls'
import Bills_supplier from './components/supplier/bills/bills'
import Supplier_products from './components/supplier/supplier_products/supplier_products'
import Supplier_reference from './components/supplier/supplier_reference/supplier_reference'
import Contracts_list_supplier from './components/supplier/contracts_list/contracts_list'
import Applicant_requests_supplier from './components/supplier/applicant_requests/applicant_requests'
import Product_details_supplier from './components/supplier/supplier_products/product_details/product_details';
import Import_files_page from './components/supplier/import_files_page/import_files_page';


import NavVertical_finance from './components/finance_service/navVertical/navVertical';
import Widget_contents_container_finance from './components/finance_service/app_widget/widget_contents_container'
import Bills_finance from './components/finance_service/bills/bills';

import Auditor_profil from './components/auditor/auditor_profil/auditor_profil';
import NavVertical_auditor from './components/auditor/navVertical/navVertical';
import Auditor_commissions from './components/auditor/auditor_commissions/auditor_commissions';
import Widget_contents_container_auditor from './components/auditor/app_widget/widget_contents_container'


import Add_company from './components/global_admin/add_company/add_company';
import Companies_list from './components/global_admin/companies_list/companies_list';
import NavVertical_gadmin from './components/global_admin/navVertical/navVertical';

import Add_auditor from './components/company_admin/add_auditor/add_auditor';
import Add_services from './components/company_admin/add_services/add_services';
import Categories_list from './components/company_admin/categories_list/categories_list';
import Company_admin_profil from './components/company_admin/company_admin_profil/company_admin_profil';
import NavVertical_cadmin from './components/company_admin/navVertical/navVertical';
import Services_list from './components/company_admin/services_list/services_list';
import Auditors_list from './components/company_admin/auditors_list/auditors_list';
import Contracts_list_cadmin from './components/company_admin/contracts_list/contracts_list'
import Worflows_creator from './components/company_admin/worflows_creator/worflows_creator';
import Docs_list from './components/company_admin/docs_list/docs_list';


function App() {

  const [user , setUser] = useState(JSON.parse(localStorage.getItem('user-info')));
  const [admin , setAdmin] = useState(JSON.parse(localStorage.getItem('admin-info')));


  window.addEventListener("storage",(e) => {
    setUser(JSON.parse(localStorage.getItem('user-info')));
    setAdmin(JSON.parse(localStorage.getItem('admin-info')));
  });

  return (
    <Router>
     <Switch>
          <Route exact path="/">
            {
              user || admin ?
                user?
                  user.role===0?<Redirect to="/fournisseur" />
                  :user.role===1?<Redirect to="/serviceAchat" />
                  :user.role===2?<Redirect to="/serviceMetier" />
                  :user.role===3?<Redirect to="/serviceFinance" />
                  :user.role===4?<Redirect to="/auditeur" />
                  :user.role===10?<Redirect to="/eadmin" />
                  :<Authentication_page />
                :<Redirect to="/gadmin" />
              :<Authentication_page />
          }
          </Route>
          <>
            {
              (user && user.role===0)?
              <Switch>
                      <Route path="/fournisseur" exact>
                        <NavVertical_supplier Component={Supplier_reference} />
                      </Route>
          
                      <Route path="/fournisseur/Fiche_Identite" >
                        <NavVertical_supplier Component={Supplier_profil} />
                      </Route>
          
                      <Route path="/fournisseur/TableauDeBord" >
                        <NavVertical_supplier Component={Widget_contents_container_supplier} />
                      </Route>
          
                      <Route path="/fournisseur/Mon_referencement" >
                        <NavVertical_supplier Component={Supplier_reference} />
                      </Route>
          
                      <Route path="/fournisseur/appelsOffres" >
                        <NavVertical_supplier Component={Offers_calls_supplier} />
                      </Route>
          
                      <Route path="/fournisseur/contrats" >
                        <NavVertical_supplier Component={Contracts_list_supplier} />
                      </Route>
          
                      <Route path="/fournisseur/my_submissions" >
                        <NavVertical_supplier Component={My_submissions} />
                      </Route>
          
                      <Route path="/fournisseur/facture" >
                        <NavVertical_supplier Component={Bills_supplier} />
                      </Route>
          
                      <Route path="/fournisseur/mes_produits" >
                        <NavVertical_supplier Component={Supplier_products} />
                      </Route>
          
                      <Route path="/fournisseur/produit/:product_code" >
                        <NavVertical_supplier Component={Product_details_supplier} />
                      </Route>
          
                      <Route path="/fournisseur/importation_fichiers" >
                        <NavVertical_supplier Component={Import_files_page} />
                      </Route>
          
                      <Route path="/fournisseur/commandes" >
                        <NavVertical_supplier Component={Applicant_requests_supplier} />
                      </Route>

                      <Route path="/fournisseur/envoyer_email" >
                        <NavVertical_supplier Component={Emails_sent} />
                      </Route>  

                      <Route path="/confirmation_reponse*" component={Save_email_awnser} exact/>

                      <Route path="/*" >
                          <Redirect to="/fournisseur" />
                      </Route>
                </Switch>
            :(user && user.role===1)?<Switch>
                <Route path="/serviceAchat" exact>
                  <NavVertical_purchase Component={Widget_contents_container_purchase} />
                </Route>

                <Route path="/serviceAchat/Fiche_Identite" >
                  <NavVertical_purchase Component={Service_profil} />
                </Route>

                <Route path="/serviceAchat/TableauDeBord" >
                  <NavVertical_purchase Component={Widget_contents_container_purchase} />
                </Route>

                <Route path="/serviceAchat/Referencements" >
                  <NavVertical_purchase Component={Suppliers_references} />
                </Route>

                <Route path="/serviceAchat/AjouterFournisseur" >
                  <NavVertical_purchase Component={CreateSupplier} />
                </Route>

                <Route path="/serviceAchat/suppliers" >
                  <NavVertical_purchase Component={Suppliers} />
                </Route>

                <Route path="/serviceAchat/mesAppelsOffres" >
                  <NavVertical_purchase Component={Offers_calls} />
                </Route>

                <Route path="/serviceAchat/soumissions" >
                  <NavVertical_purchase Component={Offers_requests_view} />
                </Route>

                <Route path="/serviceAchat/commissions" >
                  <NavVertical_purchase Component={Commissions} />
                </Route>

                <Route path="/serviceAchat/contrats" >
                  <NavVertical_purchase Component={Contracts_list} />
                </Route>

                <Route path="/serviceAchat/litiges" >
                  <NavVertical_purchase Component={Litigations} />
                </Route>

                <Route path="/serviceAchat/facture" >
                  <NavVertical_purchase Component={Bills_purchase} />
                </Route>

                <Route path="/serviceAchat/demande_achat" exact>
                  <NavVertical_purchase Component={Add_purchase_request_purchaseServ} />
                </Route>

                <Route path="/serviceAchat/demande_achat/:type" >
                  <NavVertical_purchase Component={Add_purchase_request_purchaseServ} />
                </Route>

                <Route path="/serviceAchat/produit/:product_code" >
                  <NavVertical_purchase Component={Product_details_purchaseServ} />
                </Route>

                <Route path="/serviceAchat/panier" >
                  <NavVertical_purchase Component={Card_products_purchaseServ} />
                </Route>

                <Route path="/serviceAchat/demandes" >
                  <NavVertical_purchase Component={Applicant_requests_purchase} />
                </Route> 
                
                <Route path="/serviceAchat/envoyer_email" >
                  <NavVertical_purchase Component={Emails_sent} />
                </Route>  

                <Route path="/confirmation_reponse*" component={Save_email_awnser} exact/>
                <Route path="/*" >
                    <Redirect to="/serviceAchat" />
                </Route>
                </Switch>
              :(user && user.role===2)?
              <Switch>
                <Route path="/serviceMetier" exact>
                  <NavVertical_business Component={Widget_contents_container} />
                </Route>

                <Route path="/serviceMetier/Fiche_identite" >
                  <NavVertical_business Component={Service_profil} />
                </Route>

                <Route path="/serviceMetier/TableauDeBord" >
                  <NavVertical_business Component={Widget_contents_container} />
                </Route>

                <Route path="/serviceMetier/demande_achat" exact>
                  <NavVertical_business Component={Add_purchase_request} />
                </Route>

                <Route path="/serviceMetier/demande_achat/:type" >
                  <NavVertical_business Component={Add_purchase_request} />
                </Route>

                <Route path="/serviceMetier/produit/:product_code" >
                  <NavVertical_business Component={Product_details} />
                </Route>

                <Route path="/serviceMetier/demandes" >
                      <NavVertical_business Component={Applicant_requests_business} />
                </Route>

                <Route path="/serviceMetier/panier" >
                  <NavVertical_business Component={Card_products} />
                </Route>

                <Route path="/serviceMetier/facture" >
                  <NavVertical_business Component={Bills_business} />
                </Route> 

                <Route path="/serviceMetier/envoyer_email" >
                        <NavVertical_business Component={Emails_sent} />
                      </Route>  

                <Route path="/confirmation_reponse*" component={Save_email_awnser} exact/>

                <Route path="/*" >
                    <Redirect to="/serviceMetier" />
                </Route>
              </Switch>
              :(user && user.role===3)?    
              <Switch>
                    <Route path="/serviceFinance" exact>
                      <NavVertical_finance Component={Widget_contents_container_finance} />
                    </Route>

                    <Route path="/finance_service/Fiche_Identite" >
                      <NavVertical_finance Component={Service_profil} />
                    </Route>

                    <Route path="/finance_service/TableauDeBord" >
                      <NavVertical_finance Component={Widget_contents_container_finance} />
                    </Route>

                    <Route path="/finance_service/facture" >
                      <NavVertical_finance Component={Bills_finance} />
                    </Route>

                    <Route path="/finance_service/envoyer_email" >
                        <NavVertical_finance Component={Emails_sent} />
                      </Route>  

                    <Route path="/confirmation_reponse*" component={Save_email_awnser} exact/>

                    <Route path="/*" >
                        <Redirect to="/serviceFinance" />
                    </Route>
                  </Switch>
              :(user && user.role===4)?
                  <Switch>
                    <Route path="/auditeur" exact>
                      <NavVertical_auditor Component={Widget_contents_container_auditor} />
                    </Route>
    
                    <Route path="/auditeur/fiche_identite" >
                      <NavVertical_auditor Component={Auditor_profil} />
                    </Route>
    
                    <Route path="/auditeur/TableauDeBord" >
                      <NavVertical_auditor Component={Widget_contents_container_auditor} />
                    </Route>
    
                    <Route path="/auditeur/mes_commissions" >
                      <NavVertical_auditor Component={Auditor_commissions} />
                    </Route>

                    <Route path="/auditeur/envoyer_email" >
                        <NavVertical_auditor Component={Emails_sent} />
                      </Route>  
                    <Route path="/confirmation_reponse*" component={Save_email_awnser} exact/>
                    <Route path="/*" >
                        <Redirect to="/auditeur" />
                    </Route>
                  </Switch>
                :admin?
              <Switch>
                  <Route path="/gadmin" exact>
                      <NavVertical_gadmin Component={Companies_list} />
                  </Route>
                  <Route path="/gadmin/entreprises" exact>
                      <NavVertical_gadmin Component={Companies_list} />
                  </Route>
                  <Route path="/gadmin/nv_entreprise">
                      <NavVertical_gadmin Component={Add_company} />
                  </Route>
                  <Route path="/confirmation_reponse*" component={Save_email_awnser} exact/>
                  <Route path="/*" >
                      <Redirect to="/gadmin" />
                  </Route>
              </Switch>
            :(user && user.role===10)?
              <Switch>
                    <Route path="/eadmin" exact>
                        <NavVertical_cadmin Component={Company_admin_profil} />
                    </Route>
                     <Route path="/eadmin/fiche_identité" >
                      <NavVertical_cadmin Component={Company_admin_profil} />
                    </Route>
                    <Route path="/eadmin/ajouter_service" >
                      <NavVertical_cadmin Component={Add_services} />
                    </Route>
                    <Route path="/eadmin/ajouter_commissaire" >
                      <NavVertical_cadmin Component={Add_auditor} />
                    </Route>
                    <Route path="/eadmin/services" >
                      <NavVertical_cadmin Component={Services_list} />
                    </Route>
                    <Route path="/eadmin/commissaires" >
                      <NavVertical_cadmin Component={Auditors_list} />
                    </Route>
                    <Route path="/eadmin/famille_achat" >
                      <NavVertical_cadmin Component={Categories_list} />
                    </Route>
                    <Route path="/eadmin/contrats" >
                      <NavVertical_cadmin Component={Contracts_list_cadmin} />
                    </Route>
                    <Route path="/eadmin/workflows" >
                      <NavVertical_cadmin Component={Worflows_creator} />
                    </Route>
                    <Route path="/eadmin/documents" >
                        <NavVertical_cadmin Component={Docs_list} />
                      </Route> 
                    <Route path="/eadmin/envoyer_email" >
                        <NavVertical_cadmin Component={Emails_sent} />
                      </Route>  
                    <Route path="/confirmation_reponse*" component={Save_email_awnser} exact/>
                    <Route path="/*" >
                        <Redirect to="/eadmin" />
                    </Route>
              </Switch>
            :<Switch>
              <Route path="/confirmation_reponse*" component={Save_email_awnser} exact/>
              <Route path="/*">
                  <Redirect to="/" />
              </Route>
            </Switch>
          }
        </>
     </Switch>
    </Router>
  );
}
export default App;
