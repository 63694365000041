import React, { useState,useEffect, useMemo } from 'react';
import './createSupplier.css';
import { Row,InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {create_supplier, get_categories} from '../../../../services/purchase_service';
import Button from '@material-ui/core/Button';
import CircularProgress from '@mui/material/CircularProgress';
import swal from 'sweetalert';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Select from '@material-ui/core/Select';
import Input from "@material-ui/core/Input";
import Loading_animation_view from '../../../loading_animation_view/loading_animation_view';

import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    indeterminateColor: {
      color: "#f50057"
    },
    selectAllText: {
      fontWeight: 500
    },
    selectedAll: {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)"
      }
    },
    input: {
        display: 'none',
      },
      container: {
        maxHeight: 440,
      },
      select: {
        "&:focus": {
          backgroundColor: "white",
          borderBottomWidth: 0
        }
      },
      selectInput: {
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottomWidth: 0
        },
        "&:focus": {
            backgroundColor: "white",
            borderBottomWidth: 0
          },
        "&:not($disabled):not($focused):not($error):before": {
          borderBottomWidth: 0,
          borderWidth:1
        }
      },
      disabled: {},
      focused: {},
      error: {}
  }));
  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center"
    },
    variant: "menu"
  };
  


function Create_Supplier(){
    const classes = useStyles();
    const selectInputClasses = {
        root: classes.selectInput,
        disabled: classes.disabled,
        focused: classes.focused,
        error: classes.error
      };

    let user=JSON.parse(localStorage.getItem('user-info'))
    const id_service=user.id;

    //////form inputs
    const [parentCompany,setparentCompany]=useState('');
    const [firstName,setfirstName]=useState('');
    const [lastName,setlastName]=useState('');
    const [email,setEmail]=useState('');

    const [categories,setcategories] = useState([]);

    const [wait_submitting,setWait_submitting] = useState(false);
    const [errorText,setErrorText] = useState(null);
    const [errorEmail,setErrorEmail] = useState(null);

    const [selected, setSelected] = useState([]);
    const [selectedId, setSelectedId] = useState([]);


    useEffect(()=>{
        get_categories().then((response)=>{
            let tmp_categories = response.data;
            setcategories(tmp_categories);
            let tmp_array = [];
            let tmp_id_array = [];
            tmp_categories.map((categorie) => {
                tmp_array.push(categorie.name);
                tmp_id_array.push(categorie.id_category);
            })
            setSelected(selected.length === tmp_categories.length ? [] : tmp_array);
            setSelectedId(selected.length === tmp_categories.length ? [] : tmp_id_array);
        }).catch((error)=>{
            //console.log(error);
        })
    },[])

//////////////////////////////
/////////////email validation
    function invalid_email_pattern(email){
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if(email!==""){
            if (!pattern.test(email)) {
                return true ;
              }else{
                return false ;
              }
        }else return true;
    }

////////////////////////////

/////////////////////////////
/////////////////////////////Submit
    function submit() {
        setErrorText(null);
        setErrorEmail(null);
        let tmp_array = [];
                    let tmp_id_array = [];
                    categories.map((categorie) => {
                        tmp_array.push(categorie.name);
                        tmp_id_array.push(categorie.id_category);
                    })
                    setSelected(tmp_array);
                    setSelectedId(tmp_id_array);
        if(email==="" || firstName==="" || lastName==="" || parentCompany==="" || selectedId.length===0){
            setErrorText("Champs invalid !");
        }else{
            if(invalid_email_pattern(email)){
                setErrorEmail("Email invalid !");
            }else{
                swal({
                    title: "Etes-vous sûr ?",
                    text: "!!!!",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                .then((willDelete) => {
                  if (willDelete) {
                    setWait_submitting(true);
                    let formData = new FormData();
                    formData.append('parentCompany',parentCompany);
                    formData.append('firstName',firstName);
                    formData.append('lastName',lastName);
                    formData.append('email',email);
                    formData.append('purchase_family',selectedId);
                    formData.append('id_service',id_service);
                    create_supplier(formData).then((response) => {
                        setWait_submitting(false);
                        setparentCompany('');
                        setfirstName('');
                        setlastName('');
                        setEmail('');
                        let tmp_array = [];
                        let tmp_id_array = [];
                        categories.map((categorie) => {
                            tmp_array.push(categorie.name);
                            tmp_id_array.push(categorie.id_category);
                        })
                        setSelected(tmp_array);
                        setSelectedId(tmp_id_array);
                        swal("Le fournisseur a été ajoutée avec succès !", {
                            icon: "success",
                            buttons: true,
                        }).then((willDelete) => {
                            if (willDelete) {
                        }})
                        setWait_submitting(false);
                    }).catch((error) => {
                        //console.log(error.response);
                        //setErrorText(error.response.data);
                        setWait_submitting(false);
                    });
                }})
            }
        }
        
    }


    const isAllSelected = useMemo(() => categories.length > 0 && selected.length === categories.length,[categories,selected]);

    const handleChange = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
        let tmp_array = [];
        let tmp_id_array = [];
        categories.map((supplier) => {
            tmp_array.push(supplier.name);
            tmp_id_array.push(supplier.id_category);
        })
        setSelected(selected.length === categories.length ? [] : tmp_array);
        setSelectedId(selected.length === categories.length ? [] : tmp_id_array);
        return;
        }
        setSelected(value);
        setSelectedId(categorie_id_by_name(value));
    };


    function categorie_id_by_name(names){
        let id=[];
        categories.map((categorie) => {
          names.map((name) => {
            if(categorie.name === name) id.push(categorie.id_category);
          });
            
        });
        return id;
      }

///////////////////////////////////////



/////////////////////////////////////////////////

    return (
    <>
    {
        categories.length===0
        ?<Loading_animation_view />
        :<div className="create-supplier_form">
        <h2>Nouveau Fournisseur</h2>
        <div className="form">
            <Row className="mb-12">
                {
                    <p className={errorText || errorEmail ? "col-md-12 error" : "col-md-12 valid-champ"}>
                            {errorText || errorEmail}
                    </p>
                }
                <Form.Group className="col-md-12">
                    <label>Maison mère</label>
                    <Form.Control
                        required
                        autoComplete="new-password"
                        type="text"
                        placeholder="Maison mère *"
                        value={parentCompany}
                        onChange={ e =>  {setparentCompany(e.target.value)}}
                        className={ errorText && parentCompany==="" ? "is-invalid":""}
                    />
                </Form.Group>
                <Form.Group className="col-md-12">
                    <label>Nom</label>
                    <Form.Control
                        required
                        autoComplete="new-password"
                        type="text"
                        placeholder="Nom *"
                        value={firstName}
                        onChange={ e =>  {setfirstName(e.target.value)}}
                        className={ errorText && firstName==="" ? "is-invalid":""}
                    />
                </Form.Group>
                <Form.Group className="col-md-12">
                    <label>Prénom</label>
                    <Form.Control
                        required
                        autoComplete="new-password"
                        type="text"
                        placeholder="Prénom *"
                        value={lastName}
                        onChange={ e =>  {setlastName(e.target.value)}}
                        className={ errorText && lastName===""  ? "is-invalid":""}
                    />           
                </Form.Group>
                <Form.Group className="col-md-12">
                    <label>Email</label>
                    <InputGroup hasValidation>
                        <Form.Control
                            required
                            autoComplete="new-password"
                            type="text"
                            placeholder="E-mail *"
                            value={email}
                            onChange={ e =>  {setEmail(e.target.value);}}
                            className={ errorText || errorEmail  ? "is-invalid":""}
                        />
                        <InputGroup.Text  className={ errorText || errorEmail ? "invalid-inputgroup" : "valid-inputgroup"}> 
                            <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>        
                   </InputGroup>
                </Form.Group>

                <Form.Group className="col-md-12">
                    <label>Famille d'achat</label>
                    <Select
                        required
                        labelId="mutiple-select-label"
                        multiple
                        value={selected}
                        onChange={handleChange}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                        className={ errorText && selectedId.length===0 ? "form-control form-select is-invalid form-select-control":"form-control form-select form-select-control"}
                    >
                    <MenuItem
                      value="all"
                      classes={{
                          root: isAllSelected ? classes.selectedAll : ""
                      }}
                      >
                      <ListItemIcon>
                          <Checkbox
                          classes={{ indeterminate: classes.indeterminateColor }}
                          checked={isAllSelected}
                          indeterminate={
                              selected.length > 0 && selected.length < categories.length
                          }
                          />
                      </ListItemIcon>
                      <ListItemText
                          classes={{ primary: classes.selectAllText }}
                          primary="Tout sélectionner"
                      />
                    </MenuItem>
                    {
                      categories.map((option) => (
                        <MenuItem key={option.name} value={option.name}>
                            <ListItemIcon>
                              <Checkbox checked={selected.indexOf(option.name) > -1} />
                            </ListItemIcon>
                            <ListItemText primary={option.name} />
                        </MenuItem>
                      ))
                    }
                </Select>        
            </Form.Group>

            </Row> 
            <Row style={{display:'flex',justifyContent:"center"}}>
                <Button variant="contained" className={wait_submitting ? "col-md-8 submit_but disabled_button":"col-md-8 submit_but"} onClick={submit}>
                            {wait_submitting?<CircularProgress color="inherit" className="my_icon" />:"Ajouter"}
                </Button>
            </Row>
            
        </div>

      </div>
    }
    </>
    );
    
}

export default Create_Supplier



/*<Form.Select
                        required
                        value={categorie}
                        onChange={e => {
                            setcategorie(e.target.value);
                        }}
                        className={ errorText && categorie==="null" ? "is-invalid":""}
                        name="categorie"
                    >
                        <option value={"null"} disabled selected>Famille d'Achat</option>
                        {
                            categories.map( (categorie) => (
                                <option key={categorie.id_category} value={categorie.id_category}>{categorie.name}</option>
                            ) )
                        }
                    </Form.Select>*/