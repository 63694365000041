import './offers_requests.css';

import React, { useState , useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Fab from '@mui/material/Fab';
import DownloadIcon from '@mui/icons-material/Download';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import Pagination from "react-js-pagination";


import { update_supplier_offer_request_docs,supplier_submissions,getSupplier_categories } from '../../../services/supplier_service';
import { contract_check, download_complement_docs, download_offer_files } from '../../../services/purchase_service';
import { download_offer_submission_docs } from '../../../services/purchase_service';

import Contract_content_view from './setContract_forRequest/contract_content_view'
import Loading_animation_view from '../../loading_animation_view/loading_animation_view'

let user = JSON.parse(localStorage.getItem('user-info'));


function etat(stat){
  return(
    <>
      {
       stat == 1
       ?<Button variant="contained" className="status_success">{'Accepté'}</Button>
       :stat == 0
          ?<Button variant="contained" className="status_deny">{'Refusé'}</Button>
          :<Button variant="contained" className="status_waiting">{'En cours'}</Button>
      }
    </>
  );
}


function etatDoc(doc,etat){
  var v_etat;
  if(etat===0)v_etat='Refusé'
  else if(etat===1)v_etat='Accepté'
  else v_etat='En Cours'
  return v_etat;
}


function setStatus(stat){
  return(
    <>
      {
       stat == 'En Cours'
       ?<Button variant="contained" className="status_waiting">{'En cours'}</Button>
       :stat == 'Refusé'
          ?<Button variant="contained" className="status_deny">{'Refusé'}</Button>
          :<Button variant="contained" className="status_success">{'Accepté'}</Button>
      }
    </>
  );
}

function CompareFinalDate_current(finalDate){
    let day = new Date(finalDate);
    let current_date = new Date();
    if(day.getTime() < current_date.getTime()) return false;
    else return true;
}



const headCells = [
  { id: 'objet', numeric: false, disablePadding: false, label: 'Objet d\'achat' },
  { id: 'financial_score', numeric: true, disablePadding: false, label: 'Score financier' },
  { id: 'technical_score', numeric: true, disablePadding: false, label: 'Score technique' },
  { id: 'dateDemande', numeric: true, disablePadding: false, label: 'Date de soumission' },
  { id: 'docs', numeric: true, disablePadding: false, label: 'Documents' },
  { id: 'complement', numeric: true, disablePadding: false, label: 'Complément des dossiers' },
  { id: 'pv', numeric: true, disablePadding: false, label: 'PV' },
  { id: 'accord', numeric: true, disablePadding: false, label: 'Accord' }
];



const useStyles = makeStyles((theme) => ({
    button: { margin: theme.spacing(1),},
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
  }));

  

function Offers_requests() {


  let supplier = JSON.parse(localStorage.getItem('user-info'));
  let history = useHistory();
  const [url_updated , setUrl_updated] = useState(null);

  const classes = useStyles();

  const [submissions,setsubmissions]=useState(null);
  const [chosenSubmission,setchosenSubmission]=useState(null);
  const [setContract, setSetContract] = useState(false);
  
  const [categories,setCategories]=useState([]);

  const [filter_categorie,setFilter_categorie]=useState("all");
  const [search_value,setsearch_value]=useState("");
  const [search_filter,setsearch_filter]=useState('all');

  const [waiting, setWaiting] = useState(false);

  const [currentPage,setCurrentPage]=useState(1);
  const [dataPerPage,setdataPerPage] =useState(5);
  const [data_count,setdata_count]=useState(0);

  function handleCloseSetContrat(){
    setSetContract(false);
    setchosenSubmission(null); 
  }

  function openContractView(submission){
    setSetContract(true);
    setchosenSubmission(submission);
  }

    useEffect(()=>{

      setsubmissions(null);
      setdata_count(0);
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      var tmp_searchFilter ="all";
      var tmp_searchValue = null;
      var tmp_filterCategorie = "all";

      async function load_data(){
        if(urlParams.get('filtre') && urlParams.get('valeur')){
          tmp_searchFilter = urlParams.get('filtre');
          tmp_searchValue = urlParams.get('valeur');
        }if(urlParams.get('categorie')){
          tmp_filterCategorie = urlParams.get('categorie');
        }

        await setsearch_filter(tmp_searchFilter);
        await setsearch_value(tmp_searchValue);
        await setFilter_categorie(tmp_filterCategorie);

        let page = (urlParams.get('page')?urlParams.get('page'):1);
        await setCurrentPage(page)

        await getSupplier_categories(user.id).then((response)=>{
            setCategories(response.data);
        }).catch((error)=>{
          //console.log(error.response);
        })

        const values = await supplier_submissions(user.id,tmp_filterCategorie,tmp_searchFilter,tmp_searchValue,page)
          .then((response)=> {
            setdata_count(response.data.total);
            return (response.data.data)
          });

          let tmp_array = [];
          for(let i in values){
            let sub = values[i];
            await contract_check(sub.id_submission,"all").then((response)=>{
              if(response.data!==""){
                let rep = response.data;
                sub.exist = true;
                sub.contract_type = (Object.keys(rep)[0]);
                sub.contract_subtypes = (rep[Object.keys(rep)[0]]);
              }else sub.exist = false;
            })
            await tmp_array.push(sub);
          }
          setsubmissions(tmp_array);
      }
      
      load_data();

    },[url_updated,history.location]);


 

   function download_docs(choice,id_submission) {
    setWaiting(true);
    const formData = new FormData();
    formData.append('id_submission',id_submission);
    formData.append('choice',choice);
    download_offer_submission_docs(formData).then((response) => {
       var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        let file_extension = '';
        const disposition = response.headers['content-disposition'];
        if (disposition && disposition.includes('filename')) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                file_extension = matches[1].replace(/['"]/g, '').split(".")[1];
            }
        }
        fileLink.setAttribute('download', id_submission+"_"+choice+"."+file_extension);
        document.body.appendChild(fileLink);
        fileLink.click();
        setWaiting(false);
    }).catch(err=>{
      //console.log(err,err.response);
      setWaiting(false);
    });
  }


  function upload_docs(file_type,event,id_submission){
    setWaiting(true);
    let formData = new FormData();
    formData.append(file_type,event.target.files[0]);
    formData.append('id_submission',id_submission);
    update_supplier_offer_request_docs(formData).then((response)=>{
        //console.log(response.data);
        setWaiting(false);
    }).catch((error) => {
        //console.log(error.response);
    });
  }
 
  function downloadProposalDocsForSupplier(choice,offer_id){
    const formData = new FormData();
    formData.append('offer_id',offer_id);
    formData.append('choice',choice);
    download_offer_files(formData).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        let file_extension = '';
        const disposition = response.headers['content-disposition'];
        if (disposition && disposition.includes('filename')) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                file_extension = matches[1].replace(/['"]/g, '').split(".")[1];
            }
        }
        fileLink.setAttribute('download', offer_id+"_"+choice+"."+file_extension);
        document.body.appendChild(fileLink);
        fileLink.click();
    });
  }

  

  function search(){
    let path = history.location.pathname;
    if(filter_categorie!=="all") history.push(path+"?categorie="+filter_categorie+"&filtre="+search_filter+"&valeur="+search_value);
    else history.push(path+"?filtre="+search_filter+"&valeur="+search_value);
    setUrl_updated(search_value);
  }


  function set__categorie(value){
    setFilter_categorie(value);
    let path = history.location.pathname;
    if(value!=="all") history.push(path+"?categorie="+value);
    else history.push(path+"?categorie=all");
    setUrl_updated(value);
  }

  function set__filter(value){
    if(value==="all"){
      setsearch_filter(value);
      let path = history.location.pathname;
      if(filter_categorie!=="all") history.push(path+"?categorie="+filter_categorie+"&filtre="+value);
      else history.push(path+"?filtre="+value);
      setUrl_updated(value);
    }else setsearch_filter(value);
  }

  async function change_page(page){
        await setCurrentPage(page);
        let path = history.location.pathname;
        let params = history.location.search;
        let search_params = params.split('&');
        let tmp_params = path;
        if(!params) history.push(path+"?page="+page);
        else{
          for(let i in search_params){
            if(search_params[i].includes("page=")){
              if(i==0) tmp_params=tmp_params+"?page="+page;
              else tmp_params=tmp_params+"&page="+page;
            }else{
              if(i==0) tmp_params=tmp_params+search_params[i];
              else tmp_params=tmp_params+"&"+search_params[i];
            }
          }
          if(!tmp_params.includes("page=")) tmp_params=tmp_params+"&page="+page;
          history.push(tmp_params);
        }
        await setUrl_updated(page);
      }



  return (
    <div className="supplier_requests_container" style={submissions===null?{height:'100%'}:{}} >
      {
        submissions===null
        ?<Loading_animation_view />
        :<>
            <div className='three_forms_header_container'>
                <div className='page_title'>Mes soumissions</div>
                <div className='search_filter_container'>
                  <FormControl variant="standard" className="header_form" >
                        <InputLabel id="demo-simple-select-helper-label">Famille d'achat</InputLabel>
                        <Select required value={filter_categorie} 
                              onChange={e => {set__categorie(e.target.value) }} >
                              
                              <MenuItem value={"all"}>Toutes les familles d'achat</MenuItem>
                              {
                              categories.map((categorie,id)=>(
                                  <MenuItem value={categorie.id_category} key={id} >{categorie.name}</MenuItem>
                              ))
                              }
                              
                        </Select>
                    </FormControl>

                    <FormControl  className="header_form" >
                      <InputLabel id="demo-simple-select-helper-label">Filtre</InputLabel>
                        <Select value={search_filter}  onChange={(e)=>{set__filter(e.target.value)}}>
                          <MenuItem value="all">Aucun</MenuItem>
                          <MenuItem value='Object'>Objet d'appel</MenuItem>
                          <MenuItem value='Direction'>Direction</MenuItem>
                        </Select>
                      </FormControl>

                        <Grid container spacing={1} alignItems="flex-start" className={search_filter==="all"?"search_bar disabled_button":"search_bar" } >
                          <Grid item>
                            <SearchIcon onClick={search} className={search_value==""?"my_icon disabled_button":"my_icon" } style={{cursor:"pointer"}}  />
                          </Grid>
                          <Grid>
                            <TextField label="Chercher" value={search_value} onChange={(e)=>{setsearch_value(e.target.value)}}/>
                          </Grid>
                        </Grid>
                      </div>
                </div>
          <div>
            <TableContainer>
              <Table aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table" >
                <TableHead>
                  {
                      headCells.map((cell)=>(
                        <TableCell align="left" key={cell.id}>{cell.label}</TableCell>
                      ))
                  }
                </TableHead>
                <TableBody>
                  {
                    submissions.length!==0
                    ?<>
                    {
                      submissions.map((row,index)=>(
                            <TableRow tabIndex={-1} key={index} >
                              <TableCell align="left">{row.object}</TableCell>
                              <TableCell align="center" >
                                <Rating
                                    name="financial_score" 
                                    value={row.financial_score+0} 
                                    readOnly
                                  />
                                    {
                                      row.financial_score != 0 
                                      ?<Box style={{alignContent:"center" , fontWeight:"600"}}>{row.financial_score}</Box>
                                      :<Box sx={{ ml: 2 }}>0</Box>
                                    }

                              </TableCell>
                              <TableCell align="center">
                                <Rating
                                    name="technical_score" 
                                    value={row.technical_score+0} 
                                    readOnly
                                  />
                                    {
                                      row.technical_score != 0 
                                      ?<Box style={{alignContent:"center" , fontWeight:"600"}}>{row.technical_score}</Box>
                                      :<Box sx={{ ml: 2 }}>0</Box>
                                    }
                              </TableCell>
                              <TableCell align="left">{(new Date(row.date)).toLocaleString()}</TableCell>
                              <TableCell align="left" >
                                <div className='documentsContainer'>
                                    <div style={{display:'flex',marginBottom:'10px'}}>
                                      <div className='documentsDownload'>
                                        <label htmlFor={"admin"+row.id_submission} style={{marginRight:"7px"}}> 
                                          <Fab size="small" color="default" component="span" className={(CompareFinalDate_current(row.limit_date) == false  ? " disabled_button" : "")} >
                                              <CloudUploadIcon className="my_icon" />
                                          </Fab>
                                          { 
                                          CompareFinalDate_current(row.limit_date) == false ? <></>
                                          :<input accept="application/*" className={classes.input} name={"admin"+row.id_submission} id={"admin"+row.id_submission} multiple type="file" onChange={(e)=>upload_docs("DocAdmin",e,row.id_submission)}  />
                                          }
                                        </label>
                                            <Fab size="small"  color="default" onClick={()=>download_docs("DocAdmin",row.id_submission)}><DownloadIcon className="my_icon" /></Fab>
                                            <span className='documentsTitle'>Administrative</span>
                                      </div>
                                      <div className='documentsStatus_valide' >
                                          {setStatus(etatDoc('admin',submissions[index].accordAdmin))}
                                      </div>
                                    </div>

                                    <div style={{display:'flex',marginBottom:'10px'}}>
                                      <div className='documentsDownload'>
                                          <label htmlFor={"technique"+row.id_submission} style={{marginRight:"7px"}}> 
                                              <Fab size="small" color="default" component="span" className={(CompareFinalDate_current(row.limit_date) == false  ? " disabled_button" : "")} >
                                                  <CloudUploadIcon className="my_icon" />
                                              </Fab>
                                              { 
                                                CompareFinalDate_current(row.limit_date) == false ? <></>
                                                :<input accept="application/*" className={classes.input} name={"technique"+row.id_submission} id={"technique"+row.id_submission} multiple type="file" onChange={(e)=>upload_docs("DocTech",e,row.id_submission)}  />
                                              }
                                          </label>
                                            <Fab size="small" color="default" onClick={()=>download_docs("DocTech",row.id_submission)}><DownloadIcon  className="my_icon" /></Fab>
                                            <span className='documentsTitle'>Technique</span>
                                        </div>
                                            <div className='documentsStatus_valide'>
                                              {setStatus(etatDoc('tech',submissions[index].accordTech))}               
                                        </div>
                                    </div>

                                    <div style={{display:'flex',marginBottom:'10px'}}>
                                      <div className='documentsDownload'>
                                          <label htmlFor={"finance"+row.id_submission} style={{marginRight:"7px"}}> 
                                              <Fab size="small" color="default" component="span" className={(CompareFinalDate_current(row.limit_date) == false  ? " disabled_button" : "")} >
                                                  <CloudUploadIcon className="my_icon" />
                                              </Fab>
                                              { 
                                                CompareFinalDate_current(row.limit_date) == false ? <></>
                                                :<input accept="application/*" className={classes.input} name={"finance"+row.id_submission} id={"finance"+row.id_submission} multiple type="file" onChange={(e)=>upload_docs("DocFinance",e,row.id_submission)}  />
                                              }
                                          </label>
                                            <Fab size="small" color="default" onClick={()=>download_docs("DocFinance",row.id_submission)} ><DownloadIcon className="my_icon" /></Fab>
                                            <span className='documentsTitle'>Finance</span>
                                        </div>
                                            <div className='documentsStatus_valide'  >
                                            {setStatus(etatDoc('finance',submissions[index].accordFinance))}             
                                        </div>
                                    </div>
                                  </div>
                              </TableCell>

                              <TableCell align="left">
                                <div className='documentsContainer'>
                                  <div style={{display:'flex',marginBottom:'10px'}}>
                                    <div className='documentsDownload'>
                                          <Fab size="small" className={row.addOnAdministration == null  ? "disabled_button" : ""} onClick={()=>download_docs("addOnAdministration",row.id_submission)} color="default"><DownloadIcon className="my_icon" /></Fab>
                                          <span className='documentsTitle'>Comp. administrative</span>
                                    </div>
                                  </div>

                                  <div style={{display:'flex',marginBottom:'10px'}}>
                                    <div className='documentsDownload'>
                                          <Fab size="small" color="default" className={row.addOnTechnique == null  ? "disabled_button" : ""} onClick={()=>download_docs("addOnTechnique",row.id_submission)} ><DownloadIcon className="my_icon" /></Fab>
                                          <span className='documentsTitle'>Comp. technique</span>
                                      </div>
                                  </div>

                                  <div style={{display:'flex',marginBottom:'10px'}}>
                                    <div className='documentsDownload'>
                                        <Fab size="small" color="default" className={row.addOnFinance== null  ? "disabled_button" : ""} onClick={()=>download_docs("addOnFinance",row.id_submission)}><DownloadIcon className="my_icon" /></Fab>
                                        <span className='documentsTitle'>Comp. finance</span>
                                      </div>
                                  </div>
                                </div>
                              </TableCell>

                              <TableCell align="left">
                                <div className='documentsContainer'>
                                  <div style={{display:'flex',marginBottom:'10px'}}>
                                    <div className='documentsDownload'>
                                          <Fab size="small" className={row.open_pv == null  ? "disabled_button" : ""} onClick={()=>downloadProposalDocsForSupplier("open_pv",row.offer_id)} color="default"><DownloadIcon className="my_icon" /></Fab>
                                          <span className='documentsTitle'>PV d'ouverture</span>
                                    </div>
                                  </div>

                                  <div style={{display:'flex',marginBottom:'10px'}}>
                                    <div className='documentsDownload'>
                                          <Fab size="small" color="default" className={row.result_pv == null  ? "disabled_button" : ""}  onClick={()=>downloadProposalDocsForSupplier("result_pv",row.offer_id)}><DownloadIcon className="my_icon" /></Fab>
                                          <span className='documentsTitle'>PV resultat</span>
                                      </div>
                                  </div>
                                </div>
                              </TableCell>
        {/* 
                              <TableCell align="left" style={{display:submissions[index].accord==1?'':'none'}}>
                                <Fab size="small" color="default" onClick={()=>{setchosenSubmission(submissions[index])}}><ContentPasteIcon  /></Fab>
                              </TableCell>
        */}
                              <TableCell align="left">
                                <div className='AccordCtrl_container'>
                                  <div className='AccordCtrl_contract'>
                                    {
                                      row.accord==1
                                      ?<Fab size="small" className={row.exist===true?"":"disabled_button"} color="info" onClick={()=>openContractView(row)}><ContentPasteIcon className="my_icon"  /></Fab>
                                      :<></>
                                    }
                                  </div>
                                  <div>
                                    {etat(row.accord)}
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))
                      }
                    </>
                    :<div style={{margin:"10px",fontSize:".75rem",width:'100%', textAlign:"center",alignContent:"center"}}>Aucune facture disponible</div>
                  }
                
                  
                </TableBody>
              </Table>
            </TableContainer>
            {
                submissions.length!==0
                  ?<div className="pagination_container">
                      <Pagination
                          activePage={Number(currentPage)}
                          itemsCountPerPage={dataPerPage}
                          totalItemsCount={Number(data_count)}
                          pageRangeDisplayed={4}
                          onChange={change_page.bind(this)}
                      />
                  </div>
                  :<></>
              }
          </div>
        </>
      }
      


        <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waiting}
            >
                <CircularProgress color="inherit" />
      </Backdrop>

      {
        chosenSubmission!=null && setContract==true
        ?<Contract_content_view setContract={setContract}  handleCloseSetContrat={handleCloseSetContrat} chosenSubmission={chosenSubmission} supplier={supplier} />
        :<></>
      }

    </div>
  );
}
export default Offers_requests
