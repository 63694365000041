import './state_details.css'
import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';


function state(stat){
    return(
      <>
        {
         stat === null
         ?<Button variant="contained" className="status_waiting">en cours</Button>
         :stat === "0"
            ?<Button variant="contained" className="status_deny">refusée</Button>
            :<Button variant="contained" className="status_success">validée</Button>
        }
      </>
  
    );
  }

function State_details({view_state_details,setview_state_details}){

    return (
      <Dialog className="view_state_details_container" fullWidth={true} maxWidth={'md'} open={view_state_details!=null?true:false} onClose={()=>setview_state_details(null)} aria-labelledby="form-dialog-title" >
        <DialogTitle id="form-dialog-title">Détails de l'état de la facture</DialogTitle>
        <DialogContent>
          <div>
              <div className='first_textField_container'>
                <div className="bill_state_byService">
                    <div>Service finance</div>
                    <div>{state(view_state_details[1][0])}</div>
                </div>
                <TextField
                    label="Motif du rejet"
                    value={view_state_details[0][0]===null?"...":view_state_details[0][0]}
                    variant='outlined'
                    multiline
                    minRows={4}
                    style={{width:"100%"}}
                    inputProps={
                      { readOnly: true, }
                    }
                    disabled={view_state_details[0][0]===null?true:false}
                  />
                  
              </div>
              <div className='second_textFields_container' >
                  <div className='container_11' >
                   <div className="bill_state_byService">
                        <div>Service d'achat</div>
                        <div>{state(view_state_details[1][1])}</div>
                    </div>
                    <TextField
                        label="Motif du rejet"
                        value={view_state_details[0][1]===null?"...":view_state_details[0][1]}
                        variant='outlined'
                        multiline
                        rows={7}
                        style={{width:"100%"}}
                        inputProps={
                        { readOnly: true, }
                        }
                        disabled={view_state_details[0][1]===null?true:false}
                    /> 
                  </div>
                

                    <div className='container_12'>
                        <div className="bill_state_byService">
                            <div>Service métier</div>
                            <div>{state(view_state_details[1][2])}</div>
                        </div>  
                        <TextField
                            label="Motif du rejet"
                            value={view_state_details[0][2]===null?"...":view_state_details[0][2]}
                            variant='outlined'
                            multiline
                            rows={7}
                            style={{width:"100%"}}
                            inputProps={
                            { readOnly: true, }
                            }
                            disabled={view_state_details[0][2]===null?true:false}
                        />
                    </div>
              </div>

          </div>
        </DialogContent>
      </Dialog>
    )
}

export default State_details
