import './membre_details_Dialog.css'
import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import PersonIcon from '@material-ui/icons/Person';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import CodeIcon from '@mui/icons-material/Code';
import HomeIcon from '@mui/icons-material/Home';
import PhoneIcon from '@mui/icons-material/Phone';

function Membre_details_Dialog({setView_auditor_infos,view_auditor_infos}){


    return (
        <Dialog fullWidth={true} maxWidth={'md'} open={view_auditor_infos!=null?true:false} onClose={()=>setView_auditor_infos(null)} aria-labelledby="form-dialog-title" >
          <DialogContent>
            <div className="plus_details_dialog_container">
                    <TextField readOnly  label="Nom complet" value={view_auditor_infos.fullName} className="header_form" 
                        InputProps={{ startAdornment: ( <InputAdornment position="start"> <PersonIcon className="my_icon"   /> </InputAdornment> ), }}
                    />
                     <TextField  label="Departement" value={view_auditor_infos.departement} className="header_form" 
                        InputProps={{ startAdornment: ( <InputAdornment position="start"> <CodeIcon className="my_icon"  /> </InputAdornment> ), }}
                    />
                    <TextField  label="Position" value={view_auditor_infos.position} className="header_form" 
                        InputProps={{ startAdornment: ( <InputAdornment position="start"> <CodeIcon className="my_icon"  /> </InputAdornment> ), }}
                    />
                    <TextField  label="Maison mère" value={view_auditor_infos.company_name} className="header_form" 
                        InputProps={{ startAdornment: ( <InputAdornment position="start"> <HomeIcon className="my_icon"  /> </InputAdornment> ), }}
                    />
                    <TextField  label="Adresse" value={view_auditor_infos.address+'-'+view_auditor_infos.city+'-'+view_auditor_infos.country} className="header_form" 
                        InputProps={{ startAdornment: ( <InputAdornment position="start"> <PersonPinCircleIcon className="my_icon"  /> </InputAdornment> ), }}
                    />
                    <TextField  label="cnss	" value={view_auditor_infos.cnss}  className="header_form" 
                        InputProps={{ startAdornment: ( <InputAdornment position="start"> <CodeIcon className="my_icon"  /> </InputAdornment> ), }}
                    />
                    <TextField  label="Numéro de téléphone " value={view_auditor_infos.phoneNumber} className="header_form" 
                        InputProps={{ startAdornment: ( <InputAdornment position="start"> <PhoneIcon className="my_icon"  /> </InputAdornment> ), }}
                    />
                    <TextField  label="Identifiant fiscal" value={view_auditor_infos.fiscalIdentifier} className="header_form" 
                        InputProps={{ startAdornment: ( <InputAdornment position="start"> <CodeIcon className="my_icon"  /> </InputAdornment> ), }}
                    />
                    <TextField  label="Num ICE" value={view_auditor_infos.iceNumbner} className="header_form" 
                        InputProps={{ startAdornment: ( <InputAdornment position="start"> <CodeIcon className="my_icon"  /> </InputAdornment> ), }}
                    />
                </div>
          
          </DialogContent>
        </Dialog>  
    );
}

export default Membre_details_Dialog;
