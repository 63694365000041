import '../../../../assets/css/weather_widget.css'
import React from 'react';
import ReactWeather, { useOpenWeather } from 'react-open-weather';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';


const customStyles = {
	fontFamily:  'Nunito, sans-serif',
	gradientStart:  '#46697a',
	gradientMid:  '#3b5460',
	gradientEnd:  '#5c889b',
	locationFontColor:  '#FFF',
	todayTempFontColor:  '#FFF',
	todayDateFontColor:  '#B5DEF4',
	todayRangeFontColor:  '#B5DEF4',
	todayDescFontColor:  '#B5DEF4',
	todayInfoFontColor:  '#B5DEF4',
	todayIconColor:  '#FFF',
	forecastBackgroundColor:  '#FFF',
	forecastSeparatorColor:  '#DDD',
	forecastDateColor:  '#777',
	forecastDescColor:  '#777',
	forecastRangeColor:  '#777',
	forecastIconColor:  '#5c889b',
};


const Weather_widget = ({latitude,longitude,city,removeWidget})=>{

    
  const { data, isLoading, errorMessage } = useOpenWeather({
    key: '05ed64e47299bb65b3367df1e7d90026',
    lat: latitude,
    lon: longitude,
    lang: 'fr',
    unit: 'metric', // values are (metric, standard, imperial)
  });


  return (
    <div className='weather_container'>
        <div className="weather_close_icon">
            <CloseSharpIcon style={{fontSize: "1.2rem",cursor: "pointer"}} onClick={()=>removeWidget()} />
        </div>
        <ReactWeather
            isLoading={isLoading}
            errorMessage={errorMessage}
            theme={customStyles}
            data={data}
            lang="en"
            locationLabel={city}
            unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
            showForecast
        />
    </div>
    
  );
};

export default React.memo(Weather_widget) 