import './loading_animation_view.css'
import React from 'react';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css'
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';



export default function Loading_animation_view() {

  return (
    <div className="loading_animation_container">
            <Box sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>
    </div>
      
  );
}
