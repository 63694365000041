import './save_email_awnser_view.css'
import React,{useState,useEffect} from 'react';
import pa_logo from './PA_logo.jpeg';
import DoneIcon from '@mui/icons-material/Done';
import Fab from '@mui/material/Fab';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import SendIcon from '@mui/icons-material/Send';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { services_bills_accord_withEmail, accord_request_orders_by_requester, accord_order_file_by_supplier, services_requests_orders_bills_accord_withEmail, get_company_logo, validate_doc } from '../../services/for_allServices';
import Loading_animation_view from '../loading_animation_view/loading_animation_view';
import axios from 'axios';

function Success_save(){
    return (
        <div className="save_email_awnser_body" style={{background:"#33b66e"}}>
                <div className="body_icon">
                    <Fab size="big" >
                        <DoneIcon className="done_icon" />
                    </Fab>
                </div>
                <div className="body_title">
                    Enregistrée avec succès
                </div>
                <div className="body_description">
                    Merci pour votre réponse. Votre réponse a été enregistré, et vous ne pourrez pas la changer.
                </div>
        </div>
    )
}

function Error_save(){
    return (
        <div className="save_email_awnser_body error_save" style={{background:"#f33640f3"}}>
                <div className="body_icon">
                    <Fab size="big" >
                        <DoNotDisturbIcon className="false_icon" />
                    </Fab>
                </div>
                <div className="body_title">
                        Erreur survenue
                </div>
                <div className="body_description">
                    Une erreur s'est produite, soit parce que vous aviez déjà défini votre réponse, soit parce que le code de réponse est incorrect.
                </div>
        </div>
    )
}

function Set_refuse_reason({code,response,setServeurResponse}){
    const [errorText,setErrorText] = useState("");
    const [waiting, setWaiting] = useState(false);

    const [reason,setReason]=useState('');

    function submit_refuse_reason() {
        setErrorText("");
        if(reason==''){
          setErrorText("Champ vide !");
        }else{
            setWaiting(true);
            let code_array_tmp = code.split('_');
            if(code_array_tmp[code_array_tmp.length - 1] !== "r"){
                if(code_array_tmp[code_array_tmp.length - 1] === "rc"){
                    let formData = new FormData();
                    formData.append('refuse_reason',reason);
                    formData.append("request_token",code);
                    formData.append("request_accord",response);
                    accord_order_file_by_supplier(formData).then((response)=>{
                        //console.log(response.data);
                        setServeurResponse(response.data);
                    }).catch((error)=>{
                        //console.log(error.response);
                        setServeurResponse("error");
                    })  
                }else{
                    if(code_array_tmp[code_array_tmp.length - 1] === "br" || code_array_tmp[code_array_tmp.length - 1] === "pr"){
                        let formData = new FormData();
                        formData.append('refuse_reason',reason);
                        formData.append("code",code);
                        formData.append("response",response);
                        services_requests_orders_bills_accord_withEmail(formData).then((response)=>{
                            //console.log(response.data);
                            setServeurResponse(response.data);
                        }).catch((error)=>{
                            //console.log(error.response);
                            setServeurResponse("error");
                        })  
                      }else{
                        if(code_array_tmp[code_array_tmp.length - 1] === "doc"){
                            let formData = new FormData();
                            formData.append('refuse_reason',reason);
                            formData.append("code",code);
                            formData.append("response",response);
                            validate_doc(formData).then((response)=>{
                                //console.log(response.data);
                                setServeurResponse(response.data);
                            }).catch((error)=>{
                                //console.log(error.response);
                                setServeurResponse("error");
                            }) 
                        }else{
                            let formData = new FormData();
                            formData.append('refuse_reason',reason);
                            formData.append("code",code);
                            formData.append("response",response);
                            services_bills_accord_withEmail(formData).then((response)=>{
                                //console.log(response.data);
                                setServeurResponse(response.data);
                                setWaiting(false);
                            }).catch((error)=>{
                                //console.log(error.response);
                                setServeurResponse("error");
                                setWaiting(false);
                            })
                        }
                    }
                }
            }else{
                let formData = new FormData();
                formData.append('refuse_reason',reason);
                formData.append("request_token",code);
                formData.append("request_accord",response);
                accord_request_orders_by_requester(formData).then((response)=>{
                    //console.log(response.data);
                    setServeurResponse(response.data);
                }).catch((error)=>{
                    //console.log(error.response);
                    setServeurResponse("error");
                })                    
            }
        }
            
    }

    return (
        <div className="save_email_awnser_body refuse_reason" style={{background:"white"}}>
                <div className='textfield_container'>
                {
                    errorText === "" 
                    ?<></>
                    :<p className={errorText ? "error" : "valid-champ"}>
                            {errorText}
                        </p>
                }

                    <div style={{width:"100%",marginTop:"5px"}} >
                    <TextField
                        id="outlined-multiline-flexible"
                        label="Motif"
                        variant='outlined'
                        multiline
                        rows={6}
                        value={reason}
                        onChange={(e)=>setReason(e.target.value)} 
                        style={{width:"100%"}}
                        focused
                        />
                    </div>
                </div>
                <div className='dialogBottom'>
                    <Button variant="contained" className="submit_but valide_but" startIcon={<SendIcon />} onClick={submit_refuse_reason} >
                        Envoyer
                    </Button> 
                </div>
                <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={waiting}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
        </div>
    )
}



function Save_email_awnser() {

    const [company_logo,setcompany_logo]=useState(null);
    const [serveurResponse,setServeurResponse] = useState(null);
    const [code,setCode] = useState(null);
    const [response,setResponse] = useState(null);
    

  useEffect(()=>{
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if(urlParams.get('code') && urlParams.get('reponse')){
      let code_array_tmp = urlParams.get('code').split('_');
      axios.interceptors.request.use(
            function(config) {
                config.headers["company"] = code_array_tmp[0];
                return config;
            }
        );
        get_company_logo().then((response)=>{
            setcompany_logo(response.data.company_logo);
        }).catch((error)=>{
            //console.log(error.response);
        })
      setCode(urlParams.get('code'));
      setResponse(urlParams.get('reponse'));
      if(code_array_tmp[code_array_tmp.length - 1] === "r"){
        let formData = new FormData();
        formData.append("request_token",urlParams.get('code'));
        formData.append("request_accord",urlParams.get('reponse'));
        accord_request_orders_by_requester(formData).then((response)=>{
            //console.log(response.data);
            setServeurResponse(response.data);
            }).catch((error)=>{
            //console.log(error.response);
            setServeurResponse("error");
        })
      }else{
        if(code_array_tmp[code_array_tmp.length - 1] === "rc"){
            let formData = new FormData();
            formData.append("request_token",urlParams.get('code'));
            formData.append("request_accord",urlParams.get('reponse'));
            accord_order_file_by_supplier(formData).then((response)=>{
                //console.log(response.data);
                setServeurResponse(response.data);
                }).catch((error)=>{
                //console.log(error.response);
                setServeurResponse("error");
            })  
          }else{
            if(code_array_tmp[code_array_tmp.length - 1] === "br" || code_array_tmp[code_array_tmp.length - 1] === "pr"){
                let formData = new FormData();
                formData.append("code",urlParams.get('code'));
                formData.append("response",urlParams.get('reponse'));
                services_requests_orders_bills_accord_withEmail(formData).then((response)=>{
                    //console.log(response.data);
                    setServeurResponse(response.data);
                }).catch((error)=>{
                    //console.log(error.response);
                    setServeurResponse("error");
                })  
              }else{
                if(code_array_tmp[code_array_tmp.length - 1] === "doc"){
                    let formData = new FormData();
                    formData.append("code",urlParams.get('code'));
                    formData.append("response",urlParams.get('reponse'));
                    validate_doc(formData).then((response)=>{
                        //console.log(response.data);
                        setServeurResponse(response.data);
                    }).catch((error)=>{
                        //console.log(error.response);
                        setServeurResponse("error");
                    })  
                  }else{
                    let formData = new FormData();
                    formData.append("code",urlParams.get('code'));
                    formData.append("response",urlParams.get('reponse'));
                    services_bills_accord_withEmail(formData).then((response)=>{
                        //console.log(response.data);
                        setServeurResponse(response.data);
                    }).catch((error)=>{
                        //console.log(error.response);
                        setServeurResponse("error");
                    })  
                }
                
              }
            
          }
      }
    }else{
        setServeurResponse("error");
    }

  },[]);

  return (
    <div className="save_email_awnser_container">
        <div className="logo_container">
            <a href="" style={{display: "inline-block"}} target="_blank">
                <img src={  company_logo!==""?`data:image/*;base64,${company_logo}`:pa_logo } className="brand" />
            </a>
        </div>
        {
            serveurResponse==null
            ?<div className="save_email_awnser_body error_save" style={{background:"white"}}><Loading_animation_view /></div>
            :serveurResponse==="error"
                ?<Error_save />
                :serveurResponse==="reason"?<Set_refuse_reason code={code} response={response} setServeurResponse={setServeurResponse} />:<Success_save />

        }
    </div>
  );
}

export default Save_email_awnser;
