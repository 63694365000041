import './commissions.css';
import React, { useEffect,useState,Fragment } from 'react';
import { useHistory } from "react-router-dom";

import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from '@mui/material/Fab';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import Button from '@material-ui/core/Button';
import Tooltip from '@mui/material/Tooltip';
import EventNoteIcon from '@mui/icons-material/EventNote';
import Loading_animation_view from '../../loading_animation_view/loading_animation_view'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import SendIcon from '@material-ui/icons/Send';
import CancelIcon from '@material-ui/icons/Cancel';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from "react-js-pagination";

import {makeStyles} from '@material-ui/core/styles';
import { get_commissions_by_id_service, update_commission } from '../../../services/commission_service';
import { get_categories } from '../../../services/purchase_service';
import { get_auditor_by_id,get_auditors_for_service } from '../../../services/auditor_service';
import Modal_dates_proposition from './modal_dates_proposition/modal_dates_proposition';
import Membre_details_Dialog from './membres_details/membre_details_Dialog';
import Notify_auditor_again_withCommission_modal from './notify_auditor_again_withCommission_modal/notify_auditor_again_withCommission_modal';
import Set_another_openingDate from "./set_another_openingDate_view/set_another_openingDate_view";


const status = ['En Cours','Refusé','Accepté'];

function setStatus(stat){
    return(
      <>
        {
         stat == null
         ?<Button variant="contained" className="status_waiting">{status[0]}</Button>
         :stat == 0
            ?<Button variant="contained" className="status_deny">{status[1]}</Button>
            :<Button variant="contained" className="status_success">{status[2]}</Button>
        }
      </>
  
    );
  }

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  input: {
    display: 'none',
  },
});

const columns = [
    { id: 'Objet', label: "Objet d'appel", minWidth: 0 },
    { id: 'direction', label: "Direction", minWidth: 0 },
    { id: 'categorie', label: "Famille d'achat", minWidth: 0 },
    { id: 'limiteDate', label: "Date limite", minWidth: 0 },
    { id: 'openingDate', label: "Date d'ouverture des plis", minWidth: 0 },
    { id: 'members', label: "Membres", minWidth: 0 },
    { id: 'proposition', label: "Proposition d'une nouvelle date", minWidth: 0 }
  ];



function Membre_reselect({departement,auditor_id,auditors,commission,index,valide_change_commission_membre,waiting}){
    const classes = useStyles();
    const [openChange_commission_membre,setOpenChange_commission_membre] = useState(null);

    function change_commission_membre(selected_auditor){
        setOpenChange_commission_membre(selected_auditor);
    }

    return (
        <FormControl className={classes.formControl} style={{width:"120px",margin:"0px"}} >

            <InputLabel htmlFor={departement} style={{fontSize:".9rem"}}>{departement}</InputLabel>
            <Select name={departement} id={departement} value={auditor_id} onChange={(e)=>change_commission_membre(e.target.value)}>
                <MenuItem value="membre" selected disabled>
                    Membre
                </MenuItem>
                {
                    auditors.map((auditor,index) => (
                        <MenuItem key={auditor.id} value={auditor.id}>{auditor.fullName}</MenuItem>
                    ))
                }
            </Select> 
            {
               openChange_commission_membre!=null
               ? 
                <Dialog className="notify_auditor_again_dialog" fullWidth={true} maxWidth={'md'} open={openChange_commission_membre!=null?true:false} onClose={()=>setOpenChange_commission_membre(null)} aria-labelledby="form-dialog-title" >
                    <DialogTitle id="form-dialog-title">Voulez-vous changer le membre {departement} ?</DialogTitle>
                    
                    <DialogActions>
                    <div className='dialogBottom'>
                        <div>
                        <Button variant="contained" className="submit_but cancel_but" startIcon={<CancelIcon />} onClick={()=>setOpenChange_commission_membre(null)} >
                                Annuler
                            </Button>
                            <Button variant="contained" className="submit_but valide_but" startIcon={<SendIcon />} onClick={() => {valide_change_commission_membre(commission,openChange_commission_membre,index,departement);setOpenChange_commission_membre(null)}} >
                                Envoyer
                            </Button> 
                        </div>
                    </div>      
                        
                    </DialogActions>
        
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={waiting}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={waiting}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Dialog>
                :<></>
            }
        </FormControl>
    );

}




const Commissions = React.memo(({offers_commissions,getCategorieById,setOffers_commissions,viewAuditorInfos,viewAuditor_commissionSuggestion,
        notify_auditor_again_withCommission,auditors_legal,auditors_financial,auditors_technique
        ,valide_change_commission_membre,waiting}) => {

    const [view_propose_openingDate_modal,setview_propose_openingDate_modal] = useState(null);
    const [selected_commission,setSelected_commission] = useState(null);

    return(
        <>
        {
            offers_commissions.map((offer_commission,index1) => (
                <TableRow key={offer_commission.id+"col"+index1}>
                    <TableCell align="left">{offer_commission.object}</TableCell>
                    <TableCell align="left">{offer_commission.direction}</TableCell>
                    <TableCell align="left">{getCategorieById(offer_commission.purchase_family)}</TableCell>
                    <TableCell align="left">{(new Date(offer_commission.limit_date)).toLocaleString()}</TableCell>
                    {
                        offer_commission.proposed_meet_date2!=null
                        ?<TableCell align="left">{(new Date(offer_commission.proposed_meet_date2)).toLocaleString()}</TableCell>
                        :<TableCell align="left">{(new Date(offer_commission.proposed_meet_date1)).toLocaleString()}</TableCell>
                    }
                    <TableCell align="left">
                     <div className='documentsContainer'>
                         <div style={{display:'flex',marginBottom:'10px',alignItems: "center"}}>
                                <div className='documentsDownload' style={{width: "60%",marginRight:"20px"}}>
                                    <Tooltip disableFocusListener disableTouchListener title="Fiche d'identité">
                                        <Fab size="small" color="warning" onClick={() => viewAuditorInfos(offer_commission.legal_direction)}>
                                        <AssignmentIndIcon className="my_icon" /></Fab>
                                    </Tooltip>
                                    <span className='documentsTitle'>
                                        <Membre_reselect departement={"Juridique"} commission={offer_commission} index={index1}
                                            auditor_id={offer_commission.legal_direction} auditors={auditors_legal} 
                                            valide_change_commission_membre={valide_change_commission_membre} waiting={waiting} />           
                                    </span>
                                </div>    
                                <div style={{width: "45px",marginLeft:"25px"}}>
                                    {
                                        offer_commission.validated_legal==null
                                        ?<Tooltip disableFocusListener disableTouchListener title="Rappeler le membre">
                                            <Fab size="small" color="warning" style={{backgroundColor:"#ffa726"}} onClick={() => notify_auditor_again_withCommission("jurdique",offer_commission)} >
                                                <NotificationAddIcon className="my_icon" />
                                            </Fab>
                                        </Tooltip>
                                        :<></>
                                    }
                                    {
                                        offer_commission.validated_legal==0
                                        ?<Tooltip disableFocusListener disableTouchListener title="Intervalle de disponibilité">
                                            <Fab size="small" color="warning" style={{backgroundColor:"#f33640f3"}} onClick={() => viewAuditor_commissionSuggestion("jurdique",offer_commission)} >
                                                <EventNoteIcon className="my_icon" />
                                            </Fab>
                                        </Tooltip>
                                        :<></>
                                    }
                                </div>                          
                                <Tooltip disableFocusListener disableTouchListener title="L'accord !">
                                    <div className='documentsStatus_valide' >
                                        { setStatus(offer_commission.validated_legal)}
                                    </div>
                                </Tooltip>
                                
                            </div>
                            <div style={{display:'flex',marginBottom:'10px'}}>
                                 <div className='documentsDownload' style={{width: "60%",marginRight:"20px"}}>
                                    <Tooltip disableFocusListener disableTouchListener title="Fiche d'identité">
                                        <Fab size="small" color="warning" onClick={() => viewAuditorInfos(offer_commission.finance_direction)} >
                                        <AssignmentIndIcon className="my_icon" /></Fab>
                                    </Tooltip>
                                    <span className='documentsTitle'>
                                        <Membre_reselect departement={"Finance"}
                                            auditor_id={offer_commission.finance_direction} commission={offer_commission} index={index1}
                                            auditors={auditors_financial} valide_change_commission_membre={valide_change_commission_membre} waiting={waiting} /> 
                                    </span>
                                </div>
                                <div style={{width: "45px",marginLeft:"25px"}}>
                                    {
                                        offer_commission.validated_financial==null
                                        ?<Tooltip disableFocusListener disableTouchListener title="Rappeler le membre">
                                            <Fab size="small" color="warning" style={{backgroundColor:"#ffa726"}} onClick={() => notify_auditor_again_withCommission("finance",offer_commission)}>
                                                <NotificationAddIcon className="my_icon" />
                                            </Fab>
                                        </Tooltip>
                                        :<></>
                                    }
                                    {
                                        offer_commission.validated_financial==0
                                        ?<Tooltip disableFocusListener disableTouchListener title="Intervalle de disponibilité">
                                            <Fab size="small" color="warning" style={{backgroundColor:"#f33640f3"}} onClick={() => viewAuditor_commissionSuggestion("finance",offer_commission)} >
                                                <EventNoteIcon className="my_icon" />
                                            </Fab>
                                        </Tooltip>
                                        :<></>
                                    }
                                </div>
                                <Tooltip disableFocusListener disableTouchListener title="L'accord !">
                                    <div className='documentsStatus_valide' >
                                        { setStatus(offer_commission.validated_financial)}
                                    </div>
                                </Tooltip>                            
                            </div>
                            <div style={{display:'flex',marginBottom:'10px'}}>
                                <div className='documentsDownload' style={{width: "60%",marginRight:"20px"}}>
                                    <Tooltip disableFocusListener disableTouchListener title="Fiche d'identité">
                                        <Fab size="small" color="warning" onClick={() => viewAuditorInfos(offer_commission.technical_direction)} >
                                        <AssignmentIndIcon className="my_icon"  /></Fab>
                                    </Tooltip>
                                    <span className='documentsTitle'>
                                        <Membre_reselect departement={"Technique"} 
                                            auditor_id={offer_commission.technical_direction} commission={offer_commission} index={index1}
                                            auditors={auditors_technique} valide_change_commission_membre={valide_change_commission_membre} waiting={waiting} /> 
                                    </span>
                                </div>
                                <div style={{width: "45px",marginLeft:"25px"}}>
                                    {
                                        offer_commission.validated_technical==null
                                        ?<Tooltip disableFocusListener disableTouchListener title="Rappeler le membre">
                                            <Fab size="small" color="warning" style={{backgroundColor:"#ffa726"}}  onClick={() => notify_auditor_again_withCommission("technique",offer_commission)} >
                                                <NotificationAddIcon className="my_icon"  />
                                            </Fab>
                                        </Tooltip>
                                        :<></>
                                    }
                                    {
                                        offer_commission.validated_technical==0
                                        ?<Tooltip disableFocusListener disableTouchListener title="Intervalle de disponibilité">
                                            <Fab size="small" color="warning" style={{backgroundColor:"#f33640f3"}} onClick={() => viewAuditor_commissionSuggestion("technique",offer_commission)} >
                                                <EventNoteIcon className="my_icon"  />
                                            </Fab>
                                        </Tooltip>
                                        :<></>
                                    }
                                </div>
                                <Tooltip disableFocusListener disableTouchListener title="L'accord !">
                                  <div className='documentsStatus_valide'>
                                        {setStatus(offer_commission.validated_technical)}                
                                    </div>  
                                </Tooltip>                 
                            </div>
                     </div>
                    </TableCell>
                    <TableCell align="center">
                        {
                            offer_commission.validated_technical==0 || offer_commission.validated_financial==0 || offer_commission.validated_legal==0
                            ?<Tooltip disableFocusListener disableTouchListener title="Proposer une nouvelle date">
                                <Fab size="small" color="warning" style={{backgroundColor:"#33b66e"}}  onClick={() => {
                                        setSelected_commission(index1);
                                        setview_propose_openingDate_modal(offer_commission);
                                        }}>
                                    <PublishedWithChangesIcon className="my_icon" />
                                </Fab>   
                            </Tooltip>
                            :<Tooltip disableFocusListener disableTouchListener title="Proposer une nouvelle date">
                                <span>
                                <Fab size="small" color="warning" style={{backgroundColor:"#33b66e"}} className="disabled_button" >
                                    <PublishedWithChangesIcon className="my_icon"  />
                                </Fab>   
                                </span>
                            </Tooltip>
                        }
                       
                    </TableCell>
                </TableRow>
             ))
        }

        {
            view_propose_openingDate_modal != null
            ?<Set_another_openingDate view_propose_openingDate_modal={view_propose_openingDate_modal} 
                        setview_propose_openingDate_modal={setview_propose_openingDate_modal} offers_commissions={offers_commissions}
                        index={selected_commission} setOffer_commission={setOffers_commissions} />
            :<></>
        }
        </>
    );
},(prevProps,nextProps) => {return true;})

function My_commissions() {

    const classes = useStyles();

    let purchaseService = JSON.parse(localStorage.getItem('user-info'))
    let history = useHistory();
    const [url_updated , setUrl_updated] = useState(null);

    const [departement,setDepartement] = useState(null);
    const [offers_commissions,setOffers_commissions] = useState(null);

    const [auditors_legal,setAuditors_legal] = useState([]);
    const [auditors_financial,setAuditors_financial] = useState([]);
    const [auditors_technique,setAuditors_technique] = useState([]);

    const [view_auditor_infos,setView_auditor_infos]=useState(null);
    const [view_auditor_commission_suggestion,setView_auditor_commission_suggestion]=useState(null);
    const [notify_auditor_modal,setNotify_auditor_modal]=useState(null);

    const [categories,setCategories]=useState([]);

    const [filter_categorie,setFilter_categorie]=useState("all");
    const [search_value,setsearch_value]=useState("");
    const [search_filter,setsearch_filter]=useState('all');
    
    const [waiting, setWaiting] = useState(false);

    const [currentPage,setCurrentPage]=useState(1);
    const [dataPerPage,setdataPerPage] =useState(5);
    const [data_count,setdata_count]=useState(0);

    useEffect(()=>{
        setdata_count(0);
        setOffers_commissions(null);
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        var tmp_searchFilter ="all";
        var tmp_searchValue = null;
        var tmp_filterCategorie = "all";

        async function set_params(){
            if(urlParams.get('filtre')){
                tmp_searchFilter = urlParams.get('filtre');
            }
            if(urlParams.get('valeur')){
                tmp_searchValue = urlParams.get('valeur');
            }if(urlParams.get('categorie')){
                tmp_filterCategorie = urlParams.get('categorie');
            }
            await setsearch_filter(tmp_searchFilter);
            await setsearch_value(tmp_searchValue);
            await setFilter_categorie(tmp_filterCategorie);
        }
    
        async function set_cat(){
            get_categories().then((response)=> {
                setCategories(response.data);
            }).catch((error)=>{
                //console.log(error.response);
            });
        }
          

        async function get_purchaseService_infos(){
            let page = (urlParams.get('page')?urlParams.get('page'):1);
            await setCurrentPage(page)
            await get_commissions_by_id_service(purchaseService.id,tmp_filterCategorie,tmp_searchFilter,tmp_searchValue,page).then((response)=>{
                setOffers_commissions(response.data.data);
                setdata_count(response.data.total);
            }).catch((error)=>{
                //console.log(error.response);
            });
        }

        async function get_auditors(){
            await get_auditors_for_service().then((response) => {
                setAuditors_financial(response.data.finance);
                setAuditors_legal(response.data.legal);
                setAuditors_technique(response.data.technical);
            }).catch((error) => {
                //console.log(error.response);
            });
        }

        async function load_data(){
            await set_params();
            await set_cat();
            await get_auditors();
            await get_purchaseService_infos();
        }

        load_data()
        
    },[url_updated,history.location])


    function getCategorieById(id){
        var categorie="";
        categories.map((catg)=>{
          if(catg.id_category == id) categorie = catg.name;
        })
        return categorie;
    }

  
  
    /////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////



function viewAuditorInfos(id_membre){
    get_auditor_by_id(id_membre).then((response) => {
        setView_auditor_infos(response.data);
    }).catch((error)=>{
        //console.log(error.response);
    });
}


function viewAuditor_commissionSuggestion(departement,commission){
    setDepartement(departement);
    setView_auditor_commission_suggestion(commission);
}

function notify_auditor_again_withCommission(departement,commission){
    setDepartement(departement);
    setNotify_auditor_modal(commission);
}



function valide_change_commission_membre(commission_selected,id_auditor,index,departement){
    setWaiting(true);
    let formData = new FormData();
    formData.append("departement",departement);
    formData.append("new_auditor",id_auditor);
    update_commission(commission_selected.id,formData).then((response)=>{
        setWaiting(false);
        let tmp_commissions = [...offers_commissions];
        tmp_commissions[index]=response.data;
        setOffers_commissions(tmp_commissions);
        //console.log(response.data);
    }).catch((error)=>{
        setWaiting(false);
        //console.log(error.response);
    })
}


//////////////////////
/////////////////////////////////////////////////////////
///////////////////////////////////

    function search(){
        let path = history.location.pathname;
        if(filter_categorie!=="all") history.push(path+"?categorie="+filter_categorie+"&filtre="+search_filter+"&valeur="+search_value);
        else history.push(path+"?filtre="+search_filter+"&valeur="+search_value);
        setUrl_updated(search_value);
    }


    function set__categorie(value){
        setFilter_categorie(value);
        let path = history.location.pathname;
        if(value!=="all") history.push(path+"?categorie="+value);
        else history.push(path+"?categorie=all");
        setUrl_updated(value);
    }

    function set__filter(value){
        if(value!=="Direction" && value!=="Object"){
            setsearch_filter(value);
            let path = history.location.pathname;
            if(filter_categorie!=="all") history.push(path+"?categorie="+filter_categorie+"&filtre="+value);
            else history.push(path+"?filtre="+value);
            setUrl_updated(value);
        }else setsearch_filter(value);
    }

    async function change_page(page){
        await setCurrentPage(page);
        let path = history.location.pathname;
        let params = history.location.search;
        let search_params = params.split('&');
        let tmp_params = path;
        if(!params) history.push(path+"?page="+page);
        else{
          for(let i in search_params){
            if(search_params[i].includes("page=")){
              if(i==0) tmp_params=tmp_params+"?page="+page;
              else tmp_params=tmp_params+"&page="+page;
            }else{
              if(i==0) tmp_params=tmp_params+search_params[i];
              else tmp_params=tmp_params+"&"+search_params[i];
            }
          }
          if(!tmp_params.includes("page=")) tmp_params=tmp_params+"&page="+page;
          history.push(tmp_params);
        }
        await setUrl_updated(page);
      }

  return (
    <>
    {
      offers_commissions==null
      ?<Loading_animation_view />
      :<div className="service_commissions_container" >
         <div className='three_forms_header_container'>
            <div className='page_title'>Mes commissions</div>
            <div className='search_filter_container'>
                <FormControl variant="standard" className="header_form" >
                    <InputLabel id="demo-simple-select-helper-label">Famille d'achat</InputLabel>
                    <Select required value={filter_categorie} 
                            onChange={e => {set__categorie(e.target.value) }} >
                            
                            <MenuItem value={"all"}>Toutes les familles d'achat</MenuItem>
                            {
                            categories.map((categorie,id)=>(
                                <MenuItem value={categorie.name} key={id} >{categorie.name}</MenuItem>
                            ))
                            }
                            
                    </Select>
                </FormControl>

                <FormControl className="header_form" >
                    <InputLabel id="demo-simple-select-helper-label">Filtre</InputLabel>
                    <Select value={search_filter}  onChange={(e)=>{set__filter(e.target.value)}}>
                        <MenuItem value="all">Aucun</MenuItem>
                        <MenuItem value='Object'>Objet d'appel</MenuItem>
                        <MenuItem value='Direction'>Direction</MenuItem>
                        <MenuItem value='valider'>Commission validée</MenuItem>
                        <MenuItem value='refuser'>Commission refusée</MenuItem>
                        <MenuItem value='encours'>Commission en cours de traitement</MenuItem>
                    </Select>
                    </FormControl>

                    <Grid container spacing={1} alignItems="flex-start" className={search_filter!=="Direction" && search_filter!=="Object"?"search_bar disabled_button":"search_bar" } >
                        <Grid item>
                            <SearchIcon onClick={search} className={search_value==""?"my_icon disabled_button":"my_icon" } style={{cursor:"pointer"}}  />
                        </Grid>
                        <Grid>
                            <TextField label="Chercher" value={search_value} onChange={(e)=>{setsearch_value(e.target.value)}}/>
                        </Grid>
                    </Grid>
            </div>
      </div>
      <div className="commissions_table_container">
        <TableContainer>
        <Table className={classes.table} aria-label="sticky table">
            <TableHead>
            <TableRow>
                {
                    columns.map((column) => (
                        <TableCell key={column.id} align="left">{column.label}</TableCell>
                    ))
                }
            </TableRow>
            </TableHead>
            <TableBody>
            {
                offers_commissions.length!==0
                ?<Commissions offers_commissions={offers_commissions} key={'commissions_table'} getCategorieById={getCategorieById} setOffers_commissions={setOffers_commissions} viewAuditorInfos={viewAuditorInfos} 
                            viewAuditor_commissionSuggestion={viewAuditor_commissionSuggestion} 
                            notify_auditor_again_withCommission={notify_auditor_again_withCommission} auditors_legal={auditors_legal}
                            auditors_financial={auditors_financial}
                            auditors_technique={auditors_technique} valide_change_commission_membre={valide_change_commission_membre} waiting={waiting} />
                :<div style={{margin:"10px", textAlign:"center",alignContent:"center",fontSize:'.75rem'}}>Aucune commission</div>
            }
            </TableBody> 
        </Table>
        </TableContainer>
        {
              offers_commissions.length!==0
                  ?<div className="pagination_container">
                      <Pagination
                          activePage={Number(currentPage)}
                          itemsCountPerPage={dataPerPage}
                          totalItemsCount={Number(data_count)}
                          pageRangeDisplayed={4}
                          onChange={change_page.bind(this)}
                      />
                  </div>
                  :<></>
              }
      </div>  


        {
            view_auditor_commission_suggestion!=null
            ?<Modal_dates_proposition view_auditor_commission_suggestion={view_auditor_commission_suggestion} 
                    setView_auditor_commission_suggestion={setView_auditor_commission_suggestion} departement={departement} />
            :<></>
        }

        {
            view_auditor_infos!=null
            ?<Membre_details_Dialog setView_auditor_infos={setView_auditor_infos} view_auditor_infos={view_auditor_infos} />
            :<></>
        }

        {
            notify_auditor_modal!=null
            ?<Notify_auditor_again_withCommission_modal notify_auditor_modal={notify_auditor_modal} setNotify_auditor_modal={setNotify_auditor_modal} departement={departement} />
            :<></>
        }

      <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={waiting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>

    </div>

    }
    </>
  );
}

export default My_commissions;
