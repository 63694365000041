import "./wf_modal_params.css"
import React, { useCallback, useState} from 'react'

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/Add';
import {makeStyles} from '@material-ui/core/styles';
import Form from 'react-bootstrap/Form'

import { Accordion } from 'react-bootstrap';
import PRM_permissions from "./prm_permissions";
import Pre_post_conditions from "./pre_post_conditions/pre_post_conditions";



const useStyles = makeStyles((theme) => ({
    button: { margin: theme.spacing(1),},
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
  }));


function Wf_modal_params({chosen_node,setchosen_node}){

    const classes = useStyles();
  

    const [state,setState]=useState(chosen_node.label);
    const [description,setdescription]=useState(chosen_node.description?chosen_node.description:"");
    const [permission_selected, setpermission_selected ]=useState([]);
    const [errorText,setErrorText] = useState("");

    function propose_commission() {    
        setErrorText("");
        if(state===""){
            setErrorText("Champ invalid !");
        }else{

        }
    }

    const mp = useCallback(()=>{
        //console.log(permission_selected)
    },[permission_selected])

    mp()

    return (
        <Dialog className='params_node_modal' fullWidth={true} maxWidth="sm" open={chosen_node?true:false} onClose={()=>setchosen_node(null)} aria-labelledby="form-dialog-title" >            
            <DialogTitle id="form-dialog-title">Paramètrage</DialogTitle>
            <DialogContent>
                <label htmlFor='reason'>État</label>
                    <Form.Group name="reason" style={{width: '100%',height: '100%',position: 'relative',padding:"0px"}} >
                        <Form.Control as="input" className="inputform" placeholder='État*' value={state} onChange={(e)=>setState(e.target.value)} />
                    </Form.Group>
                    
                <div  style={{margin:"15px 0px 15px 0px"}}>
                    <label htmlFor='reason'>Description (facultatif)</label>
                    <Form.Group name="reason" style={{width: '100%',height: '100%',position: 'relative',padding:"0px"}} >
                        <Form.Control as="textarea" placeholder='Description (facultatif)' rows={6} value={description} onChange={(e)=>setdescription(e.target.value)} />
                    </Form.Group>
                </div>

                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            Permissions
                        </Accordion.Header>
                        <Accordion.Body>
                            <PRM_permissions permission_selected={permission_selected} setpermission_selected={setpermission_selected}  />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            Pre-condition                         
                        </Accordion.Header>
                        <Accordion.Body>
                            <Pre_post_conditions />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            Post-condition                         
                        </Accordion.Header>
                        <Accordion.Body>
                            <Pre_post_conditions />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            Motif                         
                        </Accordion.Header>
                        <Accordion.Body>
                            <></>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            Assignation                         
                        </Accordion.Header>
                        <Accordion.Body>
                            <></>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            Notification                         
                        </Accordion.Header>
                        <Accordion.Body>
                            <></>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            Documents joints                         
                        </Accordion.Header>
                        <Accordion.Body>
                            <></>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

            </DialogContent>
            <DialogActions>
            <div className='dialogBottom'>
                <div>
                    {
                        <p className={errorText ? "col-md-10 error" : "col-md-10 valid-champ"}>
                                {errorText ? errorText : ""}
                            </p>
                    }
                </div>
                <div>
                   <Button variant="contained" className="submit_but cancel_but" startIcon={<CancelIcon className="my_icon" />} onClick={()=>setchosen_node(null)} >
                        Annuler
                    </Button>

                    <Button variant="contained" className="submit_but valide_but" startIcon={<AddIcon className="my_icon" />} onClick={propose_commission} >
                        Appliquer
                    </Button>
                
                </div>
            </div>      
                
            </DialogActions>
        </Dialog>
    )
}

export default Wf_modal_params
