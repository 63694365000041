
import './radialCharts_submissionsState.css'
import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const RadialCharts_submissionsState = ({setShowing_elements, elements_number, count_accorded, count_waiting, count_lost}) => {
    const [content_charged,setContent_charged]=useState(false);
    const [chart_data,setChart_data]=useState(
     [
        {
            series: [4],
            options: {
              chart: {
                type: 'radialBar',
                events: {
                  mounted: (chart) => {
                    chart.windowResizeHandler();
                  }
                }
              },
              fill: {
                colors:'#33688c'
              },
              plotOptions: {
                radialBar: {
                   startAngle: 0,
                   endAngle: 50000,
                   hollow: {
                    margin: 0,
                    background: '#fff',
                    image: undefined,
                    imageOffsetX: 0,
                    imageOffsetY: 0,
                    position: 'front',
                    dropShadow: {
                      enabled: true,
                      top: 3,
                      left: 0,
                      blur: 4,
                      opacity: 0.24
                    }
                  },
                  dataLabels: {
                    show: true,
                    name: {
                      offsetY: 0,
                      show: true,
                      color: '#888',
                      fontWeight:'700',
                    },
                    value: {
                      formatter: function(val) {
                        return parseInt(val);
                      },
                      color: '#111',
                      fontWeight:'700',
                      show: true,
                      offsetY: 4,
                    }
                  }
                }
              },
              labels: ['Total'],
            }
        },
        {
            series: [0],
            options: {
              chart: {
                type: 'radialBar',
                events: {
                  mounted: (chart) => {
                    chart.windowResizeHandler();
                  }
                }
              },
              fill: {
                colors:'#33b66e'
              },
              plotOptions: {
                radialBar: {
                   hollow: {
                    margin: 0,
                    background: '#fff',
                    image: undefined,
                    imageOffsetX: 0,
                    imageOffsetY: 0,
                    position: 'front',
                    dropShadow: {
                      enabled: true,
                      top: 3,
                      left: 0,
                      blur: 4,
                      opacity: 0.24
                    }
                  },
                  dataLabels: {
                    show: true,
                    name: {
                        offsetY: 0,
                        show: true,
                      color: '#888',
                      fontWeight:'700',
                    },
                    value: {
                      formatter: function(val) {
                        if(elements_number!==0) return Math.ceil(parseInt(val)/100 * elements_number);
                        else return 0;
                      },
                      color: '#111',
                      show: true,
                      offsetY: 4,
                      fontWeight:'700',
                    }
                  }
                }
              },
              labels: ['Adjugé'],
            }
        },
        {
            series: [0],
            options: {
              chart: {
                type: 'radialBar',
                events: {
                  mounted: (chart) => {
                    chart.windowResizeHandler();
                  }
                }
              },
              fill: {
                colors:'#ffa726'
              },
              plotOptions: {
                radialBar: {
                   hollow: {
                    margin: 0,
                    background: '#fff',
                    image: undefined,
                    imageOffsetX: 0,
                    imageOffsetY: 0,
                    position: 'front',
                    dropShadow: {
                      enabled: true,
                      top: 3,
                      left: 0,
                      blur: 4,
                      opacity: 0.24
                    }
                  },
                  dataLabels: {
                    show: true,
                    name: {
                        offsetY: 0,
                        show: true,
                      color: '#888',
                      fontWeight:'700',
                    },
                    value: {
                      formatter: function(val) {
                        if(elements_number!==0) return Math.ceil(parseInt(val)/100 * elements_number);
                        else return 0;
                      },
                      color: '#111',
                      show: true,
                      offsetY: 4,
                      fontWeight:'700'
                    }
                  }
                }
              },
              labels: ['En cours'],
            }
        },
        {
            series: [0],
            options: {
              chart: {
                type: 'radialBar',
                events: {
                  mounted: (chart) => {
                    chart.windowResizeHandler();
                  }
                }
              },
              fill: {
                colors:'#f33640f3'
              },
              plotOptions: {
                radialBar: {
                   hollow: {
                    margin: 0,
                    background: '#fff',
                    image: undefined,
                    imageOffsetX: 0,
                    imageOffsetY: 0,
                    position: 'front',
                    dropShadow: {
                      enabled: true,
                      top: 3,
                      left: 0,
                      blur: 4,
                      opacity: 0.24
                    }
                  },
                  dataLabels: {
                    show: true,
                    name: {
                        offsetY: 0,
                        show: true,
                      color: '#888',
                      fontWeight:'700',
                    },
                    value: {
                      formatter: function(val) {
                        if(elements_number!==0) return Math.ceil(parseInt(val)/100 * elements_number);
                        else return 0;
                      },
                      color: '#111',
                      show: true,
                      offsetY: 4,
                      fontWeight:'700'
                    }
                  }
                }
              },
              labels: ['Refusée'],
            }
        }
    ]
    );
        
    useEffect(()=>{
        let tmp_data = [...chart_data];
        tmp_data[0].series[0] = elements_number;
        if(count_accorded) tmp_data[1].series[0] = (count_accorded/elements_number) * 100;
        tmp_data[2].series[0] = (count_waiting/elements_number) * 100;
        tmp_data[3].series[0] = (count_lost/elements_number) * 100;
        setChart_data(tmp_data);
        setContent_charged(true);
    },[])
  
  return (
    <div className="radialCharts_offersState_container" onDoubleClick={() => setShowing_elements(false)} style={{cursor:'pointer'}} onTouchStartCapture={(e)=>{e.stopPropagation();setShowing_elements(false)}} >
        {
          content_charged!==false
          ?<>
            <div className="radialCharts_container">
                <ReactApexChart 
                    options={chart_data[0].options} 
                    series={chart_data[0].series} 
                    type="radialBar"
                    width="100%"
                    height="90%"/>
            </div>
            <div className="radialCharts_container">
                  <ReactApexChart 
                      options={chart_data[1].options} 
                      series={chart_data[1].series} 
                      type="radialBar"
                      width="100%"
                      height="90%"/>
            </div>
            
            <div className="radialCharts_container">
                <ReactApexChart 
                    options={chart_data[2].options} 
                    series={chart_data[2].series} 
                    type="radialBar"
                    width="100%"
                    height="90%"/>
            </div>

            <div className="radialCharts_container">
                <ReactApexChart 
                    options={chart_data[3].options} 
                    series={chart_data[3].series} 
                    type="radialBar"
                    width="100%"
                    height="90%"/>
            </div>
           </>
          :<></>
        }
    </div>
  );

}

export default React.memo(RadialCharts_submissionsState);