import './add_purchase_request.css'
import React, {useEffect,useState} from 'react';
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

import AddCommentIcon from '@mui/icons-material/AddComment';
import StoreIcon from '@mui/icons-material/Store';
import Products_gallery from './products_gallery/products_gallery';
import Loading_animation_view from '../../loading_animation_view/loading_animation_view';
import Request_off_catalogue from './request_off_catalogue/request_off_catalogue';


function Add_purchase_request() {

  let { type } = useParams();
  const [filter_request_type,setfilter_request_type]=useState(null);
  const [url_updated , setUrl_updated] = useState(null);

  let history = useHistory();


  useEffect(()=>{
      async function load_data(){
        await setfilter_request_type(null);
        if(type){
          await setfilter_request_type(type);
        }else await setfilter_request_type("all");
      } 
      load_data();
  },[url_updated,history.location]);


  function set__request_type(value){
    history.push("/serviceAchat/demande_achat/"+value);
    setUrl_updated(value);
  }

  return (
    <>
    {
      filter_request_type!==null
        ?<>
            {
            filter_request_type!=="all"
            ?<>
                {
                filter_request_type === "avec_catalogue"
                    ?<Products_gallery setUrl_updated={setUrl_updated} />
                    :filter_request_type === "sans_catalogue"
                          ?<Request_off_catalogue setUrl_updated={setUrl_updated} />
                          :<></>
                }
            </>
            :<div className="remains_widgets_container" style={{margin:'30px auto'}}>
                    <div className='widget_container' onClick={() => set__request_type('avec_catalogue')}>
                        <div className='widget_icon'>
                            <StoreIcon />
                        </div>
                        <div className='widget_title'>
                            Demande d'achat avec catalogue
                        </div>
                    </div>
                    <div className='widget_container' onClick={() => set__request_type('sans_catalogue')}>
                        <div className='widget_icon'>
                            <AddCommentIcon />
                        </div>
                        <div className='widget_title'>
                            Demande d'achat hors catalogue
                        </div>
                    </div>
            </div>
            }
         </>
         :<Loading_animation_view />
        }
    </>
  );
}

export default Add_purchase_request;