import React, { useState} from 'react'

import './notify_auditor_again_withCommission_modal.css'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import SendIcon from '@material-ui/icons/Send';
import CancelIcon from '@material-ui/icons/Cancel';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { notify_commission_membre } from '../../../../services/commission_service';




function Notify_auditor_again_withCommission_modal({notify_auditor_modal,setNotify_auditor_modal,departement}){

    const [waiting, setWaiting] = useState(false);

    function notify_auditor(){
        setWaiting(true);
        let formData = new FormData();
        formData.append("id_commission",notify_auditor_modal.id);
        formData.append("id_service",notify_auditor_modal.service_id);
        formData.append("offer_id",notify_auditor_modal.offer_id);
        notify_commission_membre(departement,formData).then((response)=>{
            setWaiting(false);
            setNotify_auditor_modal(null);
        }).catch((error) => {
            //console.log(error.response);
        });

    }
    
    return (
        <Dialog className="notify_auditor_again_dialog" fullWidth={true} maxWidth={'md'} open={notify_auditor_modal!=null?true:false} onClose={()=>setNotify_auditor_modal(null)} aria-labelledby="form-dialog-title" >
            <DialogTitle id="form-dialog-title">Voulez-vous notifier le membre une autre fois ?</DialogTitle>
            
            <DialogActions>
            <div className='dialogBottom'>
                <div>
                   <Button variant="contained" className="submit_but cancel_but" startIcon={<CancelIcon className="my_icon" />} onClick={()=>setNotify_auditor_modal(null)} >
                        Annuler
                    </Button>
                    <Button variant="contained" className="submit_but valide_but" startIcon={<SendIcon className="my_icon" />} onClick={notify_auditor} >
                        Envoyer
                    </Button> 
                </div>
            </div>      
                
            </DialogActions>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waiting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Dialog>
    )
}

export default Notify_auditor_again_withCommission_modal
