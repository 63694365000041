import './navBar.css';
import {Navbar,Form,NavDropdown} from 'react-bootstrap';
import React, {useState,useEffect } from 'react';
import pa_logo from './PA_logo.jpeg';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import {makeStyles} from '@material-ui/core/styles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { update_company_admin_profil } from '../../../services/company_admin_service';


const useStyles = makeStyles((theme) => ({
  button: { margin: theme.spacing(1),},
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));

function NavBar({menu_color,company_logo,setcompany_logo}) {

  let user = JSON.parse(localStorage.getItem('user-info'))
  const classes = useStyles();

  const [info,setInfo]=useState(null);

  const [waiting, setWaiting] = useState(false);
 
  useEffect(()=>{
      setInfo(user);
  },[]);



  function updatecompany_logo(file){
    if(file){
      setWaiting(true);
      const formData = new FormData();
      formData.append('id_login',user.id);
      formData.append('company_logo',file);
      update_company_admin_profil(formData).then((response)=>{
        setWaiting(false);
        setcompany_logo(response.data);
      }).catch((error) => {
        setWaiting(false);
        //console.log(error.response);
      })
    } 
  }

  return (
      <div id='myTopnav' className='service_topnav topnav' style={{width:"100%"}}>
        <Navbar bg="dark" variant="dark" style={{width:"100%",margin:'0px'}}>
        <div className="navbar_content_container" style={{ background:menu_color }}>
            <div className="company_logo_container">
              {
                  company_logo!==null
                  ?<>
                    <img src={  company_logo!==""?`data:image/*;base64,${company_logo}`:pa_logo } />
                    <Tooltip title="modifier le logo" placement="bottom" >
                        <span className="edit_container">
                            <input accept=".jpg,.png,.jpeg" className={classes.input} name="company_logo" id="company_logo" multiple type="file" onChange={(e)=>updatecompany_logo(e.target.files[0])} />
                            <label htmlFor="company_logo">
                              <span style={{cursor:'pointer'}}>
                                <EditIcon className="edit_but" />
                              </span>
                            </label>
                        </span>
                      </Tooltip>
                    </>
                  :<></>
              }
                
            </div>
            <div className="selection">
               <Form inline className='buttons'>
                  <div className='user_name_container' style={{width:'fit-content',display:'flex',marginRight:"25px",fontSize:"1rem"}}>
                      {info!=null?info.fullName:""}
                  </div>
                </Form>
            </div>
           
        </div>
        
      </Navbar>
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={waiting}
      >
          <CircularProgress color="inherit" />
      </Backdrop>
    </div>


  );
}

export default NavBar;
