import './bills_table.css';
import React, { useEffect,useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import Widget_count_elements from '../widget_count_elements/widget_count_elements'
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

const columns = [
    { id: 'code', label: "Code de marché", minWidth: 0 },
    { id: 'Objet', label: "Objet d'appel", minWidth: 0 },
    { id: 'supplier', label: "Fournisseur", minWidth: 0 },
    { id: 'amount', label: "Montant d'appel", minWidth: 0 },
    { id: 'bills_number', label: "Nombre de factures", minWidth: 0 }
  ];
  

function Markets({markets}){
    
    return(
        <>
        {
            markets.map((market,index1) => (
                <TableRow key={market.offer_id+"col"+index1}>
                    <TableCell align="left">{market.submission_id}</TableCell>
                    <TableCell align="left">{market.offer_object}</TableCell>
                    <TableCell align="left">{market.supplier_name}</TableCell>
                    <TableCell align="left">{market.offer_amount+" MAD"}</TableCell>
                    <TableCell align="center">{market.bills_number}</TableCell>
                </TableRow>                
             ))
        }
        </>
    );
}


function Markets_bills({data}) {

    const classes = useStyles();
    const [show_elemnts,setShowing_elements] = useState(false);



//////////////////////
/////////////////////////////////////////////////////////
///////////////////////////////////

  return (
    <>
    {
      show_elemnts!==true
        ?<Widget_count_elements setShowing_elements={setShowing_elements} elements_number={data.count} fontColor="#51a5cf" progress={data.progress}  />
        :<TableContainer className="bills_widget_table" component={Paper}  onDoubleClick={() => setShowing_elements(false)} style={{cursor:'pointer'}} onTouchStartCapture={(e)=>{e.stopPropagation();setShowing_elements(false)}} >
            <Table className={classes.table} aria-label="sticky table">
                <TableHead>
                <TableRow>
                    {
                        columns.map((column) => (
                            <TableCell key={column.id} align="left">{column.label}</TableCell>
                        ))
                    }
                </TableRow>
                </TableHead>
                <TableBody>
                <>
                    <Markets markets={data.last_markets} />
                </>
                </TableBody> 
            </Table>
        </TableContainer>
    }
    
      
    </>
    
  );
}

export default React.memo(Markets_bills);
