import "./docs_creator.css"
import React, { useCallback, useState} from 'react'

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/Add';
import {makeStyles} from '@material-ui/core/styles';
import Form from 'react-bootstrap/Form'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Accordion } from 'react-bootstrap';
import Docs_perms from "./docs_perms_WFTypes/docs_perms";
import Docs_WFTypes from "./docs_perms_WFTypes/docs_WFTypes";
import Docs_validation from "./docs_validation/docs_validation";
import swal from 'sweetalert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { create_doc } from "../../../../services/company_admin_service";



const useStyles = makeStyles((theme) => ({
    button: { margin: theme.spacing(1),},
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
  }));


function Docs_creator({add_doc_modal,setadd_doc_modal,docs,setdocs}){

    const classes = useStyles();
  

    const [doc_name,setdoc_name]=useState("");
    const [description,setdescription]=useState("");
    const [permission_selected, setpermission_selected ]=useState([]);
    const [doc_type, setdoc_type ]=useState("imported");
    const [wf_selected, setwf_selected ]=useState([]);
    const [doc_validation, setdoc_validation ]=useState([]);
    
    const [errorText,setErrorText] = useState("");
    const [waiting,setwaiting] = useState(false);

    function submit() {    
        setErrorText("");
        if(doc_name==="" || wf_selected.length===0  || permission_selected.length===0 ){
            setErrorText("Champ invalid !");
        }else{
            swal({
                title: "Etes-vous sûr ?",
                text: "!!!!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then((willDelete) => {
              if (willDelete) {
                setwaiting(true);
                let formData = new FormData();
                formData.append('doc_name',doc_name);
                formData.append('description',description);
                formData.append('doc_type',doc_type);
                formData.append('template',"");
                formData.append('permission',permission_selected);
                formData.append('validation',doc_validation);
                formData.append('workflows',wf_selected);
                create_doc(formData).then((response)=>{
                    swal("Le document a été ajoutée avec succès !", {
                            icon: "success",
                            buttons: true,
                        }).then((willDelete) => {
                            if (willDelete) {
                        }})
                        let tmp_arr = [...docs];
                        tmp_arr.push(response.data);
                        setdocs(tmp_arr);
                        setadd_doc_modal(false);
                        setwaiting(false);
                }).catch((error)=>{
                    setwaiting(false);
                    //console.log(error.response);
                })
              }
            })
        }
    }

    return (
        <Dialog className='params_node_modal' fullWidth={true} maxWidth="sm" open={add_doc_modal} onClose={()=>setadd_doc_modal(false)} aria-labelledby="form-dialog-title" >            
            <DialogTitle id="form-dialog-title">Nouveau document</DialogTitle>
            <DialogContent>
                <label htmlFor='reason'>Nom de document</label>
                    <Form.Group name="reason" style={{width: '100%',height: '100%',position: 'relative',padding:"0px"}} >
                        <Form.Control as="input" className="inputform" placeholder='Nom de document*' value={doc_name} onChange={(e)=>setdoc_name(e.target.value)} />
                    </Form.Group>
                    
                <div  style={{margin:"15px 0px 15px 0px"}}>
                    <label htmlFor='reason'>Description (facultatif)</label>
                    <Form.Group name="reason" style={{width: '100%',height: '100%',position: 'relative',padding:"0px"}} >
                        <Form.Control as="textarea" placeholder='Description (facultatif)' rows={6} value={description} onChange={(e)=>setdescription(e.target.value)} />
                    </Form.Group>
                </div>

                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            Type de document
                        </Accordion.Header>
                        <Accordion.Body>
                         <div className="docs_perms">
                            <div style={{marginBottom:'10px'}}>
                                Soit un document importé, soit un document à modifier à partir de l'éditeur de texte, soit un document basé sur un modèle importé (fichier docs) et à modifier à partir de l'éditeur de texte.                            
                            </div> 
                            <Form.Group className="col-md-12">
                                <Select required
                                    value={doc_type}
                                    className="form-control form-select form-select-control" 
                                    onChange={e => {
                                        setdoc_type(e.target.value);
                                    }} name="Etat" >
                                    <MenuItem value={"imported"}>Importé</MenuItem>
                                    <MenuItem value={"editor"}>Editeur de texte</MenuItem>
                                    <MenuItem value={"template"}>Modèle</MenuItem>
                                </Select>
                            </Form.Group>  
                         </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            Permissions                         
                        </Accordion.Header>
                        <Accordion.Body>
                            <Docs_perms permission_selected={permission_selected} setpermission_selected={setpermission_selected}  />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            Workflows                         
                        </Accordion.Header>
                        <Accordion.Body>
                            <Docs_WFTypes wf_selected={wf_selected} setwf_selected={setwf_selected} />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            Validation                         
                        </Accordion.Header>
                        <Accordion.Body>
                            <Docs_validation doc_validation={doc_validation} setdoc_validation={setdoc_validation} />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

            </DialogContent>
            <DialogActions>
            <div className='dialogBottom'>
                <div>
                    {
                        <p className={errorText ? "col-md-10 error" : "col-md-10 valid-champ"}>
                                {errorText ? errorText : ""}
                            </p>
                    }
                </div>
                <div>
                   <Button variant="contained" className="submit_but cancel_but" startIcon={<CancelIcon className="my_icon" />} onClick={()=>setadd_doc_modal(false)} >
                        Annuler
                    </Button>

                    <Button variant="contained" className="submit_but valide_but" startIcon={<AddIcon className="my_icon" />} onClick={submit} >
                        Ajouter
                    </Button>
                
                </div>
            </div>      
                
            </DialogActions>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waiting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Dialog>
    )
}

export default Docs_creator
