import React, {useEffect,useState} from 'react'
import { useHistory } from 'react-router';
import Markets_bills from './markets_bills/markets_bills';
import Orders_bills from './orders_bills/orders_bills';


function Bills() {
  const [filter_bills_src,setFilter_bills_src]=useState(null);
  const [url_updated , setUrl_updated] = useState(null);
  let history = useHistory();

  useEffect(()=>{
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      var tmp_filter_bills_src = "marchés";
      async function load_data(){
        await setFilter_bills_src(null);    
        if(urlParams.get('src')){
          await setFilter_bills_src(urlParams.get('src'));
          tmp_filter_bills_src = urlParams.get('src');
        }else await setFilter_bills_src(tmp_filter_bills_src);
      }
      load_data();

  },[url_updated,history.location]);

  return (
    <>
    {
      filter_bills_src!==null
      ?<>
        {
          filter_bills_src === "marchés"
            ?<Markets_bills filter_bills_src={filter_bills_src} setUrl_updated={setUrl_updated} />
            :<Orders_bills filter_bills_src={filter_bills_src} setUrl_updated={setUrl_updated} />
        }
      </>
      :<></>
    }
    </>
  );
}

export default Bills;