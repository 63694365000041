import './delay_of_market.css';
import React, { memo, useEffect,useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import Widget_count_elements from '../widget_count_elements/widget_count_elements'
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

const columns = [
    { id: 'offerCode', label: "Code d'appel", minWidth: 0 },
    { id: 'marketCode', label: "Code de marché", minWidth: 0 },
    { id: 'creation_date', label: "Date de création d'appel", minWidth: 0 },
    { id: 'close_date', label: "Date de clôture", minWidth: 0 },
    { id: 'delay', label: "Délai", minWidth: 0 },
  ];
  

function Markets({markets}){
    
    return(
        <>
        {
            markets.map((market,index1) => (
                <TableRow key={market.offer_id+"col"+index1}>
                    <TableCell align="left">{market.offer_infos.offer_id}</TableCell>
                    <TableCell align="left">{market.offer_infos.closed}</TableCell>
                    <TableCell align="left">{(new Date(market.offer_infos.offer_date)).toLocaleString().split(/[,| ]/)[0]+" "+(new Date(market.offer_infos.offer_date)).toLocaleString().split(/[,| ]/)[1]}</TableCell>
                    <TableCell align="left">{(new Date(market.offer_infos.closed_date)).toLocaleString().split(/[,| ]/)[0]+" "+(new Date(market.offer_infos.closed_date)).toLocaleString().split(/[,| ]/)[1]}</TableCell>
                    <TableCell align="center">{market.delay+" jrs"}</TableCell>
                </TableRow>                
             ))
        }
        </>
    );
}


const Delays = ({data}) => {

    const classes = useStyles();
    const [show_elemnts,setShowing_elements] = useState(false);
  

//////////////////////
/////////////////////////////////////////////////////////
///////////////////////////////////

  return (
    <React.Fragment>
    {
      show_elemnts!==true
        ?<Widget_count_elements setShowing_elements={setShowing_elements} elements_number={data.avg+" jrs"} fontColor="#51a5cf" progress={data.progress} />
        :<TableContainer className="delay_of_market_widget" component={Paper} onDoubleClick={() => setShowing_elements(false)} style={{cursor:'pointer'}} onTouchStartCapture={(e)=>{e.stopPropagation();setShowing_elements(false)}} >
            <Table className={classes.table} aria-label="sticky table">
                <TableHead>
                <TableRow>
                    {
                        columns.map((column) => (
                            <TableCell key={column.id} align="left">{column.label}</TableCell>
                        ))
                    }
                </TableRow>
                </TableHead>
                <TableBody>
                <>
                    <Markets markets={data.last_markets} />
                </>
                </TableBody> 
            </Table>
        </TableContainer>
    }
    
      
    </React.Fragment>
    
  );
}


export default React.memo(Delays);

